import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { setTariffs } from '../../slices'
import { TariffsTable } from '../table'
import { TariffsFilter } from '../filter'

import { tariffsDashboardStyles } from './tariffs-dashboard.styles'
import { TARIFFS_DEFAULT_QUERY } from '../../constants'

export const TariffsDashboard = withStyles(tariffsDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  //Acquire url and parse its search options
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...TARIFFS_DEFAULT_QUERY,
    ...urlSearchParams,
  }
  useEffect(() => {
    dispatch(setTariffs(searchParams))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.tariffs.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <TariffsFilter searchParams={searchParams} />
        </Box>
        <Box>
          <TariffsTable />
        </Box>
      </Container>
    </Box>
  )
})
