import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PrivateLayoutComponent } from '../../ui'
import { GlobalAlert } from '../components'
import { usePrivateRoutes } from './router.hook'

export const PrivateRouter = () => {
  const { getPrivateRoutes } = usePrivateRoutes()
  const router = getPrivateRoutes()

  return (
    <PrivateLayoutComponent>
      <GlobalAlert />
      <Switch>
        {router.routes.map((r) => (
          <Route key={r.path} path={r.path} component={r.component} />
        ))}
        <Redirect from="*" to={router.redirect} />
      </Switch>
    </PrivateLayoutComponent>
  )
}
