import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useJCACalls = () => {
  const { getById } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getJCACall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/jca', id, { timeout: 0 })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getJCACall,
  }
}
