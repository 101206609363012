import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { MarketerUsersDashboard } from '../../../features/marketer-users'
import { MarketerUsersDetail } from '../../../features/marketer-users/components/detail'
import { USERS_COMBOS_IDS } from '../constants'
import { useCombos } from '../../../features/combos/hooks'

export const MarketerUsersPage = ({ classes = {} }) => {
  useCombos(USERS_COMBOS_IDS)

  return (
    <Switch>
      <Route exact path="/marketerUsers" component={MarketerUsersDashboard} />
      <Route exact path="/marketerUsers/:userId" component={MarketerUsersDetail} />
      <Redirect from="*" to="/marketerUsers" />
    </Switch>
  )
}
