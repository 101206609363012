import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Grid, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { SelectInput, DateInput, FileUploaderInput } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'
import { AnomaliesInput } from './anomalies-input.component'
import { JCAInspCombustion } from './jca-insp-combustion.component'
import { COMBUSTION_ANOM_CODES } from '../../constants'

export const JCAInspInfoBlock = () => {
  const { formatMessage } = useIntl()
  const { formFields, addresses, changeAddress, formState } = useInspectionsContext()

  return (
    <>
      {addresses.map((address, i) => (
        <React.Fragment key={i}>
          {addresses.length > 1 ? (
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                {address.cod_derivacion}
              </Typography>
            </Grid>
          ) : null}
          {!formState.nueva_direccion ? (
            <Grid item xs={12}>
              <AnomaliesInput {...{ address, addressIndex: i }} />
              {address.solvedAnomalies &&
              address.solvedAnomalies.length !== 0 &&
              address.solvedAnomalies?.length !== address.anomalias.length ? (
                <Alert severity="warning">
                  {formatMessage({ id: 'pages.inspections.feedback.17' })}
                </Alert>
              ) : null}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SelectInput
              {...{
                ...formFields.SITUACION_SUMINISTRO,
                value: address.situacion_suministro,
                onChange: (e) => changeAddress(e, i),
              }}
            />
          </Grid>
          {address.solvedAnomalies?.some((a) => COMBUSTION_ANOM_CODES[a.cod_anomalia]) ? (
            <Grid item xs={12}>
              <JCAInspCombustion {...{ addressKey: i }} />
            </Grid>
          ) : null}
          {addresses.length > 1 && i === addresses.length - 1 ? (
            <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
          ) : null}
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <DateInput {...{ ...formFields.FECHA_JCA }} />
      </Grid>
      <Grid item xs={12}>
        <FileUploaderInput {...{ ...formFields.JUSTIFICANTE }} />
      </Grid>
    </>
  )
}
