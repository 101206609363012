import React, { useReducer } from 'react'

import { modalReducer } from './reducers'
import { ModalProvider } from './contexts'
import { DEFAULT_STATE } from './constants'
import { ModalComponent } from './components'

export const Modal = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, DEFAULT_STATE)

  const actions = {
    open: (payload) => dispatch({ payload, type: 'OPEN' }),
    close: () => dispatch({ payload: DEFAULT_STATE, type: 'CLOSE' }),
  }

  return (
    <ModalProvider value={{ actions, ...state }}>
      <ModalComponent />
      {children}
    </ModalProvider>
  )
}
