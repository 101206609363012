import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ADMIN_ROLES } from '../../../../core/constants'
import { TextInput, SelectInput, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos'
import { CompanyLogo } from '../../../company-logo'
import { COMPANY_FORM_CONFIG, INSTALLER_WORKS_OPTIONS } from '../../constants'
import { useCompanyProfileContext } from '../../contexts'
import { CoporateIdentityPanel } from '../corporate-identity-panel'
import { CompanyFinance, CompanyPublicInput } from '../company-public'
import { CompanyWorksChecklist } from '../company-works-checklist'
import { IbanStateIndicator } from './iban-state-indicator.component'
import { IPInstallers } from './ip-installers.component'
import { PrivacyPolicyCheckbox } from './privacy-policy-checkbox.component'
import { CompanyDocuments } from '../company-documents'
import { PublicOfferForm } from '../public-offer-form'
import { CompanyAddressForm } from '../company-address-form'
import { CompanyGuildIP } from '../company-guild-ip'

export const PrimaryCompanyForm = () => {
  const { company, formState, changeFormState, savingCompany } = useCompanyProfileContext()
  const { error, updateInstallerPrimaryCompany } = useCompanyProfileContext()
  const intl = useIntl()
  const combos = useCombos([])
  const global = useSelector((state) => state.global)
  const isAdmin = ADMIN_ROLES[global.role]
  const inputProps = { formState, onChange: changeFormState, combos, intl }
  const bloqueo_negocio = formState.bloqueo_negocio

  const handleSubmitForm = (e) => {
    e.preventDefault()
    updateInstallerPrimaryCompany()
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid container spacing={3}>
        {isAdmin ? (
          <Grid item xs={12}>
            <CompanyWorksChecklist
              {...{ options: INSTALLER_WORKS_OPTIONS, formState, onChange: changeFormState }}
            />
          </Grid>
        ) : null}
        {formState.captadora && formState.publica === '01' ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={company.grabadora && (isAdmin || (!isAdmin && company.gremio_ip)) ? 6 : 12}
          >
            <CompanyLogo {...{ model: formState, onChange: changeFormState, disabled: !isAdmin }} />
          </Grid>
        ) : null}
        {company.grabadora && (isAdmin || (!isAdmin && company.gremio_ip)) ? (
          <Grid item sm={12} md={company.captadora && company.publica === '01' ? 6 : 12}>
            <Box display="flex" justifyContent="center">
              <CompanyGuildIP />
            </Box>
          </Grid>
        ) : null}
        {formState.captadora && isAdmin && !bloqueo_negocio ? (
          <>
            <Grid item xs={12} sm={6}>
              <CompanyPublicInput />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <CompanyFinance />
        </Grid> */}
          </>
        ) : null}
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).NOMBRE, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).RAZON, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CIF, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).TELEFONO, readOnly: !isAdmin }} />
        </Grid>
        {formState.captadora ? (
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).WEB, readOnly: !isAdmin }} />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).EMAIL, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12}>
          <CompanyAddressForm {...{ inputProps, readOnly: !isAdmin }} />
        </Grid>
        {formState.grabadora ? (
          <>
            <Grid item xs={12} sm={6}>
              <SelectInput
                {...{ ...COMPANY_FORM_CONFIG(inputProps).CATEGORIA, readOnly: !isAdmin }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).IBAN, readOnly: !isAdmin }} />
              {isAdmin ? <IbanStateIndicator {...{ model: company }} /> : null}
            </Grid>
          </>
        ) : null}
        {formState.captadora ? (
          <>
            {!isAdmin && formState.publica === '01' && formState.acepta_financiacion === '01' ? (
              <Grid item xs={12} sm={6}>
                <SelectInput
                  {...{ ...COMPANY_FORM_CONFIG(inputProps).ACEPTA_F, readOnly: !isAdmin }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12}>
              <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CCAA, readOnly: !isAdmin }} />
            </Grid>
            {formState.publica === '01' ? (
              <Grid item xs={12}>
                <TextInput
                  {...{ ...COMPANY_FORM_CONFIG(inputProps).DESCRIPCION, readOnly: !isAdmin }}
                />
              </Grid>
            ) : null}
          </>
        ) : null}
        {formState.captadora ? (
          <Grid item xs={12}>
            <PublicOfferForm {...{ isPrimaryCompany: true }} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <CompanyDocuments {...{ isPrimaryCompany: true }} />
        </Grid>
        {company.grabadora && formState.grabadora && formState.grabadora && isAdmin ? (
          <Grid item xs={12}>
            <IPInstallers {...{ company: formState }} />
          </Grid>
        ) : null}
        {formState.captadora ? (
          <Grid item xs={12}>
            <PrivacyPolicyCheckbox company={company} />
          </Grid>
        ) : null}
        {formState.captadora ? (
          <Grid item xs={12}>
            <CoporateIdentityPanel {...{ disabled: !isAdmin }} />
          </Grid>
        ) : null}
      </Grid>
      {error.message ? (
        <Box mt={2} mb={1}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      ) : null}
      {isAdmin ? (
        <Box display="flex" justifyContent="center" width="100%" mt={4}>
          <ThemeButton
            color="primary"
            type="submit"
            disabled={savingCompany}
            loading={savingCompany}
          >
            {intl.formatMessage({ id: 'pages.profile.company.button.save' })}
          </ThemeButton>
        </Box>
      ) : null}
    </form>
  )
}
