import React from 'react'
import { Typography, FormGroup } from '@material-ui/core'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export const CompanyWorksChecklist = ({ options, formState, onChange }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target
    if (options.some((option) => formState[option.key] && option.key !== name)) {
      onChange({ target: { name, value: checked } })
    }
  }

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.key}
          style={{ pointerEvents: 'none' }}
          control={
            <Checkbox
              style={{ pointerEvents: 'auto', padding: 10 }}
              color="primary"
              checked={!!formState[option.key]}
              onChange={handleChange}
              name={option.key}
            />
          }
          label={<Typography variant="body1">{option.label}</Typography>}
        />
      ))}
    </FormGroup>
  )
}
