import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { requests } from '../../../../core/ajax'
import { ThemeButton, useModalContext } from '../../../../ui'
import { CommunicationsDialog } from './communications-dialog.component'

export const CommunicationsBtn = ({ application }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { get } = requests

  const [unreadMssgs, setUnreadMssgs] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleOpenCommsDialog = () => {
    open({
      Component: CommunicationsDialog,
      data: { application, checkUnreadComms },
      type: 'responsive',
      scroll: 'paper',
    })
  }

  const checkUnreadComms = () => {
    setLoading(true)
    get(`/comunicacion_interna/${application.id}/comunicaciones_pendientes`)
      .then(({ data }) => {
        setUnreadMssgs(data.comunicaciones_pendientes > 0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    checkUnreadComms()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeButton
      color={unreadMssgs ? 'secondary' : 'primary'}
      size="small"
      onClick={handleOpenCommsDialog}
      startIcon={unreadMssgs ? <NotificationsActiveIcon /> : null}
      disabled={loading}
    >
      {formatMessage({
        id: 'pages.application.detail.commsBtn.title',
      })}
    </ThemeButton>
  )
}
