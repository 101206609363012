import { useCallback } from 'react'

import { requests } from '../../../core/ajax'

export const useExportToXLSCalls = () => {
  const { get } = requests

  const requestNewExportByEmail = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        try {
          const {
            data: { status },
          } = await get('extracciones/en_marcha_instalador').catch((err) => {
            throw err
          })
          if (status) {
            return reject('already started')
          }
          resolve()
        } catch (err) {
          return reject(err)
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  return {
    requestNewExportByEmail,
  }
}
