export const IP_ACTIONS_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_IP: 'SET_IP',
}

export const IP_INITIAL_STATE = {
  loading: false,
  error: false,
  ip: {},
}
