import React, { createContext, useContext } from 'react'
import { useBoilersReducer } from '../hooks'

const BoilersContext = createContext()

export const BoilersContextProvider = ({ children }) => {
  const reducer = useBoilersReducer()
  return <BoilersContext.Provider value={reducer}>{children}</BoilersContext.Provider>
}

export const useBoilersContext = () => useContext(BoilersContext)
