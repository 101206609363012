import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const marketerUsersSlice = createSlice({
  name: 'marketerUsers',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedUser: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUsersData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload
    },
  },
})

export const marketerUsersReducer = marketerUsersSlice.reducer

export const { setLoading, setUsersData } = marketerUsersSlice.actions

export const setUsers = (searchParams, comercializadora_id) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 20, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }

  dispatch(setLoading(true))
  get(`comercializadoras/${comercializadora_id}/tabla_usuarios`, config)
    .then(({ data }) => {
      dispatch(
        setUsersData({
          data: data.usuarios,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_usuarios: data['total_usuarios'],
          },
        })
      )
    })
    .catch(({ response }) => {
      console.error('Error recuperando empresas comercializadoras', response)
      dispatch(setLoading(false))
    })
}

export const toggleUserActive = (row, users) => (dispatch) => {
  const { putById } = requests

  putById('/instalador', row['id'], { ...row, activo: !row['activo'] })
    .then(({ data }) => {
      const newUsers = users.slice()
      newUsers[row.tableData.id] = { ...newUsers[row.tableData.id], activo: data['activo'] }
      dispatch(
        setUsersData({
          data: newUsers,
        })
      )
    })
    .catch(() => {
      console.error('Error changing user state')
    })
}
