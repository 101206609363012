import React, { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

import { requests } from '../../../../core/ajax'
import { SelectInput } from '../../../../ui'
import { MessageItem } from './message-item.component'
import { Sender } from './sender.component'
import { useCommunicationsCalls } from './communications-calls.hook'

export const Communications = ({ application, profile, channels }) => {
  const { formatMessage } = useIntl()
  const { get } = requests
  const { readMessage } = useCommunicationsCalls()

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [target, setTarget] = useState(channels[0])

  const getMessages = useCallback((target) => {
    setLoading(true)
    let params = { _num_x_pagina: 1000 }
    if (target.sender) params['remitente'] = target.sender
    get(`/comunicacion_interna/${application.id}/${target.receiver}`, { params })
      .then(({ data }) => {
        const sortedMessages = data.comentarios.sort(
          (a, b) => new Date(b['fecha']) - new Date(a['fecha'])
        )
        setMessages(sortedMessages)
        setLoading(false)
      })
      .catch(() => {
        setError(formatMessage({ id: 'pages.application.detail.communications.error' }))
        setLoading(false)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const markAsRead = (mssgId) => {
    const payload = { comunicacion_id: mssgId }
    readMessage(payload).then(() => {
      const nextMessages = messages.map((message) => {
        if (message.id === mssgId) return { ...message, leido: true }
        else return message
      })
      setMessages(nextMessages)
    })
  }

  useEffect(() => getMessages(target), [target, application, getMessages])

  const handleChangeProfile = (e) => {
    channels.forEach((channel) => {
      if (channel.key === e.target.value) setTarget(channel)
    })
  }

  return (
    <Box width="100%">
      {channels.length > 1 ? (
        <Box width="100%">
          <SelectInput
            fullWidth
            name="target"
            value={target.key}
            values={channels}
            onChange={handleChangeProfile}
          />
        </Box>
      ) : null}
      {!target.readOnly ? (
        <Sender {...{ application, target: target.receiver, getMessages: () => getMessages(target) }} />
      ) : null}
      <Box width="100%" overflow="auto">
        {loading ? (
          <Box display="flex" height={100} justifyContent="center" alignItems="center">
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Box p={2}>
            <Typography variant="body1" paragraph>
              {error}
            </Typography>
          </Box>
        ) : (
          <List>
            {messages.length ? (
              messages.map((message, i) => {
                return (
                  <MessageItem
                    {...{ key: i, message, profile, markAsRead, checkeable: !target.readOnly }}
                  />
                )
              })
            ) : (
              <ListItem>
                <ListItemText
                  primary={formatMessage({ id: 'pages.application.detail.communications.empty' })}
                />
              </ListItem>
            )}
          </List>
        )}
      </Box>
    </Box>
  )
}
