export const APPLICATION_STATES = {
  CREATED: {
    KEY: '10',
    DESCRIPTION: 'The application has been created. Its recording into the ZEUS system is pending',
  },
  NOT_ASSIGNED: {
    KEY: '15',
    DESCRIPTION:
      'The application has been handled by the batch process (ZEUS) or by an SSPP manager. Now it has to be assigned to a manager',
  },
  ASSIGNED: {
    KEY: '20',
    DESCRIPTION:
      'The application has been assigned to a manager who will validate it and create a budget',
  },
  BUDGET_CREATED: {
    KEY: '25',
    DESCRIPTION:
      'The manager assigned to application has created a budget and now is ready to be sended to the client',
  },
  BUDGET_SENDED: {
    KEY: '30',
    DESCRIPTION:
      'The application has been sent to the client and now needs to be approved by the client',
  },
  BUDGET_ACCEPTED: {
    KEY: '35',
    DESCRIPTION:
      'The client has accepted the budget. The budget needs to be paid by the client (L>6)',
  },
  PAYMENT_VALIDATION: {
    KEY: '36',
    DESCRIPTION: 'The client has paid and now the manager has to validate the payment',
  },
  ORDER_PENDING: {
    KEY: '37',
    DESCRIPTION: 'The application needs the GODA order code',
  },
  BUDGET_PAID: {
    KEY: '40',
    DESCRIPTION:
      'The application has the GODA order code. The budget doesnt need to be paid (L<6) or the client has already paid (L>6). Now is necessary to attach a certificate',
  },
  CERTIFICATE_ATTACHED: {
    KEY: '45',
    DESCRIPTION: 'The client or the installer has attached a valid certificate',
  },
  CERTIFICATE_REJECTED: {
    KEY: '50',
    DESCRIPTION:
      'The SSPP has refejected the certificate and client or installer needs to attach a valid certificate',
  },
  CERTIFICATE_PROCESSED: {
    KEY: '60',
    DESCRIPTION:
      'The SSPP has validated and processed the certificated. The application is ready and the installation can begin',
  },
  FINISHED: {
    KEY: '90',
    DESCRIPTION: 'The installation process has finished',
  },
  REVIEW: {
    KEY: '00',
    DESCRIPTION: 'The client has problems with the application and the manager has to check it',
  },
  REJECTED_SURVEY: {
    KEY: '01',
    DESCRIPTION: 'The rejected application has a survey provided by client',
  },
  REJECTED: {
    KEY: '03',
    DESCRIPTION: 'The application has been rejected definetly',
  },
  REJECTED_2: {
    KEY: '04',
    DESCRIPTION: 'The application has been rejected definetly (rejected from certificate states)',
  },
  REJECTED_NORMALIZATION: {
    KEY: '08',
    DESCRIPTION: 'The application needed of normalization has been rejected',
  },
  NORMALIZATION: {
    KEY: '09',
    DESCRIPTION: 'The application (associated home) needs to be normalized',
  },
  NORMALIZATION_PROCESSED: {
    KEY: '88',
    DESCRIPTION: 'The application (associated home) that needs to be normalized is processed',
  },
  CANCEL_PENDING: {
    KEY: '06',
    DESCRIPTION: 'Zeus must accept or reject one cancel requests',
  },
  VALIDATION_PENDING: {
    KEY: '05',
    DESCRIPTION: 'That request must be approve',
  },
  CLIENT_CANCEL_PENDING: {
    KEY: '07',
    DESCRIPTION: 'The client must accept or reject one cancel request',
  },
}
