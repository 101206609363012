import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TextInput, SelectInput, FileUploaderInput, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos'

const TEXT_AREA_LENGTH = 250

export const AbortJCADialog = ({ close, submitNonconformity, instType }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const combos = useCombos(['motivos_no_conformidad_jca'])

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})
  const [errorMssg, setErrorMssg] = useState('')
  const [finished, setFinished] = useState(false)

  const handleChangeForm = (e) => {
    setErrorMssg('')
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (
      !formState.justificante_jca &&
      (instType === 'IRC' || formState.motivo === '2' || formState.motivo === '3')
    ) {
      setErrorMssg(formatMessage({ id: 'pages.inspections.abortJCADialog.feedback.1' }))
    } else {
      setLoading(true)
      submitNonconformity(formState)
        .then(() => {
          setLoading(false)
          setFinished(true)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const handleFinish = () => {
    history.push('/jca')
    close()
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({
              id: `pages.inspections.abortJCADialog.title.${!finished ? '1' : '2'}`,
            })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!finished ? (
          <form onSubmit={handleSubmit}>
            <Typography variant="subtitle1">
              {formatMessage({
                id: `pages.inspections.abortJCADialog.description.${
                  !instType === 'IRC' ? '1' : '2'
                }`,
              })}
            </Typography>
            <Box pt={2}>
              <Grid container spacing={3}>
                {instType !== 'IRC' ? (
                  <Grid item xs={12}>
                    <SelectInput
                      required={true}
                      fullWidth
                      name="motivo"
                      value={formState.motivo}
                      values={combos.motivos_no_conformidad_jca?.data || []}
                      onChange={handleChangeForm}
                    />
                    {formState.motivo === '1' ? (
                      <Alert severity="info">
                        {formatMessage({
                          id: 'pages.inspections.abortJCADialog.feedback.2',
                        })}
                      </Alert>
                    ) : null}
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextInput
                    name="observaciones"
                    value={formState.observaciones}
                    placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
                    multiline={true}
                    onChange={handleChangeForm}
                    rows={4}
                    rowsMax={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileUploaderInput
                    value={formState.justificante_jca}
                    name="justificante_jca"
                    title={formatMessage({
                      id: 'pages.inspections.abortJCADialog.fields.justificante_jca.title',
                    })}
                    fileProps={{
                      maxSize: 10000000, // 10Mb
                      accept: ['application/pdf'],
                    }}
                    onChange={handleChangeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileUploaderInput
                    value={formState.certificado_ip}
                    name="certificado_ip"
                    title={formatMessage({
                      id: 'pages.inspections.abortJCADialog.fields.certificado_ip.title',
                    })}
                    fileProps={{
                      maxSize: 10000000, // 10Mb
                      accept: ['application/pdf'],
                    }}
                    onChange={handleChangeForm}
                  />
                </Grid>
              </Grid>
            </Box>
            {errorMssg && (
              <Box mt={2} mb={1}>
                <Alert severity="error">{errorMssg}</Alert>
              </Box>
            )}
            <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
              <Box width={150} mb={[3, 0]}>
                <ThemeButton onClick={close} color="inherit" disabled={loading} fullWidth>
                  {formatMessage({ id: 'global.cancel' })}
                </ThemeButton>
              </Box>
              <Box width={150}>
                <ThemeButton
                  type="submit"
                  color="primary"
                  fullWidth
                  loading={loading}
                  disabled={loading}
                >
                  {formatMessage({ id: 'global.confirm' })}
                </ThemeButton>
              </Box>
            </Box>
          </form>
        ) : (
          <>
            <Typography variant="body1">
              {formatMessage({ id: 'pages.inspections.abortJCADialog.description.3' })}
            </Typography>
            <Box display="flex" justifyContent="center" mt={5}>
              <ThemeButton onClick={handleFinish} color="primary" labelM="md">
                {formatMessage({ id: 'pages.inspections.abortJCADialog.btns.finish.title' })}
              </ThemeButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Box>
  )
}
