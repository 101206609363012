import React from 'react'
import { Box } from '@material-ui/core'
import { FilterURL } from '../../../../ui'
import { BOILERS_SELECTORS } from '../../constants'
import { useBoilersContext } from '../../contexts'

export const BoilersSelectors = () => {
  const state = useBoilersContext()
  return (
    <Box display="flex" flexWrap="wrap">
      {BOILERS_SELECTORS.map((filter, i) => {
        return (
          <Box key={i} mr={2} mb={1}>
            <FilterURL
              {...filter}
              value={state[filter.name]}
              values={state[filter.selector]?.map((el) => {
                return { key: el[filter.name], value: el[filter.name] }
              })}
            />
          </Box>
        )
      })}
    </Box>
  )
}
