import React from 'react'
import { Container } from '@material-ui/core'
import { OffersPanel } from '../../../features/offers/components/offers-panel/offers-panel.component'

export const OffersPage = () => {
  return (
    <Container maxWidth="lg">
      <OffersPanel />
    </Container>
  )
}
