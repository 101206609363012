import React from 'react'
import { useIntl } from 'react-intl'

import { useModalContext, ThemeButton } from '../../../../../ui'
import { EditClientIBANDialog } from '../../dialogs'

export const EditClientIBANBtn = (props) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenModal = () =>
    open({
      Component: EditClientIBANDialog,
      data: props,
      type: 'responsive',
    })

  return (
    <ThemeButton size="small" color="primary" onClick={handleOpenModal}>
      {formatMessage({ id: 'pages.application.detail.editClientIBAN.btn.title' })}
    </ThemeButton>
  )
}
