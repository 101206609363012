import React from 'react'
import { Tooltip, Icon, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { useCombos } from '../../../combos'

export const IbanStateIndicator = ({ model }) => {
  const combos = useCombos(['estados_iban'])

  const label = model.estado_iban
    ? combos?.estados_iban?.data.find((o) => o.key === model.estado_iban).value
    : 'Introduce un IBAN si quieres realizar Inspecciones Periódicas (IP)'

  const getSeverityProps = (state, model) => {
    const extraMssg =
      model.iban_validado && model.d_certificado_iban_validado_id
        ? `Se utilizará el anterior IBAN (${model.iban_validado}) hasta que el nuevo IBAN sea validado.`
        : ''
    switch (state) {
      case '10':
        return {
          severity: 'info',
          description:
            'El IBAN y el certificado IBAN son necesarios para registrar Inspecciones Periódicas (IP). No es necesario si la empresa solo realiza Justificantes de Correción de Anomalías (JCA).',
        }
      case '15': {
        return {
          severity: 'warning',
          description: `El IBAN está pendiente de validación. ${extraMssg}`,
        }
      }
      case '20':
        return {
          severity: 'success',
          description: 'El IBAN es válido y se pueden registrar Inspecciones Periódicas.',
        }
      case '01':
        return {
          severity: 'error',
          description: `El IBAN ha sido rechazado. Por favor, introduce un IBAN válido. ${extraMssg}`,
        }
      default:
        return {
          severity: 'info',
          description:
            'El IBAN y el certificado IBAN son necesarios para registrar Inspecciones Periódicas (IP). No es necesario si la empresa solo realiza Justificantes de Correción de Anomalías (JCA).',
        }
    }
  }

  const { severity, description } = getSeverityProps(model.estado_iban, model)

  return (
    <Alert severity={severity}>
      <span>
        {label}
        <Tooltip
          arrow
          title={
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {description}
            </Typography>
          }
        >
          <Icon style={{ marginLeft: 4, fontSize: 'inherit' }}>help_outline</Icon>
        </Tooltip>
      </span>
    </Alert>
  )
}
