import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'
import { CompanyLogo } from '../../../company-logo'
import { useCompanyProfileContext } from '../../contexts'
import { MARKETER_FORM_FIELDS } from './marketer-form.constants'

export const MarketerForm = () => {
  const { formatMessage } = useIntl()
  const { formState, changeFormState, updateMarketerCompany } = useCompanyProfileContext()

  const [loading, setLoading] = useState(false)

  const handleSubmitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    updateMarketerCompany()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const inputProps = { formState, onChange: changeFormState, fm: formatMessage }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={4} mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CompanyLogo {...{ model: formState, onChange: changeFormState }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).NOMBRE} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).RAZON_SOCIAL} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).TELEFONO} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).EMAIL} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).WEB} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).DIRECCION} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).CIF} />
          </Grid>
          <Grid item xs={12}>
            <TextInput {...MARKETER_FORM_FIELDS(inputProps).DESCRIPCION} />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton type="submit" loading={loading}>
          {formatMessage({ id: 'pages.profile.marketer.button.save' })}
        </ThemeButton>
      </Box>
    </form>
  )
}
