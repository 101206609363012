import React from 'react'

import { CertificatesProvider } from '../context'
import { CertificatesFormsContainer } from './forms-container/forms-container.component'

export const CertificateComponent = ({ application }) => {
  return (
    <CertificatesProvider>
      <CertificatesFormsContainer application={application} />
    </CertificatesProvider>
  )
}
