import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Chip, withStyles } from '@material-ui/core'


import { useCombos } from '../../../combos'
import { companyPanelStyles } from './company-panel.styles'

export const PORequests = withStyles(companyPanelStyles)(
  ({ classes, data = []}) => {
    const { formatMessage: fm, formatDate: fd } = useIntl()
    const combos = useCombos(['estados_peticion_op'])



    const getStateValue = (stateKey) => {
      const poRequestCombo = combos.estados_peticion_op?.data
      const stateValue = poRequestCombo.find((item) => item.key === stateKey)?.value
      return stateValue || stateKey
    }

    return useMemo(
      () => (
        <>
          <Box display="flex" alignItems="center" mb={3}>
            <Typography variant="h6" color="primary">
              {fm({ id: 'pages.eeii.detail.poRequests.title' })}
            </Typography>
          </Box>
          {data.length ? (
            <Box>
              {data
                .sort((a, b) => new Date(b.dg_ts_insert) - new Date(a.dg_ts_insert))
                .map((item) => {
                  return (
                    <Box
                      key={item.id}
                      className={classes.poRow}
                    >
                      <Box>
                        <Typography variant="subtitle1">{item.nombre}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {fm(
                            { id: 'pages.eeii.detail.poRequests.fields.date.title' },
                            { date: fd(item.dg_ts_insert) }
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Chip clickable size="small" label={getStateValue(item.estado)} />
                      </Box>
                    </Box>
                  )
                })}
            </Box>
          ) : (
            <Typography variant="body1" color="textSecondary" paragraph>
              {fm({ id: 'pages.eeii.detail.poRequests.noData' })}
            </Typography>
          )}
        </>
      ),
      [data] // eslint-disable-line react-hooks/exhaustive-deps
    )
  }
)
