import es_ES from './es_ES.json'
// import en_US from './en-US.json'

export const LOCALE_DEFAULT = 'es-ES'

const availableMessages = {
  'es-ES': es_ES,
  // 'en-US': en_US,
}

const locale = availableMessages[navigator.language] ? navigator.language : LOCALE_DEFAULT

const messages = availableMessages[locale]

export { messages, locale }
