import BUSINESS_IMG from '../../../resources/images/offersPage/business_image.jpg'
import HEATER_IMG from '../../../resources/images/offersPage/heater_installer.jpg'
import HOT_WATER_IMG from '../../../resources/images/offersPage/hot_water.jpg'
import VIDEO_CAIXABANK from '../../../resources/video/Video_APP_FIRMA_CAIXABANK_CONSUMER.m4v'

export { BUSINESS_IMG, HEATER_IMG, HOT_WATER_IMG, VIDEO_CAIXABANK }

export const URL_OFFER_HOT_WATER =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/oferta-agua-caliente-con-gas-natural/'

export const URL_OFFER_HEATING =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/oferta-calefaccion-con-gas-natural/'

export const URL_OFFER_BUSINESS_TARIFF_3_2 =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/negocios-con-tarifa-3-2/'

export const URL_OFFER_BUSINESS_TARIFF_3_3 =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/oferta-para-negocios-con-tarifa-3-3/'

export const URL_OFFER_DECENTRALIZATION =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/oferta-de-descentralizacion-de-sala-de-calderas/'

export const URL_OFFER_TRANSFORMATION =
  'https://www.nedgia.es/colaboradores/ofertas-colaboradores/oferta-de-transformacion-de-sala-de-calderas/'

export const URL_ADHESION_GUIDE =
  'https://www.nedgia.es/wp-content/uploads/2021/06/20210614_Gu%C3%ADa-r%C3%A1pida_Adhesi%C3%B3n_financiaci%C3%B3n_EECC-1.pdf'

export const URL_CAIXABANK_DOCUMENT =
  'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/06/Documento-de-adhesi%C3%B3n-EEII-v2.pdf'

export const FINANCIATION_APLICATION_GUIDE =
  'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2021/06/20210614_Gu%C3%ADa-r%C3%A1pida_Apliaci%C3%B3n-financiaci%C3%B3n_EECC-rev2-1.pdf'
