import React from 'react'

import { UserProfile } from './components'
import { UserProfileProvider } from './contexts'

export { useUserProfileContext } from './contexts'

export const UserProfileComponent = () => {
  return (
    <UserProfileProvider>
      <UserProfile />
    </UserProfileProvider>
  )
}
