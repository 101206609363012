import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography, CircularProgress } from '@material-ui/core'

import { GuildForm } from './guild-form.component'
import { useCompanyProfileContext } from '../../contexts'

export const GuildCompany = () => {
  const { formatMessage } = useIntl()
  const { gremio_id } = useSelector((state) => state.global)
  const { loadingCompany, getGuildCompany } = useCompanyProfileContext()

  useEffect(
    () => getGuildCompany(gremio_id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (loadingCompany) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  return (
    <Box bgcolor="common.white" p={4} borderRadius={4}>
      <Typography variant="h4" color="primary" paragraph>
        {formatMessage({ id: 'pages.profile.guild.title' })}
      </Typography>
      <GuildForm />
    </Box>
  )
}
