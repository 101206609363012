import React from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { ApplicationDetailForm } from '../detail/application-detail-form.component'

export const EditClientDialog = (props) => {
  const { formatMessage } = useIntl()
  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5" color="textSecondary">
            {formatMessage({ id: 'pages.application.detail.dialogs.editClient.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ApplicationDetailForm {...props} />
      </DialogContent>
    </Box>
  )
}
