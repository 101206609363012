import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

import documentWithPublicProfile from '../../../../resources/documents/condiciones_public.pdf'
import documentWithoutPublicProfile from '../../../../resources/documents/condiciones_other.pdf'
import { MandatoryCheckbox } from '../mandatory-checkbox'
import { useCompanyProfileContext } from '../../contexts'

export const CompanyPublicInput = () => {
  const { formatMessage } = useIntl()
  const { company, formState, changeFormState } = useCompanyProfileContext()

  const defaultChecked =
    company.captadora && (!company.publica || !company.aux_pendiente_renovacion)
  const file = formState.publica === '01' ? documentWithPublicProfile : documentWithoutPublicProfile

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1" gutterBottom>
          {formatMessage({ id: 'pages.profile.company.publicProfileQuestion' })}
        </Typography>
        <RadioGroup
          name="publica"
          value={formState.publica}
          onChange={changeFormState}
          style={{ width: 80, paddingLeft: 10 }}
        >
          <FormControlLabel
            value={'01'}
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'global.true' })}
              </Typography>
            }
          />
          <FormControlLabel
            value={'00'}
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'global.false' })}
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
      <MandatoryCheckbox {...{ file, trigger: formState.publica, defaultChecked }} />
    </>
  )
}
