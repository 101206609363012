import { USER_PROFILE_ACTION_TYPES } from '../constants'

export const userProfileReducer = (state, { type, payload }) => {
  switch (type) {
    case USER_PROFILE_ACTION_TYPES.SET_USER:
      return {
        ...state,
        user: payload,
        loadingUser: false,
        error: {},
      }
    case USER_PROFILE_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loadingUser: payload,
      }
    case USER_PROFILE_ACTION_TYPES.SET_FAILURE:
      return {
        ...state,
        error: payload,
        loadingUser: false,
      }
    default:
      throw new Error('unknown action')
  }
}
