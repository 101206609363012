import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { MarketerPotentialDashboard } from '../../../features/marketer-potential'
import { MARKETER_POTENTIAL_COMBOS_IDS } from '../constants'
import { useCombos } from '../../../features/combos/hooks'

export const MarketerPotentialPage = () => {
  useCombos(MARKETER_POTENTIAL_COMBOS_IDS)

  return (
    <Switch>
      <Route exact path="/marketerPotential" component={MarketerPotentialDashboard} />
      <Redirect from="*" to="/marketerPotential" />
    </Switch>
  )
}
