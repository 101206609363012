import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { BasicListItem } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useInspectionsContext } from '../../contexts'

export const IPInspSummary = () => {
  const { formatMessage: fm } = useIntl()
  const { formState, addresses, company } = useInspectionsContext()
  const combos = useCombos([])

  return (
    <Box p={2} mt={1} bgcolor="grey.100" borderRadius={6}>
      <BasicListItem
        {...{
          label: fm({ id: 'pages.inspections.fields.empresa.label' }),
          value: company.nombre,
        }}
      />
      <BasicListItem
        {...{
          label: fm({ id: 'pages.inspections.fields.installer.label' }),
          value: combos.inspectores.data.find((el) => el.key === formState.instalador).value,
        }}
      />
      {formState.tipo_instalacion === 'IRC' ? (
        <>
          {addresses.map((address, i) => (
            <Box key={i} p={1} mb={2} bgcolor="white" borderRadius={6}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                {address.cod_derivacion}
              </Typography>
              <BasicListItem
                {...{
                  label: fm({ id: 'pages.inspections.fields.resultado_inspeccion.label' }),
                  value: address.resultado_inspeccion,
                }}
              />
              {address.resultado_inspeccion !== 'Correcto' ? (
                <>
                  <Typography variant="body1" color="textSecondary">
                    {fm({ id: 'pages.inspections.fields.anomalias.label' })}:
                  </Typography>
                  <Box pl={1} mb={1}>
                    {address.anomalias.map((anomaly, j) => {
                      return (
                        <Typography key={j} variant="body2">
                          - {anomaly.value}
                        </Typography>
                      )
                    })}
                  </Box>
                  {address.resultado_inspeccion === 'Con anomalías principales' ||
                  address.resultado_inspeccion === 'Con anomalías secundarias y principales' ? (
                    <BasicListItem
                      {...{
                        label: fm({ id: 'pages.inspections.fields.situacion_servicio.label' }),
                        value: address.situacion_servicio,
                      }}
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
          ))}
        </>
      ) : (
        <>
          <BasicListItem
            {...{
              label: fm({ id: 'pages.inspections.fields.resultado_inspeccion.label' }),
              value: addresses[0].resultado_inspeccion,
            }}
          />
          {addresses[0].resultado_inspeccion !== 'Correcto' ? (
            <>
              <Typography variant="body1" color="textSecondary">
                {fm({ id: 'pages.inspections.fields.anomalias.label' })}:
              </Typography>
              <Box pl={1} mb={1}>
                {addresses[0].anomalias.map((anomaly, j) => {
                  return (
                    <Typography key={j} variant="body2">
                      - {anomaly.value}
                    </Typography>
                  )
                })}
              </Box>
              {addresses[0].resultado_inspeccion === 'Con anomalías principales' ||
              addresses[0].resultado_inspeccion === 'Con anomalías secundarias y principales' ? (
                <BasicListItem
                  {...{
                    label: fm({ id: 'pages.inspections.fields.situacion_servicio.label' }),
                    value: addresses[0].situacion_servicio,
                  }}
                />
              ) : null}
            </>
          ) : null}
        </>
      )}
    </Box>
  )
}
