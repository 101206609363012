import React, { useState, useEffect } from 'react'
import { withStyles, Table, TableBody, TableCell, TableContainer } from '@material-ui/core'
import { TableHead, TableRow, Typography, Checkbox } from '@material-ui/core'

import { AddTableModal } from './add-table-modal.component'
import { AddTableToolbar } from './add-table-toolbar.component'
import { addTableStyles } from './add-table.styles'

export const AddTable = withStyles(addTableStyles)(
  ({ classes, data = [], config = [], rowKey = 'id', title = '', ...restProps }) => {
    const [selected, setSelected] = useState([])
    const [tableData, setTableData] = useState(data)
    const [open, setOpen] = useState(false)

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = tableData.map((n) => n[rowKey])
        setSelected(newSelecteds)
        return
      }
      setSelected([])
    }

    const handleClick = (_, name) => {
      const selectedIndex = selected.indexOf(name)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
      setSelected(newSelected)
    }

    const handleDelete = () => {
      const tableDataKeys = tableData.map((row) => row[rowKey])
      const difference = tableDataKeys.filter((key) => !selected.includes(key))
      const newTableData = tableData.filter((row) => difference.some((key) => key === row[rowKey]))
      setTableData(newTableData)
      setSelected([])
    }

    const handleAdd = (newRow) => {
      setTableData([...tableData, newRow])
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    useEffect(() => {
      if (restProps.onChange) restProps.onChange(tableData)
    }, [tableData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <div className={classes.root}>
          <AddTableToolbar
            numSelected={selected.length}
            selectedMssg="Presiona el botón de la papelera para eliminar"
            title={title}
            tooltip={restProps.tooltip}
            addIcon={restProps.addIcon}
            onAddItem={() => setOpen(true)}
            onDeleteItem={handleDelete}
          />
          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < tableData.length}
                      checked={tableData.length > 0 && selected.length === tableData.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  {config.map(({ key, align = 'left', label }) => (
                    <TableCell key={key} align={align}>
                      <Typography variant="subtitle1">{label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  const isItemSelected = isSelected(row[rowKey])
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row[rowKey])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[rowKey]}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      {config.map(({ key, align = 'left' }) => (
                        <TableCell key={key} align={align}>
                          {row[key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <AddTableModal
          {...{
            open,
            setOpen,
            onSubmit: handleAdd,
            data,
            fields: config,
            Component: restProps.Component,
          }}
        />
      </>
    )
  }
)
