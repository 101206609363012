import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, FormControl, Typography, FormGroup } from '@material-ui/core'
import { FormControlLabel, Checkbox } from '@material-ui/core'

import { checkListInputStyles } from './check-list-input.styles'

export const CheckListInput = withStyles(checkListInputStyles)(
  ({
    classes,
    readOnly = false,
    required = false,
    value = [],
    values = [],
    cKeyField = 'key',
    cKeyType = 'string',
    keyField = 'key',
    valueField = 'value',
    ...props
  }) => {
    const { formatMessage } = useIntl()
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const handleChange = (e) => {
      let newValue = [...value]
      if (e.target.checked) {
        newValue.push({
          [keyField]: cKeyType === 'int' ? Number(e.target.name) : e.target.name,
          [valueField]: values.find((v) => v[cKeyField].toString() === e.target.name).value,
        })
      } else if (!e.target.checked) {
        const index = newValue.findIndex((item) => item[keyField].toString() === e.target.name)
        newValue.splice(index, 1)
      }
      props.onChange({ target: { name: props.name, value: newValue } })
    }

    const readOnlyValues = () => {
      let valuesArray = []
      values.forEach((item) => {
        if (value.find((el) => el[keyField].toString() === item[cKeyField].toString()))
          valuesArray.push(item.value)
      })
      return valuesArray
    }

    return (
      <>
        <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
          {props.title}
          {props.required ? (<span style={{color: '#B3272C'}}> *</span>) : null}
        </Typography>
        {readOnly ? (
          readOnlyValues().map((item, i) => (
            <Typography key={i} variant={`body${typographyVariant}`} color="textSecondary">
              {item}
            </Typography>
          ))
        ) : (
          <FormControl required={true} error={required && !value.length}>
            <FormGroup>
              {values.map((item, i) => {
                return (
                  <FormControlLabel
                    required
                    className={classes.formControlLabel}
                    key={i}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        color="primary"
                        size="small"
                        checked={
                          value.findIndex(
                            (el) => el[keyField].toString() === item[cKeyField].toString()
                          ) > -1
                        }
                        onChange={handleChange}
                        name={item[cKeyField].toString()}
                      />
                    }
                    label={<Typography variant="body2">{item.value}</Typography>}
                  />
                )
              })}
            </FormGroup>
            {required && !value.length ? (
              <Typography variant="caption" color="error">
                {formatMessage({ id: 'checkListInput.required' })}
              </Typography>
            ) : null}
          </FormControl>
        )}
      </>
    )
  }
)
