import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import {
  withStyles,
  Typography,
  Box,
  Grid,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

import { ThemeButton, SelectInput, PageDisplayer, CardCheckList } from '../../../../ui'

import { useTariffCalls, useTariffDialogs, useZonesReducer } from '../../hooks'
import { tariffNewStyles } from './tariff-zone.style'

export const TariffZones = withStyles(tariffNewStyles)(({ classes }) => {
  //Tools
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { getTariff, getTariffZone, putTariff, putTariffZone } = useTariffCalls()
  const { warnZoneUnsaved, warnZoneVisible, warnZoneChanges } = useTariffDialogs()
  const {
    comunidad,
    provincias,
    zips,
    zoneLoad,
    zoneUpdateZip,
    zoneUpdateProvincia,
    zoneUpdateFull,
    zoneUpdateFromCodes,
  } = useZonesReducer()

  //Available data:
  const { tariffId } = useParams()
  const empresas = useSelector((state) => state.global.empresa_cc_aa || [])

  //Fetched data
  const [empresaId, setEmpresaId] = useState()
  const [empresaAll, setEmpresaAll] = useState({}) //We don't have a PATCH endpoint, so we need that for consistency with the current PUT
  const [isEmpty, setIsEmpty] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [provinciaMirada, setProvinciaMirada] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  useEffect(() => {
    getTariff(tariffId).then(({ data }) => {
      setEmpresaId(data.empresa_id)
      setEmpresaAll(data)
    })
  }, [tariffId, getTariff, setEmpresaId, setEmpresaAll])
  useEffect(() => {
    if (!empresaId) {
      return
    }
    getTariffZone(empresaId, tariffId).then(({ data }) => {
      setProvinciaMirada(data.codPostales[0] ? data.codPostales[0].prov : null)
      zoneLoad(data)
      setIsChanged(false)
      setIsEmpty(
        data.codPostales.reduce(
          (n, provincia) => n + provincia.cps.filter((cp) => cp.activa).length,
          0
        ) === 0
      )
    })
  }, [empresaId, tariffId, getTariffZone, setProvinciaMirada, zoneLoad, setIsEmpty, setIsChanged])

  //Processed data.
  const cc_aa_choice = empresas.map((el) => {
    return {
      key: el.id_empresa,
      value: el.nombre_cc_aa,
    }
  })
  const zip_choice = useMemo(
    () => (zips[provinciaMirada] ? zips[provinciaMirada] : []),
    [provinciaMirada, zips]
  )

  //GOING BACK
  const handleGoBack = useCallback(() => {
    history.push('/tariffs/' + tariffId + '/edit')
  }, [history, tariffId])
  const handleCallGoBack = useCallback(() => {
    if (isChanged) {
      warnZoneUnsaved(handleGoBack)
    } else {
      handleGoBack()
    }
  }, [isChanged, handleGoBack, warnZoneUnsaved])

  //CHANGING ENTERPRISE
  const handleEmpresaChange = useCallback(
    (newVal) => {
      putTariff(tariffId, {
        ...empresaAll,
        empresa_id: newVal,
      }).then(() => {
        setEmpresaId(newVal)
        //Zone cleanup:
        putTariffZone(tariffId, { codPostales: [] })
      })
    },
    [tariffId, empresaAll, putTariff, setEmpresaId] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleCallEmpresaChange = useCallback(
    (event) => {
      const newVal = parseInt(event.target.value)
      const newEmpresa = empresas.filter((empresa) => parseInt(empresa.id_empresa) === newVal)[0]
      //Check it is a valid enterprise
      if (!newEmpresa) {
        throw new Error('Unknown enterprise', newVal)
      }
      //Perform the change, with possible modal interceptor
      if (!isEmpty) {
        warnZoneChanges(comunidad, newEmpresa.nombre_cc_aa, () => handleEmpresaChange(newVal))
      } else handleEmpresaChange(newVal)
    },
    [isEmpty, empresas, tariffId, comunidad, handleEmpresaChange] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //SAVING STATE
  const handleSubmitZoneConfig = useCallback(
    (arr) => {
      setIsChanged(false)
      return putTariffZone(tariffId, {
        codPostales: arr,
      })
        .then(() => {
          setIsEmpty(arr.length === 0)
          history.push('/tariffs')
        })
        .catch(() => {
          console.debug('Error al guardar zonas')
        })
    },
    [tariffId, setIsChanged, putTariffZone, setIsEmpty, history]
  )
  const handleCallSubmitZoneConfig = useCallback(() => {
    let arr = []
    for (let provincia in zips) {
      arr = [...arr, ...zips[provincia].filter((cp) => cp.activa).map((cp) => cp.value)]
    }
    if (isEmpty && arr.length > 0 && empresaAll.activa) {
      warnZoneVisible(comunidad, () => handleSubmitZoneConfig(arr))
    } else {
      handleSubmitZoneConfig(arr)
    }
  }, [comunidad, zips, tariffId, empresaAll, handleSubmitZoneConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  //CHANGE PROVINCE BEING BROWSED
  const handleProvinciaSelect = useCallback(
    (provincia) => {
      setSearchQuery(null)
      setProvinciaMirada(provincia)
    },
    [setSearchQuery, setProvinciaMirada]
  )

  //CHECK/UNCHECK ALL IN PROVINCE
  const handleProvinciaCheck = useCallback(
    (event) => {
      setIsChanged(true)
      const newState = event.target.checked
      const checkProvincia = event.target.name
      zoneUpdateProvincia(checkProvincia, newState)
    },
    [setIsChanged, zoneUpdateProvincia]
  )

  //CHECK/UNCHECK ALL IN ALL PROVINCES
  const handleAllProvinciaCheck = useCallback(
    (event) => {
      setIsChanged(true)
      const newState = event.target.checked
      zoneUpdateFull(newState)
    },
    [setIsChanged, zoneUpdateFull]
  )

  //CHECK/UNCHECK BY CODE
  const handleCodeCheck = useCallback(
    (event, codes) => {
      setIsChanged(true)
      const newState = event.target.checked
      zoneUpdateFromCodes(newState, codes)
    },
    [setIsChanged, zoneUpdateFromCodes]
  )

  //CHECK/UNCHECK SINGLE ZIP
  const handleZipChange = useCallback(
    (event) => {
      setIsChanged(true)
      const newState = event.target.checked
      const checkZip = event.target.name
      zoneUpdateZip(provinciaMirada, checkZip, newState)
    },
    [provinciaMirada, setIsChanged, zoneUpdateZip]
  )

  //CHANGE SEARCH QUERY
  const handleSearchQueryChange = useCallback(
    (event) => {
      const newText = event.target.value
      setSearchQuery(newText)
    },
    [setSearchQuery]
  )

  //Auxiliary components:
  const ChamaleonCheckbox = withStyles({
    root: {
      color: '#fff',
      '&$checked': { color: '#fff' },
    },
    checked: {},
  })((props) => <Checkbox {...props} />)

  const CaseCheckbox = useCallback(({ provinciaMirada, element, ...props }) => {
    if (provinciaMirada === element.name) return <ChamaleonCheckbox {...props} />
    return <Checkbox {...props} />
  }, [])
  const provinceCheckRender = useCallback(
    (element) => {
      return (
        <ListItem
          key={element.name}
          className={
            classes.provinciaLine +
            (element.name === provinciaMirada ? ' ' + classes.provinciaLineChosen : '')
          }
          onClick={() => handleProvinciaSelect(element.name)}
        >
          <CaseCheckbox
            provinciaMirada={provinciaMirada}
            element={element.name}
            color="primary"
            name={element.name}
            checked={element.chosen === element.size}
            indeterminate={element.chosen < element.size && element.chosen > 0}
            onChange={handleProvinciaCheck}
            disableRipple
          />
          {element.name}
        </ListItem>
      )
    },
    [provinciaMirada, classes, handleProvinciaSelect, handleProvinciaCheck]
  )
  const zipCheckRender = useCallback(
    (element) => (
      <FormControlLabel
        key={element.value}
        className={classes.zipCard + (element.activa ? ' ' + classes.zipCardChosen : '')}
        label={element.value}
        control={
          <Checkbox
            color="primary"
            name={element.value}
            className={classes.zipMarker}
            checked={element.activa}
            onChange={handleZipChange}
            disableRipple
          />
        }
      />
    ),
    [classes, handleZipChange]
  )
  const zipBlockRender = useCallback(
    ({ children }) => <Box className={classes.zipContainer}>{children}</Box>,
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Main component
  return (
    <PageDisplayer
      loading={!zips || !zip_choice.length}
      error={false /*Canviar? */}
      handleGoBack={handleCallGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.zone.back' })}
    >
      <Typography variant="h4" color="textSecondary">
        {formatMessage({ id: 'pages.tariffs.zone.title' })}
      </Typography>
      <Box mt={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.cc_aa' })}
            </Typography>
            <SelectInput
              values={cc_aa_choice}
              value={empresaId}
              onChange={handleCallEmpresaChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.buttonContainer}>
            <ThemeButton onClick={handleCallSubmitZoneConfig}>
              {formatMessage({ id: 'pages.tariffs.zone.save_button' })}
            </ThemeButton>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.provinces.title' })}
            </Typography>
            <CardCheckList
              className={classes.colorPanel}
              keyField="name"
              nameField="name"
              elements={provincias}
              selected={provincias
                .filter((provincia) => provincia.size === provincia.chosen)
                .map((provincia) => provincia.name)}
              funcRenderElement={provinceCheckRender}
              wholeSelector={true}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.provinces.all' })}
              funcWholeOnChange={(event) => handleAllProvinciaCheck(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.zips.title' })}
            </Typography>
            <CardCheckList
              className={classes.colorPanel}
              keyField="value"
              nameField="value"
              elements={zip_choice}
              selected={zip_choice.filter((zip) => zip.activa).map((el) => el.value)}
              funcRenderElement={zipCheckRender}
              funcRenderContainer={zipBlockRender}
              searchBox={true}
              searchValue={searchQuery}
              searchPlaceholder={formatMessage({ id: 'pages.tariffs.zone.zips.filter' })}
              funcSearchOnChange={handleSearchQueryChange}
              wholeSelector={true}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.zips.all' })}
              funcWholeOnChange={handleCodeCheck}
            />
          </Grid>
        </Grid>
      </Box>
    </PageDisplayer>
  )
})
