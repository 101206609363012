import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Typography, Box, Grid, Chip} from '@material-ui/core'
import {Alert} from '@material-ui/lab'

import {SelectInput, RadioInput, ThemeButton, useModalContext, TextInput, AutocompleteInput} from '../../../../ui'
import {useCombos} from '../../../combos'
import {useInspectionsContext} from '../../contexts'
import {InstCupsBlock} from './inst-cups-block.component'
import {AddressDialog} from "../address-selector/address-dialog.component";
import {useInspectionsCalls} from "../../calls";
import AddIcon from "@material-ui/icons/Add";


export const InstallationForm = () => {
  const {formatMessage} = useIntl()
  const {formFields, validateInstallationStep, formState, error, type, company, validCups,loadingValidarYContinuar} =
    useInspectionsContext()
  const {loadingHomeInfo, resetAddresses, openNonconformity, addresses} = useInspectionsContext()
  const {getComercializadoras} = useInspectionsCalls();
  const [comercializadoras, setComercializadoras] = useState([])
  const combos = useCombos(['tipo_gas', 'potencia_gas_p', 'distribuidora'])
  const instTypesByCompanyCategory =
    (company.categoria === 'C'
      ? combos['tipo_instalacion_c']?.data
      : combos['tipo_instalacion_ab']?.data) || []


  const [showValidCups, setShowValidCups] = useState(false)
  const {
    actions: {open},
  } = useModalContext()

  const handleSubmit = (e) => {
    e.preventDefault()
    formState.address = {
      ...formState.address,
      une: formState.une,
      tipo_gas: formState.tipo_de_gas,
    }
    validateInstallationStep()
  }
  const handleSetAddress = ({selected, close, derivation}) => {
    if(derivation) {
      formState.derivation = derivation
    }
    formState.address = selected
    getComercializadoras(selected.cp).then((data) => {
      setComercializadoras(data)
    })
    close()
  }

  const resetFormState = () => {
    formState.anomalias = []
    formState.address = null
    formState.nombre_titular = null
    formState.apellido_1_titular = null
    formState.apellido_2_titular = null
    formState.potencia = null
    formState.tipo_de_gas = null
    formState.comercializadora = null
    formState.sociedad = null
    setShowValidCups(false)
  }

  const handleCancelAddress = () => {
    if (formState.address) return;
    resetFormState()
    formState.cups = null
  }

  const handleInformProblem = () => {
    openNonconformity()
  }


  const handleOpenAddressModal = () => {
    open({
      Component: AddressDialog,
      data: {
        onSelect: handleSetAddress,
        cups: formState.cups,
        isIRC: formState.tipo_instalacion === 'IRC',
        onCancel: handleCancelAddress,
        data: formState.address
      },
      type: 'responsive',
      maxWidth: 'md',
    })
  }

  useEffect(() => {
    resetAddresses()
    resetFormState()
    formState.cups = null
  }, [formState.tipo_instalacion]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetFormState()
  }, [formState.cups]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (validCups) {
      setShowValidCups(true)
      handleOpenAddressModal()
    }
  }, [validCups]);

  return (
    <Box p={3} mt={2} bgcolor="grey.200" borderRadius={6}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" color="textSecondary">
          {formatMessage({id: 'pages.inspections.installation.title'})}
        </Typography>
        <Chip size="small" label={type.toUpperCase()}/>
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioInput
                {...{...formFields.TIPO_INSTALACION, values: instTypesByCompanyCategory}}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput {...{...formFields.UNE}} />
            </Grid>
            {formState.tipo_instalacion === 'IRC' ? (
                <>
                  {(!formState.address || !formState.address.cp) && (
                    <Grid item xs={12}>
                      <ThemeButton
                        variant="outlined"
                        color="inherit"
                        labelM="sm"
                        startIcon={addresses.length ? <AddIcon/> : null}
                        onClick={handleOpenAddressModal}
                      >
                        {formatMessage({
                          id: `pages.inspections.${addresses.length ? 'addNewAddressBtn' : 'addAddressBtn'}.label`,
                        })}
                      </ThemeButton>
                    </Grid>)}
                </>)
              : <InstCupsBlock/>}
          </Grid>

          {showValidCups && !error.message && (
            <Box mt={2} mb={1} width={"100%"} bgcolor="common.white">
              <Alert severity="success"> {formatMessage({id: 'pages.inspections.feedback.40'})}</Alert>
            </Box>)}
          {formState.address && formState.address.cp && (
            <Grid container spacing={2}>
              <>
                {formState.tipo_instalacion === 'IRC' ? (
                  <>
                    <Grid item xs={12} md={8}>
                      <TextInput {...{...formFields.DIRECCION_EDITABLE}} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ThemeButton color="primary" labelM="sm" onClick={handleOpenAddressModal}
                                   style={{marginTop: '20px', width: '100%'}}>
                        {formatMessage({id: 'pages.inspections.fields.direccion.button'})}
                      </ThemeButton>
                    </Grid>
                  </>

                ) : (
                  <Grid item xs={12}>
                    <TextInput {...{...formFields.DIRECCION_EDITABLE}} />
                  </Grid>
                )}
              </>

              <Grid item xs={12} md={4}>
                <TextInput {...{...formFields.NOMBRE_TITULAR}} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput {...{...formFields.APELLIDO1_TITULAR}} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput {...{...formFields.APELLIDO2_TITULAR}} />
              </Grid>
              {formState.tipo_instalacion === 'IRI' && (
                <Grid item xs={12}>
                  <SelectInput {...{...formFields.POTENCIA_INSTALACION}} />
                </Grid>
              )}
              <Grid item xs={12}>
                <SelectInput {...{...formFields.TIPO_GAS}} />
              </Grid>
              {formState.tipo_instalacion !== 'IRC' && (
              <Grid item xs={12}>
                <AutocompleteInput {...{...formFields.EMPRESA_COMERCIALIZADORA, values: comercializadoras}} />
              </Grid>
              )}
              <Grid item xs={12}>
                <SelectInput {...{...formFields.EMPRESA_DISTRIBUIDORA}} />
              </Grid>
            </Grid>
          )}
          {error.message && (
            <Box mt={2} mb={1} bgcolor="common.white">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          )}

          <Box display="flex" justifyContent={type === 'jca' ? 'space-between' : 'flex-end'} mt={3}>
            {type === 'jca' ? (
              <ThemeButton
                onClick={handleInformProblem}
                variant="outlined"
                style={{backgroundColor: '#ffffff'}}
                labelM="sm"
                disabled={!formState.address}
              >
                {formatMessage({id: 'pages.inspections.nonconformityBtn.label'})}
              </ThemeButton>
            ) : null}
            <ThemeButton type="submit" color="primary" labelM="sm" disabled={loadingHomeInfo} loading={loadingValidarYContinuar}>
              {formatMessage({id: 'pages.inspections.validateBtn.label'})}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
