import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useWidth } from '../../../../core/utils'
import LogoNedgiaFull from '../../../../resources/images/logo_nedgia_full.svg'
import LogoNedgia from '../../../../resources/images/logo_nedgia.svg'
import { MenuComponent as Menu } from '../../../menu'
import { PROFILE_ROUTE, APP_VERSION_MSSG } from '../constants'
import { useMenu } from '../hooks'
import { privateLayoutStyles } from './private-layout.styles'
import {NoticesHistoryComponent} from "../../../../features/notices/components/notices-history";

export const PrivateLayoutComponent = withStyles(privateLayoutStyles)(({ children, classes }) => {
  const screenWidth = useWidth()
  const { getMenu } = useMenu()
  const isMobileWidth = screenWidth === 'sm' || screenWidth === 'xs'

  const menuItems = getMenu()
  const mobileView = menuItems.length > 6

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.headerLeftContent} style={mobileView ? { order: '2' } : {}}>
          <div className={classes.logo}>
            <Tooltip title={APP_VERSION_MSSG}>
              <Link to="">
                {screenWidth === 'xs' ? (
                  <img src={LogoNedgia} alt="" />
                ) : (
                  <img
                    src={LogoNedgiaFull}
                    alt="Nedgia_Logo"
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className={classes.menu} style={mobileView ? { order: '1' } : {}}>
          <Menu items={menuItems} isMobileWidth={isMobileWidth} mobileView={mobileView} />
        </div>
        <div
          className={classes.headerRightContent}
          style={menuItems.length > 5 ? { order: '3' } : {}}
        >
          <NoticesHistoryComponent />
          <Link to={PROFILE_ROUTE}>
            <IconButton className={classes.iconButton}>
              <AccountCircleOutlinedIcon />
            </IconButton>
          </Link>
        </div>
      </div>
      <div className={classes.bodyWrapper}>{children}</div>
    </div>
  )
})
