import React from 'react'
import { Box, Grid } from '@material-ui/core'

import { TextInput } from '../../../../ui'
import { COMPANY_FORM_CONFIG } from '../../constants'

export const CompanyAddressForm = ({ inputProps, readOnly }) => {
  const gridItemReadonlyLayout = readOnly ? { xs: 12, sm: 6 } : {}

  return (
    <Box p={2} bgcolor="common.white" border="1px solid" borderColor="grey.400" borderRadius={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CALLE, readOnly }} />
        </Grid>
        <Grid item xs={12} sm={2} md={2} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).NUMERO, readOnly }} />
        </Grid>
        <Grid item xs={12} sm={2} md={2} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).PISO, readOnly }} />
        </Grid>
        <Grid item xs={12} sm={3} md={3} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).POLIGONO, readOnly }} />
        </Grid>
        <Grid item xs={12} sm={9} md={7} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).POBLACION, readOnly }} />
        </Grid>
        <Grid item xs={12} sm={3} md={2} {...gridItemReadonlyLayout}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_POSTAL, readOnly }} />
        </Grid>
      </Grid>
    </Box>
  )
}
