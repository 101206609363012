export const usersDashboardStyles = (theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '50px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px',
    },
  },
})
