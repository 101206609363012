import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { setUsers } from '../../slices'
import { MarketerUsersTable } from '../table'
import { MarketerUsersFilter } from '../filter'
import { marketerUsersDashboardStyles } from './marketer-users-dashboard.styles'
import { USERS_DEFAULT_QUERY } from '../../constants'

export const MarketerUsersDashboard = withStyles(marketerUsersDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const marketerId = useSelector((state) => state.global.comercializadora_id)
  //Acquire url and parse its search options
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = useMemo(
    () => ({
      ...USERS_DEFAULT_QUERY,
      ...urlSearchParams,
    }),
    [urlSearchParams]
  )

  useEffect(() => {
    dispatch(setUsers(searchParams, marketerId))
  }, [url, dispatch, searchParams, marketerId])

  return (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.users.title' })}
          </Typography>
        </Box>
        <Box mb={2}>{<MarketerUsersFilter searchParams={searchParams} />}</Box>
        <Box>
          <MarketerUsersTable />
        </Box>
      </Container>
    </Box>
  )
})
