import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Grid, Checkbox, FormHelperText } from '@material-ui/core'

import { TextInput, NumberInput } from '../../../../ui'

export const CertificateDevices = ({ data, deviceList, onChange, errors, showErrors }) => {
  const { formatMessage } = useIntl()

  const handleChangeCheckbox = (e, device) => {
    //We want to update the current checkbox accordingly, but set the other one to "false"
    ;['_nuevos', '_transformar'].forEach((check) => {
      onChange({
        ...e,
        target: {
          ...e.target,
          name: device + check,
          value: e.target.name === device + check ? e.target.checked : false,
        },
      })
    })
  }

  return (
    <Box>
      <Typography variant="body1" color="primary" paragraph>
        {formatMessage({ id: 'pages.certificates.devices.title' })}
      </Typography>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            {formatMessage({ id: 'pages.certificates.devices.header.tipo' })}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            {formatMessage({ id: 'pages.certificates.devices.header.nuevo' })}
          </Grid>
          <Grid item xs={2}>
            {formatMessage({ id: 'pages.certificates.devices.header.transformar' })}
          </Grid>
          <Grid item xs={3}>
            {formatMessage({ id: 'pages.certificates.devices.header.marca' })}
          </Grid>
          <Grid item xs={3}>
            {formatMessage({ id: 'pages.certificates.devices.header.potencia' })}
          </Grid>
        </Grid>
        {deviceList.map(({ id, label }) => (
          <Grid key={id} container spacing={3}>
            <Grid item xs={3}>
              {!label ? (
                <TextInput name={`${id}_nombre`} value={data[`${id}_nombre`]} onChange={onChange} />
              ) : (
                <Box height="100%" display="flex" alignItems="center">
                  {label}
                </Box>
              )}
            </Grid>
            <Grid item xs={1}>
              <Box height="100%" display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  size="small"
                  name={`${id}_nuevos`}
                  checked={data[`${id}_nuevos`] || false}
                  onChange={(event) => handleChangeCheckbox(event, id)}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box height="100%" display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  size="small"
                  name={`${id}_transformar`}
                  checked={data[`${id}_transformar`] || false}
                  onChange={(event) => handleChangeCheckbox(event, id)}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <TextInput name={`${id}_marca`} value={data[`${id}_marca`]} onChange={onChange} />
            </Grid>
            <Grid item xs={3}>
              <NumberInput
                name={`${id}_potencia`}
                value={data[`${id}_potencia`]}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <FormHelperText error={showErrors && errors['devices']}>
        {showErrors && errors['devices']
          ? formatMessage({ id: 'pages.certificates.devices.error.text' })
          : null}
      </FormHelperText>
      <FormHelperText error={showErrors && errors['singleDevice']}>
        {showErrors && errors['singleDevice']
          ? formatMessage({ id: 'pages.certificates.singleDevice.error.text' })
          : null}
      </FormHelperText>
    </Box>
  )
}
