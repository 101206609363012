import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { HorizontalStepper, ThemeButton } from '../../../ui'
import { InspectionsProvider } from '../contexts'
import { useInspections } from '../reducers'
import { InstallationForm } from './installation-form'
import { InspectionForm } from './inspection-form'
import { Summary } from './summary'

export const Inspections = () => {
  const { formatMessage } = useIntl()
  const inspectionsState = useInspections()
  const { currentStep, abortInspection, setType, loading, setCompany } = inspectionsState

  useEffect(() => {
    setType()
    setCompany()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const STEPS = [
    {
      component: InstallationForm,
      label: formatMessage({ id: 'pages.inspections.steps.1.label' }),
    },
    {
      component: InspectionForm,
      label: formatMessage({ id: 'pages.inspections.steps.2.label' }),
    },
    {
      component: Summary,
      label: formatMessage({ id: 'pages.inspections.steps.3.label' }),
    },
  ]

  const CurrentStep = STEPS[currentStep].component

  const stepLabels = STEPS.map((step) => step.label)

  return (
    <InspectionsProvider value={inspectionsState}>
      {loading ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <HorizontalStepper {...{ stepLabels, currentStep: currentStep }}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box maxWidth={700}>
              <CurrentStep />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <ThemeButton color="inherit" onClick={abortInspection}>
                  {formatMessage({ id: 'pages.inspections.abortBtn.label' })}
                </ThemeButton>
              </Box>
            </Box>
          </Box>
        </HorizontalStepper>
      )}
    </InspectionsProvider>
  )
}
