import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { setCombo } from '../../../features/combos/slices'
import { ApplicationsDashboard } from '../../../features/applications'
import { ApplicationDetail } from '../../../features/application-detail'
import { CertificatesPage as Certificates } from '../../certificates'
import { requests } from '../../../core/ajax'
import { useCombos } from '../../../features/combos'
import { APPS_COMBOS_IDS } from '../constants'

export const ApplicationsPage = () => {
  const dispatch = useDispatch()
  const combos = useCombos(APPS_COMBOS_IDS)
  const role = useSelector((state) => state.global.role)
  const guildId = useSelector((state) => state.global.gremio_id)
  const ccaaCombo = combos.cod_zona_zeus

  useEffect(() => {
    if (role === 'gremio' && combos['cod_zona_zeus']?.data.length) {
      requests
        .get(`/gremios/${guildId}/empresas`, { params: { _num_x_pagina: 9999 } })
        .then(({ data }) => {
          const combo = data['empresas'].map((item) => ({
            key: item['id'],
            value: `${item['nombre']} (${
              combos['cod_zona_zeus'].data.find(
                ({ key }) => key === item['comunidad_autonoma'].toString()
              )?.value || ''
            })`,
          }))
          dispatch(setCombo({ comboId: 'empresas', data: combo }))
        })
        .catch(({ response }) => {
          console.debug('Error recuperando combo', response && response.data)
        })
    }
  }, [ccaaCombo]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route exact path="/applications" component={ApplicationsDashboard} />
      <Route exact path="/applications/:appId" component={ApplicationDetail} />
      <Route exact path="/applications/:appId/certificates" component={Certificates} />
      <Redirect from="*" to="/applications/:appId" />
    </Switch>
  )
}
