import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Checkbox } from '@material-ui/core'

import { useDidUpdateEffect } from '../../../../core/utils'
import { feedback } from '../../../../core/feedback'
import { useModalContext, ViewPDFDialog } from '../../../../ui'
import { mandatoryCheckboxStyles } from './mandatory-checkbox.styles'

export const MandatoryCheckbox = withStyles(mandatoryCheckboxStyles)(
  ({ classes, file, trigger, defaultChecked }) => {
    const { formatMessage } = useIntl()
    const {
      actions: { open },
    } = useModalContext()

    const [checked, setChecked] = useState(defaultChecked)
    const [disabled, setDisabled] = useState(!defaultChecked)

    const handleOpenDocument = () => {
      open({
        Component: ViewPDFDialog,
        data: { file, setDisabled, shortcutEnabled: true },
        type: 'fullScreen',
      })
    }

    const handleCheckboxInput = () => {
      if (disabled) {
        feedback('warning', formatMessage({ id: 'pages.profile.company.checkbox.warning.1' }))
      } else {
        setChecked(!checked)
      }
    }

    const setInitialState = () => {
      setDisabled(true)
      setChecked(false)
    }

    useDidUpdateEffect(setInitialState, [trigger])

    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          color="primary"
          size="small"
          checked={checked}
          onClick={handleCheckboxInput}
          required
        />
        <Typography variant="body2" color="textSecondary">
          {formatMessage({ id: 'pages.profile.company.checkbox.message1' })}
          <span className={classes.documentLink} onClick={handleOpenDocument}>
            {formatMessage({ id: 'pages.profile.company.checkbox.message2' })}
          </span>
        </Typography>
      </Box>
    )
  }
)
