import { APPLICATION_STATES } from '../constants/application-states.constants'

export const CANT_CANCEL_APPLICATION_STATES = {
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: true,
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.CANCEL_PENDING.KEY]: true,
  [APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY]: true,
  [APPLICATION_STATES.NORMALIZATION.KEY]: true,
  [APPLICATION_STATES.NORMALIZATION_PROCESSED.KEY]: true,
  [APPLICATION_STATES.REJECTED_NORMALIZATION.KEY]: true,
}

export const MUST_REQUEST_CANCEL = {
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,
}
