import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ConfirmationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'

export const useTariffsDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const warnPermanentDeletion = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.tariffs.table.warning.permanent.title' }),
        text: formatMessage({ id: 'pages.tariffs.table.warning.permanent.text' }),
        yesText: formatMessage({ id: 'pages.tariffs.table.warning.permanent.yes' }),
        noText: formatMessage({ id: 'pages.tariffs.table.warning.permanent.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  return {
    warnPermanentDeletion: warnPermanentDeletion,
  }
}
