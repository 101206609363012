import { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'

import { ViewPDFDialog, ConfirmationDialog } from '../../../../../ui'
import { ModalContext } from '../../../../../ui/modal/contexts'
import { usePublicOfferCalls, usePublicOfferReducer } from './index'
import { feedback } from '../../../../../core/feedback'

export const usePublicOfferDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const { getDocumentById, postAddPO } = usePublicOfferCalls()
  const { publicOfferData } = usePublicOfferReducer()

  const openGeneralCondition = useCallback((fileId, callback) => {
    getDocumentById(fileId)
      .then((data) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        open({
          Component: ViewPDFDialog,
          data: {
            file,
            setDisabled: callback,
            shortcutEnabled: true,
          },
          type: 'fullScreen',
        })
      })
      .catch((error) => {
        console.debug(error)
        feedback('error', 'No se encuentra el documento')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openMultiFile = useCallback((fileId, docId, callback) => {
    getDocumentById(fileId)
      .then((data) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        open({
          Component: ViewPDFDialog,
          data: {
            docId,
            file,
            setMultipleArray: callback,
          },
          type: 'fullScreen',
          closable: false,
        })
      })
      .catch((error) => {
        console.error(error)
        feedback('error', 'No se encuentra el documento')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openConfirmationDialog = useCallback(
    (
      publicOffersToSend,
      generalCheckAvailable,
      companyState,
      setForceRefresh,
      forceRefresh,
      resetPublicOffers
    ) => {
      open({
        Component: ConfirmationDialog,
        data: {
          title: formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.title' }),
          text: formatMessage({
            id: 'pages.profile.company.guild.info.publicOffer.add.confirmation',
          }),
          yesText: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.yes' }),
          noText: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.no' }),
          yesAction: async () => {
            await postAddPO(
              publicOffersToSend,
              generalCheckAvailable,
              publicOfferData,
              companyState
            )
            setForceRefresh(!forceRefresh)
            resetPublicOffers()
          },
        },
        type: 'centered',
        maxWidth: 'md',
      })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    openGeneralCondition,
    openMultiFile,
    openConfirmationDialog,
  }
}
