import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { CompanySelectorDialog } from '../../../ip'

export const CreateJCABtn = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {
    actions: { open },
  } = useModalContext()
  const global = useSelector((state) => state.global)
  const isGuild = !!global.gremio

  const handleCreateJCA = () => {
    if (!isGuild) {
      history.push('/inspections/jca')
    } else {
      open({
        Component: CompanySelectorDialog,
        data: { type: 'jca', guildId: global.gremio.id },
        closable: true,
        type: 'center',
        maxWidth: 'xs',
      })
    }
  }

  return (
    <ThemeButton
      color="secondary"
      labelM="sm"
      onClick={handleCreateJCA}
      style={{ marginLeft: '1em' }}
    >
      {formatMessage({ id: 'pages.jcaList.createJCAButton.label' })}
    </ThemeButton>
  )
}
