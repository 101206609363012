import React from 'react'
import { withStyles, Typography, Container, Box, CircularProgress } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

import { pageDisplayerStyles } from './page-displayer.styles'

export const PageDisplayer = withStyles(pageDisplayerStyles)(
  ({ loading = false, error = false, handleGoBack, textGoBack = 'Back', classes, children }) => {
    if (loading) {
      return (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      )
    }
    if (error) {
      return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <p>{error}</p>
        </Box>
      )
    }
    return (
      <Container className={classes.root} maxWidth="lg">
        <Box height="100%" mt={[2, 4]} mb={[2, 4]}>
          {handleGoBack ? (
            <div className={classes.return} onClick={handleGoBack}>
              <ArrowBackIos fontSize="inherit" />
              <Typography variant="body1">{textGoBack}</Typography>
            </div>
          ) : null}
          <Box bgcolor="common.white" p={['15px', '20px 40px']}>
            {children}
          </Box>
        </Box>
      </Container>
    )
  }
)

export default PageDisplayer
