import { feedback } from '../../../core/feedback'

export const ValidateAddressData = (data, intl, postAlarm) => {
  if (!Array.isArray(data)) {
    postAlarm('bad_response_address_book', JSON.stringify(data))
    feedback('error', intl.formatMessage({ id: 'pages.consult.addressError.text' }))
    return false
  }
  return true
}
