import { Box } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { ThemeButton } from '../theme-button'

export const ButtonsToEdit = ({
  editMode = false,
  isLoading = false,
  handleEdit,
  handleCancel,
  handleSave,
}) => {
  const intl = useIntl()

  if (editMode)
    return (
      <Box display="flex" justifyContent="center" m={'14px 0'}>
        <ThemeButton
          size="small"
          variant="outlined"
          onClick={handleCancel}
          disabled={isLoading}
          style={{ marginRight: 20 }}
        >
          {intl.formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          size="small"
          onClick={handleSave}
          loading={isLoading}
          style={{ marginLeft: 20 }}
        >
          {intl.formatMessage({ id: 'global.save' })}
        </ThemeButton>
      </Box>
    )

  return (
    <Box display="flex" justifyContent="center" m={'14px 0'}>
      <ThemeButton size="small" onClick={handleEdit}>
        {intl.formatMessage({ id: 'global.edit' })}
      </ThemeButton>
    </Box>
  )
}

export default ButtonsToEdit
