import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { TextInput } from '../../../../ui'
import { useCombos } from '../../../combos'
import { COMPANY_FORM_CONFIG } from '../../constants'
import { useCompanyProfileContext } from '../../contexts'

export const PublicOfferForm = ({ isPrimaryCompany = false }) => {
  const { formState, changeFormState } = useCompanyProfileContext()
  const intl = useIntl()
  const combos = useCombos([])
  const global = useSelector((state) => state.global)
  const isAdmin = ADMIN_ROLES[global.role]
  const inputProps = { formState, onChange: changeFormState, combos, intl }

  return (
    <Box>
      <Typography color="primary" variant={'h6'}>
        Datos de Registro para Oferta Pública
      </Typography>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item sx={12} sm={6}>
            <TextInput
              {...{
                ...COMPANY_FORM_CONFIG(inputProps).RESPONSABLE_LEGAL,
                readOnly: !isAdmin,
              }}
            />
          </Grid>
          <Grid item sx={12} sm={6}>
            <TextInput
              {...{
                ...COMPANY_FORM_CONFIG(inputProps).NIF_RESPONSABLE_LEGAL,
                readOnly: !isAdmin,
              }}
            />
          </Grid>
          {!isPrimaryCompany ? (
            <Grid item sx={12} sm={6}>
              <TextInput
                {...{
                  ...COMPANY_FORM_CONFIG(inputProps).IBAN_OP,
                  readOnly: !isAdmin,
                }}
              />
            </Grid>
          ) : null}
          {!isPrimaryCompany ? (
            <Grid item sx={12} sm={6}>
              <TextInput
                {...{
                  ...COMPANY_FORM_CONFIG(inputProps).SOCIEDAD,
                  readOnly: true,
                }}
              />
            </Grid>
          ) : null}
          {isPrimaryCompany ? (
            <Grid item sx={12} sm={6}>
              <TextInput
                {...{
                  ...COMPANY_FORM_CONFIG(inputProps).CODIGO_REGISTRO_INDUSTRIAL,
                }}
              />
            </Grid>
          ) : null}
          {!isPrimaryCompany ? (
            <Grid item sx={12} sm={6}>
              <TextInput
                {...{
                  ...COMPANY_FORM_CONFIG(inputProps).CODIGO_REGISTRO_INDUSTRIAL,
                  readOnly: true,
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  )
}
