import React from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Container, withStyles } from '@material-ui/core'

import { ProfileRouter, ProfileTabs } from '../../../features/profile'
import { ThemeButton } from '../../../ui'
import { logoutUser } from '../../../features/global'
import { resetAppsData } from '../../../features/applications/slices'
import { profileStyles } from './profile.styles'

export const ProfilePage = withStyles(profileStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutUser())
    dispatch(resetAppsData())
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2} display="flex" justifyContent="space-between">
          <ProfileTabs />
          <ThemeButton color="default" onClick={handleLogout}>
            {formatMessage({ id: 'pages.profile.header.button2' })}
          </ThemeButton>
        </Box>
        <ProfileRouter />
      </Container>
    </div>
  )
})
