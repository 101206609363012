import React, { useState } from 'react'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { requests } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'
import { UsersForm } from '../form'

export const UsersEditDialogComponent = ({ close, user, getUserData }) => {
  const { putById } = requests

  const [formState, setFormState] = useState(user)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleEditUser = (event) => {
    event.preventDefault()
    if (!formState.email.replace(/\s/g, '').length) {
      feedback('warning', 'Introduce un email')
    } else {
      setIsLoading(true)
      putById('/instalador', formState['id'], formState)
        .then(() => {
          setIsLoading(false)
          feedback('success', 'Datos guardados')
          getUserData(user.id)
          close()
        })
        .catch(({ response }) => {
          feedback('error', response.data.message || 'No se han podido guardar los cambios')
          setIsLoading(false)
        })
    }
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="textSecondary">
            Editar usuario
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <UsersForm
          {...{
            formState,
            close,
            onSubmit: handleEditUser,
            onChange: handleChangeForm,
            isLoading,
          }}
        />
      </DialogContent>
    </Box>
  )
}
