//We use haversine theorem from spherical trigonometry to estimate distances on (spherically-approx.) earth surface
//Recall the haversine(x)=sin²(x/2)=(1-cosx)/2
//When applied on the cosine theorem cos C = cosA cosB + sinA sinB cos c, it results in
//haversine(C) = haversine(A-B) + haversine (c) * sinA * sinB
//In terms of geodesics: C is the angle on the geodesic that joins two points, A and B are pi - latitude, and c is longitude difference,
//All in all, d= 2r asin(sqrt( haversine(lat2-lat1) + cos(lat1)*cos(lat2)*haversine(long2-long1) ))

const EARTH_RADIUS_AVG = 6371.0088

export const distHaversines = ({
  origin: { lat: lat1, lng: lng1 },
  target: { lat: lat2, lng: lng2 },
}) => {
  const toRad = (angle) => ((2 * Math.PI) / 360) * angle
  const hvsLat = Math.pow(Math.sin(toRad(lat1 - lat2) / 2), 2)
  const hvsLng = Math.pow(Math.sin(toRad(lng1 - lng2) / 2), 2)
  const HvsSol = hvsLat + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * hvsLng
  return 2 * EARTH_RADIUS_AVG * Math.asin(Math.sqrt(HvsSol))
}
