import React from 'react'
import { withStyles, Stepper, Step, StepLabel } from '@material-ui/core'

import { hStepperStyles } from './horizontal-stepper.styles'

export const HorizontalStepper = withStyles(hStepperStyles)(
  ({ classes, children, stepLabels, currentStep }) => {
    return (
      <div className={classes.root}>
        <Stepper activeStep={currentStep} alternativeLabel>
          {stepLabels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {children}
      </div>
    )
  }
)
