import React, { createContext, useContext } from 'react'

import { useAssociatesIP3Reducer } from '../hooks/associates-ip3-reducer.hook'

const AssociatesIP3Context = createContext()

export const AssociatesIP3Provider = ({ children }) => {
  const reducer = useAssociatesIP3Reducer()
  return <AssociatesIP3Context.Provider value={reducer}>{children}</AssociatesIP3Context.Provider>
}

export const useAssociatesIP3 = () => useContext(AssociatesIP3Context)
