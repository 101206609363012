import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, FormPanel } from '../../../../ui'
import { CLIENT_EDIT_FIELDS, CLIENT_EDIT_CONFIG } from '../../constants'
import { getTypeOfDocument, emailValid } from '../../../../core/utils'
import { feedback } from '../../../../core/feedback'

export const ApplicationDetailForm = ({ close, application, getApplication, updateClientInfo }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const installerEmail = useSelector((state) => state.global.email)

  const [formState, setFormState] = useState(application.usuario)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChange = useCallback(
    (e) => {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    },
    [formState]
  )

  const handleSubmit = (e) => {
    e.preventDefault()

    const docType = getTypeOfDocument(formState['identificador'])
    if (!emailValid(formState['email'])) {
      return setErrorMssg('Introduce una dirección de email válida')
    } else if (formState['tipo_identificador'] === 'NIE' && docType !== 'NIE') {
      return setErrorMssg('El identificador introducido no es un NIE válido')
    } else if (formState['tipo_identificador'] === 'NIF' && docType !== 'NIF') {
      return setErrorMssg('El identificador introducido no es un NIF válido')
    } else if (formState['email'] === installerEmail) {
      setErrorMssg('El email introducido no es válido')
    } else {
      setLoading(true)
      const payload = {
        nombre: formState.nombre,
        apellidos: formState.apellidos,
        tipo_identificador: formState.tipo_identificador,
        identificador: formState.identificador,
        email: formState.email,
        telefono: formState.telefono,
        solicitud_id: application.id,
      }
      updateClientInfo(application.usuario.id, payload)
        .then(() => {
          getApplication(application.id)
          setLoading(false)
          close()
        })
        .catch((err) => {
          if (err === 'usuario_existe') {
            feedback('error', 'El correo o NIF introducido pertenecen a otro usuario')
          }
          setLoading(false)
        })
    }
  }

  const fieldListClient = useMemo(
    () =>
      CLIENT_EDIT_FIELDS({
        combos,
        intl,
        formState,
        onChange: handleChange,
      }),
    [intl, combos, formState, handleChange]
  )

  const blockClient = useMemo(() => CLIENT_EDIT_CONFIG({ intl, formState }), [intl, formState])

  return (
    <form onSubmit={handleSubmit}>
      <FormPanel
        {...{
          data: formState,
          combos,
          intl,
          block: blockClient,
          fieldList: fieldListClient,
        }}
      />
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
        <Box mb={[3, 0]} p="0 5px">
          <ThemeButton onClick={close} variant="outlined" labelM="sm" disabled={loading}>
            {intl.formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box p="0 5px">
          <ThemeButton
            type="submit"
            color="primary"
            labelM="sm"
            loading={loading}
            disabled={loading}
          >
            {intl.formatMessage({ id: 'global.save' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
