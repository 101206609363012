import React, { useEffect } from 'react'
import { Typography, Box, Grid, CircularProgress } from '@material-ui/core'

import { TextInput, BasicListItem } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'
import {Alert} from "@material-ui/lab";
import {AddressDialog} from "../address-selector/address-dialog.component";
import {InstAddressBlock} from "./inst-address-block.component";

export const InstCupsBlock = () => {
  const { formFields, formState, checkValidCUPS } = useInspectionsContext()
  const { validCups, type } = useInspectionsContext()
  const { loadingHomeInfo } = useInspectionsContext()

  useEffect(() => {
    if (formState.cups?.length === 20) {
      checkValidCUPS(formState.cups)
    }
  }, [formState.cups]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={12}>
        <TextInput {...{ ...formFields.CUPS, disabled: loadingHomeInfo }} />
      </Grid>
      {loadingHomeInfo ? (
        <Grid item xs={12}>
          <Box p={1} display="flex" justifyContent="center">
            <CircularProgress size={20} style={{ marginRight: 15 }} />
            <Typography variant="body2" color="textSecondary">
              Recuperando datos...
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </>
  )
}
