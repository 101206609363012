export const ALL_LIST_ITEMS = (model) => {
  let items = [
    { key: 'nombre', label: 'Nombre empresa' },
    { key: 'razon_social', label: 'Razón social' },
    { key: 'cif', label: 'NIF/NIE/CIF' },
    { key: 'cod_sap', label: 'Código SAP' },
    { key: 'telefono', label: 'Teléfono' },
    { key: 'web', label: 'Web' },
    { key: 'email', label: 'Email' },
    { key: 'direccion', label: 'Dirección' },
    { key: 'cod_codigo_postal', label: 'Código Postal' },
    {
      key: 'cc_aa',
      label: 'CCAA de actuación',
      type: 'combo',
      comboId: 'cod_zona_zeus',
      multiple: true,
    },
    {
      key: 'publica',
      label: 'Acepta recibir solicitudes',
      type: 'combo',
      comboId: 'binary_values',
    },
    { key: 'registro_industrial', label: 'Registro industrial', type: 'file' },
  ]
  if (model.publica === '01') {
    const acceptFinanceItem = {
      key: 'acepta_financiacion',
      label: 'Ofrece financiación',
      type: 'combo',
      comboId: 'binary_values',
    }
    items.splice(11, 0, acceptFinanceItem)
  }
  if (model.publica === '01' && model.acepta_financiacion === '01') {
    const financeCodeItem = { key: 'codigo_entidad_financiera', label: 'Código entidad financiera' }
    items.splice(12, 0, financeCodeItem)
  }
  return items
}

export const IP_LIST_ITEMS = [
  { key: 'nombre', label: 'Nombre empresa' },
  { key: 'razon_social', label: 'Razón social' },
  { key: 'cif', label: 'NIF/NIE/CIF' },
  { key: 'telefono', label: 'Teléfono' },
  { key: 'web', label: 'Web' },
  { key: 'email', label: 'Email' },
  { key: 'direccion', label: 'Dirección' },
  { key: 'cod_codigo_postal', label: 'Código Postal' },
  { key: 'registro_industrial', label: 'Registro industrial', type: 'file' },
  { key: 'iban', label: 'IBAN' },
  { key: 'certificado_iban', label: 'Certificado IBAN', type: 'file' },
]
