import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { DialogContent, Box, Typography, CircularProgress } from '@material-ui/core'

import { SelectInput, ThemeButton } from '../../../../ui'
import { requests } from '../../../../core/ajax'
import { IBAN_VALIDATED_STATE } from '../../constants'

export const CompanySelectorDialog = ({ close, type, guildId }) => {
  const { formatMessage: fm } = useIntl()
  const history = useHistory()
  const { get } = requests

  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState('')
  const [associatedCompanies, setAssociatedCompanies] = useState([])

  const handleChangeCompanyId = (e) => setCompanyId(e.target.value)

  const handleSubmit = () => {
    if (companyId) {
      history.push(`/inspections/${type}?companyId=${companyId}`)
      close()
    }
  }

  useEffect(() => {
    setLoading(true)
    get(`/gremios/${guildId}/empresas_ip_asociadas`)
      .then(({ data }) => {
        const combo = data.map((record) => {
          const value =
            type === 'ip' && !(record.estado_iban === IBAN_VALIDATED_STATE || record.iban_validado)
              ? `${record.nombre} (IBAN no válido)`
              : !record.aceptacion_gremio
              ? `${record.nombre} (requiere confirmar vinculación)`
              : !record.activo
              ? `${record.nombre} (empresa inactiva)`
              : record.nombre
          return {
            key: record.id,
            value,
            disabled:
              !record.activo ||
              !record.aceptacion_gremio ||
              (type === 'ip' &&
                !(record.estado_iban === IBAN_VALIDATED_STATE || record.iban_validado)),
          }
        })
        setAssociatedCompanies(combo)
        if (combo.length === 1 && !combo[0].disabled) {
          setCompanyId(combo[0].key)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [get, guildId]) // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={200}>
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box p={2}>
      <DialogContent>
        <Typography variant="h6">
          {fm({ id: 'pages.jcaList.createJCADialog.description' })}
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <SelectInput
            fullWidth
            name="companyId"
            value={companyId}
            values={associatedCompanies}
            onChange={handleChangeCompanyId}
          />
        </Box>
        <Box display="flex" justifyContent="center" mt={3}>
          <Box width={150}>
            <ThemeButton fullWidth onClick={handleSubmit} color="primary" disabled={!companyId}>
              {fm({ id: 'global.continue' })}
            </ThemeButton>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  )
}
