export const tableActionsStyles = (theme) => ({
  actionRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    borderRadius: '100%',
    border: '1px solid #333',
    height: '1.7em',
    width: '1.7em',
    marginLeft: '10px',
    marginRight: '10px',
  },
})
