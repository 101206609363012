import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { POTENTIAL_TABLE_COLUMNS } from '../../constants'
import { marketerPotentialTableStyles } from './marketer-potential-table.styles'
import { TableUrlPagination } from '../../../../ui'
import { setSelectedPotentials } from '../../slices'
import { marketerPotentialsDataPreparation } from '../../logics/marketer-potentials.logics'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const MarketerPotentialTable = () => {
  const { formatMessage } = useIntl()
  const intl = useIntl()
  const dispatch = useDispatch()
  const {
    data,
    search,
    pagination: { total_potenciales: totalEntries },
    isLoading,
    selectedRows,
  } = useSelector((state) => state.marketerPotential)
  const combos = useSelector((state) => state.combos)

  const handleSelectRows = useCallback((rows) => dispatch(setSelectedPotentials(rows)), [dispatch])

  const formattedColumns = useMemo(
    () =>
      [...POTENTIAL_TABLE_COLUMNS].map((column) => {
        if (column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: formatMessage({
            id: `pages.marketer.potential.table.columns.${column.field}.title`,
          }),
          cellStyle: { ...marketerPotentialTableStyles.cell, ...column.cellStyle },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(() => {
    const selectedIds = selectedRows.map((el) => el.id)
    return marketerPotentialsDataPreparation(POTENTIAL_TABLE_COLUMNS, data, { intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, intl, selectedRows])

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      fixedColumns: {
        right: 0,
      },
      tableLayout: 'fixed',
      headerStyle: marketerPotentialTableStyles.header,
      draggable: false,
    }),
    []
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.children}
        </MTableCell>
      ),
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      components={tableComponents}
      //Not transformed in the url table interface:
      data={processedData}
      isLoading={isLoading}
      onRowClick={undefined}
      onSelectionChange={handleSelectRows}
    />
  )
}
