import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCombosByIds, setCombo } from '../../combos/slices'
import { useCombosCalls } from './combo-calls.hooks'

export const useCombos = (comboIds, comboIdsToSort = []) => {
  const dispatch = useDispatch()
  const combos = useSelector((state) => state.combos)

  useEffect(() => {
    const comboIdsArray = []
    comboIds.forEach((comboId) => {
      const order = comboIdsToSort.indexOf(comboId) > -1
      if (!combos[comboId]) {
        comboIdsArray.push({ comboId, order })
      }
    })
    if (comboIdsArray.length) {
      dispatch(setCombosByIds(comboIdsArray))
    }
  }, [comboIds]) // eslint-disable-line react-hooks/exhaustive-deps

  return combos
}

export const useManualCombo = () => {
  const dispatch = useDispatch()
  return {
    setManualCombo: (id, data) => dispatch(setCombo({ comboId: id, data })),
  }
}

export const useCustomCombo = (customCombos = [], payload) => {
  const { getTarifaByConsumo } = useCombosCalls()
  const dispatch = useDispatch()
  const combos = useSelector((state) => state.combos)

  useEffect(() => {
    customCombos.forEach((comboId) => {
      if (!combos[comboId]) {
        setCustomCombos(comboId)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const setCustomCombos = (comboId) => {
    switch (comboId) {
      case 'tarifas':
        if (payload.uso) {
          getTarifaByConsumo(payload['cidi'], payload['uso'])
            .then((data) => {
              dispatch(setCombo({ comboId: 'tarifas', data }))
            })
            .catch((response) =>
              console.error('Error recuperando custom combo', response && response.data)
            )
        }
        break
      default: {
        return console.debug('Custom combo not defined: ', comboId)
      }
    }
  }
}
