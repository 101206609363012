import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

import { useIPDetail } from '../../contexts'

export const IPAIValidations = () => {
  const { formatMessage: fm } = useIntl()
  const { ip } = useIPDetail()

  const getStatusModel = useCallback((code) => {
    if (code.startsWith('2')) {
      return {
        severity: 'success',
        icon: <CheckCircleIcon fontSize="small" style={{ color: '#4caf50' }} />,
      }
    } else if (code.startsWith('3')) {
      return {
        severity: 'warning',
        icon: <WarningIcon fontSize="small" style={{ color: '#ffcd38' }} />,
      }
    } else if (code.startsWith('4')) {
      return {
        severity: 'error',
        icon: <ErrorIcon fontSize="small" style={{ color: '#b23c17' }} />,
      }
    }
  }, [])

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1">
          {fm({ id: 'pages.ipDetail.fields.aiValidations.title' })}
        </Typography>
      </Box>
      {ip.ai_validations?.map((validation, i) => {
        const statusModel = getStatusModel(validation.code)
        const showCodeMessage =
          statusModel.severity !== 'success' ||
          (validation.validation_type === 'factura' && ip.importe_fra === 0)

        return statusModel.severity === 'error' ? (
          <Box key={i} ml={2} display="flex">
            <span style={{ marginRight: '0.5em' }}>{statusModel.icon}</span>
            <Typography
              display="inline"
              variant={statusModel.severity !== 'success' ? 'subtitle1' : 'body1'}
              color={statusModel.severity !== 'success' ? 'textPrimary' : 'textSecondary'}
              style={{ marginRight: '0.4em' }}
            >
              {fm({ id: `pages.ipDetail.fields.aiValidations.${validation.validation_type}` })}
              {showCodeMessage ? ':' : ''}
            </Typography>
            {showCodeMessage ? (
              <Typography display="inline" variant="body1" color="textSecondary">
                {validation.code_message}
              </Typography>
            ) : null}
          </Box>
        ) : null
      })}
    </>
  )
}
