import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const NewCertificateWarningDialog = ({ close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={4}>
        <Typography>
          {formatMessage({
            id: 'pages.application.detail.modal.newCertificateWarningUnavailable.message',
          })}
        </Typography>
      </Box>
      <Box textAlign="center">
        <ThemeButton color="default" labelM="lg" onClick={close}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
