import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, Switch, CircularProgress } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { toggleUserActive } from '../../slices'

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActions = withStyles(switchStyles)(({ classes, rowData, ...props }) => {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.users.data)
  const global = useSelector((state) => state.global)
  const switchDisabled = !ADMIN_ROLES[global.role] || '' + rowData.id === global.user_id
  const userState = rowData['activo']
  const [isLoading, setIsLoading] = useState(false)

  const handleToggleState = () => dispatch(toggleUserActive(rowData, users, setIsLoading))

  return isLoading ? (
    <CircularProgress size={20} style={{ display: 'inline-block', verticalAlign: 'middle' }} />
  ) : (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={userState}
      onClick={handleToggleState}
      disabled={switchDisabled}
    />
  )
})
