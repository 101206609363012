import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'

import { loginUser } from '../../features/global'
import { PrivateRouter } from './private.router'
import { PublicRouter } from './public.router'
import { loadLocalStorage } from '../../core/utils'
import {NoticesPopupComponent} from "../../features/notices";

export const BootstrapRouter = () => {
  const dispatch = useDispatch()
  const user = loadLocalStorage('apins-auth')

  const { isAuthorized, role } = useSelector((state) => state.global)

  useEffect(() => {
    if (!isAuthorized && user?.token) dispatch(loginUser(user))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => {
    return isAuthorized || user?.token ? (
      !role ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <>
          <PrivateRouter />
        </>

      )
    ) : (
      <PublicRouter />
    )
  }, [isAuthorized, user, role])
}
