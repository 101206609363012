import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const jcaSlice = createSlice({
  name: 'jca',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setJCAData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
  },
})

export const jcaReducer = jcaSlice.reducer

export const { setLoading, setJCAData } = jcaSlice.actions

export const getJCAs = (searchParams, isGuild) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 20, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }

  const endpoint = isGuild ? '/jca/tabla_gremios' : '/jca/tabla_jca'
  dispatch(setLoading(true))
  get(endpoint, config)
    .then(({ data }) => {
      dispatch(
        setJCAData({
          data: data.registros,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_registros: data['total_registros'],
          },
        })
      )
    })
    .catch(({ response }) => {
      console.error('Error recuperando JCAs', response)
      dispatch(setLoading(false))
    })
}
