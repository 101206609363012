//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const USERS_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 240,
  },
  {
    field: 'email',
    align: 'left',
    width: 280,
  },
  {
    field: 'telefono',
    align: 'left',
    width: 250,
  },
  {
    field: 'rol',
    align: 'left',
    width: 330,
    exportType: 'combo',
    comboId: 'rol_captador_grabador',
  },
]

export const USERS_TABLE_ACTIONS = [
  {
    field: 'active',
    width: 100,
    align: 'center',
    sorting: false,
    disableClick: true,
  },
]
