export const publicOfferResumeStyles = (theme) => ({
  title: {
    fontWeight: 'bold',
  },

  noChecked: {
    color: '#004a6b',
    cursor: 'pointer',
    maxWidth: '20px',
    maxHeight: '20px',
  },
})
