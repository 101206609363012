import makeStyles from '@material-ui/core/styles/makeStyles'

export const ipFilterStyles = makeStyles((theme) => ({
  filterSpace: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      '&:nth-child(-n+2)': {
        maxWidth: 230,
      },
      '&:nth-child(-n+4)': {
        flex: '1 0 21%',
      },
    },
  },
}))
