export const JCA_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}

export const JCA_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_jca',
    multiple: true,
  },
  {
    name: 'tipo_instalacion',
    comboId: 'tipo_instalacion_ab',
    multiple: true,
  },
  {
    name: 'situacion_suministro',
    comboId: 'situacion_suministro',
    multiple: true,
  },
  {
    name: 'fecha_creacion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_creacion_fin',
  },
  {
    name: 'fecha_creacion_fin',
    filterType: 'date',
    minDateField: 'fecha_creacion_inicio',
  },
  {
    name: 'fecha_jca_inicio',
    filterType: 'date',
    maxDateField: 'fecha_jca_fin',
  },
  {
    name: 'fecha_jca_fin',
    filterType: 'date',
    minDateField: 'fecha_jca_inicio',
  },
]

export const JCA_COMBOS = ['tipo_instalacion_ab', 'situacion_suministro']
