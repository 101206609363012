import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, withStyles } from '@material-ui/core'

import { feedback } from '../../../core/feedback'
import { TextInput, ThemeButton } from '../../../ui'
import { useLoginCalls } from '../../login/hooks/login-calls.hook'
import { pwdRecoveryStyles } from './pwd-recovery.styles'

export const PwdRecoveryDialogComponent = withStyles(pwdRecoveryStyles)(({ classes, close }) => {
  const { formatMessage } = useIntl()
  const { pwdRecovery, resendValidation } = useLoginCalls()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleResendValidation = (mail) => {
    resendValidation(mail).then(() => {
      setErrorMssg(formatMessage({ id: 'calls.login.error.inactiveUser.sent' }))
    })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    pwdRecovery(formState['email'])
      .then(() => {
        feedback('success', formatMessage({ id: 'pages.login.pwdRecovery.feedback.success' }))
        setLoading(false)
        close()
      })
      .catch((code) => {
        setLoading(false)
        if (code === 'inactive_user') {
          setErrorMssg('inactive_user')
          return
        }
        console.debug('Error recovery password')
        setErrorMssg('El correo introducido no es válido')
      })
  }

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h5" color="primary">
          {formatMessage({ id: 'pages.login.pwdRecovery.title' })}
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmitForm}>
          <Box mb={4}>
            <TextInput
              type="email"
              required
              value={formState['email']}
              name="email"
              title={formatMessage({ id: 'pages.login.pwdRecovery.form.email.label' })}
              placeholder={formatMessage({ id: 'pages.login.pwdRecovery.form.email.placeholder' })}
              onChange={handleChangeForm}
            />
          </Box>
          {errorMssg && (
            <Box mb={1} bgcolor="grey">
              <Typography variant="body2" color="error">
                {errorMssg === 'inactive_user' ? (
                  <span>
                    {formatMessage({ id: 'calls.login.error.inactiveUser.description.before' })}
                    <span
                      onClick={() => handleResendValidation(formState['email'])}
                      className={classes.link}
                    >
                      {formatMessage({ id: 'calls.login.error.inactiveUser.description.link' })}
                    </span>
                    {formatMessage({ id: 'calls.login.error.inactiveUser.description.after' })}
                  </span>
                ) : (
                  errorMssg
                )}
              </Typography>
            </Box>
          )}
          <Box mb={2}>
            <ThemeButton type="submit" fullWidth loading={loading}>
              {formatMessage({ id: 'pages.login.pwdRecovery.form.button.1.label' })}
            </ThemeButton>
          </Box>
          <ThemeButton variant="outlined" fullWidth disabled={loading} onClick={close}>
            {formatMessage({ id: 'pages.login.pwdRecovery.form.button.2.label' })}
          </ThemeButton>
        </form>
      </Box>
    </Box>
  )
})
