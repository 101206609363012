import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { Applications, Consult, Guilds, GuildUsers, OffersPage } from '../../pages'
import {
  PrivateAreaNavigationGuidePage,
  MarketerUsers,
  Profile,
  NewNedgiaAppsPage,
} from '../../pages'
import { Tariffs, Users, MarketerPotential, Boilers, Inspections, IP, JCA } from '../../pages'
import { CorporateIdentityPage, AssociatesIP3, EIDetail } from '../../pages'

export const usePrivateRoutes = () => {
  const global = useSelector((state) => state.global)

  const getPrivateRoutes = useCallback(() => {
    let router = {}
    switch (global.role) {
      case 'gremio':
        router = {
          routes: [
            { path: '/guildUsers', component: GuildUsers },
            { path: '/profile', component: Profile },
            { path: '/private-area-navigation-guide', component: PrivateAreaNavigationGuidePage },
            { path: '/otherApps', component: NewNedgiaAppsPage },
            { path: '/corporative-identity', component: CorporateIdentityPage },
          ],
          redirect: '/profile',
        }
        if (global.gremio?.captadora) {
          router.routes.push({ path: '/applications', component: Applications })
          router.routes.push({ path: '/offers', component: OffersPage })
          router.routes.push({ path: '/consult2', component: Boilers })
          router.routes.push({ path: '/consult', component: Consult })
          router.routes.push({ path: '/associates/:id', component: EIDetail })
          router.routes.push({ path: '/associates', component: Guilds })
        }
        if (global.gremio?.grabadora) {
          router.routes.push({ path: '/associates-ip3', component: AssociatesIP3 })
          router.routes.push({ path: '/ip', component: IP })
          router.routes.push({ path: '/jca', component: JCA })
          router.routes.push({ path: '/inspections/:inspectionType', component: Inspections })
        }
        break
      case 'comercializadora':
        router = {
          routes: [
            { path: '/profile', component: Profile },
            { path: '/marketerUsers', component: MarketerUsers },
            { path: '/marketerUsers/:userId', component: MarketerUsers },
            { path: '/marketerPotential', component: MarketerPotential },
          ],
          redirect: '/marketerUsers',
        }
        break
      case 'administrador':
      case 'instalador':
        router = {
          routes: [
            { path: '/profile', component: Profile },
            { path: '/applications', component: Applications },
            { path: '/tariffs', component: Tariffs },
            { path: '/users', component: Users },
            { path: '/consult', component: Consult },
            { path: '/consult2', component: Boilers },
            { path: '/offers', component: OffersPage },
            { path: '/private-area-navigation-guide', component: PrivateAreaNavigationGuidePage },
            { path: '/otherApps', component: NewNedgiaAppsPage },
            { path: '/corporative-identity', component: CorporateIdentityPage },
          ],
          redirect: '/applications',
        }
        break
      case 'administrador_grabador':
      case 'instalador_grabador':
        router = {
          routes: [
            { path: '/profile', component: Profile },
            { path: '/users', component: Users },
            { path: '/inspections/:inspectionType', component: Inspections },
            { path: '/ip', component: IP },
            { path: '/jca', component: JCA },
          ],
          redirect: '/profile',
        }
        break
      case 'admin_captador_grabador':
      case 'instalador_captador_grabador':
        router = {
          routes: [
            { path: '/profile', component: Profile },
            { path: '/applications', component: Applications },
            { path: '/tariffs', component: Tariffs },
            { path: '/users', component: Users },
            { path: '/consult', component: Consult },
            { path: '/consult2', component: Boilers },
            { path: '/offers', component: OffersPage },
            { path: '/private-area-navigation-guide', component: PrivateAreaNavigationGuidePage },
            { path: '/otherApps', component: NewNedgiaAppsPage },
            { path: '/corporative-identity', component: CorporateIdentityPage },
            { path: '/inspections/:inspectionType', component: Inspections },
            { path: '/ip', component: IP },
            { path: '/jca', component: JCA },
          ],
          redirect: '/applications',
        }
        break
      default:
        router = { routes: [{ path: '/profile', component: Profile }], redirect: '/profile' }
    }

    return router
  }, [global])

  return { getPrivateRoutes }
}
