import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { guildsStyles } from './guilds.styles'
import { GuildsTable } from '../../../features/guilds/components/table/guilds-table.component'
import { GuildFilters, GuildsContext } from '../../../features/guilds'
export const GuildsPage = withStyles(guildsStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <GuildsContext>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.guilds.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <GuildFilters />
        </Box>
        <Box>
          <GuildsTable />
        </Box>
      </Container>
    </GuildsContext>
  )
})
