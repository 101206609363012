export const tariffNewStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '40px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 10px',
    },
  },
  containerWrapper: {
    alignItems: 'center',
    padding: '40px 50px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.02)',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px',
    },
  },
  buttons: {
    height: '32px',
    width: '180px',
    fontSize: '14px',
  },
  upload: {
    color: '#63666a',
  },
  gridRow: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: '24px 0 0 0',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  gridRowHeader: {
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
  },
  divider: {
    margin: '12px 0',
  },
  return: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 15px 0',
    paddingLeft: '40px',
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
    },
  },
})
