import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { feedback } from '../../../core/feedback'

export const useBoilersCalls = () => {
  const { get, getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getBoilersProvincias = useCallback(
    () =>
      new Promise((resolve, reject) => {
        get(`/cuartos_calderas/provincias`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getBoilersMunicipios = useCallback(
    (provincia) =>
      new Promise((resolve, reject) => {
        getById(`/cuartos_calderas/municipios`, provincia)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getBoilersViviendas = useCallback(
    (municipio, config) =>
      new Promise((resolve, reject) => {
        getById(`/cuartos_calderas/viviendas`, municipio, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 404:
                feedback('error', formatMessage({ id: 'calls.calderas.error.notFound' }))
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getBoilersMunicipios,
    getBoilersProvincias,
    getBoilersViviendas,
  }
}
