import packageJson from '../../../../../package.json'

const { REACT_APP_ENVIRONMENT } = process.env

export let FOOTER_LINKS = [
  {
    id: 'layout.public.footer.upper.contact.label',
    link: 'https://www.nedgia.es/quierogasnatural/contacto/',
  },
  {
    id: 'layout.public.footer.upper.accesibility.label',
    link: 'https://www.nedgia.es/quierogasnatural/accesibilidad/',
  },
  {
    id: 'layout.public.footer.upper.legalAdvice.label',
    link: 'https://www.nedgia.es/quierogasnatural/nota-legal/',
  },
  {
    id: 'layout.public.footer.upper.cookiesAdvice.label',
    link: 'https://www.nedgia.es/quierogasnatural/politica-de-cookies/',
  },
  {
    id: 'layout.public.footer.upper.privacyPolicy.label',
    link: 'https://www.nedgia.es/politica-de-privacidad/',
  },
  {
    id: 'layout.public.footer.upper.media.label',
    link: 'https://www.nedgia.es/nuestra-presencia-en-redes-sociales/',
  },
  {
    id: 'layout.public.footer.upper.blog.label',
    link: 'https://www.nedgia.es/blog-gas-natural/',
  },
]

export let CONTACT_PHONE = '900 500 405'

export let DEFAULT_ROUTE = '/login'

export const APP_VERSION_MSSG = `Version ${packageJson.version} (${REACT_APP_ENVIRONMENT})`
