import React from 'react'
import { useSelector } from 'react-redux'
import { withStyles, Switch } from '@material-ui/core'

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActions = withStyles(switchStyles)(({ classes, rowData }) => {
  const global = useSelector((state) => state.global)
  const switchDisabled = '' + rowData.id === global.user_id
  const userState = rowData['activo']

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={userState}
      disabled={switchDisabled}
    />
  )
})
