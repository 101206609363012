import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../../../core/ajax/contexts'
import { feedback } from '../../../../../core/feedback'
import { useCompanyProfileContext } from '../../../contexts'
import { downloadFile } from '../../../../../core/utils'

export const usePublicOfferCalls = () => {
  const { get, getById, post, put } = useAjaxContext()
  const { formatMessage } = useIntl()
  const { company } = useCompanyProfileContext()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getPublicOfferData = useCallback(
    (empresa_ca_id) =>
      new Promise((resolve, reject) => {
        get(`/campana/listado_campanas/${empresa_ca_id}`)
          .then((response) => resolve(response.data))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const downloadOPFile = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/documento', id)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getDocumentById = useCallback(
    (fileId) =>
      new Promise((resolve, reject) => {
        getById('/documento', fileId)
          .then((response) => {
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelOp = useCallback(
    (poId, poEstado, publicOfferData) =>
      new Promise((resolve, reject) => {
        put(`/campana/adhesiones/${poId}`, {
          estado: poEstado,
        })
          .then((response) => {
            resolve(response)
            publicOfferData(company.id)
            feedback(
              'success',
              formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.cancelop' })
            )
          })
          .catch(({ response }) => {
            if (
              response?.request?.status === 400 &&
              response?.data?.code === 3414 &&
              response?.data?.message
            ) {
              feedback('error', response.data.message)
              return reject(response.data.message)
            } else {
              feedback(
                'error',
                formatMessage({ id: 'pages.profile.company.guild.publicOffer.rejected.error' })
              )
              return reject(
                formatMessage({ id: 'pages.profile.company.guild.publicOffer.rejected.error' })
              )
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const addObservaciones = useCallback(
    (poId, publicOfferData, payload) =>
      new Promise((resolve, reject) => {
        put(`/campana/adhesiones/${poId}`, payload)
          .then((response) => {
            resolve(response)
            publicOfferData(company.id)
            feedback(
              'success',
              formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.observaciones.request',
              })
            )
          })
          .catch(({ response }) => {
            if (
              response?.request?.status === 400 &&
              response?.data?.code === 11205 &&
              response?.data?.message
            ) {
              feedback('error', response.data.message)
              return reject(response.data.message)
            } else {
              feedback(
                'error',
                formatMessage({
                  id: 'pages.profile.company.guild.publicOffer.info.status.observaciones.reject',
                })
              )
              return reject(
                formatMessage({
                  id: 'pages.profile.company.guild.publicOffer.info.status.observaciones.reject',
                })
              )
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAddPO = useCallback(
    (publicOffersToSend, generalConditions, publicOfferData, companyState) =>
      new Promise((resolve, reject) => {
        post('/campana/adherirse', {
          empresa_ca_id: company.id,
          campanas: publicOffersToSend,
          acepta_condiciones_generales_op: generalConditions ? generalConditions : companyState,
        })
          .then((response) => {
            resolve(response)
            feedback(
              'success',
              formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.succes' })
            )
          })
          .catch(({ response }) => {
            if (
              response?.request?.status === 400 &&
              [3408, 3421].includes(response?.data?.code) &&
              response?.data?.message
            ) {
              feedback('error', response.data.message)
              return reject(response.data.message)
            } else {
              feedback(
                'error',
                formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.reject' })
              )
              return reject(
                formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.reject' })
              )
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postAutoAddPO = useCallback(
    (markets, generalConditions, publicOfferData, active = true) =>
      new Promise((resolve, reject) => {
        post('/campana/adherirse_automaticamente', {
          empresa_ca_id: company.id,
          mercados: markets,
          acepta_condiciones_generales_op: generalConditions,
          acepta_adhesion_masiva: active,
          automatico: active
        })
          .then((response) => {
            resolve(response)
            feedback(
              'success',
              formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.succes' })
            )
            publicOfferData(company.id)
          })
          .catch(({ response }) => {
            if (
              response?.request?.status === 400 &&
              [3408, 3421].includes(response?.data?.code) &&
              response?.data?.message
            ) {
              feedback('error', response.data.message)
              return reject(response.data.message)
            } else {
              feedback(
                'error',
                formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.reject' })
              )
              return reject(
                formatMessage({ id: 'pages.profile.company.guild.info.publicOffer.add.reject' })
              )
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    cancelOp,
    addObservaciones,
    getPublicOfferData,
    getDocumentById,
    postAddPO,
    downloadOPFile,
    postAutoAddPO,
  }
}
