import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography, Link, FormControl, MenuItem, Select } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import GetAppIcon from '@material-ui/icons/GetApp'

import {
  ES_CI_HELP_URLS,
  CORPORATE_IDENTITY_STUCTURE,
  CORPORATE_IDENTITY_FIELDS,
} from '../constants/corporate-identity-constants'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { corporateIdentityDownloadsStyles } from './corporate-identify-downloads.styles'

export const CorporateIdentityDownloadsComponent = withStyles(corporateIdentityDownloadsStyles)(
  ({ classes, corporateIdentity }) => {
    const { putById } = requests
    const { formatMessage } = useIntl()

    const [language, setLanguage] = useState('ES')
    const [ciFormState, setCiFormState] = useState(corporateIdentity)

    const handleChangeLanguage = (event) => setLanguage(event.target.value)

    const handleDownloadDoc = (e, url, name) => {
      e.preventDefault()
      const payload = { ...ciFormState, [name]: true }

      putById('/descargas', ciFormState.id, payload)
        .then(({ data }) => {
          setCiFormState(data)
        })
        .catch(({ response }) => {
          feedback(
            'error',
            (response.data && response.data.message) ||
              formatMessage({ id: 'pages.profile.user.feedback.2' })
          )
        })
      window.open(url[language], '_blank')
    }

    return (
      <Box bgcolor="white" p={4}>
        <Box textAlign="center" className={[classes.section, classes.sectionMain].join(' ')}>
          <Box className={classes.sectionHeader}>
            <Typography variant="h6" color="primary" className={classes.title}>
              {formatMessage({ id: 'pages.corporateIdentity.title' })}
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.title}>
              {formatMessage({ id: 'pages.corporateIdentity.subtitle' })}
            </Typography>
          </Box>

          <Grid container justifyContent="center" spacing={4}>
            {ES_CI_HELP_URLS.map((CiItem) => (
              <Grid className={classes.gridBlock} item key={CiItem.title}>
                <Link
                  className={classes.link}
                  onClick={(e) => handleDownloadDoc(e, CiItem.url, CiItem.name)}
                >
                  <GetAppIcon style={{ fontSize: '2em' }} />
                  <Typography style={{ marginBottom: '0.5em', fontSize: '1em' }}>
                    {CiItem.title}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box textAlign="center" className={classes.section}>
          <Box className={classes.sectionHeader}>
            <Typography variant="h6" color="primary" className={classes.title}>
              {'Recursos'}
            </Typography>
          </Box>

          <FormControl style={{ position: 'absolute', right: 0, top: 0 }}>
            <Box fontWeight="fontWeightBold">
              <Typography color="primary">{'Idioma Recursos'}</Typography>
            </Box>
            <Select value={language} onChange={handleChangeLanguage}>
              <MenuItem value={'ES'}>{'Español'}</MenuItem>
              <MenuItem value={'CA'}>{'Català'}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Grid
          container
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}
          spacing={4}
        >
          {CORPORATE_IDENTITY_STUCTURE.map((CiItem) => (
            <Grid item xs={12} sm={12} md={6} className={classes.gridBlock} key={CiItem.title}>
              <Typography variant="body1" color="textPrimary" className={classes.header}>
                {CiItem.title}
              </Typography>
              <Grid container className={classes.gridSubblock}>
                {CiItem.links.map((e, i) => {
                  const itemLink = CORPORATE_IDENTITY_FIELDS[e]
                  return (
                    <Grid item xs={12} sm={12} md={6} className={classes.gridBlock} key={i}>
                      <Link
                        className={classes.link}
                        onClick={(event) => handleDownloadDoc(event, itemLink.url, itemLink.name)}
                      >
                        {itemLink.url[language].slice(-3) === 'pdf' ? (
                          <GetAppIcon style={{ fontSize: '2em' }} />
                        ) : (
                          <img src={itemLink.url[language]} style={{ width: '7em' }} alt="" />
                        )}
                        <Typography color="primary" style={{ margin: '0.2em 0em' }}>
                          {itemLink.title['ES']}
                        </Typography>
                      </Link>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }
)
