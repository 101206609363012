import 'date-fns'
import esLocale from 'date-fns/locale/es'
import React, { useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useIntl } from 'react-intl'
import { withStyles, FormHelperText, Box, Typography, TextField } from '@material-ui/core'

import { textInputStyles } from './text-input.styles'
import { ThemeButton } from '../theme-button'

export const CustomTextInputDatepickerButton = React.memo(
  withStyles(textInputStyles)(({ classes, readOnly, buttonMessage, hiddenInput, ...props }) => {
    const [open, setOpen] = useState(false)
    const objectDate = props.value
    const { formatMessage } = useIntl()
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const input = (
      <TextField
        variant="outlined"
        value={objectDate || ''}
        disabled={true}
        InputProps={{ className: classes.smallInput }}
      />
    )
    return (
      <div className={classes.root}>
        {props.title ? (
          <Typography variant={`subtitle${typographyVariant}`}>{props.title}</Typography>
        ) : null}
        {readOnly ? (
          <Typography
            variant={`body${typographyVariant}`}
            color="textSecondary"
            style={{ whiteSpace: 'pre-line' }}
          >
            {props.value ? props.value : '--'}
          </Typography>
        ) : (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <Box display="flex" alignItems="center">
                <DatePicker
                  {...props}
                  open={open}
                  disableToolbar
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  name={props.name}
                  value={objectDate}
                  onChange={(date) => {
                    props.onChange({ target: { name: props.name, value: date } })
                  }}
                  // InputProps={{ className: classes.smallInput }}
                  okLabel={formatMessage({ id: 'picker.ok' })}
                  cancelLabel={formatMessage({ id: 'picker.cancel' })}
                  onAccept={() => setOpen(false)}
                  onClose={() => setOpen(false)}
                  TextFieldComponent={() => (hiddenInput ? null : input)}
                />
                <ThemeButton
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={() => setOpen((isOpen) => !isOpen)}
                >
                  {buttonMessage}
                </ThemeButton>
              </Box>
            </MuiPickersUtilsProvider>
            <FormHelperText id="my-helper-text" error={props.error}>
              {props.error ? props.errorMessage : props.helpMessage}
            </FormHelperText>
          </>
        )}
      </div>
    )
  })
)
