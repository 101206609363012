import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'
import {} from '../../'

export const SolveAlertDialog = ({
  close,
  id,
  putOnTask,
  application,
  forceRefresh,
  setForceRefresh,
}) => {
  const { formatMessage } = useIntl()

  const [formState, setFormState] = useState({ estado: '50', alerta: true })

  const handleChangeObservations = (e) => {
    const {
      target: { value, name },
    } = e
    setFormState({ ...formState, [name]: value })
  }
  const handleSolve = () => {
    putOnTask(id, formState, application).then((response) => {
      close()
      setForceRefresh(!forceRefresh)
    })
  }

  return (
    <Box pb={4} pl={4} pr={4} mb={1}>
      <Typography variant="h4" color="primary" align="center" paragraph>
        {formatMessage({
          id: 'pages.application.detail.panels.6.observaciones.title',
        })}
      </Typography>
      <Box mb={2} mt={3}>
        <TextInput
          multiline={true}
          fullWidth={true}
          required={true}
          title={'Observaciones'}
          value={formState.observaciones_peticionario}
          name="observaciones_peticionario"
          onChange={handleChangeObservations}
          maxLength={250}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton labelM="large" color="primary" onClick={handleSolve}>
          Resolver Incidencia
        </ThemeButton>
      </Box>
    </Box>
  )
}
