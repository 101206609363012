import React from 'react'
import { Container } from '@material-ui/core'

import { NewNedgiaAppGuidePanel } from '../../../features/new-nedgia-apps/components'

export const NewNedgiaAppsPage = () => {
  return (
    <div>
      <Container maxWidth="lg">
        <NewNedgiaAppGuidePanel />
      </Container>
    </div>
  )
}
