import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setTariffsData: (state, { payload }) => {
      state.data = payload.data
      state.search = payload.search
      state.pagination = payload.pagination
      state.isLoading = false
    },
  },
})

export const tariffsReducer = tariffsSlice.reducer

export const { setLoading, setTariffsData } = tariffsSlice.actions

export const setTariffs = (searchParams) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 50, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
    // params: { ...restSearchParams },
  }

  get('/tarifa/obtenerTarifasInstalador', config)
    .then(({ data }) => {
      // history.push({ search: `?${JSON.stringify(config.params)}` })
      dispatch(
        setTariffsData({
          data: data.tarifas,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_tarifas: data['total_tarifas'],
          },
          // search: { ...config.params, total_solicitudes: data['total_solicitudes'] },
        })
      )
      //dispatch(setLoading(false))
    })
    .catch(({ response }) => {
      console.debug('Error recuperando solicitudes', response)
      dispatch(setLoading(false))
    })
  dispatch(setLoading(true))
}
