import React from 'react'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'

import { AcceptationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'

export const useSignupDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const warnWrongCif = (callback) => {
    const NEDGIA_COLABORADORES_URL = 'https://www.nedgia.es/colaboradores/ser-colaborador'
    const textComponent = (
      <>
        <Typography paragraph>
          {formatMessage({ id: 'pages.signup.notify.wrongCif.description.1' })}
        </Typography>
        {formatMessage({
          id: 'pages.signup.notify.wrongCif.description.2',
        })}{' '}
        <a target="_blank" href={NEDGIA_COLABORADORES_URL} rel="noopener noreferrer">
          {NEDGIA_COLABORADORES_URL}
        </a>{' '}
        {formatMessage({
          id: 'pages.signup.notify.wrongCif.description.3',
        })}
      </>
    )
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.notify.wrongCif.title' }),
        textComponent,
        yesText: formatMessage({ id: 'pages.signup.notify.wrongCif.yes' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }
  const warnWrongCifTolerant = (callback) => {
    const NEDGIA_COLABORADORES_EMAIL_LINK = 'mailto:canal_oferta_publica@nedgia.es'
    const NEDGIA_COLABORADORES_EMAIL = 'canal_oferta_publica@nedgia.es'
    const textComponent = (
      <>
        <Typography paragraph>
          {formatMessage({ id: 'pages.signup.notify.wrongCifTolerant.description.1' })}
        </Typography>
        {formatMessage({
          id: 'pages.signup.notify.wrongCifTolerant.description.2',
        })}{' '}
        <a target="_blank" href={NEDGIA_COLABORADORES_EMAIL_LINK} rel="noopener noreferrer">
          {NEDGIA_COLABORADORES_EMAIL}
        </a>{' '}
        {formatMessage({
          id: 'pages.signup.notify.wrongCifTolerant.description.3',
        })}
        <ul>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.1',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.2',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.3',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.4',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.5',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.6',
            })}
          </li>
        </ul>
        <Typography paragraph>
          {formatMessage({
            id: 'pages.signup.notify.wrongCifTolerant.description.4',
          })}
        </Typography>
        <Typography paragraph style={{ fontWeight: 600 }}>
          {formatMessage({
            id: 'pages.signup.notify.wrongCifTolerant.description.5',
          })}
        </Typography>
      </>
    )
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.notify.wrongCif.title' }),
        textComponent,
        yesText: formatMessage({ id: 'pages.signup.notify.wrongCif.yes' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const notifySuccessfulSignup = (callback) => {
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.feedback.1.title' }),
        text: formatMessage({ id: 'pages.signup.feedback.1.text' }),
        yesText: formatMessage({ id: 'global.return' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }
  const notifySignupFailure = (error) => {
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.feedback.2.title' }),
        text: error || formatMessage({ id: 'pages.signup.feedback.2.text' }),
        yesText: formatMessage({ id: 'global.return' }),
      },
      type: 'centered',
    })
  }

  return {
    warnWrongCif,
    warnWrongCifTolerant,
    notifySignupFailure,
    notifySuccessfulSignup,
  }
}
