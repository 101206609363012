import React from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, withStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import { tableActionsStyles } from './table-actions.style'
import { useGuildsContext } from '../../contexts/guilds.context'
import { useGuildDialogs } from '../../hooks/guilds-dialogs.hook'
import { GUILD_TABLE_STATES } from '../../constants'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const companyId = props.rowData['id']
  const belongsToTheGuild = props.rowData['aceptacion_gremio'] === GUILD_TABLE_STATES.accepted
  const { warnGuildEnterpriseDeletion } = useGuildDialogs()

  const { acceptCompany, deleteCompany } = useGuildsContext()
  const global = useSelector((state) => state.global)

  const handleDelete = () => {
    deleteCompany(global.gremio_id, companyId)
  }

  const handleDeleteRequest = () => {
    warnGuildEnterpriseDeletion(handleDelete)
  }

  const handlePut = () => {
    acceptCompany(global.gremio_id, companyId)
  }

  const guildsButtons = {
    delete: {
      onClick: handleDeleteRequest,
      icon: <DeleteIcon />,
    },
    accept: {
      onClick: handlePut,
      icon: <DoneIcon />,
    },
  }

  const availableActions = ['delete']
  if (!belongsToTheGuild) {
    availableActions.unshift('accept')
  }
  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={guildsButtons[action].onClick}
          >
            {guildsButtons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})
