export const filterDateStyles = (theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'baseline',
    '&>div': {
      marginTop: '0',
    },
  },
  label: {
    marginRight: '0.5em',
  },
})
