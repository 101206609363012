import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { feedback } from '../../../core/feedback'
import { requests } from '../../../core/ajax'
import { isValidCIF, isValidNIE, isValidNIF } from '../../../core/utils'
import { COMPANY_PROFILE_ACTION_TYPES } from '../constants'
import { assembleInstallerPrimaryPutPayload, assembleInstallerSecondaryPutPayload } from '../logics'
import { logoPostDto, assembleGuildPutPayload, assembleMarketerPutPayload } from '../logics'
import { useProfileCalls, useProfileDialogs } from './index'
import { loginUser } from '../../global'
import { downloadFile } from '../../../core/utils'

export const useCompanyProfileActions = (state, dispatch) => {
  const reduxDispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const { formatMessage: fm } = useIntl()
  const { getById, putById, post } = requests
  const { warnWrongCifTolerant } = useProfileDialogs()
  const { checkCompanySAPCode } = useProfileCalls()
  const { company, formState, formStateSaved } = state
  const { formatMessage } = useIntl()

  const changeFormState = (e) => {
    const { name, value } = e.target
    dispatch({
      type: COMPANY_PROFILE_ACTION_TYPES.CHANGE_FORM_STATE,
      payload: { key: name, value },
    })
    //TO DO: Revisar si se puede quitar el workaround de los campos de gremio
    if (formStateSaved && ['gremio_id,aceptacion_gremio'].indexOf(e.target.name) === -1) {
      setFormStateSaved(false)
    }
  }

  const setFormStateSaved = (value) => {
    dispatch({
      type: COMPANY_PROFILE_ACTION_TYPES.SET_FORM_STATE_SAVED,
      payload: value,
    })
  }

  const getInstallerPrimaryCompany = (companyId) => {
    dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_LOADING, payload: true })
    getById('/empresa', companyId)
      .then(({ data }) => {
        const payloadData = {
          ...data,
          publica: data.publica ? '01' : '00',
          acepta_financiacion: data.acepta_financiacion ? '01' : '00',
          acepta_uso_marca:
            data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : '',
          cc_aa: data.empresas_filiales.map((el) => '' + el.comunidad_autonoma),
        }
        dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: payloadData })
      })
      .catch(({ response }) =>
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: response.response.data,
        })
      )
  }

  const putCheckOp = (marketerId, comunidadId) =>
    new Promise((resolve, reject) => {
      putById(`/comercializadoras/${marketerId}/checks_op`, comunidadId)
        .then(({ data }) => {
          const payloadData = comunidadId

          dispatch({
            type: COMPANY_PROFILE_ACTION_TYPES.COMPANY_SET_CHECK_OP,
            payload: payloadData,
          })
          setFormStateSaved(true)
          reduxDispatch(loginUser(global))
          return resolve(payloadData)
        })
        .catch(({ response }) => {
          // feedback('error', error.message)
          return reject(response)
        })
    })

  const getDocumentById = useCallback(
    (fileId) =>
      new Promise((resolve, reject) => {
        getById('/documento', fileId)
          .then((response) => {
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const downloadOPFile = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/documento', id)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putInstallerPrimaryCompany = (additionalFormState) =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_SAVING, payload: true })
      let payload = assembleInstallerPrimaryPutPayload(state.formState)
      if (additionalFormState) {
        payload = { ...payload, ...additionalFormState }
      }
      putById('/empresa', state.company.id, payload)
        .then(({ data }) => {
          const payloadData = {
            ...data,
            publica: data.publica ? '01' : '00',
            acepta_financiacion: data.acepta_financiacion ? '01' : '00',
            acepta_uso_marca:
              data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : '',
            //Workaround añadiendo lo que había antes del put en cc_aa y empresas_filiales porque la API no devuelve empresas_filiales
            cc_aa: state.company.cc_aa,
            empresas_filiales: state.company.empresas_filiales,
          }
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: payloadData })
          if (
            company.captadora !== formState.captadora ||
            company.grabadora !== formState.grabadora
          ) {
            feedback('success', fm({ id: 'pages.profile.company.feedback.2' }), {
              autoClose: false,
            })
          } else {
            feedback('success', fm({ id: 'pages.profile.company.feedback.1' }))
          }
          setFormStateSaved(true)
          reduxDispatch(loginUser(global))
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_SAVING, payload: false })
          return resolve(payloadData)
        })
        .catch(({ response }) => {
          let error = { message: fm({ id: 'pages.profile.user.feedback.2' }) }
          if (response?.status === 400 && response?.data?.code) {
            if (response.data.code === 3922) {
              error = {
                ...response.data,
                message: fm({ id: 'calls.profile.error.installerInCA.description' }),
              }
            } else if (response.data.code === 3923) {
              error = {
                ...response.data,
                message: fm({ id: 'calls.profile.error.tariffInCA.description' }),
              }
            } else {
              error = response.data
            }
          }
          dispatch({
            type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
            payload: error,
          })
          // feedback('error', error.message)
          return reject(error)
        })
    })

  const updateInstallerPrimaryCompany = () =>
    new Promise((resolve, reject) => {
      const { formState, company } = state
      if (
        formState.grabadora &&
        formState.iban &&
        !(company.d_certificado_iban_id || formState.certificado_iban)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: { message: 'Si has informado un IBAN, debes añadir un Certificado IBAN' },
        })
        reject()
      } else if (
        formState.grabadora &&
        !formState.iban &&
        (company.d_certificado_iban_id || formState.certificado_iban)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: { message: 'Si has añadido un Certificado IBAN, debes informar el IBAN' },
        })
        reject()
      } else if (
        formState.captadora &&
        !company.registro_industrial_id &&
        !formState.registro_industrial_nuevo
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: { message: 'Añade el documento de Registro Industrial' },
        })
        reject()
      } else if (formState.responsable_legal && !formState.nif_responsable_legal) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has informado de un Responsable Legal, debes informar el campo NIF/NIE/CIF Responsable Legal',
          },
        })
        reject()
      } else if (formState.nif_responsable_legal && !formState.responsable_legal) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has informado de un NIF/NIE/CIF Responsable Legal, debes informar el campo Responsable Legal',
          },
        })
        reject()
      } else if (
        formState.nif_responsable_legal &&
        !isValidNIF(formState.nif_responsable_legal) &&
        !isValidNIE(formState.nif_responsable_legal) &&
        !isValidCIF(formState.nif_responsable_legal)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message: 'El formato del NIF/NIE/CIF del Responsable Legal no es correcto.',
          },
        })
        reject()
      } else {
        if (!company.captadora && formState.captadora) {
          checkCompanySAPCode(company.cif).then((data) => {
            if (data.exists) {
              return putInstallerPrimaryCompany({ cod_sap: parseInt(data.cod_sap) })
            } else {
              warnWrongCifTolerant(putInstallerPrimaryCompany)
            }
          })
        } else {
          putInstallerPrimaryCompany()
        }
      }
    })

  const getInstallerSecondaryCompany = (companyId) => {
    dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_LOADING, payload: true })
    getById('/empresa_ca', companyId)
      .then(({ data }) => {
        const payloadData = {
          ...data,
          publica: data.publica ? '01' : '00',
          acepta_uso_marca:
            data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : '',
        }
        dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: payloadData })
      })
      .catch(({ response }) =>
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: response.response.data,
        })
      )
  }

  const putInstallerSecondaryCompany = () =>
    new Promise((resolve, reject) => {
      dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_SAVING, payload: true })
      let payload = assembleInstallerSecondaryPutPayload(state.formState)
      putById('/empresa_ca', state.company.id, payload)
        .then(({ data }) => {
          const payloadData = {
            ...data,
            publica: data.publica ? '01' : '00',
            acepta_uso_marca:
              data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : '',
          }
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: payloadData })
          feedback('success', fm({ id: 'pages.profile.user.feedback.1' }))
          setFormStateSaved(true)
          reduxDispatch(loginUser(global))
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_SAVING, payload: false })
          return resolve(payloadData)
        })
        .catch(({ response }) => {
          let error = { message: fm({ id: 'pages.profile.user.feedback.2' }) }
          if (response?.data?.message) {
            error = response.data
          }
          dispatch({
            type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
            payload: error,
          })
          feedback('error', error.message)
          return reject(error)
        })
    })

  const updateInstallerSecondaryCompany = () =>
    new Promise((resolve, reject) => {
      const { formState, company } = state
      if (
        formState.iban_op &&
        !(company.certificado_iban_op || formState.certificado_iban_op_nuevo)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has informado un Código IBAN, debes añadir el documento Certificado Bancario con Iban y código Swift',
          },
        })
        reject()
      } else if (
        !formState.iban_op &&
        (company.certificado_iban_op || formState.certificado_iban_op_nuevo)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has añadido un Certificado Bancario con Iban y código Swift, debes informar el Código IBAN',
          },
        })
        reject()
      } else if (formState.responsable_legal && !formState.nif_responsable_legal) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has informado de un Responsable Legal, debes informar el campo NIF/NIE/CIF Responsable Legal',
          },
        })
        reject()
      } else if (formState.nif_responsable_legal && !formState.responsable_legal) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message:
              'Si has informado de un NIF/NIE/CIF Responsable Legal, debes informar el campo Responsable Legal',
          },
        })
        reject()
      } else if (
        formState.nif_responsable_legal &&
        !isValidNIF(formState.nif_responsable_legal) &&
        !isValidNIE(formState.nif_responsable_legal) &&
        !isValidCIF(formState.nif_responsable_legal)
      ) {
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: {
            message: 'El formato del NIF/NIE/CIF del Responsable Legal no es correcto.',
          },
        })
        reject()
      } else {
        putInstallerSecondaryCompany()
          .then((data) => resolve(data))
          .catch((error) => reject(error))
      }
    })

  const getGuildCompany = (guildId) => {
    getById('/gremios', guildId)
      .then(({ data }) => {
        data.acepta_uso_marca =
          data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : ''
        dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: data })
      })
      .catch(({ response }) =>
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: response.response.data,
        })
      )
  }

  const updateGuildCompany = () => {
    return new Promise((resolve, reject) => {
      const payload = assembleGuildPutPayload(state.formState)
      putById('/gremios', state.company.id, payload)
        .then(({ data }) => {
          data.acepta_uso_marca =
            data.acepta_uso_marca === true ? '01' : data.acepta_uso_marca === false ? '00' : ''
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: data })
          feedback('success', fm({ id: 'pages.profile.user.feedback.1' }))
          resolve(data)
        })
        .catch(({ response }) => {
          let error = { message: fm({ id: 'pages.profile.user.feedback.2' }) }
          if (response?.data?.message) {
            error = response.data
          }
          dispatch({
            type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
            payload: error,
          })
          feedback('error', error.message)
          reject(error)
        })
    })
  }

  const getMarketerCompany = (marketerId) => {
    getById('/comercializadoras', marketerId)
      .then(({ data }) => {
        dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: data })
      })
      .catch(({ response }) =>
        dispatch({
          type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
          payload: response.response.data,
        })
      )
  }

  const updateMarketerCompany = () => {
    return new Promise((resolve, reject) => {
      if (state.formState.logoNew) {
        updateCompanyLogo().then((logoId) => {
          putMarketerCompany(logoId).then(() => {
            resolve()
          })
        })
      } else {
        putMarketerCompany().then(() => {
          resolve()
        })
      }
    })
  }

  const putMarketerCompany = (logoId) => {
    return new Promise((resolve, reject) => {
      let payload = assembleMarketerPutPayload(state.formState)
      if (logoId) {
        payload.logo_id = logoId
      }
      putById('/comercializadoras', state.company.id, payload)
        .then(({ data }) => {
          dispatch({ type: COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY, payload: data })
          feedback('success', fm({ id: 'pages.profile.user.feedback.1' }))
          resolve(data)
        })
        .catch(({ response }) => {
          let error = { message: fm({ id: 'pages.profile.user.feedback.2' }) }
          if (response?.data?.message) {
            error = response.data
          }
          dispatch({
            type: COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE,
            payload: error,
          })
          feedback('error', error.message)
          reject(error)
        })
    })
  }

  const updateCompanyLogo = () => {
    return new Promise((resolve, reject) => {
      const payload = logoPostDto(state.formState)
      post('/documento', payload, { timeout: 0 })
        .then(({ data }) => {
          resolve(data.id)
        })
        .catch(({ response }) => {
          let error = {
            message:
              'No se han podido guardar los datos. Ha habido un problema subiendo la imagen del logo.',
          }
          if (response?.data?.message) {
            error = response.data
          }
          feedback('error', error.message)
          reject()
        })
    })
  }

  return {
    getInstallerPrimaryCompany,
    getInstallerSecondaryCompany,
    updateInstallerPrimaryCompany,
    updateInstallerSecondaryCompany,
    getGuildCompany,
    updateGuildCompany,
    getMarketerCompany,
    updateMarketerCompany,
    changeFormState,
    setFormStateSaved,
    getDocumentById,
    downloadOPFile,
    putCheckOp,
  }
}
