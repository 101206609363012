//From https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
import React from 'react'
import MapsLoader from './maps-loader.class'

class Map extends MapsLoader {
  onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    )
    this.props.onMapLoad(map)
  }

  render() {
    return <div style={{ width: '100%', height: '100%' }} id={this.props.id} />
  }
}

export default Map
