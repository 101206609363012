export const corporateIdentityDownloadsStyles = (theme) => ({
  section: {
    marginBottom: '1em',
    position: 'relative',
  },
  sectionMain: {
    border: '1px solid ' + theme.palette.primary.main,
    padding: '1em',
    width: '80%',
    margin: '1em auto',
  },
  title: {
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '3em',
  },
  header: {
    borderTop: '1px solid ' + theme.palette.primary.main,
    textAlign: 'center',
    width: '80%',
    paddingTop: '1em',
  },
  gridBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridSubblock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '20em',
    margin: '0.5em',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
})
