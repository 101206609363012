import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { TableActions } from '../table-actions'
import { USERS_TABLE_ACTIONS, USERS_TABLE_COLUMNS } from '../../constants'
import { marketerUsersTableStyles } from './marketer-users-table.styles'
import { TableUrlPagination } from '../../../../ui'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const MarketerUsersTable = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {
    data,
    search,
    pagination: { total_usuarios: totalEntries },
    isLoading,
  } = useSelector((state) => state.marketerUsers)
  const combos = useSelector((state) => state.combos)

  const handleRowClick = useCallback(
    (_, row) => {
      history.push(`/marketerUsers/${row['id']}`)
    },
    [history]
  )

  const formattedColumns = useMemo(
    () =>
      [...USERS_TABLE_COLUMNS, ...USERS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: formatMessage({ id: `pages.marketer.users.table.columns.${column.field}.title` }),
          cellStyle: marketerUsersTableStyles.cell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [USERS_TABLE_COLUMNS, marketerUsersTableStyles.cell, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data]
  )

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      fixedColumns: {
        right: 1,
      },
      tableLayout: 'fixed',
      headerStyle: marketerUsersTableStyles.header,
      draggable: false,
    }),
    []
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'active' ? <TableActions {...props} /> : props.children}
        </MTableCell>
      ),
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      components={tableComponents}
      //Not transformed in the url table interface:
      data={processedData}
      isLoading={isLoading}
      onRowClick={handleRowClick}
    />
  )
}
