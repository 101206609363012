export const companyLogoStyles = (theme) => ({
  logoContainer: {
    height: 150,
    width: 150,
    minWidth: 150,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  editLogoButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
})
