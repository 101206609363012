import { useCallback, useReducer } from 'react'

//Auxiliary reducer for error administration
const RESET_ERRORS = 'RESET_ERRORS'
const UPDATE_ERROR = 'ADD_ERROR'
const errorReducer = (state, { type, payload }) => {
  switch (type) {
    case RESET_ERRORS:
      return {}
    case UPDATE_ERROR:
      if (!payload.value && !state[payload.name]) {
        return state
      }
      return {
        ...state,
        [payload.name]: payload.value,
      }
    default:
      throw new Error('Unrecognized action')
  }
}
export const useErrorReducer = () => {
  const [errors, dispatch] = useReducer(errorReducer, {})
  const resetErrors = useCallback(() => {
    dispatch({
      type: RESET_ERRORS,
    })
  }, [])
  const updateError = useCallback((field, value) => {
    dispatch({
      type: UPDATE_ERROR,
      payload: {
        name: field,
        value: value,
      },
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const validateField = useCallback((rule, field, value, aux) => {
    let error = false
    switch (rule) {
      case 'nonZero':
        const num = value ? parseFloat(('' + value).replace(',', '.')) : false
        if (!num || isNaN(num)) {
          error = true
        }
        break
      case 'required':
        if (!value) {
          error = true
        }
        break

      case 'mandatory': //required input with regex
        if (
          !value ||
          (value instanceof Array && value.length === 0) ||
          (value && !aux.test(value))
        ) {
          error = true
        }
        break
      case 'notEmpty':
        if (!value || (value instanceof Array && value.length === 0)) {
          error = true
        }
        break
      case 'date':
        if (!(value instanceof Date)) {
          error = true
        }
        break
      case 'regex':
        if (value && !aux.test(value)) {
          error = true
        }
        break
      default:
        //No need to update anything for unknown validation rules
        return
    }
    updateError(field, error)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    errors,
    resetErrors,
    updateError,
    validateField,
  }
}
