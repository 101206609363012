import React from 'react'
import { InputAdornment } from '@material-ui/core'

export const USER_GRID_ROW_ITEMS = [
  {
    label: 'pages.profile.user.fields.name',
    valueParsed: (data) => `${data['nombre']} ${data['apellidos']}`,
  },
  {
    label: 'pages.profile.user.fields.id',
    value: 'username',
  },
  {
    label: 'pages.profile.user.fields.rol',
    valueParsed: (data, intl) =>
      intl.formatMessage({ id: `pages.profile.user.fields.rol.${data['rol']}` }),
  },
]

export const INSTALLER_WORKS_OPTIONS = [
  {
    key: 'captadora',
    label: 'La Empresa gestiona solicitudes de Gas',
  },
  {
    key: 'grabadora',
    label:
      'La Empresa realiza Inspecciones Periódicas (IP) y/o Justificantes de Correción de Anomalías (JCA)',
  },
]

export const GUILD_WORKS_OPTIONS = [
  {
    key: 'captadora',
    label: 'La Asociación gestiona solicitudes de Gas',
  },
  {
    key: 'grabadora',
    label:
      'La Asociación realiza Inspecciones Periódicas (IP) y/o Justificantes de Correción de Anomalías (JCA)',
  },
]

export const FILE_PROPS = {
  maxSize: 10000000, // 10Mb
  accept: ['application/pdf'],
}

export const COMPANY_FORM_CONFIG = ({ formState, onChange, combos, intl }) => {
  return {
    NOMBRE: {
      required: true,
      value: formState['nombre'],
      name: 'nombre',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.nombre.title' }),
      placeholder: intl.formatMessage({ id: 'pages.profile.company.fields.nombre.placeholder' }),
      onChange,
    },
    RAZON: {
      required: true,
      value: formState['razon_social'],
      name: 'razon_social',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.razon_social.title' }),
      placeholder: intl.formatMessage({
        id: 'pages.profile.company.fields.razon_social.placeholder',
      }),
      onChange,
    },
    CIF: {
      required: true,
      disabled: true,
      value: formState['cif'],
      name: 'cif',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.cif.title' }),
      onChange,
    },
    TELEFONO: {
      required: true,
      value: formState['telefono'],
      name: 'telefono',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.telefono.title' }),
      placeholder: intl.formatMessage({ id: 'pages.profile.company.fields.telefono.placeholder' }),
      inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
      InputProps: {
        startAdornment: <InputAdornment position="start">+34</InputAdornment>,
      },
      onChange,
    },
    WEB: {
      value: formState['web'],
      name: 'web',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.web.title' }),
      placeholder: intl.formatMessage({ id: 'pages.profile.company.fields.web.placeholder' }),
      onChange,
    },
    EMAIL: {
      required: true,
      type: 'email',
      value: formState['email'],
      name: 'email',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.email.title' }),
      placeholder: intl.formatMessage({ id: 'pages.profile.company.fields.email.placeholder' }),
      onChange,
    },
    DIRECCION: {
      required: true,
      value: formState['direccion'],
      name: 'direccion',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.direccion.title' }),
      placeholder: intl.formatMessage({ id: 'pages.profile.company.fields.direccion.placeholder' }),
      onChange,
    },
    CALLE: {
      required: true,
      value: formState['calle'],
      name: 'calle',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.calle.title' }),
      onChange,
    },
    NUMERO: {
      required: true,
      value: formState['numero'],
      name: 'numero',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.numero.title' }),
      onChange,
    },
    POBLACION: {
      required: true,
      value: formState['poblacion'],
      name: 'poblacion',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.poblacion.title' }),
      onChange,
    },
    PISO: {
      required: false,
      value: formState['piso'],
      name: 'piso',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.piso.title' }),
      onChange,
    },
    POLIGONO: {
      required: false,
      value: formState['poligono'],
      name: 'poligono',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.poligono.title' }),
      onChange,
    },
    COD_POSTAL: {
      required: true,
      inputProps: {
        pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
        maxLength: 5,
      },
      value: formState['cod_codigo_postal'],
      name: 'cod_codigo_postal',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.cod_codigo_postal.title' }),
      placeholder: intl.formatMessage({
        id: 'pages.profile.company.fields.cod_codigo_postal.placeholder',
      }),
      onChange,
    },
    CODIGO_F: {
      required: true,
      value: formState['codigo_entidad_financiera'],
      name: 'codigo_entidad_financiera',
      title: intl.formatMessage({
        id: 'pages.profile.company.fields.codigo_entidad_financiera.title',
      }),
      tooltip: intl.formatMessage({
        id: 'pages.profile.company.fields.codigo_entidad_financiera.tooltip',
      }),
      inputProps: { pattern: '[0-9]{8}' },
      onChange,
    },
    /*ACEPTA_F: {
      required: true,
      value: formState['acepta_financiacion'],
      name: 'acepta_financiacion',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.acepta_financiacion.title' }),
      placeholder: intl.formatMessage({
        id: 'pages.profile.company.fields.acepta_financiacion.placeholder',
      }),
      values: combos['binary_values']?.data || [],
      fullWidth: true,
      onChange,
    }, */
    DESCRIPCION: {
      required: true,
      value: formState['descripcion'],
      name: 'descripcion',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.descripcion.title' }),
      placeholder: intl.formatMessage(
        { id: 'pages.profile.company.fields.descripcion.placeholder' },
        { maxLength: TEXT_AREA_LENGTH }
      ),
      multiline: true,
      rows: 5,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      onChange,
    },
    CCAA: {
      required: true,
      fullWidth: true,
      value: formState['cc_aa'],
      values: combos['cod_zona_zeus']?.data || [],
      name: 'cc_aa',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.cc_aa.title' }),
      multiple: true,
      onChange,
    },
    CATEGORIA: {
      required: true,
      value: formState['categoria'],
      name: 'categoria',
      title: 'Categoría',
      placeholder: 'Selecciona',
      values: combos['ip_installer_categories']?.data || [],
      fullWidth: true,
      onChange,
    },
    IBAN: {
      // required: !!formState['certificado_iban'] || !!formState['d_certificado_iban'],
      required: false,
      value: formState['iban'],
      name: 'iban',
      title: 'IBAN',
      placeholder: 'ES00 0000 0000 0000 0000 0000',
      tooltip:
        'Para España, el IBAN tiene 24 dígitos y se forma de la siguiente manera:\n\n- "ES” (código de país para España -dos letras-, especificado en la norma ISO 3166).\n- Un número de control (2 dígitos).\n- El CCC completo de la correspondiente cuenta corriente bancaria (20 dígitos).',
      inputProps: {
        pattern:
          '([a-zA-Z]{2})\\s*\\t*(\\d{2})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})',
      },
      onChange,
    },
    IBAN_OP: {
      required: false,
      value: formState['iban_op'],
      name: 'iban_op',
      title: 'Código IBAN',
      placeholder: 'ES00 0000 0000 0000 0000 0000',
      tooltip:
        'Para España, el IBAN tiene 24 dígitos y se forma de la siguiente manera:\n\n- "ES” (código de país para España -dos letras-, especificado en la norma ISO 3166).\n- Un número de control (2 dígitos).\n- El CCC completo de la correspondiente cuenta corriente bancaria (20 dígitos).',
      inputProps: {
        pattern:
          '([a-zA-Z]{2})\\s*\\t*(\\d{2})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})',
      },
      onChange,
    },
    RESPONSABLE_LEGAL: {
      required: false,
      value: formState['responsable_legal'],
      name: 'responsable_legal',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.responsable_legal.title' }),
      onChange,
    },
    NIF_RESPONSABLE_LEGAL: {
      required: false,
      value: formState['nif_responsable_legal'],
      name: 'nif_responsable_legal',
      title: intl.formatMessage({
        id: 'pages.profile.company.fields.nif_responsable_legal.title',
      }),
      onChange,
    },
    SOCIEDAD: {
      required: false,
      value: formState['sociedad'],
      name: 'sociedad',
      title: intl.formatMessage({ id: 'pages.profile.company.fields.sociedad.title' }),
      onChange,
    },
    CODIGO_REGISTRO_INDUSTRIAL: {
      required: false,
      value: formState['codigo_registro_industrial'],
      name: 'codigo_registro_industrial',
      title: intl.formatMessage({
        id: 'pages.profile.company.fields.codigo_registro_industrial.title',
      }),
      onChange,
    },
    IBAN_CERTIFICADO: {
      value: formState['certificado_iban'],
      name: 'certificado_iban',
      title: 'Certificado IBAN',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['d_certificado_iban'],
    },
    REGISTRO_INDUSTRIAL: {
      value: formState['registro_industrial_nuevo'],
      name: 'registro_industrial_nuevo',
      title: 'Registro Industrial',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['registro_industrial'],
    },
    NIF_EMPRESA: {
      value: formState['documento_nif_nuevo'],
      name: 'documento_nif_nuevo',
      title: 'NIF Empresa',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['documento_nif'],
    },
    CERTIFICADO_BANCARIO_IBAN_SWIFT: {
      value: formState['certificado_iban_op_nuevo'],
      name: 'certificado_iban_op_nuevo',
      title: 'Certificado Bancario con Iban y código Swift (formato a color)',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['certificado_iban_op'],
    },
    PERSONA_FISICA: {
      value: formState['documento_pf_nuevo'],
      name: 'documento_pf_nuevo',
      title: 'Persona fisica (modelo 036 o 037 del IAE)',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['documento_pf'],
    },
    FORMULARIO_RGPD: {
      value: formState['formulario_rgpd_nuevo'],
      name: 'formulario_rgpd_nuevo',
      title: 'Formulario RGPD',
      onChange,
      fileProps: FILE_PROPS,
      currentDoc: formState['formulario_rgpd'],
    },
  }
}

export const COMPANY_GUILD_PREFIX = 'pages.profile.company.guild.info'

export const GUILD_INFO = ({ data, intl }) => {
  const text = (ext) => intl.formatMessage({ id: COMPANY_GUILD_PREFIX + '.' + ext })
  return {
    NOMBRE: {
      name: 'nombre',
      value: data['nombre'],
      title: text('name.title'),
    },
    TELEFONO: {
      name: 'telefono',
      value: data['telefono'],
      title: text('phone.title'),
    },
    STATUS: {
      name: 'status',
      value: data['status'] ? text('status.accepted') : text('status.pending'),
      title: text('status.title'),
    },
    PERMIT: {
      name: 'permit',
      color: 'primary',
      size: 'small',
    },
    MAIL_CHOICE: {
      name: 'recibir_mails_solicitud_agremiado',
      color: 'primary',
      size: 'small',
    },
    DEMANDA_CHOICE: {
      name: 'gremio_recibe_demandas',
      color: 'primary',
      size: 'small',
    },
  }
}

const TEXT_AREA_LENGTH = 250

export const COMPANY_COMBOS_IDS = ['cod_zona_zeus', 'ip_installer_categories']

export const INSTALLERS_FORM_CONFIG = [
  { key: 'nombre', label: 'Nombre completo' },
  { key: 'identificador', label: 'NIF/NIE' },
  { key: 'categoria', label: 'Categoría' },
  { key: 'codigo', label: 'Código Instalador' },
]

export const COMPANY_PROFILE_ACTION_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_COMPANY: 'SET_COMPANY',
  CHANGE_FORM_STATE: 'CHANGE_FORM_STATE',
  SET_FORM_STATE_SAVED: 'SET_FORM_STATE_SAVED',
  SET_SAVING: 'SET_SAVING',
  COMPANY_SET_CHECK_OP: 'COMPANY_SET_CHECK_OP',
}

export const COMPANY_PROFILE_INITIAL_STATE = {
  loadingCompany: true,
  savingCompany: false,
  error: {},
  company: {},
  formState: {},
  formStateSaved: true,
}
