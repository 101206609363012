import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'

import { useCombos } from '../../../combos'
import { useGuildListContext } from '../../../guilds/contexts'
import { COMPANY_COMBOS_IDS } from '../../constants'
import { useCompanyProfileContext } from '../../contexts'
import { SecondaryCompanyForm } from './secondary-company-form.component'

export const SecondaryCompany = ({ companyId }) => {
  useCombos(COMPANY_COMBOS_IDS)
  const guildListValues = useGuildListContext()
  const { loadingCompany, getInstallerSecondaryCompany } = useCompanyProfileContext()

  useEffect(() => {
    getInstallerSecondaryCompany(companyId)
  }, [companyId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    guildListValues.guildListLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingCompany) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  return <SecondaryCompanyForm />
}
