import React from 'react'
import { Grid, Box } from '@material-ui/core'

import { FileUploaderInput, NumberInput } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'

export const JCAInspCombustion = ({ addressKey }) => {
  const { formFields, addresses, changeAddress } = useInspectionsContext()

  return (
    <Box
      m={0.5}
      p={3}
      borderRadius={6}
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.2)"
      bgcolor="#ffffff"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FileUploaderInput
            {...{
              ...formFields.TICKET_COMBUSTION,
              value: addresses[addressKey].ticket_combustion,
              onChange: (e) => changeAddress(e, addressKey),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput
            {...{
              ...formFields.CO_AMBIENTE,
              value: addresses[addressKey].co_ambiente,
              onChange: (e) => changeAddress(e, addressKey),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput
            {...{
              ...formFields.CO_CORREGIDO,
              value: addresses[addressKey].co_corregido,
              onChange: (e) => changeAddress(e, addressKey),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
