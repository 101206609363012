import { useContext } from 'react'
import { AjaxContext } from '../../core/ajax'
import { APP_VERSION_MSSG } from '../../ui/layout/public-layout/constants'

export const useAlarm = () => {
  const { post } = useContext(AjaxContext)
  const postAlarm = (error, info) => {
    const data = {
      environment: 'APINS - ' + APP_VERSION_MSSG,
      type: 'React alarm',
      path: window.location.hash,
      error: error,
      info: info,
    }
    post('/error_report', data).then(() => {
      console.debug('alarm sent', data)
    })
  }
  return { postAlarm }
}
