import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Paper } from '@material-ui/core'

import { TableUrlPagination } from '../../../../ui'
import { IP_TABLE_COLUMNS_GUILD, IP_TABLE_COLUMNS } from '../../constants'
import { ipTableStyles } from './ip-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const IPTable = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {
    data,
    search,
    pagination: { total_registros: totalEntries },
    isLoading,
  } = useSelector((state) => state.ip)
  const combos = useSelector((state) => state.combos)
  const global = useSelector((state) => state.global)
  const isGuild = !!global.gremio

  const formattedColumns = useMemo(
    () => {
      const columns = isGuild ? IP_TABLE_COLUMNS_GUILD : IP_TABLE_COLUMNS
      return columns.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.ipList.table.columns.${column.field}.title` }),
          cellStyle: ipTableStyles.cell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      })
    },
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data]
  )

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      tableLayout: 'fixed',
      headerStyle: ipTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
    }),
    []
  )

  const handleRowClick = useCallback((_, row) => {
    const props = {
      pathname: `/ip/${row.id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      components={tableComponents}
      data={processedData}
      isLoading={isLoading}
      onRowClick={handleRowClick}
    />
  )
}
