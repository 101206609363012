const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const numberInputStyles = {
  root: {
    width: '100%',
    // margin: styledBy('spaceless', {
    //   undefined: '12px 0',
    //   false: '12px 0',
    //   true: 0,
    // }),
  },
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    // fontSize: 16,
    '&+*': { marginTop: 0, width: '100%' },
  },
  input: {
    height: styledBy('multiline', {
      undefined: '48px',
      false: '48px',
      true: 'auto',
    }),
  },
}
