import React from 'react'
import { InputAdornment } from '@material-ui/core'

export const COMPANY_FORM_CONFIG = ({ formState, onChange, combos, intl }) => ({
  NOMBRE: {
    required: true,
    value: formState['nombre'],
    name: 'nombre',
    title: 'Nombre de la empresa',
    placeholder: 'Como le aparece al cliente',
    onChange,
  },
  ACTIVIDAD: {
    required: true,
    value: formState['tipo_actividad'],
    name: 'tipo_actividad',
    title: 'Tipo de actividad',
    placeholder: 'Selecciona',
    values: combos['tipo_actividad'] && combos['tipo_actividad'].data,
    fullWidth: true,
    onChange,
  },
  RAZON: {
    required: true,
    value: formState['razon_social'],
    name: 'razon_social',
    title: 'Razón social',
    placeholder: 'Ej. Sociedad Anónima cerrada (S.A.C)',
    onChange,
  },
  CIF: {
    required: true,
    value: formState['cif'],
    name: 'cif',
    title: 'NIF/NIE/CIF',
    placeholder: 'Ej. 12345678Z',
    onChange,
  },
  COD_SAP: {
    value: formState['cod_sap'],
    name: 'cod_sap',
    title: 'Código SAP',
    placeholder: 'Ej. SAP',
    disabled: true,
    onChange,
  },
  TELEFONO: {
    required: true,
    value: formState['telefono'],
    name: 'telefono',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    InputProps: {
      startAdornment: <InputAdornment position="start">+34</InputAdornment>,
    },
    onChange,
  },
  WEB: {
    value: formState['web'],
    name: 'web',
    title: 'Página web (opcional)',
    placeholder: 'Ej. tuweb.com',
    onChange,
  },
  EMAIL: {
    required: true,
    type: 'email',
    value: formState['email'],
    name: 'email',
    title: 'Email de contacto',
    placeholder: 'Ej. nombre@dominio.com',
    onChange,
  },
  DIRECCION: {
    required: true,
    value: formState['direccion'],
    name: 'direccion',
    title: 'Dirección',
    placeholder: 'Linea 1',
    onChange,
  },
  CALLE: {
    required: true,
    value: formState['calle'],
    name: 'calle',
    title: 'Calle',
    // placeholder: 'Linea 1',
    onChange,
  },
  NUMERO: {
    required: true,
    value: formState['numero'],
    name: 'numero',
    title: 'Número',
    // placeholder: 'Linea 1',
    onChange,
  },
  POBLACION: {
    required: true,
    value: formState['poblacion'],
    name: 'poblacion',
    title: 'Población',
    // placeholder: 'Linea 1',
    onChange,
  },
  PISO: {
    required: false,
    value: formState['piso'],
    name: 'piso',
    title: 'Piso',
    // placeholder: 'Linea 1',
    onChange,
  },
  POLIGONO: {
    required: false,
    value: formState['poligono'],
    name: 'poligono',
    title: 'Polígono',
    // placeholder: 'Linea 1',
    onChange,
  },
  COD_POSTAL: {
    required: true,
    inputProps: {
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
      maxLength: 5,
    },
    value: formState['cod_codigo_postal'],
    name: 'cod_codigo_postal',
    title: 'Código Postal',
    placeholder: 'Ej. 01234',
    onChange,
  },
  CCAA: {
    required: true,
    value: formState['cc_aa'],
    name: 'cc_aa',
    title: 'Seleccionar CCAA de actuación',
    placeholder: 'Selecciona',
    values: combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data,
    fullWidth: true,
    multiple: true,
    onChange,
    selectAll: true,
  },
  CATEGORIA: {
    required: true,
    value: formState['categoria'],
    name: 'categoria',
    title: 'Categoría',
    placeholder: 'Selecciona',
    values: combos['ip_installer_categories'] && combos['ip_installer_categories'].data,
    fullWidth: true,
    onChange,
  },
  IBAN: {
    // required: !!formState['certificado_iban'],
    required: false,
    value: formState['iban'],
    name: 'iban',
    title: 'IBAN',
    placeholder: 'ES00 0000 0000 0000 0000 0000',
    tooltip:
      'Para España, el IBAN tiene 24 dígitos y se forma de la siguiente manera:\n\n- "ES” (código de país para España -dos letras-, especificado en la norma ISO 3166).\n- Un número de control (2 dígitos).\n- El CCC completo de la correspondiente cuenta corriente bancaria (20 dígitos).',
    inputProps: {
      pattern:
        '([a-zA-Z]{2})\\s*\\t*(\\d{2})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})\\s*\\t*(\\d{4})',
    },
    onChange,
  },
  IBAN_CERTIFICADO: {
    value: formState['certificado_iban'],
    name: 'certificado_iban',
    title: 'Certificado IBAN',
    onChange,
    fileProps: FILE_PROPS,
  },
  REG_INDUSTRIAL: {
    value: formState['registro_industrial'],
    name: 'registro_industrial',
    title: 'Registro Industrial',
    onChange,
    fileProps: FILE_PROPS,
  },
})

export const FILE_PROPS = {
  maxSize: 10000000, // 10Mb
  accept: ['application/pdf'],
}

export const INSTALLER_WORKS_OPTIONS = [
  {
    key: 'catchment',
    label: 'La Empresa gestiona solicitudes de Gas',
  },
  {
    key: 'ip',
    label:
      'La Empresa realiza Inspecciones Periódicas (IP) y/o Justificantes de Corrección de Anomalías (JCA)',
  },
]

export const GUILD_WORKS_OPTIONS = [
  {
    key: 'catchment',
    label: 'La Asociación gestiona solicitudes de Gas',
  },
  {
    key: 'ip',
    label:
      'La Asociación realiza Inspecciones Periódicas (IP) y/o Justificantes de Corrección de Anomalías (JCA)',
  },
]
