export const uploadTooltipStyles = (theme) => ({
  container: {
    display: 'flex',
  },
  title: {
    fontFamily: 'FSEmeric-Medium',
    fontSize: 12,
    paddingRight: '5px',
    opacity: 1,
  },
  description: {
    fontFamily: 'FSEmeric-Medium',
    fontSize: 12,
    opacity: 1,
  },
})
