import { useCallback, useReducer } from 'react'

import { useJCACalls } from './jca-calls.hook'
import { jcaReducer } from '../reducers'
import { JCA_INITIAL_STATE, JCA_ACTIONS_TYPES } from '../reducers'

export const useJCAReducer = () => {
  const [state, dispatch] = useReducer(jcaReducer, JCA_INITIAL_STATE)
  const { getJCACall } = useJCACalls()

  const getJCA = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: JCA_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        getJCACall(id)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                motivo: data.motivo?.toString() || '',
                empresa_nombre: data.empresa?.nombre || '',
                empresa_cif: data.empresa?.cif || '',
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getJCA,
    ...state,
  }
}
