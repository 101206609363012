import React, { useState, useEffect } from 'react'
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha'
import { LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { feedback } from '../../core/feedback'
import { TextInput } from '../text-input'
import { ThemeButton } from '../theme-button'

const DEFAULT_CHAR_LENGTH = 6

export const CaptchaInput = ({
  charLength = DEFAULT_CHAR_LENGTH,
  layout = 'column',
  isReloadable = true,
  onSubmit = () => {},
}) => {
  const [value, setValue] = useState('')
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    if (!confirmed) loadCaptchaEnginge(charLength)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (validateCaptcha(value) === true) {
      setConfirmed(true)
      onSubmit(true)
    } else {
      loadCaptchaEnginge(charLength)
      feedback('error', 'El captcha introducido es incorrecto')
      setValue('')
      onSubmit(false)
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Box mt={1} mb={1} display="flex" alignItems="center" flexDirection={layout}>
      {!confirmed ? (
        <>
          <Box mt={1} flex={1} display="flex">
            {isReloadable ? <LoadCanvasTemplate /> : <LoadCanvasTemplateNoReload />}
          </Box>
          <Box display="flex">
            <Box maxWidth={200} mr={1}>
              <TextInput name="captcha_input" onChange={handleChange} value={value} />
            </Box>
            <ThemeButton onClick={handleSubmit}>Verificar</ThemeButton>
          </Box>
        </>
      ) : (
        <Alert severity="success">Código de confirmación correcto.</Alert>
      )}
    </Box>
  )
}
