import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress, Divider, DialogActions } from '@material-ui/core'
import { List, ListItem, ListItemText, DialogContent } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import { requests } from '../../../../core/ajax'
import { ReviewItem } from './review-item.component'
import { SummaryReview } from './summary-review.component'

export const ReviewsDialog = ({ close, companyId }) => {
  const history = useHistory()
  const { getById } = requests

  const [reviews, setReviews] = useState([])
  const [pagination, setPagination] = useState({ _pagina: 1, _num_x_pagina: 5 })
  const [loading, setLoading] = useState(false)

  const handleGoToDetail = (id) => {
    if (history) {
      history.push(`/applications/${id}`)
      close()
    }
  }

  const fetchReviews = () => {
    setLoading(true)
    const config = {
      params: {
        _pagina: pagination._pagina,
        _num_x_pagina: pagination._num_x_pagina,
        _order: 'desc',
        _order_by: 'fecha_comentario',
      },
    }
    getById('/valoraciones/empresa_ca', companyId, config)
      .then(({ data }) => {
        const { comentarios, ...restData } = data

        setReviews(comentarios)
        setPagination({ ...pagination, ...restData })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleChangePagination = (_, page) => {
    setPagination({ ...pagination, _pagina: page })
  }

  useEffect(() => {
    fetchReviews()
  }, [pagination._pagina]) // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <Box minHeight={200} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <>
      <DialogContent>
        <List>
          {reviews.length ? (
            <Box>
              <SummaryReview {...{ companyId, pagination }} />
              {reviews.map((review, i) => (
                <React.Fragment key={i}>
                  <ReviewItem
                    {...{
                      ...review,
                      handleGoToDetail,
                    }}
                  />
                  {i !== reviews.length - 1 ? <Divider /> : null}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <ListItem>
              <ListItemText primary="No hay valoraciones." />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {pagination?.total_paginas > 1 ? (
          <Box display="flex" justifyContent="center" width="100%">
            <Pagination
              count={pagination.total_paginas}
              page={pagination._pagina}
              shape="rounded"
              onChange={handleChangePagination}
            />
          </Box>
        ) : null}
      </DialogActions>
    </>
  )
}
