import React from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'

export const GridForm = ({ grid, inputs }) => {
  return grid.map((gridRow, i) => (
    <Box key={i} mb={2}>
      {gridRow.title ? (
        <Typography variant="h6" color="primary" gutterBottom>
          {gridRow.title}
        </Typography>
      ) : null}
      <Grid container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
        {gridRow.fields.map((gridItem, i) => {
          const { component, ...restItemProps } = inputs[gridItem.ref]
          const InputComponent = component
          return restItemProps.hidden ? null : (
            <Grid {...{ ...gridItem.conf, key: i }}>
              <InputComponent {...restItemProps} />
            </Grid>
          )
        })}
      </Grid>
      {gridRow.divider ? <Divider light /> : null}
    </Box>
  ))
}
