import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { Tooltip, Icon, useTheme } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { IPInstallersTable } from './ip-installers-table.component'

export const IPInstallers = ({ company }) => {
  const { formatMessage: fm } = useIntl()
  const theme = useTheme()

  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{ backgroundColor: theme.palette.grey[200] }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant="subtitle1">
            {fm({ id: 'pages.profile.company.installersList.title' })}
            <span style={{ marginLeft: 4 }}>
              <Tooltip
                placement="top"
                title={
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {fm({ id: 'pages.profile.company.installersList.description' })}
                  </Typography>
                }
              >
                <Icon fontSize="inherit">help_outline</Icon>
              </Tooltip>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IPInstallersTable {...{ data: company.inspectores, companyId: company.id }} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
