import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { IPDashboard } from '../../../features/ip'
import { IPDetailWrapper as IPDetail } from '../../../features/ip-detail'

export const IPRouter = () => {
  return (
    <Switch>
      <Route exact path="/ip" component={IPDashboard} />
      <Route exact path="/ip/:ipId" component={IPDetail} />
      <Redirect from="*" to="/ip" />
    </Switch>
  )
}
