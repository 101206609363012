import React, { createContext, useContext, useReducer } from 'react'

import { useUserProfileActions } from '../hooks'
import { userProfileReducer } from '../reducers'
import { USER_PROFILE_INITIAL_STATE } from '../constants'

const UserProfileContext = createContext()

export const useUserProfileContext = () => useContext(UserProfileContext)

export const UserProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userProfileReducer, USER_PROFILE_INITIAL_STATE)

  const actions = useUserProfileActions(state, dispatch)

  return (
    <UserProfileContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserProfileContext.Provider>
  )
}
