export const marketerUsersDetailStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '40px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  userImg: {
    width: 45,
    height: 45,
    marginRight: 10,
  },
  return: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 15px 0',
    paddingLeft: theme.spacing(6),
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  gridPanel: {
    boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: theme.palette.grey[200],
  },
})
