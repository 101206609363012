import { createSlice } from '@reduxjs/toolkit'

import { saveLocalStorage } from '../../../core/utils'
import { requests } from '../../../core/ajax'
import { setCombo } from '../../combos/slices'

const initialState = {
  isAuthorized: false,
}

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setAuthData: (state, { payload }) => payload,
    resetAuthData: () => initialState,
    setCompanyData: (state, { payload }) => {
      state.empresa = payload
    },
  },
})

export const globalReducer = globalSlice.reducer

export const { setAuthData, resetAuthData, setCompanyData } = globalSlice.actions

export const loginUser = (payload) => async (dispatch) => {
  const { getById, get } = requests
  const localData = { 'apins-auth': { ...payload } || {} }
  if (localData['apins-auth']?.permissions) {
    delete localData['apins-auth'].permissions
  }
  saveLocalStorage('dsc', localData)
  if (!payload?.user_id) {
    dispatch(resetAuthData())
    return
  }

  const handleForceLogout = () => {
    saveLocalStorage('dsc', {})
    dispatch(resetAuthData())
    setTimeout(window.location.reload(), 1000)
  }

  const loadGremio = async (content) => {
    const { data } = await getById('/instalador', content['user_id']).catch(({ response }) => {
      if (response.status === 403) {
        handleForceLogout()
      }
      return false
    })
    if (!data) {
      return false
    }

    return { ...content, gremio: data['gremio'], permissions: data['permissions'] }
  }

  const loadComercializadora = async (content) => content

  const loadInstaller = async (content) => {
    const { data: comboData } = await get('/combo_box', { params: { parent_id: 'cod_zona_zeus' } })
    const { data } = await getById('/instalador', content['user_id']).catch(({ response }) => {
      if (response.status === 403) {
        handleForceLogout()
      }
      return false
    })
    if (!data) {
      return false
    }
    let empresa_cc_aa = null
    if (data['empresas_ca_relacionadas']) {
      empresa_cc_aa = data['empresas_ca_relacionadas'].map((empresa) => {
        const comunitat = comboData.filter(
          (el) => parseInt(el.key) === empresa['comunidad_autonoma']
        )[0]
        return {
          ...empresa,
          id_empresa: empresa['id'],
          id_cc_aa: empresa['comunidad_autonoma'],
          nombre_empresa: empresa['nombre'],
          nombre_cc_aa: comunitat ? comunitat.value : 'Unknown',
          gremio_id: empresa['gremio_id'],
          aceptacion_gremio: empresa['aceptacion_gremio'],
          telefono: empresa['telefono'],
        }
      })
    }
    if (data['empresa_id']) {
      await getById('/empresa', data['empresa_id']).then(({ data: empresa }) => {
        data['empresa'] = {
          ...data['empresa'],
          cc_aa: empresa.empresas_filiales.map((el) => '' + el.comunidad_autonoma),
        }
      })
    }

    dispatch(setCombo({ comboId: 'cod_zona_zeus', data: comboData }))

    return {
      ...content,
      permissions: data['permissions'],
      email: data['email'],
      empresa_id: data['empresa_id'],
      empresa: data['empresa'],
      empresa_cc_aa,
    }
  }

  let content = { ...payload, isAuthorized: true }

  switch (payload.role) {
    case 'gremio':
      content = await loadGremio(content)
      break

    case 'comercializadora':
      content = await loadComercializadora(content)
      break

    default:
      content = await loadInstaller(content)
  }
  if (!content) {
    return
  }
  dispatch(setAuthData(content))
}

export const logoutUser = () => (dispatch) => {
  const localData = { 'apins-auth': {} }
  dispatch(resetAuthData())
  saveLocalStorage('dsc', localData)
}
