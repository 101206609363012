import React from 'react'

import { EITabs } from '../tabs'

export const EIPanel = ({ companyData, guildCompany, getEIPrimaryCompany}) => {
   return  <EITabs
        {...{
            primaryCompany: companyData,
            guildCompany: guildCompany,
            getEIPrimaryCompany
        }}
    />
}