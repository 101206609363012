import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, withStyles, IconButton, Typography } from '@material-ui/core'

import { downloadFile } from '../../../../core/utils'

import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { feedback } from '../../../../core/feedback'
import { useIntl } from 'react-intl'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'
import { documentsViewStyles } from './documents-view.style'
import { Cancel, Check } from '@material-ui/icons'

export const DocumentsActions = withStyles(documentsViewStyles)(
  ({ classes, value, context, row }) => {
    const intl = useIntl()
    const { getById, putById } = useAjaxContext()
    const [anchorEl, setAnchorEl] = useState(null)
    const [confirmedDetachFile, setConfirmedDetachFile] = useState(false) // eslint-disable-line no-unused-vars

    const { model: originalModel, setApplication, getListItems } = context
    const [loading, setLoading] = useState(false) // eslint-disable-line no-unused-vars
    const [model, setModel] = useState({ ...originalModel })
    const { role } = useSelector((state) => state.global)

    const doc = DOCUMENT_ATTACHMENT_CONFIG[row.field]

    const handleDownloadFile = (id) => {
      setLoading(true)
      getById('/documento', id)
        .then(({ data }) => {
          downloadFile(data['documento'], data['extension'], data['nombre'])
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }

    const handleClickConfirm = (event) => {
      setAnchorEl(event.currentTarget)
      setConfirmedDetachFile(true)
    }

    const handleClickDetach = (event) => {
      if (model.estado === '03') {
        feedback(
          'error',
          intl.formatMessage({ id: 'pages.application.view.documentDetacherr.feedback.error' })
        )
        handleClose()
      }
      handleDetachFile(value).then(() => {
        setAnchorEl(null)
        setConfirmedDetachFile(false)
      })
    }

    const handleClose = () => {
      setAnchorEl(null)
      setConfirmedDetachFile(false)
    }

    const open = Boolean(anchorEl)

    const handleDetachFile = (id) =>
      new Promise((resolve, reject) => {
        if (doc.subfield === 'presupuesto') {
          if (!model['presupuesto']) {
            feedback('error', 'Presupuesto exterior no encontrado')
            return
          }
          const payload = {
            [doc.id]: 0,
          }
          setLoading(true)
          putById('/presupuesto', model['presupuesto']['id'], payload)
            .then(({ data }) => {
              setModel({ ...model, presupuesto: data })
              setApplication({
                ...model,
                presupuesto_exterior: data,
              })
              getListItems({
                // AMN to update parent's parent data and force refreshing the list
                ...model,
                presupuesto_exterior: data,
              })
              setLoading(false)
              feedback(
                'success',
                intl.formatMessage({ id: 'pages.application.view.documentDetacherr.feedback.1' })
              )
            })
            .catch(() => {
              console.error('Error changing budget doc')
              setLoading(false)
            })
        } else {
          const payload = { [doc.id]: 0 }
          putById('/solicitudes/update_docs', model['id'], payload)
            .then(({ data }) => {
              setModel({ ...data, presupuesto: data.presupuesto_exterior })
              setApplication(data)
              getListItems({ ...data, presupuesto: data.presupuesto_exterior }) // AMN to update parent's parent data and force refreshing the list
              setLoading(false)
              feedback(
                'success',
                intl.formatMessage({ id: 'pages.application.view.documentDetacherr.feedback.1' })
              )
            })
            .catch(() => {
              console.error('Error changing application doc')
              setLoading(false)
            })
        }
      })

    return (
      <Box className={classes.actionRow} p={0}>
        {open ? (
          <Box p={0} display="flex" flexDirection="column">
            <Typography variant="body1" color="textSecondary">
              &nbsp; ¿Está seguro que quiere eliminar el documento?
            </Typography>
            <Box className={classes.actionRow} display="flex" flexDirection="row">
              <IconButton
                className={classes.actionButton}
                key="delete"
                size="small"
                onClick={handleClickDetach}
              >
                <Check />
              </IconButton>
              <IconButton
                className={classes.actionButton}
                key="cancel"
                size="small"
                onClick={handleClose}
              >
                <Cancel />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>
            <IconButton
              className={classes.actionButton}
              key="download"
              size="small"
              onClick={() => handleDownloadFile(value)}
            >
              <GetAppIcon />
            </IconButton>
            {(!model.gremio_id || role === 'gremio') && doc.subfield !== 'presupuesto' ? (
              <IconButton
                className={classes.actionButton}
                key="delete"
                size="small"
                onClick={handleClickConfirm}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </>
        )}
      </Box>
    )
  }
)
