import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { PageDisplayer } from '../../../../ui'
import { JCAForm } from '../form'
import { useJCADetail } from '../../contexts'

export const JCADetail = () => {
  const history = useHistory()
  const { jcaId } = useParams()
  const { formatMessage } = useIntl()
  const { loading, error, getJCA } = useJCADetail()

  useEffect(() => {
    getJCA(jcaId)
  }, [jcaId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/jca' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      loading={loading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.jcaDetail.goBack' })}
    >
      {error ? (
        formatMessage({ id: 'pages.jcaDetail.error' })
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h4" color="textSecondary">
              {formatMessage({ id: 'pages.jcaDetail.title' })}
            </Typography>
          </Box>
          <JCAForm />
        </>
      )}
    </PageDisplayer>
  )
}
