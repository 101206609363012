import { useEffect, useReducer } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import { types } from '../constants'
import { feedback } from '../../../core/feedback'
import {
  assembleNormalizedHome,
  assembleNotNormalizedHome,
  checkConsultAnswers,
  createApp,
  createAppAndCertificate,
  updateAppAndCreateCertificate,
  checkUser,
  checkClientForUnlockPath,
  refineAddress,
} from '../logics'
import {
  getAppByCidi,
  getDataForm,
  getFlats,
  getNumbers,
  getStreets,
  getZipMunicipalities,
} from '../calls'
import { rollbackApp } from '../calls/consult-creation.calls'
import { consultReducer, consultInitialState } from '../reducers'
import { APP_PATH_RULES } from '../constants/application-types.constants'
import { assembleCertificate } from '../logics/object-assembling.logics'
import { useSelector } from 'react-redux'
import { ValidateAddressData } from '../logics/validate-address-data.logics'
import { useAlarm } from '../../../core/utils/useAlarm'

export const useConsultActions = () => {
  const [state, dispatch] = useReducer(consultReducer, consultInitialState)
  const { consumo } = useSelector((state) => state.combos)
  const intl = useIntl()
  const { postAlarm } = useAlarm()

  const location = useLocation()
  const history = useHistory()
  const stepSearch = parseInt(new URLSearchParams(location.search).get('step'))
  const step = isNaN(stepSearch) ? 0 : stepSearch

  const setStep = (step, options = {}) => {
    if (step === 0) {
      dispatch({
        type: types.RENORMALIZE,
      })
    }
    if (options.replace) {
      history.replace({ search: `?step=${step}` })
    } else {
      history.push({ search: `?step=${step}` })
    }
  }

  useEffect(() => {
    if (step === 0 && !state.normalized) {
      dispatch({
        type: types.RENORMALIZE,
      })
    }
    if (
      (step === 1 && (!state.addressForm || !state.addressForm.cp_municipio)) ||
      (step === 2 && (!state.selectedHome || !state.selectedHome['cod_postal'])) ||
      (step === 3 &&
        (!state.selectedHome ||
          !state.consultData['flowType'] ||
          !APP_PATH_RULES[state.consultData['flowType']]?.certificate ||
          !state.certificateForm ||
          !state.certificateForm['tipo_mercado'])) ||
      step > 3
    ) {
      dispatch({
        type: types.RESET,
      })
      setStep(0, { replace: true })
    }
  }, [step]) // eslint-disable-line react-hooks/exhaustive-deps

  const actions = {
    reset: () => {
      dispatch({
        type: types.RESET,
      })
      setStep(0)
    },
    changeStep: (payload) => {
      setStep(payload)
    },
    nextStep: () => {
      setStep(step + 1)
    },
    prevStep: () => {
      setStep(step - 1)
    },
    changeToNotNormalized: (dataUpdate = {}) => {
      const homeData = assembleNotNormalizedHome(state, dataUpdate)
      dispatch({
        type: types.CHANGE_TO_UNNORMALIZED,
        payload: homeData,
      })
      setStep(1)
    },
    changeAddressForm: ({ value, name }) => {
      if (value) {
        if (name === 'direccion') {
          getNumbers(state.addressForm, value).then((data) => {
            if (!ValidateAddressData(data, intl, postAlarm)) return
            dispatch({
              type: types.SET_NUMBER_VALUES,
              payload: data,
            })
          })
        }
        if (name === 'numero') {
          getFlats(state.addressForm, value).then((data) => {
            if (!ValidateAddressData(data, intl, postAlarm)) return
            dispatch({
              type: types.SET_HOME_VALUES,
              payload: data,
            })
          })
        }
      } else {
        dispatch({
          type: types.SET_ADDRESS_FORM,
          payload: { key: name, value: null },
        })
      }
      dispatch({
        type: types.RESET_ADDRESS_FORM,
        payload: refineAddress(name, value, state),
      })
    },
    changeNotNormalizedAddressForm: ({ value, name }) => {
      dispatch({
        type: types.SET_SELECTED_HOME,
        payload: {
          ...state.selectedHome,
          [name]: value,
        },
      })
      dispatch({
        type: types.SET_LOADING,
        payload: false,
      })
    },
    getTownValues: (value) => {
      return new Promise((resolve, reject) => {
        getZipMunicipalities(value)
          .then((data) => {
            if (!ValidateAddressData(data, intl, postAlarm)) return
            dispatch({
              type: types.SET_TOWN_VALUES,
              payload: data,
            })
            resolve()
          })
          .catch(() => reject())
      })
    },
    getAddressValues: (value) => {
      return new Promise((resolve, reject) => {
        getStreets(state.addressForm, value)
          .then((data) => {
            if (!ValidateAddressData(data, intl, postAlarm)) return
            dispatch({
              type: types.SET_ADDRESS_VALUES,
              payload: data,
            })
            resolve()
          })
          .catch(() => reject())
      })
    },
    getConsult: (selectedHome, installerId) => {
      const homeData = assembleNormalizedHome(selectedHome, state)
      dispatch({
        type: types.SET_SELECTED_HOME,
        payload: homeData,
      })
      getDataForm(selectedHome['cidi']).then((consultResponse) => {
        //getEmpresaCAByZip(installerId, homeData['cod_postal']).then((empresa) => {
        getAppByCidi(selectedHome['cidi']).then((data) => {
          // Added this call here to be able to choose a DESBLOQUEO PATH later
          dispatch({
            type: types.SET_CONSULT_DATA,
            payload: checkConsultAnswers(
              selectedHome['estado'],
              {
                ...consultResponse,
                exterior_pendiente_certificado: data.exterior_pendiente_certificado,
                solicitud_existente: data.solicitud_existente,
                origen: data.tipo_solicitud,
              },
              homeData
            ),
          })
          setStep(1)
        })
        //})
      })
    },
    setEmpresa: (empresa) => {
      dispatch({
        type: types.SET_EMPRESA,
        payload: empresa,
      })
    },
    changeCustomerFormArray: (array) => {
      dispatch({
        type: types.SET_CUSTOMER_FORM_ARRAY,
        payload: array,
      })
    },
    changeIsContratable: (payload) => {
      dispatch({
        type: types.SET_IS_CONTRATABLE,
        payload: payload,
      })
    },
    changeCamino: (isContratable, originCamino, mainCamino) => {
      if (isContratable) {
        dispatch({
          type: types.SET_CHANGE_CAMINO,
          payload: { after: 'SCR2', before: originCamino },
        })
      } else {
        dispatch({
          type: types.SET_CHANGE_CAMINO,
          payload: { after: mainCamino, before: 'SCR2' },
        })
      }
    },
    changeCustomerForm: ({ value, name }) => {
      dispatch({
        type: types.SET_CUSTOMER_FORM,
        payload: { key: name, value },
      })
      if (name === 'potencia') {
        const consumoSelected = consumo.data.find((option) => option.potencia === value)
        /*if (!!consumoSelected) {
          dispatch({
            type: types.SET_CUSTOMER_FORM,
            payload: { key: 'consumo', value: consumoSelected.key },
          })
          const tarifa = consumoSelected.numero_tarifa_aplicada
          console.log("Tarifa" + tarifa)
          dispatch({
            type: types.SET_CUSTOMER_FORM,
            payload: { key: 'numero_tarifa_aplicada', value: tarifa },
          })
        } */
      } else if (name === 'consumo') {
        const tarifa = consumo.data.find((option) => option.key === value)?.numero_tarifa_aplicada
        if (tarifa) {
          dispatch({
            type: types.SET_CUSTOMER_FORM,
            payload: { key: 'numero_tarifa_aplicada', value: tarifa },
          })
        }
      }
    },
    checkIfAppExist: async (cidi) => {
      getAppByCidi(cidi).then((data) => {
        //const existenceState = state.consultData.solicitud_existente
        const existenceState = data['solicitud_existente']
        if (existenceState === 0) {
          if (data.bloqueo_creacion_zeus) {
            return feedback('error', data.bloqueo_creacion_zeus)
          } else if (data.op_payment_restriction) {
            feedback('warning', data.op_payment_restriction)
          }
        }
        switch (existenceState) {
          //Cases with errors:
          case 1:
          case 2:
          case 3:
            if (process.env.REACT_APP_MOCKS) break
            return feedback(
              'error',
              intl.formatMessage({ id: 'pages.consult.existence.error.' + existenceState })
            )
          default:
            console.debug('Error: existenceState is not defined')
        }
        setStep(step + 1)
      })
    },

    createApplication: (installerId, appSubtype, documentsToUpload) => {
      //Start procedure
      dispatch({
        type: types.SET_LOADING,
        payload: true,
      })
      return createApp(state, installerId, appSubtype, documentsToUpload)
        .then(() => {
          feedback(
            'success',
            'Se ha creado la solicitud. En el apartado "Peticiones" podrás ver su estado de avance.'
          )
          dispatch({
            type: types.RESET,
          })
          setStep(0)
        })
        .catch((error) => {
          dispatch({
            type: types.SET_LOADING,
            payload: false,
          })
          if (error.tmpApp) {
            rollbackApp(error.tmpApp)
          }
          return Promise.reject(error)
        })
    },
    prepareCertificate: () => {
      dispatch({
        type: types.SET_LOADING,
        payload: true,
      })
      return checkUser(state)
        .then(() => {
          dispatch({
            type: types.PREPARE_CERTIFICATE_FORM,
            payload: assembleCertificate(state),
          })
          setStep(3)
        })
        .catch((error) => {
          dispatch({
            type: types.SET_LOADING,
            payload: false,
          })
          return Promise.reject(error)
        })
    },
    prepareCertificateUnlockedPath: () => {
      dispatch({
        type: types.SET_LOADING,
        payload: true,
      })
      return checkClientForUnlockPath(state)
        .then((data) => {
          dispatch({
            type: types.PREPARE_CERTIFICATE_FORM,
            payload: assembleCertificate({ ...state, client_id: data.client_id }),
          })
          setStep(3)
        })
        .catch((error) => {
          dispatch({
            type: types.SET_LOADING,
            payload: false,
          })
          return Promise.reject(error)
        })
    },
    changeCertificateForm: ({ value, name }) => {
      dispatch({
        type: types.SET_CERTIFICATE_FORM,
        payload: {
          key: name,
          value,
        },
      })
    },
    createCertificate: (
      installerId,
      appSubtype,
      documentsToUpload,
      documentsToUploadCertificate,
      endpoint
    ) =>
      new Promise((resolve, reject) => {
        //Start procedure
        dispatch({
          type: types.SET_LOADING,
          payload: true,
        })
        if (state.consultData.exterior_pendiente_certificado) {
          updateAppAndCreateCertificate(
            state,
            installerId,
            appSubtype,
            documentsToUpload,
            documentsToUploadCertificate,
            endpoint
          )
            .then(() => {
              feedback('success', 'Se ha creado el certificado')
              dispatch({
                type: types.RESET,
              })
              setStep(0)
              resolve()
            })
            .catch((error) => {
              dispatch({
                type: types.SET_LOADING,
                payload: false,
              })
              return reject(error)
            })
        } else {
          createAppAndCertificate(
            state,
            installerId,
            appSubtype,
            documentsToUpload,
            documentsToUploadCertificate,
            endpoint
          )
            .then(() => {
              feedback('success', 'Se ha creado el certificado')
              dispatch({
                type: types.RESET,
              })
              setStep(0)
              resolve()
            })
            .catch((error) => {
              dispatch({
                type: types.SET_LOADING,
                payload: false,
              })
              if (error.tmpApp) {
                rollbackApp(error.tmpApp)
              }
              setStep(0)
              return reject(error)
            })
        }
      }),
  }

  useEffect(() => {
    if (step === 0) {
      dispatch({
        type: types.RESET,
      })
    }
  }, [step])

  return {
    ...actions,
    ...state,
    step,
  }
}
