import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress, Typography, Container } from '@material-ui/core'

import { UserForm } from './user-form.component'
import { useUserProfileContext } from '../contexts'

export const UserProfile = () => {
  const { formatMessage } = useIntl()
  const { loadingUser, getUserData } = useUserProfileContext()

  useEffect(() => {
    getUserData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingUser) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  return (
    <Container maxWidth="sm">
      <Box bgcolor="common.white" p={4} borderRadius={4}>
        <Typography variant="h4" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.user.title' })}
        </Typography>
        <UserForm />
      </Box>
    </Container>
  )
}
