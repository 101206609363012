const COMUNICACION_GESTOR = {
  key: 'gestor',
  value: 'Comunicación con Gestor',
  receiver: 'gestor',
  readOnly: false,
}

const COMUNICACION_E_COMMERCE = {
  key: 'e_commerce',
  value: 'Comunicación con E-commerce',
  receiver: 'e_commerce',
  readOnly: false,
}

const COMUNICACION_CLIENTE_GESTOR = {
  key: 'client',
  value: 'Comunicación entre Cliente y Gestor',
  receiver: 'client',
  sender: 'gestor',
  readOnly: true,
}

const COMUNICACION_CALL_CENTER = {
  key: 'call_center',
  value: 'Comunicación con Plataforma',
  receiver: 'call_center',
  readOnly: false,
}

export const COMMS_CHANNELS = ({ camino_solicitud, estado }) => {
  if (estado === '05')
    return [
      COMUNICACION_GESTOR,
      COMUNICACION_CLIENTE_GESTOR,
      COMUNICACION_E_COMMERCE,
      COMUNICACION_CALL_CENTER,
    ]
  switch (camino_solicitud) {
    case '01':
      return [COMUNICACION_GESTOR, COMUNICACION_CLIENTE_GESTOR, COMUNICACION_CALL_CENTER]
    default:
      return [COMUNICACION_E_COMMERCE, COMUNICACION_CALL_CENTER]
  }
}

export const TEXT_AREA_LENGTH = 250
