import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, Switch } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { useAjaxContext } from '../../../../core/ajax'
import { setTariffs } from '../../../tariffs'

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActive = withStyles(switchStyles)(({ classes, ...props }) => {
  const dispatch = useDispatch()
  const { patchById } = useAjaxContext()
  const global = useSelector((state) => state.global)
  const searchParams = useSelector((state) => state.tariffs.search)
  const tariffId = props.rowData['idTarifa']
  const tariffActive = props.rowData['activa']
  const switchDisabled = !ADMIN_ROLES[global.role]
  const showSwitch = props.rowData['provincias'] !== ''

  const handleToggleState = (e) => {
    patchById('tarifa/modificar', tariffId, { activa: e.target.checked })
      .then(() => {
        dispatch(setTariffs(searchParams))
      })
      .catch(() => console.error('Error activating/deactivating tariff'))
  }

  if (!showSwitch) {
    return '--'
  }

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={tariffActive}
      onClick={handleToggleState}
      disabled={switchDisabled}
    />
  )
})
