import { createTheme, responsiveFontSizes } from '@material-ui/core'

// Theme localization: https://material-ui.com/guides/localization/
import * as locales from '@material-ui/core/locale'

import { LOCALE_DEFAULT } from '../../i18n/constants'
import { MATERIAL_COMPONENTS_OVERRIDES } from './overrides'
import { APP_THEME_COLORS } from './colors'

// Default theme: https://material-ui.com/customization/default-theme/
export let theme = responsiveFontSizes(
  createTheme(
    {
      overrides: MATERIAL_COMPONENTS_OVERRIDES,
      palette: {
        primary: {
          main: APP_THEME_COLORS[30],
          contrastText: APP_THEME_COLORS[10],
        },
        secondary: {
          main: APP_THEME_COLORS[40],
          contrastText: APP_THEME_COLORS[20],
        },
      },
      typography: {
        fontFamily: 'FSEmeric-Regular',
        h4: {
          fontFamily: 'FSEmeric-Medium',
        },
        h5: {
          fontFamily: 'FSEmeric-Medium',
        },
        h6: {
          fontFamily: 'FSEmeric-Medium',
          lineHeight: 1,
        },
        subtitle1: {
          fontFamily: 'FSEmeric-Medium',
          lineHeight: 1.25,
        },
        subtitle2: {
          fontFamily: 'FSEmeric-Medium',
        },
        body1: {
          lineHeight: 1.25,
        },
      },
    },
    locales[LOCALE_DEFAULT.replace('-', '')]
  )
)
