import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { InfoBox } from '../../../../ui'
import { getIPs } from '../../slices'
import { IPTable } from '../table'
import { IPFilter } from '../filter'
import { ipDashboardStyles } from './ip-dashboard.styles'
import { IP_DEFAULT_QUERY } from '../../constants'

export const IPDashboard = withStyles(ipDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...IP_DEFAULT_QUERY,
    ...urlSearchParams,
  }
  const isAdmin = ADMIN_ROLES[global.role]
  const isGuild = !!global.gremio

  useEffect(() => {
    dispatch(getIPs(searchParams, isGuild))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <InfoBox
        {...{
          severity: 'warning',
          hidden: !!global.gremio || !!global.empresa?.iban_validado,
          title: formatMessage({ id: 'global.alert.title' }),
          message: formatMessage({
            id: `pages.ipList.ibanAlert.${isAdmin ? '1' : '2'}.description`,
          }),
        }}
      />
      <Container className={classes.container} maxWidth="xl">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.ipList.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <IPFilter />
        </Box>
        <Box>
          <IPTable />
        </Box>
      </Container>
    </Box>
  )
})
