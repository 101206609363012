export const ERROR_CODES = {
  3550: {
    error: 'No existe',
    message: 'La URL proporcionada no existe.',
  },
  3551: {
    error: 'Caducado (no grabador)',
    message: 'La petición ha caducado.',
  },
  3552: {
    error: 'Caducado (grabador / grabador+captador)',
    message: 'La petición ha caducado. Por favor vuelva a realizar el registro.',
    redirectTo: 'signup',
  },
  3553: {
    error: 'Ya ha sido confirmado',
    message: 'El registro ya ha sido confirmado. Por favor inicie sesión.',
    redirectTo: 'login',
  },
}
