export const TARIFFS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
  only_active: 'false',
}

export const APPS_FILTERS = [
  {
    name: 'only_active',
    filterType: 'switch',
  },
  {
    name: 'ccaa',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
    cleanup: ['provincias'],
  },
  {
    name: 'provincias',
    comboId: 'provincias',
    size: 'small',
    multiple: true,
  },
]
