import React, { useLayoutEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton } from '../../../../ui'

export const RejectInspDialog = ({ close, error, cancelAction, confirmAction }) => {
  const { formatMessage } = useIntl()

  const [customError, setCustomError] = useState({})

  const handleConfirm = () => {
    close()
    confirmAction()
  }

  const getCustomError = (error) => {
    let errorCode = error.code || 'default'
    return {
      code: errorCode,
      message: formatMessage({
        id: `pages.inspections.rejectInspDialog.feedback.${errorCode}.description`,
      }),
    }
  }

  useLayoutEffect(() => {
    setCustomError(getCustomError(error))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.inspections.rejectInspDialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      {customError.code === 8503 ? (
        <DialogContent>
          <Typography variant="body1" paragraph>
            {customError.message}
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" paragraph>
            {formatMessage({ id: 'pages.inspections.rejectInspDialog.description.1' })}
          </Typography>
          <Alert severity="warning">
            <Typography variant="body1">{error.message}</Typography>
          </Alert>
        </DialogContent>
      )}
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-around" flexWrap="wrap" p={1}>
          <Box width={150}>
            <ThemeButton onClick={handleConfirm} color="primary" fullWidth>
              {formatMessage({ id: 'pages.inspections.rejectInspDialog.confirm' })}
            </ThemeButton>
          </Box>
        </Box>
      </DialogActions>
    </Box>
  )
}
