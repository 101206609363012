import React, { useLayoutEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'

import { useCombos } from '../../../combos'
import { COMPANY_COMBOS_IDS } from '../../constants'
import { useCompanyProfileContext } from '../../contexts'
import { PrimaryCompanyForm } from './primary-company-form.component'

export const PrimaryCompany = ({ companyId }) => {
  useCombos(COMPANY_COMBOS_IDS)
  const { loadingCompany, getInstallerPrimaryCompany } = useCompanyProfileContext()

  useLayoutEffect(
    () => getInstallerPrimaryCompany(companyId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (loadingCompany) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  return <PrimaryCompanyForm />
}
