import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid } from '@material-ui/core'

import { verifyPwd } from '../../core/utils'
import { useAjaxContext } from '../../core/ajax'
import { feedback } from '../../core/feedback'
import { PasswordInput, ThemeButton } from '../../ui'

export const PasswordForm = () => {
  const { formatMessage } = useIntl()
  const { put } = useAjaxContext()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  useEffect(() => {
    verifyPwd(
      formState['newPassword'],
      formState['repitNewPassword'],
      isValidPassword,
      setIsValidPassword,
      setErrorMssg
    )
  }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    if (isValidPassword) {
      const payload = {
        password: formState['currentPassword'],
        new_password: formState['newPassword'],
      }
      put('/usuario/modify_password', payload)
        .then(() => {
          setLoading(false)
          feedback('success', formatMessage({ id: 'pages.profile.pwd.feedback.1' }))
        })
        .catch((response) => {
          setLoading(false)
          const errorMssg =
            response?.response?.data?.message ||
            formatMessage({ id: 'pages.profile.pwd.feedback.2' })
          feedback('error', errorMssg)
        })
    }
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.pwd.subtitle' })}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
          {formatMessage({ id: 'pages.profile.pwd.description' })}
        </Typography>
      </Box>
      <Box mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PasswordInput
              value={formState['currentPassword']}
              name="currentPassword"
              title={formatMessage({ id: 'pages.profile.pwd.fields.currentPwd' })}
              placeholder={formatMessage({ id: 'pages.profile.pwd.fields.currentPwd' })}
              onChange={handleChangeForm}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              value={formState['newPassword']}
              name="newPassword"
              title={formatMessage({ id: 'pages.profile.pwd.fields.newPwd' })}
              placeholder={formatMessage({ id: 'pages.profile.pwd.fields.newPwd' })}
              onChange={handleChangeForm}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              value={formState['repitNewPassword']}
              name="repitNewPassword"
              title={formatMessage({ id: 'pages.profile.pwd.fields.repitPwd' })}
              placeholder={formatMessage({ id: 'pages.profile.pwd.fields.repitPwd' })}
              onChange={handleChangeForm}
              required
            />
          </Grid>
        </Grid>
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton type="submit" loading={loading} disabled={!isValidPassword}>
          {formatMessage({ id: 'pages.profile.pwd.button.save' })}
        </ThemeButton>
      </Box>
    </form>
  )
}
