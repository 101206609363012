import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import {
  AssociatesIP3Table,
  AssociatesIP3Filters,
  AssociatesIP3Provider,
} from '../../../features/associates-ip3'
import { associatesIP3Styles } from './associates-ip3.styles'

export const AssociatesIP3Page = withStyles(associatesIP3Styles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <AssociatesIP3Provider>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.associatesIP3.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <AssociatesIP3Filters />
        </Box>
        <Box>
          <AssociatesIP3Table />
        </Box>
      </Container>
    </AssociatesIP3Provider>
  )
})
