export const tariffNewStyles = (theme) => ({
  colorPanel: {
    backgroundColor: '#fafafa',
  },
  provinciaLine: {
    cursor: 'pointer',
  },
  provinciaLineChosen: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  zipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  zipCard: {
    margin: '0.5em',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '1em',
  },
  zipCardChosen: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  zipMarker: {
    '&:first-child': {
      display: 'block',
      width: '1px',
      padding: '0',
      '& svg': {
        display: 'none',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})
