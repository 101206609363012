export const companyGuildIPStyles = (theme) => ({
  container: {
    borderRadius: '3px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    minWidth: '150px',
    maxWidth: '500px',
    minHeight: '150px',
    padding: '1em',
  },
})
