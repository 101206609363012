import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { useModalContext } from '../../../../ui'
import { ThemeButton, FilterBox } from '../../../../ui'

import { MarketerUsersCreateDialog } from '../dialog'

export const MarketerUsersFilter = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleCreateMarketerUser = () =>
    open({
      Component: MarketerUsersCreateDialog,
      type: 'responsive',
      maxWidth: 'md',
    })

  return (
    <FilterBox>
      <Box ml={2}>
        <ThemeButton color="primary" labelM="sm" onClick={handleCreateMarketerUser}>
          {formatMessage({ id: 'pages.guild.users.addButton.label' })}
        </ThemeButton>
      </Box>
    </FilterBox>
  )
}
