export const BILLING_DATA_FORM_FIELDS = ({ formState, onChange, fm }) => ({
  NOMBRE: {
    required: true,
    name: 'nombre',
    value: formState.nombre,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.name.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.name.placeholder' }),
    onChange,
  },
  APELLIDOS: {
    required: true,
    name: 'apellidos',
    value: formState.apellidos,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.apellidos.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.apellidos.placeholder' }),
    onChange,
  },
  TELEFONO: {
    required: true,
    name: 'telefono',
    value: formState.telefono,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.telefono.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.telefono.title' }),
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
  },
  EMAIL: {
    required: true,
    name: 'email',
    value: formState.email,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.email.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.email.placeholder' }),
    onChange,
    inputProps: { type: 'email' },
  },
  NIF: {
    required: true,
    name: 'nif',
    value: formState.nif,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.identificador.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.identificador.placeholder' }),
    onChange,
  },
  CODIGO_POSTAL: {
    required: true,
    name: 'cod_postal',
    value: formState.cod_postal,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.codigo_postal.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.codigo_postal.placeholder' }),
    onChange,
    inputProps: {
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
      maxLength: 5,
    },
  },
  MUNICIPIO: {
    required: true,
    name: 'municipio',
    value: formState.municipio,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.municipio.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.municipio.placeholder' }),
    onChange,
  },
  DIRECCION: {
    required: true,
    name: 'direccion',
    value: formState.direccion,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.via.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.via.placeholder' }),
    onChange,
  },
  NUMERO: {
    required: true,
    name: 'numero',
    value: formState.numero,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.numero.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.numero.placeholder' }),
    onChange,
  },
  PROVINCIA: {
    required: true,
    name: 'provincia',
    value: formState.provincia,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.provincia.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.provincia.placeholder' }),
    onChange,
  },
})

export const prepareAutoFill = (formState, data) => {
  return {
    ...formState,
    nombre: data.usuario.nombre,
    apellidos: data.usuario.apellidos,
    telefono: data.usuario.telefono,
    email: data.usuario.email,
    tipo_identificador: data.usuario.tipo_identificador,
    nif: data.usuario.identificador,
    cod_postal: data.cod_postal,
    municipio: data.municipio,
    direccion: data.direccion,
    numero: data.numero,
    provincia: data.provincia,
  }
}
