import React from 'react'
import { Box, Grid } from '@material-ui/core'

import { TextInput } from '../../../../ui'
import { COMPANY_FORM_CONFIG } from './constants'

export const CompanyAddressForm = ({ inputProps }) => {
  return (
    <Box p={2} bgcolor="grey.200" boxShadow={'1px 2px 4px rgba(0, 0, 0, 0.25)'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CALLE }} />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).NUMERO }} />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).PISO }} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).POLIGONO }} />
        </Grid>
        <Grid item xs={12} sm={9} md={7}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).POBLACION }} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_POSTAL }} />
        </Grid>
      </Grid>
    </Box>
  )
}
