export const USER_FORM_CONFIG = ({ formState, onChange, combos, intl }) => ({
  NOMBRE: {
    required: true,
    value: formState['u_nombre'],
    name: 'u_nombre',
    // title={int.formatMessage({ id: '' })}
    title: 'Nombre',
    // placeholder={intl.formatMessage({ id: '' })}
    placeholder: 'Ej. Joan',
    onChange,
  },
  APELLIDOS: {
    required: true,
    value: formState['u_apellidos'],
    name: 'u_apellidos',
    title: 'Apellidos',
    placeholder: 'Ej. Sánchez Rodríguez',
    onChange,
  },
  TELEFONO: {
    required: true,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    // inputProps: {
    //   // type: 'tel',
    //   pattern: '/(^[9|8](?!0)d{8}$)|(^7[1-4]d{7}$)|(^6{1}d{8}$)/g',
    //   maxLength: 9,
    // },
    value: formState['u_telefono'],
    name: 'u_telefono',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    onChange,
  },
  EMAIL: {
    required: true,
    inputProps: { type: 'email' },
    value: formState['u_email'],
    name: 'u_email',
    title: 'Correo electrónico (usuario)',
    placeholder: 'Ej. nombre@dominio.com',
    onChange,
    onPaste: (e) => e.preventDefault(),
  },
  REMAIL: {
    required: true,
    inputProps: { type: 'remail' },
    value: formState['u_remail'],
    name: 'u_remail',
    title: 'Repite el correo electrónico',
    placeholder: 'Confirma tu correo',
    onChange,
    onPaste: (e) => e.preventDefault(),
  },
  PASSWORD: {
    required: true,
    value: formState['u_password'],
    name: 'u_password',
    title: 'Contraseña',
    placeholder: 'Mínimo 8 caracteres',
    autoComplete: 'new-password',
    tooltip: intl.formatMessage({ id: 'pages.pwdRecovery.extra' }),
    onChange,
  },
  RPASSWORD: {
    required: true,
    value: formState['u_rpassword'],
    name: 'u_rpassword',
    title: 'Repite la contraseña',
    placeholder: 'Confirma la contraseña',
    onChange,
  },
})
