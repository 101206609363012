import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Grid, CircularProgress } from '@material-ui/core'

import { ThemeButton, FileUploader, UploadTooltip, SelectInput } from '../../../../ui'
import { RequestGasPStyles } from './request-gas_p.style'
import { UPLOAD_BUTTONS_GAS_P_CONFIG } from '../../constants'
import { feedback } from '../../../../core/feedback'
import { DOCUMENT_ATTACHMENT_GAS_P_CONFIG } from '../../../documents'
import { useCombos } from '../../../combos'

export const RequestGasPComponent = withStyles(RequestGasPStyles)(
  ({ close, application, postGASP, putGASP, forceRefresh, setForceRefresh }) => {
    const intl = useIntl()

    const [model, setModel] = useState({ ...application })
    const [loading, setLoading] = useState(false)
    const combos = useCombos(['potencia_gas_p'])
    const [canSave, setCanSave] = useState(false)
    const [uploadButtonArray, setUploadButtonArray] = useState([])
    const [power, setPower] = useState(
      application.gas_p?.potencia_instalacion_gas ? application.gas_p.potencia_instalacion_gas : ''
    )
    const [documentData, setDocumentData] = useState({
      d_peticion_gasp: application.gas_p ? application.gas_p.d_peticion_gasp : null,
      d_escritura_poderes: application.gas_p ? application.gas_p.d_escritura_poderes : null,
      d_dn: application.gas_p ? application.gas_p.d_dn : null,
      d_cc: application.gas_p ? application.gas_p.d_cc : null,
      d_proyecto_gas: application.gas_p ? application.gas_p.d_proyecto_gas : null,
      d_proyecto_calefaccion: application.gas_p ? application.gas_p.d_proyecto_calefaccion : null,
    })

    const handleUploadFile = (data, buttonData) => {
      setDocumentData({ ...documentData, [buttonData]: data })
    }

    const handleChangePower = (e) => {
      setPower(e.target.value)
    }

    useEffect(() => {
      if (application) {
        setModel({
          ...application,
        })
      }
    }, [application])

    const handleRequestGas = () => {
      setLoading(true)
      const payload = {
        solicitud_id: application.id,
        potencia_instalacion_gas: power,
        d_peticion_gasp_id: documentData['d_peticion_gasp'].id,
        d_escritura_poderes_id: documentData['d_escritura_poderes']
          ? documentData['d_escritura_poderes'].id
          : null,
        d_dn_id: documentData['d_dn'] ? documentData['d_dn'].id : null,
        d_cc_id: documentData['d_cc'] ? documentData['d_cc']?.id : null,
        d_proyecto_gas_id: documentData['d_proyecto_gas']
          ? documentData['d_proyecto_gas']?.id
          : null,
        d_proyecto_calefaccion_id: documentData['d_proyecto_calefaccion']
          ? documentData['d_proyecto_calefaccion']?.id
          : null,
      }

      const appId = model.id
      if (model.gas_p !== null) {
        const gaspId = model.gas_p.id
        putGASP(payload, gaspId, appId).then(() => {
          // getAppById(appId).then(() => {
          //   setModel(data.data)
          //   setLoading(false)
          //   close()
          // })
          setLoading(false)
          close()
        })
      } else {
        postGASP(payload, appId).then(() => {
          // getAppById(appId).then((data) => {
          //   setModel(data.data)
          //   setLoading(false)
          //   close()
          // })
          setLoading(false)
          close()
        })
      }
      if (setForceRefresh) {
        setForceRefresh(!forceRefresh)
      }
    }

    useEffect(() => {
      if (documentData['d_peticion_gasp']?.id) {
        setCanSave(true)
        return
      }
      setCanSave(false)
    }, [power, documentData])

    useEffect(() => {
      if (model && power) {
        setUploadButtonArray(
          UPLOAD_BUTTONS_GAS_P_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_GAS_P_CONFIG[doc]).map(
            (doc) => {
              return {
                ...doc,
                title: intl.formatMessage({
                  id: `pages.application.view.requestGasP.documents.${doc.field}`,
                }),
                name: documentData[doc.model]?.nombre || '',
              }
            }
          )
        )
      }
    }, [model, power, documentData, intl])

    const handleUploadError = (data) => {
      feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
    }

    return (
      <Box p={4}>
        <Box mb={4}>
          <Typography
            variant="h5"
            style={{ marginBottom: '2em', display: 'flex', justifyContent: 'center' }}
          >
            {model.gas_p
              ? intl.formatMessage({
                  id: 'pages.application.detail.actionButtons.modify_request_gas_p.label',
                })
              : intl.formatMessage({
                  id: 'pages.application.detail.actionButtons.request_gas_p.label',
                })}
          </Typography>
          <Grid container spacing={4}>
            <>
              <Box mb={2} ml={1}>
                <SelectInput
                  required
                  fullWidth
                  name="potencia_gas_p"
                  title="Potencia instalación gas"
                  value={power}
                  values={combos['potencia_gas_p']?.data}
                  onChange={handleChangePower}
                />
              </Box>
              {uploadButtonArray.map((uploadButton) => {
                return (
                  <Grid key={uploadButton.id} item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Box display="flex" alignItems="center">
                          <Typography display="inline" variant="body1">
                            {uploadButton.title}
                          </Typography>
                          <UploadTooltip file={uploadButton} />
                        </Box>
                        <Typography
                          variant="body2"
                          color={uploadButton.estado === 'Rechazado' ? 'error' : 'initial'}
                        >
                          {uploadButton.name}
                        </Typography>
                      </Box>
                      <FileUploader
                        applicationId={model['id']}
                        budgetId={model['presupuesto']?.id}
                        clientId={model['usuario']['id']}
                        onSuccess={(data) => handleUploadFile(data, uploadButton.model)}
                        onError={handleUploadError}
                        {...uploadButton}
                      >
                        {(props) => (
                          <span
                            className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                            style={{ marginLeft: 10 }}
                            disabled={loading}
                          >
                            {props.loading ? (
                              <CircularProgress size={12} />
                            ) : (
                              intl.formatMessage({ id: 'pages.certificates.documents.button.add' })
                            )}
                          </span>
                        )}
                      </FileUploader>
                    </Box>
                  </Grid>
                )
              })}
            </>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="space-around" mt={3}>
          <ThemeButton onClick={() => close()} color="primary" labelM="lg">
            {intl.formatMessage({ id: 'global.close' })}
          </ThemeButton>
          <ThemeButton
            onClick={() => handleRequestGas()}
            color="primary"
            labelM="lg"
            loading={loading}
            disabled={!canSave}
          >
            {intl.formatMessage({ id: 'global.save' })}
          </ThemeButton>
        </Box>
        {!canSave && power !== '' ? (
          <Box>
            <Typography
              variant="body2"
              style={{ marginTop: '1.5em', display: 'flex', justifyContent: 'flex-start' }}
            >
              {intl.formatMessage({
                id: 'pages.application.view.requestGasP.cannot_save.title',
              })}
            </Typography>
          </Box>
        ) : null}
      </Box>
    )
  }
)
