import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'
import { USERS_FORM_CONFIG } from '../../constants'

export const GuildUsersFormComponent = ({ formState, close, onSubmit, onChange, isLoading }) => {
  const intl = useIntl()
  const inputProps = { formState, onChange, intl }

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).NOMBRE }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).APELLIDOS }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).EMAIL }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).TELEFONO }} />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-around" p={8} pt={6} pb={3} flexWrap="wrap">
        <Box mb={[3, 0]}>
          <ThemeButton onClick={close} variant="outlined" labelM="sm" disabled={isLoading}>
            Cancelar
          </ThemeButton>
        </Box>
        <Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="sm"
            loading={isLoading}
            disabled={isLoading}
          >
            {formState['id'] ? 'Guardar' : 'Crear'}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
