import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { feedback } from '../../../core/feedback'

export const useProfileCalls = () => {
  const { putById, getPublic, getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const updateEmpresaCA = useCallback((id_empresa, body) => {
    return new Promise((resolve, reject) => {
      putById(`/empresa_ca`, id_empresa, body)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkCompanySAPCode = useCallback((cif) => {
    return new Promise((resolve, reject) => {
      getPublic(`/proveedores_sap/existsbycif/${cif}`)
        .then(({ data }) => resolve(data))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getDocumentById = useCallback(
    (fileId) =>
      new Promise((resolve, reject) => {
        getById('/documento', fileId)
          .then((response) => {
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    updateEmpresaCA,
    checkCompanySAPCode,
    getDocumentById,
  }
}
