import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TextInput, SelectInput, ThemeButton } from '../../../../../ui'
import { getTypeOfDocument } from '../../../../../core/utils'
import { useCombos } from '../../../../combos'

export const IPInstallerAddForm = ({ tableData, onSubmit }) => {
  const combos = useCombos(['ip_installer_categories'])

  const [formState, setFormState] = useState({})
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!getTypeOfDocument(formState.identificador)) {
      return setErrorMssg('No es un NIF o NIE válido')
    } else if (tableData.some((row) => row.identificador === formState.identificador)) {
      return setErrorMssg('Ya existe un instalador con el mismo NIF/NIE.')
    } else if (tableData.some((row) => row.codigo === formState.codigo)) {
      return setErrorMssg('Ya existe un instalador con el mismo código.')
    } else {
      onSubmit(e, formState)
    }
  }

  const defaultInputProps = {
    required: true,
    onChange: handleChangeForm,
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              value={formState.nombre}
              name="nombre"
              title="Nombre completo"
              {...defaultInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              value={formState.identificador}
              name="identificador"
              title="NIF/NIE"
              {...defaultInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              fullWidth={true}
              value={formState.categoria}
              values={combos.ip_installer_categories?.data || []}
              name="categoria"
              title="Categoría"
              {...defaultInputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              value={formState.codigo}
              name="codigo"
              title="Código Instalador"
              tooltip="Tipo de habilitación otorgado por el Organismo Competente"
              {...defaultInputProps}
            />
          </Grid>
        </Grid>
      </Box>
      {errorMssg ? (
        <Box mb={3}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      ) : null}
      <Box display="flex" justifyContent="center">
        <ThemeButton type="submit">Guardar</ThemeButton>
      </Box>
    </form>
  )
}
