import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax'
import { SignupFormProvider } from '../contexts'
import { WizardComponent } from './wizard'
import { UserFormComponent } from '../components/user-form'
import { CompanyFormComponent } from '../components/company-form'
import { CompanyPublicFormComponent } from '../components/company-public-form'
import { CompanySummaryComponent } from '../components/company-summary'
import { assembleSubmitPayload } from '../logics'
import { useSignupDialogs } from '../hooks/useSignupDialog'
import { correctUser } from '../../../mocks/signup.mock'

const mocks = process.env.REACT_APP_MOCKS ? correctUser : {}

export const DEFAULT_FORM_STATE = {
  tipo_actividad: '01',
  acepta_financiacion: '00',
  publica: '01',
  ...mocks,
}

export const SignupComponent = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { postPublic } = useAjaxContext()
  const { notifySignupFailure, notifySuccessfulSignup } = useSignupDialogs()

  const [formState, setFormState] = useState(DEFAULT_FORM_STATE)
  const [installerWorks, setInstallerWorks] = useState({ catchment: true })
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
  const [termsAndConditionsDisabled, setTermsAndConditionsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeForm = useCallback((e) => {
    const { name, value } = e.target
    setFormState((formState) => ({ ...formState, [name]: value }))
  }, [])

  const handleSubmitForm = () => {
    const { endpoint, payload } = assembleSubmitPayload({ formState, installerWorks })
    setIsLoading(true)
    postPublic(endpoint, payload)
      .then(() => {
        setIsLoading(false)
        notifySuccessfulSignup(() => {
          history.push('/login')
        })
      })
      .catch(({ response }) => {
        let message = formatMessage({ id: 'pages.signup.feedback.2.text' })
        if (response?.data?.code && response.data.message) {
          switch (response.data.code) {
            case 3103:
              message = formatMessage({ id: 'pages.signup.confirmation.error.nif.description' })
              break
            case 3105:
              message = formatMessage({
                id: 'pages.signup.confirmation.error.captadorNotIP.description',
              })
              break
            default:
              message = response.data.message
              break
          }
        }
        setIsLoading(false)
        notifySignupFailure(message)
      })
  }

  const getStepViews = useCallback(() => {
    let stepViews = [UserFormComponent, CompanyFormComponent]
    if (formState.tipo_actividad === '01') {
      if (installerWorks.catchment && formState.publica === '01') {
        stepViews = [...stepViews, CompanyPublicFormComponent, CompanySummaryComponent]
      } else if (installerWorks.ip) {
        stepViews = [...stepViews, CompanySummaryComponent]
      }
    }
    return stepViews
  }, [formState.tipo_actividad, formState.publica, installerWorks])

  return (
    <SignupFormProvider
      value={{
        formState,
        onChange: handleChangeForm,
        onSubmit: handleSubmitForm,
        installerWorks,
        setInstallerWorks,
        termsAndConditionsChecked,
        setTermsAndConditionsChecked,
        termsAndConditionsDisabled,
        setTermsAndConditionsDisabled,
        isLoading,
      }}
    >
      <WizardComponent stepViews={getStepViews()} />
    </SignupFormProvider>
  )
}
