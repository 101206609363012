export const IP_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}

export const IP_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_ip',
    multiple: true,
  },
  {
    name: 'tipo_instalacion',
    comboId: 'tipo_instalacion_ab',
    multiple: true,
  },
  {
    name: 'fecha_creacion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_creacion_fin',
  },
  {
    name: 'fecha_creacion_fin',
    filterType: 'date',
    minDateField: 'fecha_creacion_inicio',
  },
  {
    name: 'fecha_inspeccion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_inspeccion_fin',
  },
  {
    name: 'fecha_inspeccion_fin',
    filterType: 'date',
    minDateField: 'fecha_inspeccion_inicio',
  },
]

export const IBAN_VALIDATED_STATE = '20'
