import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { ThemeButton, TextInput } from '../../../../../../ui'

const TEXT_AREA_LENGTH = 250

export const ObservacionesDialog = ({
  AdhesionId,
  close,
  addObservaciones,
  publicOfferData,
  poObservacionesInstalador,
}) => {
  const { formatMessage } = useIntl()

  const [formState, setFormState] = useState({
    observaciones_instalador: poObservacionesInstalador,
  })

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    addObservaciones(AdhesionId, publicOfferData, formState)
    close()
  }
  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            required
            name="observaciones_instalador"
            title={`${formatMessage({
              id: 'pages.profile.company.guild.info.publicOffer.observaciones.label',
            })}:`}
            placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
            value={formState['observaciones_instalador']}
            multiline
            rows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton type="submit" color="primary" labelM="lg">
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
