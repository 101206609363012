export const actionButtonStyles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    cursor: 'pointer',
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
})
