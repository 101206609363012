import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useSignupFormContext } from '../../contexts'
import { TextInput, PasswordInput } from '../../../../ui'
import { WizardView } from '../wizard'
import { USER_FORM_CONFIG } from './constants'
import { emailValid, verifyPwd } from '../../../../core/utils'

export const UserFormComponent = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { formState, onChange } = useSignupFormContext()

  const [isValidPwd, setIsValidPwd] = useState(false)
  const [isValidMail, setIsValidMail] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const inputProps = { formState, onChange, combos, intl }

  const verifyMail = (mail, reMail, valid, setValid, setError) => {
    if (!mail) {
      if (valid) setValid(false)
      setError('Es necesario introducir un email')
      return false
    } else if (mail && mail !== reMail) {
      /* S2 */
      if (valid) setValid(false)
      setError('Los emails no coinciden')
      return false
    } else if (mail && mail === reMail && !emailValid(mail)) {
      if (valid) setValid(false)
      setError('El email no tiene un formato válido')
      return false
    } else if (mail && mail === reMail && emailValid(mail)) {
      if (!valid) setValid(true)
      setError('')
      return true
    } else return false
  }

  const handleNext = ({ step, setStep }) => {
    if (
      verifyPwd(
        formState['u_password'],
        formState['u_rpassword'],
        isValidPwd,
        setIsValidPwd,
        setErrorMssg
      ) &&
      verifyMail(
        formState['u_email'],
        formState['u_remail'],
        isValidMail,
        setIsValidMail,
        setErrorMssg
      )
    )
      setStep(step + 1)
  }

  return (
    <WizardView title="Datos de contacto" onNext={handleNext}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextInput {...{ ...USER_FORM_CONFIG(inputProps).NOMBRE }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput {...{ ...USER_FORM_CONFIG(inputProps).APELLIDOS }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput {...{ ...USER_FORM_CONFIG(inputProps).TELEFONO }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USER_FORM_CONFIG(inputProps).EMAIL }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USER_FORM_CONFIG(inputProps).REMAIL }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordInput {...{ ...USER_FORM_CONFIG(inputProps).PASSWORD }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordInput {...{ ...USER_FORM_CONFIG(inputProps).RPASSWORD }} />
        </Grid>
      </Grid>
      {errorMssg && (
        <Box mt={2} mb={1}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      )}
    </WizardView>
  )
}
