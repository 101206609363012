export const CertificateServicesStyles = (theme) => ({
  root: {
    width: '100%',
  },
  divider: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  rb: { height: 34, marginTop: -9 },
  mainBox: { marginBottom: '1em' },
  input: {
    backgroundColor: '#FFFFFF',
    height: 48,
  },
  container: {
    marginRight: 10,
  },
  campaignBox: {
    margin: '0 0 0 1em',
    padding: '1em',
    borderRadius: '1em',
    flex: '1',
    background: theme.palette.grey[200],
  },
})