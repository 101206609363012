export const APP_PATH = {
  NNSAG: 'NNSAG',
  SCR1: 'SCR1',
  SCR2: 'SCR2',
  SCR3: 'SCR3',
  DESBLOQUEO: 'DESBLOQUEO',
}

export const APP_TYPE = {
  SCR: 'scr',
  SAG: 'sag',
  NN: 'pdte_normalizar_direccion',
  NNE: 'pdte_normalizar_direccion_ext',
  NNI: 'pdte_normalizar_direccion_int',
}

export const APP_PATH_RULES = {
  NNSAG: {
    id: APP_PATH.NNSAG,
    selector: [
      { id: APP_TYPE.NNI, labelId: 'pages.consult.radioButtons.NNI.label' },
      { id: APP_TYPE.NNE, labelId: 'pages.consult.radioButtons.NNE.label' },
      { id: APP_TYPE.NN, labelId: 'pages.consult.radioButtons.NN.label' },
    ],
    normalize: true,
    app_type: (doc) => APP_TYPE['NN'],
    certificate: false,
    condition: ({ homeData }) => !homeData['cidi'],
  },
  DESBLOQUEO: {
    id: APP_PATH.DESBLOQUEO,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => doc,
    certificate: true,
    condition: ({ consulta }) =>
      consulta['camino_solicitud'] === '01' && consulta['exterior_pendiente_certificado'],
  },
  SCR1: {
    id: APP_PATH.SCR1,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => doc,
    certificate: false,
    condition: ({ consulta }) => consulta['camino_solicitud'] === '01',
  },
  SCR2: {
    id: APP_PATH.SCR2,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => doc,
    certificate: true,
    condition: ({ consulta }) => consulta['camino_solicitud'] === '03',
  },
  SCR3: {
    id: APP_PATH.SCR3,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => doc,
    certificate: true,
    condition: () => false,
  },
}
