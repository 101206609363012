import { IP_EXPORT_COLUMNS } from '../constants'

export const ipExportDataPreparation = ({ data, combos, intl }) => {
  const filteredData = data.filter((e) => e.estado !== '15')

  return filteredData.map((item) => {
    const formattedText = {}
    IP_EXPORT_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)

        formattedText[column.field] = formatedDate
      }
      if (column.field === 'tipo_documento') {
        formattedText[column.field] = 'IP'
      }
      if (column.field === 'estado') {
        const estadoValue =
          combos.estados_ip_readonly.data?.find((estado) => estado.key === item[column.field])
            ?.value || ''
        formattedText[column.field] = estadoValue
      }
      if (column.field === 'tipo_gas' && item[column.field]) {
        let tipoGas = ''
        if (item[column.field] === 'GAS NATURAL' || item[column.field] === 'GAS_NATURAL') {
          tipoGas = 'GN'
        } else {
          tipoGas = 'GLP'
        }

        formattedText[column.field] = tipoGas
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
}
