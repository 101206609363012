import React, { useState, useEffect } from 'react'
import { withStyles, Box, TextField, Typography } from '@material-ui/core'
import { filterBasicInputStyles } from './filter-basic-input.styles'
import { useTypingRefresh } from '../../core/utils/useTypingRefresh'
import { useHistory, useLocation } from 'react-router-dom'

const DEFAULT_TYPE_REFRESH_MS = 800
export const FilterBasicInput = withStyles(filterBasicInputStyles)(
  ({ classes, typeRefresh = DEFAULT_TYPE_REFRESH_MS, ...props }) => {
    const [inputValue, setInputValue] = useState('')
    const history = useHistory()
    const location = useLocation()
    const currentSearch = new URLSearchParams(location.search)
    const searchQuery = currentSearch.get(props.name)

    useEffect(() => setInputValue(searchQuery ? searchQuery : ''), [searchQuery])

    const changeSearchValue = (search) => {
      if ((!search || search.length === 0) && !currentSearch.get(props.name)) {
        // Do nothing
      } else {
        currentSearch.set(props.name, search)
        currentSearch.set('_pagina', 1)
        history.replace({
          search: '?' + currentSearch.toString(),
        })
      }
    }

    const { ref: textRef } = useTypingRefresh({
      timeout: typeRefresh,
      action: (val) => changeSearchValue(val),
      textFromRef: (ref) => (ref.current ? ref.current.querySelector('input') : null),
    })
    return (
      <Box style={{ flex: 1 }} ref={textRef}>
        <TextField
          className={classes.root}
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            className: classes.input,
            startAdornment: (
              <Typography variant="body1" className={classes.title}>
                {props.label}:
              </Typography>
            ),
          }}
        />
      </Box>
    )
  }
)
