import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { InstallerCompanyTabs } from '../installer-company-tabs'
import { PrimaryCompany } from '../primary-company'

export const InstallerCompany = () => {
  const { formatMessage } = useIntl()
  const primaryCompany = useSelector((state) => state.global.empresa)

  return (
    <Box bgcolor="common.white" p={4} borderRadius={4}>
      <Typography variant="h4" color="primary" paragraph>
        {formatMessage({ id: 'pages.profile.company.title' })}
      </Typography>
      {primaryCompany.captadora ? (
        <InstallerCompanyTabs />
      ) : (
        <PrimaryCompany companyId={primaryCompany.id} />
      )}
    </Box>
  )
}
