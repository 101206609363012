import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { ADMIN_ROLES, CAPTACION_ROLES } from '../../../core/constants'
import { TextInput, SelectInput, ThemeButton } from '../../../ui'
import { useCombos } from '../../combos'
import { useUserProfileContext } from '../contexts'

export const UserForm = () => {
  const { formatMessage } = useIntl()
  const combos = useCombos([])
  const { user, updateUser } = useUserProfileContext()
  const isCaptadorUser = CAPTACION_ROLES[user.rol]
  const disabled = !ADMIN_ROLES[user.rol]

  const filteredCombos = () => {
    let fCombos = { ...combos, roles: { data: combos.rol_captador_grabador.data } }
    if (isCaptadorUser) {
      const relatedCompaniesKeys = user.empresa.cc_aa
      const availableCompanies = combos.cod_zona_zeus?.data?.filter(({ key }) =>
        relatedCompaniesKeys.includes(key)
      )
      fCombos.cod_zona_zeus = { ...combos.cod_zona_zeus, data: availableCompanies }
    }
    return fCombos
  }

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState(user)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    let payload = { ...formState }
    for (let key in payload) {
      if (payload[key] === null) delete payload[key]
    }
    updateUser(payload)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              required
              value={formState['nombre']}
              name="nombre"
              title={formatMessage({ id: 'pages.profile.user.fields.name.title' })}
              placeholder={formatMessage({ id: 'pages.profile.user.fields.name.placeholder' })}
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              value={formState['apellidos']}
              name="apellidos"
              title={formatMessage({ id: 'pages.profile.user.fields.surname.title' })}
              placeholder={formatMessage({ id: 'pages.profile.user.fields.surname.placeholder' })}
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              type="email"
              required
              value={formState['email']}
              name="email"
              title={formatMessage({ id: 'pages.profile.user.fields.email.title' })}
              placeholder={formatMessage({ id: 'pages.profile.user.fields.email.placeholder' })}
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              inputProps={{ type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 }}
              value={formState['telefono']}
              name="telefono"
              title={formatMessage({ id: 'pages.profile.user.fields.phone.title' })}
              placeholder={formatMessage({ id: 'pages.profile.user.fields.phone.placeholder' })}
              onChange={handleChangeForm}
            />
          </Grid>
          {user.rol !== 'gremio' && user.rol !== 'comercializadora' ? (
            <>
              <Grid item xs={12}>
                <SelectInput
                  readOnly={true}
                  fullWidth={true}
                  value={formState['rol']}
                  values={filteredCombos().roles?.data || []}
                  name="rol"
                  title={formatMessage({ id: 'pages.profile.user.fields.tipo_perfil.title' })}
                  placeholder="Selecciona"
                />
              </Grid>
              {user.empresa.captadora ? (
                <Grid item xs={12}>
                  <SelectInput
                    required={true}
                    disabled={disabled}
                    fullWidth={true}
                    value={formState['cc_aa']}
                    values={filteredCombos().cod_zona_zeus?.data || []}
                    name="cc_aa"
                    title={formatMessage({
                      id: 'pages.profile.user.fields.ambito_geografico.title',
                    })}
                    placeholder="Selecciona"
                    onChange={handleChangeForm}
                    multiple={true}
                  />
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton type="submit" loading={loading}>
          {formatMessage({ id: 'pages.profile.user.button.save' })}
        </ThemeButton>
      </Box>
    </form>
  )
}
