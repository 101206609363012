import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { IP_EXPORT_COLUMNS, IP_FILTERS } from '../../constants'
import { ExportToXLSBtn } from '../../../../ui/exporttoxls-btn/exporttoxls-btn.component'
import { requests } from '../../../../core/ajax'
import { ipExportDataPreparation } from '../../logics'
import { feedback } from '../../../../core/feedback'
import { CreateIPBtn } from '../buttons'
import { ipFilterStyles } from './ip-filter.styles'

export const IPFilter = () => {
  const ipClasses = ipFilterStyles()
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const { get } = requests
  const combos = useSelector((state) => state.combos)
  const global = useSelector((state) => state.global)
  const showCreateIPButton =
    (global.empresa && global.empresa.activo && !!global.empresa.iban_validado) || global.gremio

  const search = useSelector((state) => state.ip.search)

  const data = IP_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.ipList.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox classes={ipClasses} filters={data}>
      <ExportToXLSBtn
        exportSelectionEnabled={false}
        exportAllEnabled={true}
        columnsExport={IP_EXPORT_COLUMNS}
        getAllData={() => {
          return new Promise((resolve, reject) => {
            get('inspecciones/tabla_instaladores', { params: { ...search, _num_x_pagina: 0 } })
              .then(({ data }) => {
                return resolve(ipExportDataPreparation({ data: data.registros, combos, intl }))
              })
              .catch((err) => {
                feedback('error', formatMessage({ id: 'global.errorDownload' }))
                return reject(err)
              })
          })
        }}
        titleRoot="ip_"
        headerNamespace="ipExport"
      />
      {showCreateIPButton ? <CreateIPBtn /> : null}
    </FilterBox>
  )
}
