import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { withStyles, Typography, Box, Grid } from '@material-ui/core'

import { TARIFF_FORM_CONFIG, TARIFF_FORM_STRUCTURE, TIMEOUT_REFRESH } from '../../constants'
import { ThemeButton, FoldingPanel, TextInput } from '../../../../ui'
import { tariffFormStyles } from './tariff-form.style'
import { TextInputReplace } from '../../../../ui/text-input'

import { useTypingRefresh } from '../../../../core/utils/useTypingRefresh'

export const TariffForm = withStyles(tariffFormStyles)(
  ({ classes, inputProps, submit, goToZoneConfig, checkName, clone }) => {
    const formatMessage = inputProps.intl.formatMessage

    //Form submit functionality and buttons, with custom validation
    const formRef = useRef()
    const [showErrors, setShowErrors] = useState(false)
    const [errors, setErrors] = useState({})
    // const htmlValidation = () =>
    //   new Promise((resolve, reject) => {
    //     if (!formRef.current.checkValidity()) {
    //       formRef.current.reportValidity()
    //       reject('Incorrect data')
    //     }
    //     resolve()
    //   })
    const customValidation = () => {
      return new Promise((resolve, reject) => {
        setShowErrors(true)
        for (let error in errors) {
          if (errors[error]) {
            reject('Incorrect data')
          }
        }
        resolve()
      })
    }

    //Field by field validation
    const customValidate = (rule, field, value) => {
      let error = false
      switch (rule) {
        case 'nonZero':
          const num = parseFloat(value.replace(',', '.'))
          if (isNaN(num) || num === 0) {
            error = true
          }
          break
        case 'required':
          if (!value) {
            error = true
          }
          break
        default:
          return false
      }
      if ((error || errors[field]) && errors[field] !== error) {
        setErrors((errors) => ({ ...errors, [field]: error }))
      }
      return error
    }
    //Original state of validation
    useEffect(() => {
      const lines = TARIFF_FORM_CONFIG(inputProps)
      for (let line in lines) {
        customValidate(lines[line].custom_validation, lines[line].name, lines[line].value)
      }
    }, [TARIFF_FORM_CONFIG, inputProps]) // eslint-disable-line react-hooks/exhaustive-deps

    //Form buttons
    const buttons = []
    if (goToZoneConfig) {
      buttons.push({
        text: formatMessage({ id: 'pages.tariffs.form.buttons.zones' }),
        key: 'goToZoneConfig',
        attributes: {
          color: 'default',
          variant: 'outlined',
          type: 'button',
          style: { marginLeft: 20 },
          onClick: () => {
            customValidation().then(goToZoneConfig)
          },
        },
      })
    }
    if (submit) {
      buttons.push({
        text: formatMessage({ id: 'pages.tariffs.form.buttons.save' }),
        key: 'submit',
        attributes: {
          color: 'primary',
          labelM: 'sm',
          type: 'button',
          style: { marginLeft: 20 },
          onClick: () => {
            customValidation().then(submit)
          },
        },
      })
    }
    if (clone) {
      buttons.push({
        text: 'Guardar como duplicado',
        key: 'clone',
        attributes: {
          color: 'secondary',
          labelM: 'sm',
          type: 'button',
          style: { marginLeft: 20 },
          onClick: () => {
            customValidation().then(clone)
          },
        },
      })
    }
    const ButtonBlock = ({ className }) =>
      buttons.length ? (
        <div className={className}>
          {buttons.map((button) => (
            <ThemeButton key={button.key} {...button.attributes}>
              {button.text}
            </ThemeButton>
          ))}
        </div>
      ) : null

    //Disable when immutable
    const ShowComponent = useMemo(
      () => (inputProps.disabled ? TextInputReplace : TextInput),
      [inputProps.disabled]
    )

    //Prepare arguments for validation:
    const prepareValidation = useCallback(
      (ref) => {
        const { custom_validation, text_invalid, onChange, ...entryProps } =
          TARIFF_FORM_CONFIG(inputProps)[ref]
        //Validate according to each component's rules
        const error = errors[entryProps.name] && showErrors
        return {
          ...entryProps,
          error: error,
          helperText: error && text_invalid,
          onChange: (event) => {
            customValidate(custom_validation, entryProps.name, event.target.value)
            onChange(event)
          },
        }
      },
      [TARIFF_FORM_CONFIG, inputProps, showErrors, errors] // eslint-disable-line react-hooks/exhaustive-deps
    )

    //Name properties
    const { ref: nameRef } = useTypingRefresh({
      timeout: TIMEOUT_REFRESH,
      action: (value) => {
        if (!value) {
          return
        }
        checkName().then((response) => {
          setErrors((errors) => ({ ...errors, repeated_name: response }))
        })
      },
      textFromRef: (ref) => (ref.current ? ref.current.querySelector('input') : null),
    })
    const name_props = { ...prepareValidation('NOMBRE') }
    name_props.error = name_props.error ? name_props.error : errors['repeated_name']
    name_props.helperText = name_props.helperText
      ? name_props.helperText
      : errors['repeated_name']
      ? formatMessage({ id: 'pages.tariffs.form.name.unicity.error' })
      : ''

    //Component itself
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        ref={formRef}
        className={classes.fullForm}
      >
        <Typography variant="h4" color="textSecondary" paragraph>
          {formatMessage({ id: 'pages.tariffs.form.title' })}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} ref={nameRef}>
              <ShowComponent {...name_props} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <ButtonBlock className={classes.upperButtons} />
              <Typography variant="body1" style={{ textAlign: 'right', marginTop: '0.5em' }}>
                {formatMessage({
                  id: 'pages.tariffs.form.disclaimer' + (submit ? '.edit' : '.view'),
                })}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {TARIFF_FORM_STRUCTURE(inputProps).map((line) => (
          <div className={classes.gridRow} key={line.title}>
            <FoldingPanel isExpanded title={line.title}>
              <Box display="block" style={{ width: '100%' }}>
                {line.prepend ? line.prepend(inputProps) : null}
                {line.items.map((row, i) => (
                  <Grid container spacing={3} key={i} style={{ marginTop: 0, marginBottom: 0 }}>
                    {row.map((entry) => {
                      return (
                        <Grid {...entry.conf} key={entry.ref}>
                          <ShowComponent {...prepareValidation(entry.ref)} />
                        </Grid>
                      )
                    })}
                  </Grid>
                ))}
              </Box>
            </FoldingPanel>
          </div>
        ))}

        <ButtonBlock className={classes.bottomButtons} />
      </form>
    )
  }
)
