import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper, Typography } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { BOILERS_TABLE_COLUMNS } from '../../constants'
import { TableUrlPagination } from '../../../../ui'
import { boilersTableStyles } from './boilers-table.styles'
import { useBoilersContext } from '../../contexts'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const BoilersTable = () => {
  const { formatMessage } = useIntl()
  const {
    data,
    search,
    pagination: { total_viviendas: totalEntries },
    loading: isLoading,
  } = useBoilersContext()
  const combos = useSelector((state) => state.combos)

  //Column structure
  const formattedColumns = useMemo(
    () =>
      [...BOILERS_TABLE_COLUMNS].map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.boilers.table.columns.${column.field}.title` }),
          cellStyle: { ...boilersTableStyles.cell, minWidth: column.minWidth },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          nombre_via:
            `${item['tipo_via']} ${item['nombre_via']} ${item['numero']}` +
            `${item['anexo'] ? ' ' + item['anexo'] : ''} ` +
            `${item['bloque'] ? ' ' + item['bloque'] : ''} ` +
            `${item['escalera'] ? ' ' + item['escalera'] : ''} ` +
            `${item['piso'] ? '-' + item['piso'] : ''}`,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data]
  )

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      // tableLayout: 'fixed',
      headerStyle: boilersTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const line = (column) => {
          switch (column) {
            default:
              return props.children
          }
        }
        return (
          <MTableCell {...props} style={{ padding: 0 }}>
            {line(props.columnDef.field)}
          </MTableCell>
        )
      },
    }),
    []
  )

  return (
    <>
      <TableUrlPagination
        columns={formattedColumns}
        components={tableComponents}
        options={tableOptions}
        totalEntries={totalEntries}
        search={search}
        //Not transformed in the url table interface
        data={processedData}
        isLoading={isLoading}
      />
      <Typography variant="body2" color="textSecondary">
        {formatMessage({ id: 'pages.boilers.table.footer.text' })}
      </Typography>
    </>
  )
}
