import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { ThemeButton, SelectInput } from '../../../../ui'
import { useCombos } from '../../../combos'
import { MUST_REQUEST_CANCEL } from '../../constants'
import { feedback } from '../../../../core/feedback'

export const CancelAppDialog = ({
  close,
  estadoSolicitud,
  id,
  cancelApplication,
  updateApplication,
}) => {
  const { formatMessage } = useIntl()
  const combos = useCombos(['motivos_anulacion_instalador'])

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    let reasons = {}
    combos.motivos_anulacion_instalador.data.forEach(
      (reason) => (reasons[reason.key] = reason.value)
    )
    const payload = {
      motivo_anulacion_instalador: reasons[formState.respuesta],
    }

    setLoading(true)
    cancelApplication(id, payload)
      .then(() => {
        if (MUST_REQUEST_CANCEL[estadoSolicitud]) {
          feedback(
            'success',
            formatMessage({ id: 'pages.applications.cancelDialog.mustRequestCancelCall.Ok' })
          )
        }
        updateApplication()
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <SelectInput
            required
            fullWidth
            name="respuesta"
            title={`${formatMessage({
              id: 'pages.application.detail.dialogs.cancelApp.fields.1.title',
            })}:`}
            value={formState['respuesta']}
            values={combos?.motivos_anulacion_instalador?.data || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={4}>
            <ThemeButton color="default" labelM="sm" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="sm"
            disabled={loading}
            loading={loading}
          >
            {MUST_REQUEST_CANCEL[estadoSolicitud]
              ? formatMessage({ id: 'pages.application.detail.actionButtons.cancelAppRequest' })
              : formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
