import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Box } from '@material-ui/core'

import { ADMIN_ROLES } from '../../../../core/constants'
import { ThemeButton, FilterBox } from '../../../../ui'
import { tariffsFilterStyles } from './tariffs-filter.styles'
import { useCombos } from '../../../combos/hooks/combos.hook'
import { APPS_FILTERS } from '../../constants'

export const TariffsFilter = withStyles(tariffsFilterStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const location = useLocation()
  const currentSearch = new URLSearchParams(location.search)
  const ccaa = currentSearch.get('ccaa')
  const combos = useSelector((state) => state.combos)
  const global = useSelector((state) => state.global)

  useCombos(['cod_zona_zeus', 'provincias'])

  //Other actions:
  const handleCreateTariff = () => history.push('/tariffs/new')

  const data = APPS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.comboId === 'provincias' && item.values && ccaa) {
        item.values = item.values.filter((provincia) => {
          return -1 < ccaa.split(',').indexOf(provincia.ca)
        })
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.tariffs.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox filters={data}>
      {ADMIN_ROLES[global.role] ? (
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <Box ml={2}>
            <ThemeButton color="primary" labelM="sm" onClick={handleCreateTariff}>
              {formatMessage({ id: 'pages.tariffs.addButton.label' })}
            </ThemeButton>
          </Box>
        </Box>
      ) : null}
    </FilterBox>
  )
})
