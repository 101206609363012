import React, {useState} from 'react'
import { withStyles, Tabs, Tab, Typography, Box } from '@material-ui/core/'

import { useCombos } from '../../../combos'
import { CompanyForm } from '../form/company-form.component'
import { eiTabsStyles } from './ei-tabs.styles'

const TabTitle = ({ company }) => {
  const combos = useCombos([])
  const ccaaCombo = combos.cod_zona_zeus?.data
  const ccaaName =
    company.comunidad_autonoma &&
    ccaaCombo?.find(({ key }) => key === company.comunidad_autonoma + '')

  return (
    <>
      <Typography variant="subtitle1" color="primary">
        {company.nombre}
      </Typography>
      {company.comunidad_autonoma && (
        <Typography variant="caption"> {(ccaaName && ccaaName.value) || '--'} </Typography>
      )}
    </>
  )
}

export const EITabs = withStyles(eiTabsStyles)(
  ({ classes, primaryCompany, guildCompany, getPrimaryCompany }) => {
   
    const [selectedTabIndex, setSelectedTabIndex] = useState(1)

    const handleChange = (_, newValue) => {
        setSelectedTabIndex(newValue)
   
  }
    return (
      <>
        <Tabs value={selectedTabIndex} onChange={handleChange} variant="scrollable">
          <Tab
            key={0}
            label={<TabTitle company={primaryCompany} />}
            classes={{ selected: classes.tabSelected }}
          />
          <Tab
            key={1}
            label={<TabTitle company={guildCompany} />}
            classes={{ selected: classes.tabSelected }}
          />
        </Tabs>
        <Box p={3} bgcolor="grey.100">
          {selectedTabIndex === 0 ? (
            <CompanyForm
              {...{ model: primaryCompany, isPrimaryCompany: true, getPrimaryCompany }}
            />
          ) : (
            <CompanyForm
              {...{
                model: {
                  ...guildCompany,
                  publica: primaryCompany.publica,
                },
                isPrimaryCompany: false,
              }}
            />
          )}
        </Box>
      </>
    )
  }
)
