import React from 'react'
import { useSelector } from 'react-redux'

import { InstallerCompany } from './installer-company'
import { MarketerCompany } from './marketer-company'
import { GuildCompany } from './guild-company'

export const CompanyProfile = () => {
  const userRole = useSelector((state) => state.global.role)

  return userRole === 'gremio' ? (
    <GuildCompany />
  ) : userRole === 'comercializadora' ? (
    <MarketerCompany />
  ) : (
    <InstallerCompany />
  )
}
