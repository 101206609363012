import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'qs'

import { useModalContext, ConfirmationDialog } from '../../../ui'
import { feedback } from '../../../core/feedback'
import { ACTION_TYPES, INSPECTIONS_FIELDS, A_P_FUGA_GAS_CODE } from '../constants'
import { COMBUSTION_ANOM_CODES } from '../constants'
import {useAddressCalls, useInspectionsCalls} from '../calls'
import { ValidateInspDialog, AbortIPDialog, RejectInspDialog } from '../components/dialogs'
import { AbortJCADialog, CreateAddressDialog } from '../components/dialogs'
import { useCombos, setCombo } from '../../combos'
import { ipPostPayload, ipPutPayload, jcaPostPayload, jcaValidatePayload } from '../utils'
import { jcaNonConformityPayload } from '../utils'

export const useInspectionsActions = (state, dispatch) => {
  const reduxDispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const global = useSelector((state) => state.global)
  const { formatMessage, formatNumber } = intl
  const { formState, type, inspection, currentStep, addresses, canContinue, company } = state
  const { ipPost, ipPut, homeInfoGetCall, ipNonConformityCall, jcaPost } = useInspectionsCalls()
  const { fetchInspectors, jcaValidationCall, jcaNonConformityCall } = useInspectionsCalls()
  const { associatedCompaniesCall } = useInspectionsCalls()
  const { validateCupsWithDireccion } = useAddressCalls()
  const combos = useCombos([])
  const {
    actions: { open },
  } = useModalContext()
  const { inspectionType } = useParams()
  const urlSearchParams = parse(history.location.search.split('?')[1])

  const setType = (type) => {
    // dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true })
    dispatch({ type: ACTION_TYPES.SET_TYPE, payload: inspectionType })
  }

  const setCompany = () => {
    if (!!global.gremio) {
      if (urlSearchParams.companyId) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: true })
        associatedCompaniesCall(global.gremio.id).then((response) => {
          const selectedCompany = response.data?.find(
            (record) =>
              record.aceptacion_gremio && record.id.toString() === urlSearchParams.companyId
          )
          if (selectedCompany) {
            dispatch({ type: ACTION_TYPES.SET_COMPANY, payload: selectedCompany })
          } else {
            history.push(`/${inspectionType}`)
          }
        })
      } else {
        history.push(`/${inspectionType}`)
      }
    } else {
      dispatch({ type: ACTION_TYPES.SET_COMPANY, payload: global.empresa })
    }
  }

  const redirectToTable = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push(`/${type}` + prevSearch)
  }

  const validateInstallationFields = () => {
    let isValid = true
    if(!formState.tipo_instalacion) {
      isValid = false
    }
    if(!formState.une) {
      isValid = false
    }
    if (!formState.nombre_titular) {
      isValid = false
    }
    if (!formState.apellido_1_titular && formState.tipo_instalacion === 'IRI') {
      isValid = false
    }
    if (!formState.comercializadora && ((formState.tipo_instalacion === 'IRI' || formState.tipo_instalacion === 'IND') && formState.tipo_de_gas !=='PROPANO')) {
      isValid = false
    }
    if (!formState.sociedad) {
      isValid = false
    }
    if (formState.tipo_instalacion === 'IRI' && !formState.potencia) {
      isValid = false
    }
    if (!formState.tipo_de_gas) {
      isValid = false
    }
    if (!formState.cups && formState.tipo_instalacion !== 'IRC') {
      isValid = false
    }
    if (!formState.address || !formState.address.cp) {
      isValid = false
    }
    if (isValid) {
      dispatch({ type: ACTION_TYPES.SET_CAN_VALIDATE_FORM, payload: true })
    }
    else {
      dispatch({ type: ACTION_TYPES.SET_CAN_VALIDATE_FORM, payload: false })
    }
    return isValid
  }

  const changeForm = (e) => {
    const { name, value } = e.target
    const newFormState = { ...formState, [name]: value }
    dispatch({ type: ACTION_TYPES.CHANGE_FORM, payload: newFormState })
    validateInstallationFields()
    clearErrorMssg()
  }

  const changeFormAutocomplete = ({name, value}) => {
    const newFormState = { ...formState, [name]: value }
    dispatch({ type: ACTION_TYPES.CHANGE_FORM, payload: newFormState })
    clearErrorMssg()
  }

  const clearErrorMssg = () => {
    dispatch({ type: ACTION_TYPES.SET_ERROR, payload: {} })
  }

  Math.fmod = function (a,b) { return Number((a - (Math.floor(a / b) * b)).toPrecision(8)); };

  const validateCUPS = (CUPS) => {
    let status = false;
    let RegExPattern =/^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;
    if ((CUPS.match(RegExPattern)) && (CUPS!=='')) {
      let CUPS_16 = CUPS.substr(2,16);
      let control = CUPS.substr(18,2);
      let letters = Array('T','R','W','A','G','M','Y','F','P','D','X','B','N','J','Z','S','Q','V','H','L','C','K','E');

      let fmodv = Math.fmod(CUPS_16,529);
      let imod = parseInt(fmodv);
      let quotient = Math.floor(imod / 23);
      let remainder = imod % 23;

      let dc1 = letters[quotient];
      let dc2 = letters[remainder];
      status = (control === dc1+dc2);
    } else {
      status=false;
    }
    return status;
  }

  const getAddressByCUPS = (cups) => {
    const NOT_FOUND_HOME_CODE = 4230

    dispatch({ type: ACTION_TYPES.FETCHING_ADDRESS_BY_CUPS })
    if(!validateCUPS(cups)) {
      let errorMssg = formatMessage({ id: 'pages.inspections.feedback.3' })
      dispatch({ type: ACTION_TYPES.SET_ERROR, payload: { message: errorMssg } })
      return
    }
    homeInfoGetCall(cups)
      .then(({ data }) => {
        let anomalias = []
        if (type === 'jca') {
          anomalias = data.anomalias
        }
        dispatch({ type: ACTION_TYPES.SET_ADDRESS_BY_CUPS, payload: { ...data, anomalias } })
      })
      .catch((response) => {
        if (formState.tipo_instalacion === 'IND') {
          dispatch({ type: ACTION_TYPES.CHANGE_FORM, payload: { ...formState, cups: '' } })
          dispatch({ type: ACTION_TYPES.SET_LOADING_HOME_INFO, payload: false })
          // const initialFormState = { ...formState, nueva_direccion: true }
          open({
            Component: CreateAddressDialog,
            data: { initialFormState: formState, createAddress },
            type: 'fullScreen',
          })
        } else {
          let errorMssg = formatMessage({ id: 'pages.inspections.feedback.1' })
          if (response?.response?.data?.code === NOT_FOUND_HOME_CODE) {
            errorMssg = formatMessage({ id: 'pages.inspections.feedback.2' })
          }
          dispatch({ type: ACTION_TYPES.SET_ERROR, payload: { message: errorMssg } })
        }
      })
  }

  const checkValidCUPS = (cups) => {
    const NOT_FOUND_HOME_CODE = 4230
    dispatch({ type: ACTION_TYPES.FETCHING_ADDRESS_BY_CUPS })
    if(!validateCUPS(cups)) {
      let errorMssg = formatMessage({ id: 'pages.inspections.feedback.3' })
      dispatch({ type: ACTION_TYPES.SET_ERROR, payload: { message: errorMssg } })
      return
    }
    homeInfoGetCall(cups)
      .then(({ data }) => {
        dispatch({ type: ACTION_TYPES.SET_VALID_CUPS, payload: { ...data } })
      })
      .catch((response) => {
        if (formState.tipo_instalacion === 'IND') {
          dispatch({ type: ACTION_TYPES.CHANGE_FORM, payload: { ...formState, cups: '' } })
          dispatch({ type: ACTION_TYPES.SET_LOADING_HOME_INFO, payload: false })
          // const initialFormState = { ...formState, nueva_direccion: true }
          open({
            Component: CreateAddressDialog,
            data: { initialFormState: formState, createAddress },
            type: 'fullScreen',
          })
        } else {
          let errorMssg = formatMessage({ id: 'pages.inspections.feedback.1' })
          if (response?.response?.data?.code === NOT_FOUND_HOME_CODE) {
            errorMssg = formatMessage({ id: 'pages.inspections.feedback.2' })
          }
          dispatch({ type: ACTION_TYPES.SET_ERROR, payload: { message: errorMssg } })
        }
      })
  }

  const createAddress = (address) => {
    return new Promise((resolve, reject) => {
      if (type === 'ip') {
        const payload = ipPostPayload(
          { ...formState, nueva_direccion: true },
          [address],
          company.id
        )
        ipPost(payload)
          .then(({ data }) => {
            dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: [address] })
            dispatch({ type: ACTION_TYPES.VALIDATE_INSTALLATION_STEP, payload: data })
            resolve()
          })
          .catch((response) => {
            if (response?.response?.data) {
              dispatch({ type: ACTION_TYPES.SET_ERROR, payload: response.response.data })
            }
            feedback('error', response.response.data.message)
            reject()
          })
      } else if (type === 'jca') {
        dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: [address] })
        dispatch({
          type: ACTION_TYPES.CHANGE_FORM,
          payload: { ...formState, nueva_direccion: true },
        })
        dispatch({ type: ACTION_TYPES.VALIDATE_INSTALLATION_STEP, payload: {} })
        resolve()
      }
    })
  }

  const resetAddresses = () => dispatch({ type: ACTION_TYPES.RESET_ADDRESSES })

  const changeAddress = (e, addressIndex) => {
    const { name, value } = e.target
    const newAddresses = addresses.map((address, i) => {
      if (i === addressIndex) {
        return {
          ...address,
          [name]: value,
        }
      } else {
        return address
      }
    })
    dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: newAddresses })
    clearErrorMssg()
  }

  const deleteAddress = (addressToDelete) => {
    const newAddresses = addresses.filter(
      (address) => address.cod_derivacion !== addressToDelete.cod_derivacion
    )
    dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: newAddresses })
    dispatch({ type: ACTION_TYPES.SET_ERROR, payload: {} })
  }

  const addAddress = (addressesToAdd) => {
    if (type === 'ip') {
      addressesToAdd.map((address) => {
        delete address.anomalias
        return address
      })
    }
    let newAddresses = addresses || []
    if (addresses.length) {
      addressesToAdd.forEach((newAddress) => {
        if (
          !addresses.some(
            (currentAddress) => newAddress.cod_derivacion === currentAddress.cod_derivacion
          )
        ) {
          newAddresses.push(newAddress)
        }
      })
    } else {
      newAddresses = addressesToAdd
    }
    dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: newAddresses })
  }

  const validateInstallationStep = () => {
    dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: true })
    const openDialog = () =>
      open({
        Component: ValidateInspDialog,
        data: {
          submitInspection: submitInstallationStep,
          abortInspection,
          openNonconformity,
          addresses,
          formState,
          currentStep,
          type,
          onClose: () => dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false }),
        },
        type: 'center',
        maxWidth: 'sm',
      })
    const dispatchError = (messageId) =>
      dispatch({
        type: ACTION_TYPES.SET_ERROR,
        payload: { message: formatMessage({ id: `pages.inspections.${messageId}` }) },
      })
    let isValid = true

    if (formState.tipo_instalacion === 'IRI' || formState.tipo_instalacion === 'IND') {
      if (!formState.cups || formState.cups.length !== 20 || !validateCUPS(formState.cups)) {
        isValid = false
        dispatchError('feedback.3')
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })


      }
      else if (!formState.address || !formState.address.cp?.length > 0) {
        isValid = false
        dispatchError('feedback.7')
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

      }
      else if (!validateInstallationFields()) {
        isValid = false
        dispatchError('feedback.41')
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

      }
      if (isValid) {
        homeInfoGetCall(formState.cups).then(({data}) => {
          formState.address.anyo_fin_vigencia_ip3 = data.anyo_fin_vigencia_ip3
          formState.address.criterio_aceptacion_ip3 = data.criterio_aceptacion_ip3
          formState.address.motivo_rechazo_ip3 = data.motivo_rechazo_ip3
          formState.tipo_gas = data.tipo_de_gas
          formState.anomalias = data.anomalias
          if (type === 'jca' && !formState.anomalias.length) {
            dispatchError('feedback.6')
            isValid = false
            dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })
          } else {
            validateCupsWithDireccion(formState.address).then((data) => {
              if(data == true) {
                formState.address = {
                  ...formState.address,
                  nombre: formState.nombre_titular,
                  apellido_1: formState.apellido_1_titular,
                  apellido_2: formState.apellido_2_titular,
                  comercializadora: formState.comercializadora?.key,
                  sociedad: formState.sociedad,
                  tipo_instalacion: formState.tipo_instalacion + (formState.tipo_instalacion === 'IRI' ? ` (DOMESTICO ${formState.potencia}KW)` : ''),
                  anomalias: formState.anomalias
                }

                addresses[0] = formState.address
                openDialog()
              }
              else {
                isValid = false
                dispatchError('createAddressForm.addressNotMatchCups.feedback')
                dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

              }

            })
              .catch((response) => {
                isValid = false
                dispatchError('createAddressForm.addressNotMatchCups.feedback')
                dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

              })
          }
        })
          .catch((response) => {
            isValid = false
            dispatchError('feedback.2')
            dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

          })
      }
    } else if (formState.tipo_instalacion === 'IRC') {
      if (!formState.address || !formState.address.cp?.length > 0) {
        isValid = false
        dispatchError('feedback.7')
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

      }
      else if (!validateInstallationFields()) {
        isValid = false
        dispatchError('feedback.41')
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

      }
      else if (type === 'jca' && formState.derivation.some((address) => !address.anomalias.length)) {
        dispatchError('feedback.6')
        isValid = false
        dispatch({ type: ACTION_TYPES.SET_LOADING_VALIDAR_CONTINUAR, payload: false })

      }
      else {
        addresses.length = 0;

        formState.derivation.forEach((address) => {
          addresses.push({
            ...address,
            nombre: formState.nombre_titular,
            apellido_1: formState.apellido_1_titular,
            apellido_2: formState.apellido_2_titular,
            comercializadora: formState.comercializadora?.key,
            sociedad: formState.sociedad,
            tipo_instalacion: formState.tipo_instalacion,
            anomalias: address.anomalias
          })
        })
        dispatch({ type: ACTION_TYPES.CHANGE_ADDRESSES, payload: addresses })

        openDialog()
      }

    }
    /*if (formState.tipo_instalacion === 'IRI' || formState.tipo_instalacion === 'IND') {
      if (!addresses.length || !addresses[0].cups) {
        dispatchError('3')
      } else if (!addresses[0].tipo_instalacion) {
        dispatchError('10')
      } else if (
        formState.tipo_instalacion === 'IRI' &&
        !addresses[0].tipo_instalacion.includes('IRI')
      ) {
        dispatchError('4')
      } else if (
        formState.tipo_instalacion === 'IND' &&
        !addresses[0].tipo_instalacion.includes('IND')
      ) {
        dispatchError('5')
      } else if (type === 'jca' && !addresses[0].anomalias.length) {
        dispatchError('6')
      } else {
        openDialog()
      }
    } else if (formState.tipo_instalacion === 'IRC') {
      if (addresses.length === 0) {
        dispatchError('7')
      } else if (type === 'jca' && addresses.some((address) => !address.anomalias.length)) {
        if (formState.tipo_instalacion === 'IRC') {
          dispatchError('8')
        } else {
          dispatchError('9')
        }
      } else {
        openDialog()
      }
    } */
  }

  const submitInstallationStep = ({ closeModal }) => {
    return new Promise((resolve, reject) => {
      if (type === 'ip') {
        const payload = ipPostPayload(formState, addresses, company.id)
        ipPost(payload)
          .then(({ data }) => {
            dispatch({ type: ACTION_TYPES.VALIDATE_INSTALLATION_STEP, payload: data })
            resolve()
          })
          .catch((response) => {
            if (response?.response?.data) {
              dispatch({ type: ACTION_TYPES.SET_ERROR, payload: response.response.data })
            }
            closeModal()
            reject()
          })
      } else {
        const payload = jcaValidatePayload(formState, addresses, company.id)
        jcaValidationCall(payload)
          .then(({ data }) => {
            dispatch({ type: ACTION_TYPES.VALIDATE_INSTALLATION_STEP, payload: {} })
            resolve()
          })
          .catch((response) => {
            if (response?.response?.data) {
              dispatch({ type: ACTION_TYPES.SET_ERROR, payload: response.response.data })
            }
            closeModal()
            reject()
          })
      }
    })
  }

  const validateInspectionStep = () => {
    const openModal = () =>
      open({
        Component: ValidateInspDialog,
        data: {
          submitInspection: submitInspectionStep,
          abortInspection,
          openNonconformity,
          addresses,
          formState,
          currentStep,
          type,
        },
        type: 'center',
        maxWidth: 'sm',
      })
    const dispatchError = (messageId) =>
      dispatch({
        type: ACTION_TYPES.SET_ERROR,
        payload: { message: formatMessage({ id: `pages.inspections.feedback.${messageId}` }) },
      })
    if (type === 'ip') {
      if (
        addresses.some(
          (address) => address.resultado_inspeccion !== 'Correcto' && !address.anomalias?.length
        )
      ) {
        dispatchError('12')
      } else if (
        addresses.some(
          (address) =>
            address.resultado_inspeccion === 'Con anomalías secundarias y principales' &&
            !address.anomalias.some((a) => a.isSecondary)
        )
      ) {
        dispatchError('28')
      } else if (
        addresses.some(
          (address) =>
            address.resultado_inspeccion === 'Con anomalías secundarias y principales' &&
            !address.anomalias.some((a) => a.isPrimary)
        )
      ) {
        dispatchError('29')
      } else if (
        addresses.some(
          (address) =>
            address.situacion_servicio === 'En servicio' &&
            address.anomalias.some((a) => a.isPrimary)
        )
      ) {
        dispatchError('30')
      } else if (
        addresses.some(
          (address) =>
            address.situacion_servicio === 'Sin servicio Parcial' &&
            address.anomalias.some((a) => a.corte_total && a.cod_anomalia !== A_P_FUGA_GAS_CODE)
        )
      ) {
        dispatchError('27')
      } else if (
        addresses.some(
          (address) =>
            address.resultado_inspeccion !== 'Con anomalías secundarias' &&
            address.situacion_servicio === 'Sin servicio Total' &&
            !address.anomalias.some((a) => a.corte_total)
        )
      ) {
        dispatchError('31')
      } else if (!formState.fecha_inspeccion) {
        dispatchError('33')
      } else if (!formState.certificado) {
        dispatchError('11')
      } else if (!formState.fecha_factura) {
        dispatchError('34')
      } else if (new Date(formState.fecha_factura) < new Date(formState.fecha_inspeccion)) {
        dispatchError('35')
      } else if (formState.importe && Number(formState.importe) !== 0 && !formState.factura) {
        dispatchError('13')
      } else {
        openModal()
      }
    } else if (type === 'jca') {
      if (formState.tipo_instalacion === 'IND' && formState.nueva_direccion) {
        if (!formState.fecha_jca) {
          dispatchError('39')
        } else if (!formState.justificante) {
          dispatchError('14')
        } else {
          openModal()
        }
      } else {
        const newAddresses = addresses.map((address) => ({
          ...address,
          anomDiff: address.anomalias.filter(
            (anom) => !address.solvedAnomalies.some((solvedAnom) => solvedAnom.key === anom.key)
          ),
        }))
        if (newAddresses.some((address) => !address.solvedAnomalies?.length)) {
          dispatchError('12')
        } else if (
          newAddresses.some(
            (address) =>
              address.situacion_suministro === 'En servicio' &&
              address.anomDiff.length &&
              address.anomDiff.some((a) => a.tipo === 'PRINCIPAL')
          )
        ) {
          dispatchError('18')
        } else if (
          newAddresses.some(
            (address) =>
              address.situacion_suministro === 'Corte parcial' &&
              (address.anomDiff.some((a) => a.corte_total) ||
                address.anomDiff.every((a) => a.tipo === 'SECUNDARIA'))
          )
        ) {
          dispatchError('19')
        } else if (
          newAddresses.some(
            (address) =>
              address.situacion_suministro === 'Corte total' &&
              !address.anomDiff.some((a) => a.corte_total)
          )
        ) {
          dispatchError('20')
        } else if (
          newAddresses.some((address) =>
            address.solvedAnomalies?.some(
              (a) => COMBUSTION_ANOM_CODES[a.cod_anomalia] && !address.ticket_combustion
            )
          )
        ) {
          dispatchError('36')
        } else if (
          newAddresses.some((address) =>
            address.solvedAnomalies?.some(
              (a) =>
                COMBUSTION_ANOM_CODES[a.cod_anomalia] &&
                (!address.co_ambiente ||
                  parseInt(address.co_ambiente) > 14 ||
                  !address.co_corregido ||
                  parseInt(address.co_corregido) > 499)
            )
          )
        ) {
          dispatchError('37')
        } else if (!formState.fecha_jca) {
          dispatchError('39')
        } else if (!formState.justificante) {
          dispatchError('14')
        } else {
          openModal()
        }
      }
    }
  }

  const submitInspectionStep = ({ closeModal }) => {
    return new Promise((resolve, reject) => {
      let payload = {}
      if (type === 'ip') {
        payload = ipPutPayload(inspection, formState, addresses, company.id)
        ipPut(inspection.id, payload)
          .then(({ data }) => {
            dispatch({ type: ACTION_TYPES.VALIDATE_INSPECTION_STEP, payload: data })
            resolve()
          })
          .catch((response) => {
            let error = { message: formatMessage({ id: 'pages.inspections.feedback.15' }) }
            if (response?.response?.data) {
              error = response.response.data
            }
            dispatch({ type: ACTION_TYPES.SET_API_ERROR, payload: error })
            if (response?.response?.data?.code) {
              switch (response.response.data.code) {
                // case 8501:
                case 8508:
                case 8509:
                  dispatch({ type: ACTION_TYPES.SET_ERROR, payload: response.response.data })
                  closeModal()
                  break
                default:
                  dispatch({ type: ACTION_TYPES.SET_CAN_CONTINUE, payload: false })
              }
            }
            reject()
          })
      } else if (type === 'jca' && !formState.nueva_direccion) {
        const payload = jcaPostPayload(formState, addresses, company.id)
        jcaValidationCall(payload)
          .then(({ data }) => {
            dispatch({ type: ACTION_TYPES.VALIDATE_INSPECTION_STEP, payload: {} })
            resolve()
          })
          .catch((response) => {
            if (response?.response?.data) {
              dispatch({ type: ACTION_TYPES.SET_ERROR, payload: response.response.data })
            }
            closeModal()
            reject()
          })
      } else {
        dispatch({ type: ACTION_TYPES.VALIDATE_INSPECTION_STEP, payload: {} })
        closeModal()
      }
    })
  }

  const abortInspection = () => {
    const abortAndReturnToTable = () => {
      feedback('success', formatMessage({ id: 'pages.inspections.feedback.25' }))
      redirectToTable()
    }
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.inspections.abortIPDialog.title' }),
        text: formatMessage({ id: 'pages.inspections.abortIPDialog.description.1' }),
        yesText: formatMessage({ id: 'global.confirm' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: abortAndReturnToTable,
      },
      type: 'center',
      maxWidth: 'sm',
    })
  }

  const openNonconformity = () => {
    let NonconformityComponent = AbortJCADialog
    if (type === 'ip' && formState.tipo_instalacion === 'IRC') {
      NonconformityComponent = AbortIPDialog
    } else if (type === 'jca') {
      NonconformityComponent = AbortJCADialog
    }
    open({
      Component: NonconformityComponent,
      data: { submitNonconformity, instType: formState.tipo_instalacion },
      type: 'center',
      maxWidth: 'sm',
    })
  }

  const submitNonconformity = (nonConfFormState) => {
    return new Promise((resolve, reject) => {
      if (type === 'ip') {
        const ipPayload = ipPostPayload(formState, addresses, company.id)
        const ipNonconformityPayload = { ...ipPayload, ...nonConfFormState }
        ipNonConformityCall(ipNonconformityPayload)
          .then(() => {
            feedback('success', formatMessage({ id: 'pages.inspections.feedback.24' }))
            resolve({ redirectToTable })
          })
          .catch((response) => {
            let error = { message: formatMessage({ id: 'pages.inspections.feedback.38' }) }
            if (response?.response?.data?.message) error = response.response.data
            feedback('error', error.message)
            reject()
          })
      } else if (type === 'jca') {
        addresses.push(formState.address)
        const payload = jcaNonConformityPayload(
          { ...formState, ...nonConfFormState },
          addresses,
          company.id
        )
        jcaNonConformityCall(payload)
          .then(() => {
            feedback('success', formatMessage({ id: 'pages.inspections.feedback.24' }))
            resolve({ redirectToTable })
          })
          .catch((response) => {
            let error = { message: formatMessage({ id: 'pages.inspections.feedback.38' }) }
            if (response?.response?.data?.message) error = response.response.data
            feedback('error', error.message)
            reject()
          })
      }
    })
  }

  const finishInspection = () => {
    let textMssg = formatMessage({ id: 'pages.inspections.finishBtn.description' })
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.inspections.finishBtn.title' }),
        text: textMssg,
        yesText: formatMessage({ id: 'pages.inspections.finishBtn.confirm' }),
        noText: formatMessage({ id: 'pages.inspections.finishBtn.cancel' }),
        yesAction: submitFinishInspection,
      },
      type: 'center',
      maxWidth: 'sm',
    })
  }

  const submitFinishInspection = () => {
    return new Promise((resolve, reject) => {
      if (type === 'ip') {
        const { certificado_ip, factura_ip, ...restInspection } = inspection
        const payload = { id_empresa_ip: company.id, ...restInspection }
        ipPut(inspection.id, payload)
          .then(() => {
            redirectToTable()
            resolve()
          })
          .catch((response) => {
            let error = { message: formatMessage({ id: 'pages.inspections.feedback.15' }) }
            if (response?.response?.data?.message) error = response.response.data
            feedback('error', error.message)
            reject()
          })
      } else {
        const payload = jcaPostPayload(formState, addresses, company.id)
        jcaPost(payload)
          .then(() => {
            redirectToTable()
            feedback('success', formatMessage({ id: 'pages.inspections.feedback.26' }))
            resolve()
          })
          .catch((response) => {
            let error = { message: formatMessage({ id: 'pages.inspections.feedback.15' }) }
            if (response?.response?.data?.message) error = response.response.data
            feedback('error', error.message)
            reject()
          })
      }
    })
  }

  const confirmBillAmount = () => {
    if (formState.importe || parseInt(formState.importe) === 0) {
      open({
        Component: ConfirmationDialog,
        data: {
          title: formatMessage({ id: 'pages.inspections.amountDialog.title' }),
          text: formatMessage(
            { id: 'pages.inspections.amountDialog.description' },
            {
              amount: formatNumber(formState.importe, {
                style: 'currency',
                currency: 'EUR',
              }),
            }
          ),
          yesText: formatMessage({ id: 'pages.inspections.amountDialog.confirm' }),
          noText: formatMessage({ id: 'pages.inspections.amountDialog.cancel' }),
          noAction: () => changeForm({ target: { name: 'importe', value: '' } }),
        },
        type: 'center',
        maxWidth: 'sm',
      })
    }
  }

  const inputProps = { formState, onChange: changeForm, combos, intl, onChangeAutocomplete: changeFormAutocomplete }
  const formFields = INSPECTIONS_FIELDS(inputProps)

  useEffect(() => {
    fetchInspectors(urlSearchParams.companyId).then(({ data }) => {
      const comboData = data.map((r) => ({
        key: r.id,
        value: `${r.nombre} - ${r.codigo} - ${r.categoria}`,
      }))
      reduxDispatch(setCombo({ comboId: 'inspectores', data: comboData }))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!canContinue) {
      const initCanContinue = () => dispatch({ type: ACTION_TYPES.SET_CAN_CONTINUE, payload: true })
      open({
        Component: RejectInspDialog,
        data: {
          error: state.apiError,
          confirmAction: redirectToTable,
          cancelAction: initCanContinue,
        },
        type: 'center',
        maxWidth: 'sm',
      })
    }
  }, [canContinue]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    resetAddresses,
    setType,
    setCompany,
    formFields,
    changeForm,
    clearErrorMssg,
    validateInstallationStep,
    getAddressByCUPS,
    addAddress,
    deleteAddress,
    validateInspectionStep,
    abortInspection,
    finishInspection,
    confirmBillAmount,
    redirectToTable,
    changeAddress,
    openNonconformity,
    checkValidCUPS,
  }
}
