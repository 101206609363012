import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, DialogContent, DialogActions } from '@material-ui/core'

import { Communications } from './communications.component'
import { COMMS_CHANNELS } from './communications.constants'
import { ThemeButton } from '../../../../ui'

export const CommunicationsDialog = ({ close, application, checkUnreadComms }) => {
  const { formatMessage } = useIntl()
  const role = useSelector((state) => state.global.role)

  const handleClose = () => {
    checkUnreadComms()
    close()
  }

  const channels = COMMS_CHANNELS(application)

  return (
    <>
      <DialogContent>
        <Communications {...{ application, profile: role, channels: channels }} />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <ThemeButton color="primary" labelM="lg" onClick={handleClose}>
            {formatMessage({ id: 'global.close' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </>
  )
}
