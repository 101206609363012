import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useHistory, Link } from 'react-router-dom'
import { withStyles, Container, Typography, Box, Grid } from '@material-ui/core'

import { feedback } from '../../../core/feedback'
import { useAjaxContext } from '../../../core/ajax'
import { verifyPwd } from '../../../core/utils'
import { PasswordInput, ThemeButton } from '../../../ui'
import { pwdRecoveryStyles } from './password-recovery.styles'

export const PasswordRecoveryPage = withStyles(pwdRecoveryStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const { putById, getById } = useAjaxContext()
  const history = useHistory()

  const [deprecatedRequest, setDeprecatedRequest] = useState(false)
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  useEffect(() => {
    getById('/usuario/get_one_use', id)
      .then(() => {
        setDeprecatedRequest(false)
      })
      .catch(() => {
        setDeprecatedRequest(true)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    verifyPwd(
      formState['password'],
      formState['repitPassword'],
      isValidPassword,
      setIsValidPassword,
      setErrorMssg
    )
  }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    if (isValidPassword) {
      const payload = { new_password: formState['password'] }
      putById('/usuario/new_password', id, payload)
        .then(() => {
          feedback('success', formatMessage({ id: 'pages.pwdRecovery.feedback.success' }))
          history.push('/login')
        })
        .catch(() => {
          console.debug('Error requesting change password')
          setErrorMssg(formatMessage({ id: 'pages.pwdRecovery.feedback.error' }))
        })
    }
    setLoading(false)
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <div className={classes.panel}>
          {deprecatedRequest ? (
            <Box textAlign="center" pt={4} pb={4}>
              <Typography variant="h5" paragraph>
                {formatMessage({ id: 'pages.pwdRecovery.notFound' })}
              </Typography>
              <Link to="/login" replace>
                <ThemeButton type="submit" color="secondary" labelM="lg">
                  {formatMessage({ id: 'global.return' })}
                </ThemeButton>
              </Link>
            </Box>
          ) : (
            <form onSubmit={handleSubmitForm}>
              <Box mb={3}>
                <Typography variant="h6" color="primary" paragraph>
                  {formatMessage({ id: 'pages.pwdRecovery.title' })}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {formatMessage({ id: 'pages.pwdRecovery.extra' })}
                </Typography>
              </Box>
              <Box mb={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PasswordInput
                      value={formState['password']}
                      name="password"
                      title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
                      placeholder={formatMessage({
                        id: 'pages.pwdRecovery.form.password.placeholder',
                      })}
                      onChange={handleChangeForm}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordInput
                      value={formState['repitPassword']}
                      name="repitPassword"
                      title={formatMessage({ id: 'pages.pwdRecovery.form.repitPassword.label' })}
                      placeholder={formatMessage({
                        id: 'pages.pwdRecovery.form.repitPassword.placeholder',
                      })}
                      onChange={handleChangeForm}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
              {errorMssg && (
                <Box mb={1} bgcolor="grey">
                  <Typography variant="body2" color="error">
                    {errorMssg}
                  </Typography>
                </Box>
              )}
              <ThemeButton type="submit" fullWidth loading={loading}>
                {formatMessage({ id: 'pages.pwdRecovery.form.button.label' })}
              </ThemeButton>
            </form>
          )}
        </div>
      </Box>
    </Container>
  )
})
