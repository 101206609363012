import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid } from '@material-ui/core'

import { RatingInput, ThemeButton, useModalContext } from '../../../../ui'
import { ReplayRatingDialog } from '../rating/reply.rating-dialog.component'
import { useAppsDetail } from '../../hooks'
import { COMBOS_MOCK } from '../../../../mocks/combos.mock'

export const RatingApp = ({ application, combos }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { getAppById } = useAppsDetail()

  const [respuesta, setRespuesta] = useState(
    application.valoracion?.respuesta ? application.valoracion?.respuesta : ''
  )

  const handleOpenModal = () => {
    open({
      Component: ReplayRatingDialog,
      data: {
        respuesta: application.valoracion.respuesta,
        valoracionId: application.valoracion_id,
      },
      type: 'responsive',
      maxWidth: 'sm',
    })
  }
  /*useEffect(() => {
    getAppById(application.id).then(({ data }) => {
      if (data.valoracion) {
        setRespuesta(data.valoracion.respuesta)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
*/
  return (
    <>
      {application.valoracion ? (
        <>
          <Box bgcolor="grey.100" borderRadius={4} p={2}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                {formatMessage({ id: 'pages.application.detail.information.review.label' })}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item sm={12} md={6}>
                <Box mb={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    {formatMessage({
                      id: 'pages.application.detail.information.tiempo_contacto_instalador.label',
                    })}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {
                      COMBOS_MOCK.tipo_dias.data.find(
                        (option) => option.key === application.valoracion.tiempo_contacto_instalador
                      )?.value
                    }
                  </Typography>
                </Box>
                <Box mb={2}>
                  <RatingInput
                    {...{
                      name: 'valoracion_tiempo_instalacion',
                      value: application.valoracion.valoracion_tiempo_instalacion,
                      readOnly: true,
                      title: formatMessage({
                        id: 'pages.application.detail.information.valoracion_tiempo_instalacion.label',
                      }),
                      size: 'small',
                      fontSize: 'small',
                    }}
                  />
                </Box>
                <RatingInput
                  {...{
                    name: 'valoracion',
                    value: application.valoracion.valoracion,
                    readOnly: true,
                    title: formatMessage({
                      id: 'pages.application.detail.information.valoracion.label',
                    }),
                    size: 'small',
                    fontSize: 'small',
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                {application.valoracion.comentario ? (
                  <Box mb={2} display="flex" flexDirection="column">
                    <Typography variant="subtitle2" gutterBottom>
                      {formatMessage({
                        id: 'pages.application.detail.information.comentario.label',
                      })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {application.valoracion.comentario}
                    </Typography>
                  </Box>
                ) : null}
                {respuesta ? (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      {formatMessage({
                        id: 'pages.application.detail.information.respuesta.label',
                      })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {respuesta}
                    </Typography>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          <Box mt={1}>
            <ThemeButton size="small" color="secondary" onClick={handleOpenModal}>
              {formatMessage({
                id: respuesta
                  ? 'pages.application.detail.information.edit'
                  : 'pages.profile.guild.ratings.replyrating',
              })}
            </ThemeButton>
          </Box>
        </>
      ) : (
        <Box>
          <Typography variant="subtitle2">
            {formatMessage({ id: 'pages.application.detail.information.review.label' })}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {' '}
            {formatMessage({ id: 'pages.application.detail.information.withoutReview.label' })}
          </Typography>
        </Box>
      )}
    </>
  )
}
