import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { withStyles, Tabs, Tab, Typography, Box } from '@material-ui/core'
import { parse } from 'qs'

import { useCompanyProfileContext } from '../../contexts'
import { useCombos } from '../../../combos'
import { useProfileDialogs } from '../../hooks'
import { PrimaryCompany } from '../primary-company'
import { SecondaryCompany } from '../secondary-company'
import { installerCompanyTabsStyles } from './installer-company-tabs.styles'
import { GuildListProvider } from '../../../guilds/contexts'

const TabTitle = ({ company }) => {
  const combos = useCombos([])
  const ccaaCombo = combos.cod_zona_zeus?.data

  const ccaaName =
    company.comunidad_autonoma &&
    ccaaCombo?.find(({ key }) => key === company.comunidad_autonoma + '')
  return (
    <>
      <Typography variant="subtitle1" color="primary">
        {company.nombre}
      </Typography>
      {company.comunidad_autonoma && (
        <Typography variant="caption">{(ccaaName && ccaaName.value) || '--'}</Typography>
      )}
    </>
  )
}

export const InstallerCompanyTabs = withStyles(installerCompanyTabsStyles)(({ classes }) => {
  const history = useHistory()
  const primaryCompany = useSelector((state) => state.global.empresa)
  const secondaryCompanies = useSelector((state) => state.global.empresa_cc_aa)
  const { confirmNotSavedChanges } = useProfileDialogs()
  const { formStateSaved, setFormStateSaved } = useCompanyProfileContext()
  const defaultTabIndex = parse(history.location.search.substring(1)).tab

  const [selectedTabIndex, setSelectedTabIndex] = useState(Number(defaultTabIndex) || 0)

  const handleChange = (_, newValue) => {
    const changeCompanyTab = () => {
      setFormStateSaved(true)
      setSelectedTabIndex(newValue)
      history.push(`${history.location.pathname}?tab=${newValue}`)
    }
    if (!formStateSaved) {
      confirmNotSavedChanges(changeCompanyTab)
    } else {
      changeCompanyTab()
    }
  }

  useEffect(() => {
    if (!secondaryCompanies[selectedTabIndex - 1]) {
      setSelectedTabIndex(0)
    }
  }, [defaultTabIndex, secondaryCompanies]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Tabs value={selectedTabIndex} onChange={handleChange} variant="scrollable">
        <Tab
          key={0}
          label={<TabTitle company={primaryCompany} />}
          classes={{ selected: classes.tabSelected }}
        />
        {secondaryCompanies.map((company, i) => {
          return (
            <Tab
              key={i + 1}
              label={<TabTitle company={company} />}
              classes={{ selected: classes.tabSelected }}
            />
          )
        })}
      </Tabs>
      <Box p={3} bgcolor="grey.100">
        {selectedTabIndex === 0 || !secondaryCompanies[selectedTabIndex - 1] ? (
          <PrimaryCompany companyId={primaryCompany.id} />
        ) : (
          <GuildListProvider>
            <SecondaryCompany companyId={secondaryCompanies[selectedTabIndex - 1].id} />
          </GuildListProvider>
        )}
      </Box>
    </>
  )
})
