import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'

import { CorporateIdentityDownloadsComponent } from '../../../features/corporate-identity'
import { PageDisplayer } from '../../../ui'

export const CorporateIdentityPage = () => {
  const { global } = useSelector((state) => state)
  const enterprise = global.gremio || global.empresa
  return enterprise.acepta_uso_marca ? (
    <PageDisplayer>
      <Typography variant="h4" color="textSecondary">
        Identidad Corporativa
      </Typography>
      <CorporateIdentityDownloadsComponent
        corporateIdentity={enterprise.identidad_corporativa}
      ></CorporateIdentityDownloadsComponent>
    </PageDisplayer>
  ) : (
    <PageDisplayer>
      <Box>
        <Typography style={{ marginBottom: '2em' }}>
          Para acceder a estos recursos debe aceptar las condiciones de uso de la identidad
          corporativa.
        </Typography>
        <Typography>
          Pulse <Link to="/profile">AQUÍ</Link> para ir a su Perfil y aceptar la Identidad
          Corporativa en el panel de Datos de la empresa.
        </Typography>
      </Box>
    </PageDisplayer>
  )
}
