import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { requests } from '../../../../core/ajax'
import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { useCombos } from '../../../combos'
import { JCA_FILTERS, JCA_EXPORT_COLUMNS, JCA_COMBOS } from '../../constants'
import { jcaExportDataPreparation } from '../../logics'
import { feedback } from '../../../../core/feedback'
import { CreateJCABtn } from '../buttons'
import { jcaFilterStyles } from './jca-filter.styles'

export const JCAFilter = () => {
  const jcaClasses = jcaFilterStyles()
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const { get } = requests
  const combos = useCombos(JCA_COMBOS)
  const global = useSelector((state) => state.global)
  // const isGuild = !!global.gremio

  const search = useSelector((state) => state.jca.search)
  const data = JCA_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.jcaList.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data} classes={jcaClasses}>
        <ExportToXLSBtn
          exportSelectionEnabled={false}
          exportAllEnabled={true}
          columnsExport={JCA_EXPORT_COLUMNS}
          getAllData={() => {
            return new Promise((resolve, reject) => {
              get('jca/tabla_jca', { params: { ...search, _num_x_pagina: 0 } })
                .then(({ data }) => {
                  return resolve(jcaExportDataPreparation({ data: data.registros, combos, intl }))
                })
                .catch((err) => {
                  feedback('error', formatMessage({ id: 'global.errorDownload' }))
                  return reject(err)
                })
            })
          }}
          titleRoot="jca_"
          headerNamespace="jcaExport"
        />
        {global.empresa?.activo || !!global.gremio ? <CreateJCABtn /> : null}
      </FilterBox>
    </>
  )
}
