import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { getEmpresasByCA } from '../../calls'
import EmpresaInfo from './empresa-info.component'
import EmpresaSelector from './empresa-selector.component'

export const EmpresaBlock = ({ home, empresa, setEmpresa }) => {
  const user = useSelector((state) => state.global)
  const [empresas, setEmpresas] = useState([])
  const combos = useSelector((state) => state.combos)
  const cc_aa = useMemo(() => {
    return combos['provincias']?.data.find((el) => el.cp === home.cod_postal?.substring(0, 2))?.ca
  }, [home, combos])
  useEffect(() => {
    if (user.empresa_cc_aa && user.empresa_cc_aa.length) {
      const ownEmpresa = user.empresa_cc_aa.find((el) => '' + el.id_cc_aa === '' + cc_aa)
      if (ownEmpresa) {
        setEmpresa({
          ...ownEmpresa,
          nombre: ownEmpresa.nombre_empresa,
          telefono: ownEmpresa.telefono,
          empresa_ca_id: ownEmpresa.id_empresa,
        })
      }
    } else if (user.gremio_id) {
      getEmpresasByCA(user.gremio_id, cc_aa).then(({ data }) => {
        setEmpresas(
          data.empresas
            .filter((el) => el.aceptacion_gremio)
            .map((el) => ({ ...el, empresa_ca_id: el.id }))
        )
      })
    }
  }, [cc_aa, user]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Typography variant="h4" color="textSecondary" paragraph>
        Empresa
      </Typography>
      <Box bgcolor="grey.300" p={3} mt={3}>
        <Box display="flex" flexDirection="column">
          <EmpresaInfo empresa={empresa} empresas={empresas} />
          {user.empresa || !empresas.length ? null : (
            <EmpresaSelector empresa={empresa} setEmpresa={setEmpresa} empresas={empresas} />
          )}
        </Box>
      </Box>{' '}
    </>
  )
}

export default EmpresaBlock
