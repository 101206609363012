import React from 'react'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { Toolbar, Typography, IconButton, Tooltip, Icon } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}))

export const AddTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected, title, selectedMssg, onAddItem, onDeleteItem, addIcon } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {selectedMssg}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
          {props.tooltip ? (
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          ) : null}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Eliminar">
          <IconButton onClick={onDeleteItem}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Añadir">
          <IconButton onClick={onAddItem}>{addIcon || <AddIcon />}</IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}
