import { useCallback, useReducer } from 'react'

import { useAssociatesIP3Calls } from './associates-ip3-calls.hook'
import { associatesIP3Reducer } from '../reducers'
import { ASSOCIATES_IP3_INITIAL_STATE, ASSOCIATES_IP3_ACTIONS_TYPES } from '../reducers'

export const useAssociatesIP3Reducer = () => {
  const [state, dispatch] = useReducer(associatesIP3Reducer, ASSOCIATES_IP3_INITIAL_STATE)
  const { getAssociatedCompanies, acceptCompanyCall, rejectCompanyCall } = useAssociatesIP3Calls()

  const getTableData = useCallback(
    (search, gremio_id) => {
      const config = { params: search }
      dispatch({
        type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_LOADING,
      })
      getAssociatedCompanies(config, gremio_id)
        .then(({ data }) => {
          dispatch({
            type: ASSOCIATES_IP3_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['empresas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_solicitudes'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_FAILURE,
          })
        })
    },

    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_LOADING,
        })
        acceptCompanyCall(gremio_id, id_empresa_ca)
          .then(({ data }) => {
            dispatch({
              type: ASSOCIATES_IP3_ACTIONS_TYPES.UPDATE_ASSOCIATES,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_LOADING,
        })
        rejectCompanyCall(gremio_id, id_empresa_ca)
          .then(() => {
            getTableData(state.search, gremio_id)
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: ASSOCIATES_IP3_ACTIONS_TYPES.ASSOCIATES_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getTableData,
    acceptCompany,
    deleteCompany,
    ...state,
  }
}
