import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'

import { TariffForm } from '../form'
import { PageDisplayer, InfoBox } from '../../../../ui'

import { useTariffCalls } from '../../hooks'
import { tariffStyles } from './tariff-detail.style'

export const TariffDetail = withStyles(tariffStyles)(({ classes }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const { getTariff } = useTariffCalls()
  const { tariffId } = useParams()
  const acceptsApplications = useSelector((state) => state.global.empresa.publica)

  const [tariff, setTariff] = useState({})

  useEffect(() => {
    getTariff(tariffId).then(({ data }) => setTariff(data))
  }, [tariffId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    history.push(`/tariffs`)
  }

  const inputProps = { formState: tariff, intl, disabled: true }

  return (
    <PageDisplayer
      loading={!tariff || !Object.keys(tariff).length}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.edit.back' })}
    >
      <InfoBox
        severity="warning"
        message={formatMessage({ id: 'pages.tariffs.alerts.1.description' })}
        hidden={acceptsApplications}
      />
      <TariffForm {...{ inputProps, submit: false, goToZoneConfig: false }} />
    </PageDisplayer>
  )
})
