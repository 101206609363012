const BINARY_VALUES = [
  { key: '01', value: 'Sí' },
  { key: '00', value: 'No' },
]

export const COMPANY_FORM_CONFIG = ({ formState, onChange, combos, intl }) => {
  return {
    CODIGO_F: {
      required: true,
      value: formState['codigo_entidad_financiera'],
      name: 'codigo_entidad_financiera',
      title: intl.formatMessage({
        id: 'pages.signup.form.codigo_entidad_financiera.title',
      }),
      inputProps: { pattern: '[0-9]{8}' },
      tooltip: intl.formatMessage({
        id: 'pages.signup.form.codigo_entidad_financiera.tooltip',
      }),
      onChange,
    },
    ACEPTA_F: {
      required: true,
      value: formState['acepta_financiacion'],
      name: 'acepta_financiacion',
      title: intl.formatMessage({ id: 'pages.signup.form.acepta_financiacion.title' }),
      placeholder: intl.formatMessage({
        id: 'pages.signup.form.acepta_financiacion.placeholder',
      }),
      values: BINARY_VALUES,
      fullWidth: true,
      onChange,
    },
  }
}

export const TEXT_AREA_LENGTH = 250

export const FILE_PROPS = {
  maxSize: 10000000, // 10Mb
  accept: ['image/jpg', 'image/jpeg', 'image/png'],
}

export const REGEX_CODIGO_FINANCIACION = /^[0-9]{8}$/
