export const ASSOCIATES_IP3_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  ASSOCIATES_LOADING: 'ASSOCIATES_LOADING',
  ASSOCIATES_FAILURE: 'ASSOCIATES_FAILURE',
  UPDATE_ASSOCIATES: 'UPDATE_ASSOCIATES',
  DELETE_ASSOCIATED: 'DELETE_ASSOCIATED',
}

export const ASSOCIATES_IP3_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: { totalSolicitudes: 0 },
  loading: false,
  msLoaded: false,
  error: false,
}

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '5' }
