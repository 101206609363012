import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../../core/ajax'
import { getFormattedTime, dataToXLS } from '../../../../core/utils'
import { ThemeButton, useModalContext } from '../../../../ui'
import {
  MARKETER_POTENTIAL_TABLE_COLUMNS_KEY,
  EXPORT_POTENTIAL_TABLE_COLUMNS as columns,
} from '../../constants'
import { ExportNotEmptyDialog, ExportSomethingDialog } from '../dialogs'
import { marketerPotentialsDataPreparation } from '../../logics/marketer-potentials.logics'
import { feedback } from '../../../../core/feedback'
import { useExportToXLSCalls } from '../../hooks'

export const ExportPotentialsToXLSBtn = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const email = useSelector((state) => state.global.email)
  const marketerId = useSelector((state) => state.global.comercializadora_id)
  const selectedRows = useSelector((state) => state.marketerPotential.selectedRows)
  const search = useSelector((state) => state.marketerPotential.search)
  const pagination = useSelector((state) => state.marketerPotential.pagination)
  const combos = useSelector((state) => state.combos)
  const [loading, setLoading] = useState(false)
  const { get } = useAjaxContext()
  const { requestNewExportByEmail } = useExportToXLSCalls()
  const exportUrl = `/comercializadoras/${marketerId}/tabla_potenciales`
  const exportUrlAsync = `/extracciones/by_email_instalador`
  const {
    actions: { open },
  } = useModalContext()

  const mainExport = (data) => {
    const fileName = `potenciales_comercializadora_${getFormattedTime()}.xlsx`
    dataToXLS(data, columns, combos, intl, MARKETER_POTENTIAL_TABLE_COLUMNS_KEY, fileName)
    setLoading(false)
  }

  const handleExport = async () => {
    if (selectedRows && selectedRows.length) {
      setLoading(true)
      mainExport(selectedRows)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  const handleExportAll = async () => {
    setLoading(true)
    const { _num_x_pagina, _pagina, ...otherSearch } = search
    const { total_potenciales } = pagination
    if (!total_potenciales) {
      open({
        Component: ExportNotEmptyDialog,
      })
      setLoading(false)
      return
    }
    if (total_potenciales > 500) {
      requestNewExportByEmail(email)
        .then(({ data }) => {})
        .catch((code) => {
          if (code === 'already started') {
            feedback(
              'warning',
              formatMessage(
                { id: 'pages.marketer.potential.exportToXLS.dialogs.running.export.text' },
                { email }
              )
            )
          } else {
            get(exportUrlAsync, {
              params: { ...otherSearch },
            })
              .then(({ data }) => {})
              .catch(({ response }) => {
                /*                if (!response || response.data.message) {
                  feedback('error', response.data.message || 'Error desconocido')
                }*/
                setLoading(false)
              })
            feedback(
              'info',
              formatMessage({ id: 'pages.marketer.potential.exportToXLS.dialogs.large.text' })
            )
          }
          setLoading(false)
        })
    } else {
      get(exportUrl, {
        params: { ...otherSearch, _num_x_pagina: total_potenciales, _pagina: '1' },
      })
        .then(({ data }) => {
          if (!data) {
            return Promise.reject({ response: 'data_too_large' })
          }
          const recievedData = marketerPotentialsDataPreparation(columns, data.potenciales, {
            intl,
          })
          mainExport(recievedData)
        })
        .catch(({ response }) => {
          if (!response || response === 'data_too_large') {
            feedback('error', response.data.message || 'Error desconocido')
          }
          setLoading(false)
        })
    }
  }

  return (
    <>
      <ThemeButton
        color="primary"
        labelM="sm"
        onClick={handleExport}
        style={{ marginRight: '1em' }}
      >
        {intl.formatMessage({ id: 'global.exportSelected' })} ({selectedRows.length})
      </ThemeButton>
      <ThemeButton color="primary" labelM="sm" loading={loading} onClick={handleExportAll}>
        {intl.formatMessage({ id: 'global.exportAll' })}
      </ThemeButton>
    </>
  )
}
