import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useAssociatesIP3Calls = () => {
  const { get, put, deleteCall } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getAssociatedCompanies = useCallback(
    (config, gremio_id) =>
      new Promise((resolve, reject) => {
        get(`/gremios/${gremio_id}/empresas_ip`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 403: //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptCompanyCall = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        put(`/gremios/${gremio_id}/empresas_ip/${id_empresa_ca}`)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.guilds.error.put.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rejectCompanyCall = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        deleteCall(`/gremios/${gremio_id}/empresas_ip/${id_empresa_ca}`)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.guilds.error.delete.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getAssociatedCompanies,
    acceptCompanyCall,
    rejectCompanyCall,
  }
}
