import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { UsersDashboard, UsersDetail } from '../../../features/users'
import { USERS_COMBOS_IDS } from '../constants'
import { useCombos } from '../../../features/combos/hooks'

export const UsersPage = () => {
  useCombos(USERS_COMBOS_IDS)

  return (
    <Switch>
      <Route exact path="/users" component={UsersDashboard} />
      <Route exact path="/users/:userId" component={UsersDetail} />
      <Redirect from="*" to="/users" />
    </Switch>
  )
}
