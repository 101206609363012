import { instances } from '../instances'

const { publicInstance, privateInstance } = instances

export const requests = {
  serverPing: () => publicInstance.get('check_working_api'),
  login: (data) => publicInstance.post('login/login_oauth2', { ...data, area: 'instalador' }),
  signupConfirm: (id) => publicInstance.get(`usuario/register_confirmation/${id}`),
  postPublic: (url, data) => publicInstance.post(url, data),
  putPublic: (url, data) => publicInstance.put(url, data),
  getPublic: (url, config = {}) => publicInstance.get(url, config),
  getById: (url, id, config = {}) => privateInstance.get(`${url}/${id}`, config),
  get: (url, config = {}) => privateInstance.get(url, config),
  post: (url, data, config = {}) => privateInstance.post(url, data, config),
  put: (url, data, config = {}) => privateInstance.put(url, data, config),
  putById: (url, id, data, config = {}) => privateInstance.put(`${url}/${id}`, data, config),
  patchById: (url, id, data, config = {}) => privateInstance.patch(`${url}/${id}`, data, config),
  deleteCall: (url, config = {}) => privateInstance.delete(`${url}`, config),
  deleteById: (url, id, config = {}) => privateInstance.delete(`${url}/${id}`, config),
}
