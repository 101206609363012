import { APP_PATH_RULES, APP_TYPE } from './application-types.constants'

const TEXT_AREA_LENGTH = 250

const URL_ESTRUCTURA_FINCA =
  'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2020/09/hoja-estructura-finca.pdf'

const URL_CATASTRO = 'https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCBusqueda.aspx'

const LINK_ESTRUCTURA_FINCA = {
  prepend: 'Puedes descargar el ',
  append: '.',
  text: 'modelo para rellenar',
  url: URL_ESTRUCTURA_FINCA,
}

const LINK_CATASTRO = {
  prepend: 'El ',
  append: ' te puede resultar útil.',
  text: 'buscador de catastro',
  url: URL_CATASTRO,
}

export const CUSTOMER_FIELDS_CONFIG = ({
  formState,
  onChange,
  combos,
  intl,
  selectedPotencia,
  selectedConsumo,
}) => ({
  NOMBRE: {
    value: formState['nombre'],
    name: 'nombre',
    title: 'Nombre',
    placeholder: 'Ej. Lucía',
    required: true,
    onChange,
  },
  APELLIDOS: {
    value: formState['apellidos'],
    name: 'apellidos',
    title: formState['persona'] === 'F' ? 'Apellidos' : 'Razón Social',
    placeholder: formState['persona'] === 'F' ? 'Ej. Sánchez Molinero' : 'Razón Social',
    required: true,
    onChange,
  },
  TIPO_PERSONA: {
    value: formState['persona'],
    name: 'persona',
    title: 'Tipo de Persona',
    required: true,
    onChange,
    values: [
      { key: 'F', value: 'Física' },
      { key: 'J', value: 'Jurídica' },
    ],
  },
  TIPO_IDENTIFICADOR: {
    value: formState['tipo_identificador'],
    name: 'tipo_identificador',
    title: 'Tipo de documento',
    placeholder: 'Selecciona',
    tooltip: intl.formatMessage({ id: 'pages.consult.tooltip.tipo_identificador' }),
    required: true,
    fullWidth: true,
    onChange,
    values: [
      { key: '', value: '' },
      { key: 'NIF', value: 'NIF' },
      { key: 'NIE', value: 'NIE' },
      { key: 'Passport', value: 'Pasaporte' },
    ],
  },
  IDENTIFICADOR: {
    value: formState['identificador'],
    name: 'identificador',
    title: 'Número',
    placeholder: 'Ej. 00000000A',
    required: true,
    onChange,
  },
  EMAIL: {
    inputProps: { type: 'email' },
    value: formState['email'],
    name: 'email',
    title: 'Correo electrónico',
    placeholder: 'Ej. nombre@dominio.com',
    required: true,
    type: 'email',
    onChange,
  },
  TELEFONO: {
    value: formState['telefono'],
    name: 'telefono',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    required: true,
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
  },
  IBAN: {
    value: formState['iban'],
    name: 'iban',
    title: 'Número cuenta bancaria',
    placeholder: 'Ej. ES1234567891234567891234',
    required: true,
    onChange,
    inputProps: {
      type: 'iban',
      pattern:
        '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
    },
  },
  CODIGO_FINANCIACION: {
    value: formState['codigo_financiacion'],
    name: 'codigo_financiacion',
    title: 'Código financiación',
    placeholder: 'Ej. 202105880547041',
    required: true,
    onChange,
    inputProps: {
      type: 'codigo_financiacion',
      pattern: '20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$',
    },
  },
  NUM_CEDULA_HAB: {
    value: formState['num_cedula_habitabilidad'],
    name: 'num_cedula_habitabilidad',
    type: 'number',
    decimalScale: 0,
    // title: 'Número cédula habitabilidad',
    onChange,
  },
  NUM_SUMINISTRO_FINCA_POT: {
    value: formState['num_suministros_finca_potenciales'],
    name: 'num_suministros_finca_potenciales',
    type: 'number',
    decimalScale: 0,
    // title: 'Número cédula habitabilidad',
    onChange,
    required: true,
  },
  NUM_SUMINISTRO_FINCA_INT: {
    value: formState['num_suministros_finca_interesados'],
    name: 'num_suministros_finca_interesados',
    type: 'number',
    decimalScale: 0,
    onChange,
    required: true,
  },
  REF_CATASTRAL: {
    value: formState['referencia_catastral'],
    name: 'referencia_catastral',
    onChange,
    required: true,
  },
  OBSERVACIONES: {
    value: formState['observaciones'],
    name: 'observaciones',
    title: 'Observaciones',
    placeholder: `Máx. ${TEXT_AREA_LENGTH} caracteres`,
    multiline: true,
    rows: 5,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    onChange,
  },
  POTENCIA: {
    fullWidth: true,
    value: formState['potencia'],
    name: 'potencia',
    onChange,
    values: selectedPotencia && selectedPotencia,
    required: true,
  },
  CONSUMO: {
    fullWidth: true,
    value: formState['consumo'],
    name: 'consumo',
    onChange,
    values: selectedConsumo && selectedConsumo,
    required: true,
    readOnly: !formState['potencia'],
  },
  UBICACION_CONTADOR: {
    value: formState['ubi_contador'],
    name: 'ubi_contador',
    values: combos['ubicacion_contador']?.data,
    required: true,
  },
  PROPIEDAD_CONTADOR: {
    value: formState['prop_contador']
      ? formState['prop_contador']
      : (formState['prop_contador'] = 'Distribuidora'),
    name: 'prop_contador',
    required: true,
  },
  PROPIEDAD_ARMARIO: {
    value: formState['prop_irc_armario_reg']
      ? formState['prop_irc_armario_reg']
      : (formState['prop_irc_armario_reg'] = 'Usuario'),
    name: 'prop_irc_armario_reg',
    values: combos['propiedad_irc_armario_regulacion']?.data,
    required: true,
  },
  CASO_CALDERA: {
    value: formState['caso_caldera_centr'],
    name: 'caso_caldera_centr',
    values: combos['caso_caldera_centralizada']?.data,
    required: true,
  },
  TARIFA: {
    value: formState['numero_tarifa_aplicada'],
    name: 'numero_tarifa_aplicada',
    values: combos['tarifa_aplicada']?.data,
  },
})

export const SCR_UPLOAD_BUTTONS_CONFIG = [
  { doc: 'CR', required: (path) => !APP_PATH_RULES[path].certificate },
  { doc: 'DN', required: (path) => !APP_PATH_RULES[path].certificate },
  { field: 'NUM_CEDULA_HAB', title: 'Número cédula habitabilidad' },
  { doc: 'CH' },
  { doc: 'FC1' },
]

export const NEW_SCR_UPLOAD_BUTTONS_CONFIG = [
  {
    field: 'UBICACION_CONTADOR',
    radio: true,
    required: () => true,
    title: 'Ubicación del contador',
  },
  {
    field: 'PROPIEDAD_CONTADOR',
    radio: false,
    required: () => true,
    title: 'Propiedad del contador',
    readOnly: true,
  },
  {
    field: 'PROPIEDAD_ARMARIO',
    radio: true,
    required: () => true,
    title: 'Propiedad de IRC y/o Armario Regulación',
  },
  {
    field: 'CASO_CALDERA',
    radio: true,
    required: () => true,
    title: 'Caso Caldera Centralizada',
    condition: (data) => data['uso'] === 'caldera_centralizada',
  },
  // {
  //   field: 'TARIFA',
  //   radio: true,
  //   title: 'Tarifa a Contratar',
  // },
  { doc: 'croquis_i' },
  { doc: 'DN', required: () => true, condition: (data) => data['applyCampaign'] },
  { doc: 'CCEE', required: () => true, condition: (data) => data['applyCampaign'] },
  {
    doc: 'CPI',
    required: () => true,
    condition: (data) =>
      data['applyCampaign'] &&
      (data.uso === 'caldera_centralizada' ||
        data.mercado === 'CC' ||
        data.uso === 'gran_consumo' ||
        data.mercado === 'GC'),
  },
  {
    doc: 'CB',
    required: () => true,
    condition: (data) => data['applyCampaign'] && (data.uso === 'pymes' || data.mercado === 'PYME'),
  },
  {
    doc: 'EF',
    condition: (data) => data['uso'] === 'caldera_centralizada',
    link: LINK_ESTRUCTURA_FINCA,
  },
]

export const SAG_UPLOAD_BUTTONS_CONFIG = [
  {
    field: 'NUM_SUMINISTRO_FINCA_POT',
    title: 'Número de potenciales',
    required: (path) => path !== 'NNSAG',
  },
  {
    field: 'NUM_SUMINISTRO_FINCA_INT',
    title: 'Número de interesados del inmueble',
    required: (path) => path !== 'NNSAG',
  },
  { doc: 'SG', required: (path) => path !== 'NNSAG' },
  { doc: 'croquis_i', required: (path) => path !== 'NNSAG' },
  { doc: 'AR' },
  { doc: 'FC1' },
]

export const NNE_UPLOAD_BUTTONS_CONFIG = [
  { doc: 'croquis_i' },
  { field: 'REF_CATASTRAL', title: 'Referencia catastral', link: LINK_CATASTRO },
  { doc: 'EF', link: LINK_ESTRUCTURA_FINCA },
  { doc: 'DN' },
  { doc: 'FC1' },
]

export const NNI_UPLOAD_BUTTONS_CONFIG = [{ doc: 'tallo_i' }, { doc: 'FC1' }]

export const GASP_UPLOAD_BUTTONS_CONFIG = [
  { doc: 'peticion_gasp' },
  { doc: 'escritura_poderes' },
  { doc: 'dn' },
  { doc: 'cc' },
  { doc: 'proyecto_gas' },
  { doc: 'proyecto_calefaccion' },
]

export const FORM_BUTTONS_CONFIG = {
  [APP_TYPE.SCR]: NEW_SCR_UPLOAD_BUTTONS_CONFIG,
  [APP_TYPE.SAG]: SAG_UPLOAD_BUTTONS_CONFIG,
  [APP_TYPE.NNE]: NNE_UPLOAD_BUTTONS_CONFIG,
  [APP_TYPE.NN]: NNE_UPLOAD_BUTTONS_CONFIG,
  [APP_TYPE.NNI]: NNI_UPLOAD_BUTTONS_CONFIG,
}

export const SCR_NO_CAMPAIGNS_REQUIRED_FIELDS = [
  'uso',
  'ubi_contador',
  'prop_contador',
  'prop_irc_armario_reg',
]

export const SCR_CAMPAIGNS_REQUIRED_FIELDS = [
  'uso',
  'ubi_contador',
  'prop_contador',
  'prop_irc_armario_reg',
  'DN',
  'CCEE',
]

export const SCR_PYMES_CAMPAIGNS_REQUIRED_FIELDS = [
  'uso',
  'ubi_contador',
  'prop_contador',
  'prop_irc_armario_reg',
  'DN',
  'CCEE',
  'CB',
]

export const SCR_GG_AND_CC_CAMPAIGNS_REQUIRED_FIELDS = [
  'uso',
  'ubi_contador',
  'prop_contador',
  'prop_irc_armario_reg',
  'DN',
  'CCEE',
  'CPI',
]

export const USO_MERCADO = [
  { id: 'pymes', value: 'PYME' },
  { id: 'caldera_centralizada', value: 'CC' },
  { id: 'gran_consumo', value: 'GC' },
]
