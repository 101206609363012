import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, Switch } from '@material-ui/core'
import { toggleUserActive } from '../../slices'

// import { toggleUserActive } from '../../slices'

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActions = withStyles(switchStyles)(({ classes, rowData, ...props }) => {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.marketerUsers.data)
  const global = useSelector((state) => state.global)
  const switchDisabled = '' + rowData.id === global.user_id
  const userState = rowData['activo']

  const handleToggleState = () => dispatch(toggleUserActive(rowData, users))

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={userState}
      onClick={handleToggleState}
      disabled={switchDisabled}
    />
  )
})
