import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Typography, Box, Button } from '@material-ui/core'

import { PageDisplayer } from '../../../ui'
import {
  BoilersContextProvider,
  useBoilersContext,
  BoilersTable,
  BoilersSelectors,
} from '../../../features/boilers'

import { consultPageLink } from '../constants'

const BoilersPageBare = () => {
  const { formatMessage } = useIntl()
  const state = useBoilersContext()

  return (
    <PageDisplayer>
      <Box bgcolor="common.white" borderRadius={4}>
        <Box display="flex" style={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            {formatMessage({ id: 'pages.boilers.title' })}
          </Typography>
          <Link to={consultPageLink} style={{ textDecoration: 'none' }}>
            <Button color="primary" style={{ fontSize: '1.1em' }} variant="contained">
              {formatMessage({ id: 'pages.boilers.buttonToConsult' })}
            </Button>
          </Link>
        </Box>
        <Box>
          <BoilersSelectors />
        </Box>
        <Box>{state.municipio ? <BoilersTable /> : null}</Box>
      </Box>
    </PageDisplayer>
  )
}

export const BoilersPage = () => (
  <BoilersContextProvider>
    <BoilersPageBare />
  </BoilersContextProvider>
)
