export const GUILD_IP_ACTION_TYPES = {
  RESET: 'RESET',
  SET_LOADING: 'SET_LOADING',
  SET_NOTIF_LOADING: 'SET_NOTIF_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_GUILDS_LIST: 'SET_GUILDS_LIST',
  SET_SELECTED_GUILD: 'SET_SELECTED_GUILD',
  SET_MANDATORY_CHECKED: 'SET_MANDATORY_CHECKED',
  SET_NOTIFICATIONS_CHECKED: 'SET_NOTIFICATIONS_CHECKED',
}

export const GUILD_IP_INITIAL_STATE = {
  loading: false,
  notifLoading: false,
  error: '',
  guildsList: [],
  selectedGuild: '',
  mandatoryChecked: false,
  notificationsChecked: false,
}

export const I18N_ROOT = 'pages.profile.company.guildIP3'
