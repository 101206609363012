import { GUILD_IP_ACTION_TYPES, GUILD_IP_INITIAL_STATE } from './constants'

export const companyGuildIPReducer = (state, { type, payload }) => {
  switch (type) {
    case GUILD_IP_ACTION_TYPES.RESET:
      return {
        ...{ ...GUILD_IP_INITIAL_STATE, guildsList: state.guildsList },
      }
    case GUILD_IP_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case GUILD_IP_ACTION_TYPES.SET_NOTIF_LOADING:
      return {
        ...state,
        notifLoading: payload,
      }
    case GUILD_IP_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: payload,
      }
    case GUILD_IP_ACTION_TYPES.SET_GUILDS_LIST:
      return {
        ...state,
        guildsList: payload,
        loading: false,
      }
    case GUILD_IP_ACTION_TYPES.SET_SELECTED_GUILD:
      return {
        ...state,
        selectedGuild: payload,
      }
    case GUILD_IP_ACTION_TYPES.SET_MANDATORY_CHECKED:
      return {
        ...state,
        mandatoryChecked: payload,
      }
    case GUILD_IP_ACTION_TYPES.SET_NOTIFICATIONS_CHECKED:
      return {
        ...state,
        notificationsChecked: payload,
      }
    default:
      throw new Error('unkown action')
  }
}
