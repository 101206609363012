import React, { createContext, useContext, useReducer } from 'react'

import { useCertificatesPlusActions } from '../hooks'
import { certificatesReducer } from '../reducers'
import { CERTIFICATE_INITIAL_STATE } from '../constants'

const CertificateContext = createContext()

export const useCertificateContext = () => useContext(CertificateContext)

export const CertificatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(certificatesReducer, CERTIFICATE_INITIAL_STATE)

  const actions = useCertificatesPlusActions(state, dispatch)

  return (
    <CertificateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </CertificateContext.Provider>
  )
}
