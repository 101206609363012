import React, { createContext, useContext } from 'react'

import { requests } from '../requests'

const AjaxContext = createContext({})

const AjaxProvider = ({ children }) => {
  return <AjaxContext.Provider value={requests}>{children}</AjaxContext.Provider>
}

const useAjaxContext = () => useContext(AjaxContext)

export { AjaxContext, AjaxProvider, useAjaxContext }
