import { globalReducer } from '../../features/global'
import { applicationsReducer } from '../../features/applications'
import { tariffsReducer } from '../../features/tariffs'
import { usersReducer } from '../../features/users'
import { combosReducer } from '../../features/combos'
import { guildUsersReducer } from '../../features/guild-users/slices'
import { marketerUsersReducer } from '../../features/marketer-users/slices'
import { marketerPotentialReducer } from '../../features/marketer-potential/slices'
import { ipReducer } from '../../features/ip/slices'
import { jcaReducer } from '../../features/jca/slices'

export const reduxReducer = {
  global: globalReducer,
  applications: applicationsReducer,
  tariffs: tariffsReducer,
  users: usersReducer,
  guildUsers: guildUsersReducer,
  marketerUsers: marketerUsersReducer,
  marketerPotential: marketerPotentialReducer,
  ip: ipReducer,
  jca: jcaReducer,
  combos: combosReducer,
}
