import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Grid, ListItemText, TextField } from '@material-ui/core'

import { SelectInput } from '../../../ui'
import { gridRowStyles } from './grid-row.styles'

export const GridRowComponent = withStyles(gridRowStyles)(
  ({ classes, items, data, spacing = 4, justify = 'flex-start', ...props }) => {
    const intl = useIntl()

    const handleEnterKey = (event) => {
      if (event.keyCode === 13) {
        props.onChange(event)
      }
    }

    const getValueByType = (item, data) => {
      if (data[item.value] === null) return ''
      if (item.type === 'combo') {
        let selectedOption =
          props.combos &&
          props.combos[item.comboId].data &&
          props.combos[item.comboId].data.length !== 0 &&
          props.combos[item.comboId].data.find((o) => o.key === data[item.value])
        if (!selectedOption) {
          selectedOption = {}
          console.debug('WARNING: Value mismatch', props.combos, 'item ', item, 'data ', data)
        }
        return selectedOption.value || data[item.value]
      } else return data[item.value]
    }

    return (
      <Grid container spacing={spacing} justifyContent={justify}>
        {items.map((item, i) => (
          <Grid item key={i}>
            <ListItemText
              primary={intl.formatMessage({ id: item.label })}
              secondary={
                <span style={{ whiteSpace: 'pre-line' }}>
                  {item.type === 'input' ? (
                    <TextField
                      id="outlined-basic"
                      name={item.name}
                      variant="outlined"
                      defaultValue={
                        item.valueParsed ? item.valueParsed(data, intl) : data[item.value]
                      }
                      onBlur={props.onChange}
                      onKeyDown={handleEnterKey}
                      size="small"
                      disabled={props.disabled}
                    />
                  ) : item.type === 'select' ? (
                    <SelectInput
                      name={item.value}
                      label={item.label}
                      value={data[item.value]}
                      onChange={props.onChange}
                      values={
                        props.combos && props.combos[item.comboId]
                          ? props.combos[item.comboId].data
                          : []
                      }
                      disabled={props.disabled}
                      // loading={!props.combos[item.value] || props.combos[item.value].isLoading}
                    />
                  ) : item.valueParsed ? (
                    item.valueParsed(data, intl) || ''
                  ) : (
                    getValueByType(item, data)
                  )}
                </span>
              }
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'body2' }}
            />
          </Grid>
        ))}
      </Grid>
    )
  }
)
