export const ASSOCIATES_IP3_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 250,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  {
    field: 'aceptacion_gremio',
    align: 'left',
    width: 120,
  },
  {
    field: 'razon_social',
    align: 'left',
    width: 150,
  },
  {
    field: 'email',
    align: 'left',
    width: 200,
  },
  {
    field: 'telefono',
    align: 'left',
    width: 120,
  },
  {
    field: 'direccion',
    align: 'left',
    width: 250,
  },
  {
    field: 'cod_codigo_postal',
    align: 'left',
    width: 140,
  },
]

export const ASSOCIATES_IP3_TABLE_STATES = {
  accepted: 'Confirmado',
  pending: 'Pendiente',
  yes: 'Sí',
  no: 'No',
}

export const ASSOCIATES_IP3_COMBOS = ['cod_zona_zeus', 'provincias']
