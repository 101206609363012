import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

import { useSignupFormContext } from '../../../contexts'
import { MandatoryCheckbox } from '../mandatory-checkbox.component'
import documentWithPublicProfile from '../../../../../resources/documents/condiciones_public.pdf'
import documentWithoutPublicProfile from '../../../../../resources/documents/condiciones_other.pdf'

export const CompanyFormExtraComponent = () => {
  const { formatMessage } = useIntl()
  const {
    formState,
    onChange,
    setTermsAndConditionsChecked,
    setTermsAndConditionsDisabled,
  } = useSignupFormContext()

  const handleChangeRB = (e) => {
    onChange(e)
    setTermsAndConditionsChecked(false)
    setTermsAndConditionsDisabled(true)
  }

  const doc = formState.publica === '01' ? documentWithPublicProfile : documentWithoutPublicProfile
  const mssg = formatMessage({
    id: `pages.signup.form.checkbox.message.${formState.publica === '01' ? '2' : '3'}`,
  })

  return (
    <Box bgcolor="grey.100" p={1} data-role="terms and conditions">
      <Box p={1}>
        <Typography variant="body1" gutterBottom>
          {formatMessage({ id: 'pages.signup.form.publicProfileQuestion' })}
        </Typography>
        <RadioGroup
          value={formState.publica}
          onChange={handleChangeRB}
          style={{ width: 80, paddingLeft: 10 }}
          name="publica"
        >
          <FormControlLabel
            value="01"
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'global.true' })}
              </Typography>
            }
          />
          <FormControlLabel
            value="00"
            control={<Radio size="small" color="primary" />}
            label={
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'global.false' })}
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
      <MandatoryCheckbox {...{ doc, mssg }} />
    </Box>
  )
}
