import React, { useState } from 'react'
import { Box, Grid, Tooltip, Chip, Typography, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { downloadFile } from '../../../../core/utils'
import { requests } from '../../../../core/ajax'
import { TextInput, SelectInput } from '../../../../ui'
import { COMPANY_FORM_CONFIG } from '../../constants'

export const IpIBANDisplayer = ({ inputProps, getEIData }) => {
  const { formState, intl } = inputProps
  const fm = intl.formatMessage
  const boxProps = {
    bgcolor: 'grey.200',
    borderRadius: 4,
    p: 2,
    display: 'flex',
  }

  const [loading, setLoading] = useState(false)

  const handleDownloadFile = (id) => {
    const { getById } = requests
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box {...boxProps}>
      <Grid container spacing={3}>
        {(formState.estado_iban === '15' || formState.estado_iban === '01') &&
        formState.iban_validado ? (
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography variant="body1">
                {fm({ id: 'pages.eeii.detail.ibanDisplayer.existValidIBAN' })}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{formState.iban_validado}</strong>
              </Typography>
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={6} md={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).IBAN }} />
          {formState.d_certificado_iban ? (
            <Tooltip
              arrow
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {fm({ id: 'pages.eeii.detail.fields.certificado.tooltip' })}
                </Typography>
              }
            >
              <Chip
                avatar={
                  loading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <AttachFileIcon style={{ backgroundColor: 'transparent' }} />
                  )
                }
                label={`${formState.d_certificado_iban.nombre}.${formState.d_certificado_iban.extension}`}
                onClick={() => handleDownloadFile(formState.d_certificado_iban.id)}
              />
            </Tooltip>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).ESTADO_IBAN }} />
        </Grid>
      </Grid>
    </Box>
  )
}
