import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, withStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
//import RestoreIcon from '@material-ui/icons/RestoreFromTrash'

import { ADMIN_ROLES } from '../../../../core/constants'
import { useAjaxContext } from '../../../../core/ajax'
import { setTariffs } from '../../../tariffs'
import { useTariffsDialogs } from '../../hooks'
import { tableActionsStyles } from './table-actions.style'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { deleteById } = useAjaxContext()
  const global = useSelector((state) => state.global)
  const tariffId = props.rowData['idTarifa']
  const searchParams = useSelector((state) => state.tariffs.search)

  const { warnPermanentDeletion } = useTariffsDialogs()

  const handleGoToDetail = () => history.push(`/tariffs/${tariffId}`)

  const handleEdit = () => history.push(`/tariffs/${tariffId}/edit`)

  //Delete action, with modal
  const handleDelete = () => {
    deleteById('tarifa/eliminar', tariffId)
      .then(() => {
        dispatch(setTariffs(searchParams))
      })
      .catch(() => console.error('Error deleting tariff'))
  }
  const handleCallDelete = () => {
    warnPermanentDeletion(handleDelete)
  }

  // const handleActivate = () => {
  //   console.debug('Not implemented yet')
  // }

  const tariffButtons = {
    edit: {
      onClick: handleEdit,
      icon: <EditIcon />,
    },
    delete: {
      onClick: handleCallDelete,
      icon: <DeleteIcon />,
    },
    detail: {
      onClick: handleGoToDetail,
      icon: <SearchIcon />,
    },
    // activate: {
    //   onClick: handleActivate,
    //   icon: <RestoreIcon />,
    // },
  }

  const availableActions = ['detail']
  if (ADMIN_ROLES[global.role]) {
    availableActions.push('edit')
    // availableActions.push(tariffActive ? 'delete' : 'activate')
    availableActions.push('delete')
  }

  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={tariffButtons[action].onClick}
          >
            {tariffButtons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})
