import { useEffect, useRef } from 'react'

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) return fn()
    else didMountRef.current = true
  }, inputs) // eslint-disable-line react-hooks/exhaustive-deps
}
