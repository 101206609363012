import React, { createContext, useContext } from 'react'

import { useJCAReducer } from '../hooks'

const JCADetailContext = createContext()

export const JCADetailProvider = ({ children }) => {
  const reducer = useJCAReducer()
  return <JCADetailContext.Provider value={reducer}>{children}</JCADetailContext.Provider>
}

export const useJCADetail = () => useContext(JCADetailContext)
