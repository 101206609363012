import React, { useState } from 'react'
import { useIntl } from 'react-intl'
// import { Box } from '@material-ui/core'
// import { Alert } from '@material-ui/lab'

// import { ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos/hooks'
import { useJCADetail } from '../../contexts'
import { GridForm } from './grid-form.component'
import { INPUTS_CONFIG, FORM_CONFIG, JCA_NONCONFORMITY_STATES } from './jca-form.constants'
import { JCANonconfomityView } from './jca-nonconformity-view.component'

export const JCAForm = () => {
  const intl = useIntl()
  const combos = useCombos([])
  // const {  editJCA } = useJCADetail()
  const { jca } = useJCADetail()

  const [formState, setFormState] = useState(jca)
  // const [editMode, setEditMode] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  // const handleCancel = () => {
  //   setErrorMssg(false)
  //   setFormState(jca)
  //   setEditMode(false)
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   let error = ''
  //   // TO DO: manage errors
  //   if (editMode) {
  //     if (!error) {
  //       setLoading(true)
  //       editJCA(formState)
  //         .then((data) => {
  //           setErrorMssg('')
  //           setEditMode(false)
  //           setFormState(data)
  //           setLoading(false)
  //         })
  //         .catch(() => {
  //           setLoading(false)
  //         })
  //     } else {
  //       setErrorMssg(error)
  //     }
  //   } else {
  //     setEditMode(true)
  //   }
  // }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    // readOnly: !editMode,
    readOnly: true,
  })

  const GRID = FORM_CONFIG({ intl })

  return (
    // <form onSubmit={handleSubmit}>
    <form>
      {/* <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
        {editMode ? (
          <Box mr={1}>
            <ThemeButton color="default" onClick={handleCancel} disabled={loading}>
              {intl.formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
        ) : null}
        <ThemeButton type="submit" color="primary" loading={loading}>
          {intl.formatMessage({
            id: `pages.ip.detail.button.${editMode ? 'save' : 'edit'}.label`,
          })}
        </ThemeButton>
      </Box> */}
      {/* {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null} */}
      {JCA_NONCONFORMITY_STATES[jca.estado] ? (
        <JCANonconfomityView />
      ) : (
        <GridForm {...{ grid: GRID, inputs: INPUTS }} />
      )}
    </form>
  )
}
