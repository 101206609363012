export const GUILDS_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  GUILDS_LOADING: 'GUILDS_LOADING',
  GUILDS_FAILURE: 'GUILDS_FAILURE',
  UPDATE_GUILDS_COMPANY: 'UPDATE_GUILDS_COMPANY',
  DELETE_GUILDS_COMPANY: 'DELETE_GUILDS_COMPANY',
}

export const GUILDS_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: { totalSolicitudes: 0 },
  loading: false,
  msLoaded: false,
  error: false,
}

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '5' }
