import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { MENU_ITEMS, MENU_INSTALLER_C, MENU_INSTALLER_IP, MENU_INSTALLER_C_IP } from '../constants'
import { MENU_GUILD_C, MENU_GUILD_IP, MENU_GUILD_C_IP, MENU_MARKETER } from '../constants'

export const useMenu = () => {
  const { formatMessage } = useIntl()
  const global = useSelector((state) => state.global)

  const getMenuItemLabel = useCallback(
    (menuItemId) => {
      return formatMessage({ id: `menu.${menuItemId}` })
    },
    [formatMessage]
  )

  const getCompleteMenu = useCallback(
    (menuItems) => {
      const menu = menuItems.map((item) => {
        if (item.children) {
          return {
            ...MENU_ITEMS[item.id],
            label: getMenuItemLabel(item.id),
            children: item.children.map((child) => ({
              ...MENU_ITEMS[child.id],
              label: getMenuItemLabel(child.id),
            })),
          }
        } else {
          return { ...MENU_ITEMS[item.id], label: getMenuItemLabel(item.id) }
        }
      })
      return menu
    },
    [getMenuItemLabel]
  )

  const getMenu = useCallback(() => {
    let menuItems = []
    switch (global.role) {
      case 'gremio':
        if (global.gremio?.captadora && global.gremio?.grabadora) {
          menuItems = MENU_GUILD_C_IP
        } else if (global.gremio?.captadora) {
          menuItems = MENU_GUILD_C
        } else {
          menuItems = MENU_GUILD_IP
        }
        break
      case 'comercializadora':
        menuItems = MENU_MARKETER
        break
      case 'administrador_grabador':
        menuItems = MENU_INSTALLER_IP
        break
      case 'admin_captador_grabador':
        menuItems = MENU_INSTALLER_C_IP
        break
      default:
        menuItems = MENU_INSTALLER_C
        break
    }

    return getCompleteMenu(menuItems)
  }, [global, getCompleteMenu])

  return {
    getMenu,
  }
}
