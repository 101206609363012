import * as publicOffersActions from '../constants'

/*Goals of our reducer:
 */

export const publicOfferReducer = (state, { type, payload }) => {
  switch (type) {
    case publicOffersActions.GET_PUBLICOFFERS_DATA:
      return {
        ...state,
        publicOffer: payload.campaingsOnHisMarkets,
        resumeFields: payload.resumeFields,
        generalConditions: payload.generalConditions,
        generalConditionsDocumentId: payload.generalConditionsDocumentId,
      }
    case publicOffersActions.TOOGLE_GENERALCONDITIONS:
      return {
        ...state,
        generalConditions: payload,
      }
    case publicOffersActions.TOOGLE_PUBLICOFFERS:
      const arraySupp = [...state.publicOffersToSend]
      const indexToPush = arraySupp.indexOf(payload)
      if (indexToPush === -1) {
        arraySupp.push(payload)
      } else {
        arraySupp.splice(indexToPush, 1)
      }
      return {
        ...state,
        publicOffersToSend: arraySupp,
      }

    case publicOffersActions.SET_ISLOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case publicOffersActions.RESET_OFFERS_TO_SEND:
      return {
        ...state,
        publicOffersToSend: [],
      }
    default:
      throw new Error('Unrecognized action')
  }
}
