import React from 'react'
import { AutocompleteInput } from '../../../../ui'

export const EmpresaSelector = ({ empresa, setEmpresa, empresas }) => {
  return (
    <AutocompleteInput
      title="Lista de empresas disponibles:"
      data-role="empresa"
      name="empresa"
      optionKey="nombre"
      values={empresas}
      value={empresa}
      onChange={(choice) => {
        const empresa = choice.value
        setEmpresa(empresa)
      }}
    />
  )
}

export default EmpresaSelector
