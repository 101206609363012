export const basicTableStyles = (theme) => ({
  row: {},
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  loadingRow: {
    pointerEvents: 'none',
    cursor: 'default',
  },
})