import React from 'react'

export const ADDRESS_FIELDS_CONFIG = ({ formState, onChange, intl }) => ({
  CP_MUNICIPIO: {
    value: formState['cp_municipio'],
    name: 'cp_municipio',
    optionKey: 'municipio',
    title: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.cp_municipio.label',
    }),
    placeholder: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.cp_municipio.placeholder',
    }),
    required: true,
    renderOption: (option) => `${option['cp']} - ${option['municipio']}`,
    requestTimeout: 600,
    onChange,
  },
  DIRECCION: {
    value: formState['direccion'],
    name: 'direccion',
    optionKey: 'nombreVia',
    title: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.direccion.label',
    }),
    placeholder: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.direccion.placeholder',
    }),
    renderOption: (option) => `${option['tipo_via'] || ''} ${option['calle']}`,
    required: true,
    disabled: !formState['cp_municipio'],
    inputMinLength: 1,
    requestTimeout: 600,
    onChange,
  },
  NUMERO: {
    value: formState['numero'],
    name: 'numero',
    optionKey: 'numero',
    title: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.numero.label',
    }),
    placeholder: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.numero.placeholder',
    }),
    renderOption: (option) => option['numero_finca'],
    required: true,
    disabled: !formState['direccion'],
    onChange,
  },
  VIVIENDA: {
    value: formState['vivienda'],
    name: 'vivienda',
    optionKey: 'vivienda',
    title: 'Vivienda',
    labelOption: (option) =>
      (option['portal'] ? `Portal ${option['portal']} ` : '') +
      (option['escalera'] ? `Escalera ${option['escalera']} ` : '') +
      (option['piso'] ? `Piso ${option['piso']} ` : '') +
      (option['puerta'] ? `Puerta ${option['puerta']} ` : ''),
    renderOption: (option) => {
      const portal = option['portal'] ? (
        <span>
          Portal <b>{option['portal']} </b>
        </span>
      ) : (
        ''
      )
      const escalera = option['escalera'] ? (
        <span>
          Escalera <b>{option['escalera']}</b>
        </span>
      ) : (
        ''
      )
      const piso = option['piso'] ? (
        <span>
          Piso <b>{option['piso']}</b>
        </span>
      ) : (
        ''
      )
      const puerta = option['puerta'] ? (
        <span>
          Puerta <b>{option['puerta']}</b>
        </span>
      ) : (
        ''
      )
      return (
        <span>
          {portal} {escalera} {piso} {puerta}
        </span>
      )
    },
    placeholder: 'Portal, Escalera, Piso, Puerta',
    required: true,
    disabled: !formState['numero'],
    onChange,
  },
  // DERIVATION: {
  //   value: formState['derivation'],
  //   name: 'derivation',
  //   optionKey: 'derivation',
  //   title: intl.formatMessage({
  //     id: 'pages.inspections.addAddressForm.fields.derivation.label',
  //   }),
  //   placeholder: intl.formatMessage({
  //     id: 'pages.inspections.addAddressForm.fields.derivation.placeholder',
  //   }),
  //   required: true,
  //   multiple: true,
  //   disabled: !formState['numero'],
  //   onChange,
  // },
  DERIVATION: {
    multiple: true,
    value: formState['derivation'] || [],
    name: 'derivation',
    optionKey: 'derivation',
    title: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.derivation.label',
    }),
    placeholder: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.derivation.placeholder',
    }),
    // labelOption: (option) =>
    //   (option['portal'] ? `Portal: ${option['portal']}, ` : '') +
    //   (option['escalera'] ? `Escalera: ${option['escalera']}, ` : '') +
    //   (option['cod_derivacion'] ? `Código: ${option['cod_derivacion']} ` : ''),
    labelOption: (option) => option['cod_derivacion'],
    renderOption: (option) => {
      const portal = option['portal'] ? (
        <span>
          Portal: <b>{option['portal']}</b>,
        </span>
      ) : (
        ''
      )
      const escalera = option['escalera'] ? (
        <span>
          Escalera: <b>{option['escalera']}</b>,
        </span>
      ) : (
        ''
      )
      const count_cups = option['count_cups'] ? (
        <span>
          Nº CUPS: <b>{option['count_cups']}</b>,
        </span>
      ) : (
        ''
      )
      const cod_derivacion = option['cod_derivacion'] ? (
        <span>
          Código: <b>{option['cod_derivacion']}</b>
        </span>
      ) : (
        ''
      )
      return (
        <span>
          {portal} {escalera} {count_cups} {cod_derivacion}
        </span>
      )
    },
    required: true,
    disabled: !formState['numero'],
    onChange,
  },
  CUPS_NUMBER: {
    readOnly: true,
    value: formState['cups_number'],
    name: 'cups_number',
    label: intl.formatMessage({
      id: 'pages.inspections.addAddressForm.fields.cups_number.label',
    }),
  }


})

export const ADDRESS_FORM_FIELDS = ['cp_municipio', 'direccion', 'numero', 'derivation','cups_number', 'vivienda']
