import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const marketerPotentialSlice = createSlice({
  name: 'marketerPotential',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedRows: [],
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setPotentialData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
    setSelectedPotentials: (state, { payload }) => {
      state.selectedRows = [
        ...state.selectedRows.filter(
          (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
        ),
        ...payload,
      ]
    },
  },
})

export const marketerPotentialReducer = marketerPotentialSlice.reducer

export const { setLoading, setPotentialData, setSelectedPotentials } = marketerPotentialSlice.actions

export const setPotential = (searchParams, comercializadora_id) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 20, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
    timeout: 0,
  }
  dispatch(setLoading(true))
  get(`comercializadoras/${comercializadora_id}/tabla_potenciales`, config)
    .then(({ data }) => {
      dispatch(
        setPotentialData({
          data: data.potenciales,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_potenciales: data['total_potenciales'],
          },
        })
      )
    })
    .catch(({ response }) => {
      console.error('Error recuperando potenciales', response)
      dispatch(setLoading(false))
    })
}
