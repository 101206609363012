import React from 'react'

import { IPDetailProvider } from './contexts'
import { IPDetail } from './components'

export const IPDetailWrapper = () => (
  <IPDetailProvider>
    <IPDetail />
  </IPDetailProvider>
)
