import React from 'react'
import { useIntl } from 'react-intl'
import { Box, ListItem, Typography, TextField } from '@material-ui/core'

import { RatingInput, ThemeButton } from '../../../../ui'
import { requests } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

const TEXT_AREA_LENGTH = 250

export const ReviewItem = (props) => {
  let { respuesta } = props
  const { formatMessage, formatDate } = useIntl()
  const { putById } = requests

  const [input, setInput] = React.useState(respuesta || '')
  const [openInput, setOpenInput] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleOnClick = () => {
    if (props.id) {
      props.handleGoToDetail(props.id)
    }
  }

  const handleChangeInput = (e) => {
    setInput(e.target.value)
  }

  const handleInput = () => {
    setOpenInput(!openInput)
  }

  const handleSubmitForm = () => {
    if (!input || !input.trim()) {
      feedback('warning', formatMessage({ id: 'pages.profile.guild.ratings.answer.empty' }))
    } else {
      setLoading(true)
      const payload = { respuesta: input }

      putById('/valoraciones', props.valoracion_id, payload)
        .then(() => {
          feedback(
            'success',
            formatMessage({ id: 'pages.profile.guild.ratings.answer.feedback.success' })
          )
          setOpenInput()
          setLoading(false)
        })
        .catch((error) => {
          feedback(
            'error',
            formatMessage({ id: 'pages.profile.guild.ratings.answer.feedback.error' })
          )
          setLoading(false)
        })
    }
  }

  return (
    <ListItem style={{ display: 'block' }}>
      <Box>
        <Typography component="span" variant="body2" color="textPrimary">
          {props.nombre}
        </Typography>
        {props.id_solicitud ? (
          <>
            {' - '}
            <Typography
              component="span"
              variant="caption"
              color="primary"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleOnClick}
            >
              {props.id_solicitud}
            </Typography>
          </>
        ) : null}
        <Box display="flex" style={{ marginBottom: '0.5em' }}>
          <RatingInput
            {...{
              name: 'valoracion',
              value: props.valoracion,
              readOnly: true,
              size: 'small',
            }}
          />
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
            style={{ marginLeft: 4 }}
          >
            {formatDate(props.fecha_comentario)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="baseline">
          <Typography component="span" variant="body2" color="textPrimary">
            {props.comentario}
          </Typography>

          {!openInput && !input ? (
            <Box ml={1} mb={1}>
              <Typography
                component="span"
                variant="caption"
                color="primary"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleInput}
              >
                {formatMessage({
                  id: 'pages.profile.guild.ratings.replyrating',
                })}
              </Typography>
            </Box>
          ) : null}
        </Box>
        {openInput ? (
          <>
            <Box mt={1} mb={2}>
              <TextField
                name="input"
                placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
                value={input}
                multiline
                variant="outlined"
                fullWidth
                rows={5}
                inputProps={{ maxLength: TEXT_AREA_LENGTH }}
                onChange={handleChangeInput}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" maxWidth="34%">
              <ThemeButton
                onClick={handleSubmitForm}
                color="primary"
                labelM="sm"
                size="small"
                loading={loading}
              >
                {formatMessage({ id: 'pages.profile.guild.ratings.answerbutton' })}
              </ThemeButton>
              <ThemeButton
                onClick={handleInput}
                color="primary"
                labelM="sm"
                size="small"
                loading={loading}
              >
                {formatMessage({ id: 'global.cancel' })}
              </ThemeButton>
            </Box>
          </>
        ) : null}
        {input && !openInput ? (
          <Box display="flex" flexDirection="column" mt={1}>
            <Box ml={1} display="flex">
              <Box mr={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  {formatMessage({ id: 'pages.profile.guild.ratings.answer' })}
                  {`${props.autor_respuesta}`}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {formatDate(props.fecha_respuesta)}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" ml={1} mt={1}>
              <Typography component="span" variant="body2" color="textPrimary">
                {input}
              </Typography>
              {input ? (
                <Box ml={1} alignSelf="flex-end">
                  <Typography
                    component="span"
                    variant="caption"
                    color="primary"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleInput}
                  >
                    {formatMessage({
                      id: 'pages.profile.guild.ratings.edit',
                    })}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
    </ListItem>
  )
}
