export const tariffFormStyles = (theme) => ({
  fullForm: {
    // '& input:disabled': {
    //   border: 'none',
    //   padding: 0,
    //   color: 'black',
    //   '&:before': {
    //     content: '"HELP!!!"',
    //     backgroundColor: 'red',
    //   },
    //   '&:after': {
    //     content: '"NOT WORKING"',
    //   },
    // },
  },
  upperButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      justifyContent: 'center',
    },
  },
  bottomButtons: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  gridRow: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: '24px 0 0 0',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  },
})
