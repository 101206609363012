import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ReduxProvider } from '../../core/redux'
import { MaterialThemeProvider } from '../../core/theme'
import { I18NProvider } from '../../core/i18n'
import { AjaxProvider } from '../../core/ajax'
import { Modal as ModalProvider } from '../../ui/modal'
import { BootstrapRouter } from '../routers/bootstrap.router'
import OnCrash from '../onCrash/onCrash.component'
import { OnServerDown } from '../onServerDown/onServerDown.component'

export const BootstrapComponent = () => {
  return (
    <ReduxProvider>
      <MaterialThemeProvider>
        <I18NProvider>
          <AjaxProvider>
            <OnServerDown>
              <OnCrash>
                <HashRouter hashType="hashbang">
                  <ModalProvider>
                    <BootstrapRouter />
                  </ModalProvider>
                </HashRouter>
              </OnCrash>
            </OnServerDown>
          </AjaxProvider>
          <ToastContainer limit={1} autoClose={10000} />
        </I18NProvider>
      </MaterialThemeProvider>
    </ReduxProvider>
  )
}
