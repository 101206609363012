import React, { useState } from 'react'
import { Dialog, Box, IconButton, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { TextInput } from '../text-input'
import { ThemeButton } from '../theme-button'

export const AddTableModal = ({ open, setOpen, onSubmit, fields, data, Component }) => {
  const [formState, setFormState] = useState({})

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (e, form) => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit(form)
    setOpen(false)
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box p={3}>
        {Component ? (
          <Component onSubmit={handleSubmitForm} tableData={data} />
        ) : (
          <form onSubmit={(e) => handleSubmitForm(e, formState)}>
            <Box mb={3}>
              <Grid container spacing={3}>
                {fields.map((field) => (
                  <Grid key={field.key} item xs={12}>
                    <TextInput
                      required
                      value={formState[field.key]}
                      name={field.key}
                      title={field.label}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center">
              <ThemeButton type="submit">Guardar</ThemeButton>
            </Box>
          </form>
        )}
      </Box>
    </Dialog>
  )
}
