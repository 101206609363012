import React from 'react'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { AddTable } from '../../../../../ui/add-table'
import { IPInstallerAddForm } from './ip-installer-add-form.component'
import { INSTALLERS_TABLE_CONFIG } from '../../../constants'

export const IPInstallerAddTable = ({ data = [], onChange }) => {
  const handleChangeTable = (data) => {
    onChange({ target: { name: 'inspectores', value: data } })
  }

  return (
    <AddTable
      {...{
        data,
        config: INSTALLERS_TABLE_CONFIG,
        rowKey: 'identificador',
        title: 'Listado de Instaladores',
        tooltip:
          'Instaladores que realizan las inspecciones. No son usuarios de la aplicación. Si no quieres añadirlos ahora, puedes hacerlo más tarde una vez vez te hayas registrado.',
        onChange: handleChangeTable,
        addIcon: <PersonAddIcon />,
        Component: IPInstallerAddForm,
      }}
    />
  )
}
