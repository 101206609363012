import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TARIFFS_TABLE_ACTIONS, TARIFFS_TABLE_COLUMNS } from '../../constants'
import { TableActions } from '../table-actions'
import { TableActive } from '../table-active'
import { TableUrlPagination } from '../../../../ui'
import { tariffsTableStyles } from './tariffs-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const TariffsTable = () => {
  const { formatMessage } = useIntl()
  const {
    data,
    search,
    pagination: { total_tarifas: totalEntries },
    isLoading,
  } = useSelector((state) => state.tariffs)
  const combos = useSelector((state) => state.combos)

  //Column structure
  const formattedColumns = useMemo(
    () =>
      [...TARIFFS_TABLE_COLUMNS, ...TARIFFS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'actions' || column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: formatMessage({ id: `pages.tariffs.table.columns.${column.field}.title` }),
          cellStyle: { ...tariffsTableStyles.cell, minWidth: column.minWidth },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          nombre: item.nombre.toUpperCase(),
          provincias: !(item.provincias.length > 0)
            ? ''
            : item.provincias
                .map(
                  (provincia) =>
                    provincia.charAt(0).toUpperCase() + provincia.slice(1).toLowerCase()
                )
                .join(', '),
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data]
  )

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      fixedColumns: {
        right: 2,
      },
      headerStyle: tariffsTableStyles.header,
      draggable: false,
    }),
    []
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const line = (column) => {
          switch (column) {
            case 'actions':
              return <TableActions {...props} />
            case 'active':
              return <TableActive {...props} />
            default:
              return props.children
          }
        }
        return (
          <MTableCell {...props} style={{ padding: 0 }}>
            {line(props.columnDef.field)}
          </MTableCell>
        )
      },
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={isLoading}
    />
  )
}
