import React from 'react'
import { withStyles, Box, Container } from '@material-ui/core'

import { Inspections } from '../../features/inspections'
import { InspectionsStyles } from './inspections.styles'

export const InspectionsPage = withStyles(InspectionsStyles)(({ classes }) => {
  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Box bgcolor="common.white" borderRadius={4} p={[2, 5]} mt={[1, 3]}>
          <Inspections />
        </Box>
      </Container>
    </div>
  )
})
