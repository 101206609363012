import React, { createContext, useContext } from 'react'
import { useGuildsReducer } from '../hooks/guilds-reducer.hook'

const GuildsContext = createContext()

export const GuildsContextProvider = ({ children }) => {
  const reducer = useGuildsReducer()
  return <GuildsContext.Provider value={reducer}>{children}</GuildsContext.Provider>
}

export const useGuildsContext = () => useContext(GuildsContext)
