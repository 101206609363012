import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {Box} from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import {ThemeButton, FileUploader, useModalContext} from '../../../../../ui'
import {APPLICATION_STATES} from '../../../constants'
import {useAppsDetail} from '../../../hooks'
import {feedback} from '../../../../../core/feedback'
import {BillingDataDialog} from '../../dialogs/billing-data-dialog';

const PAYMENT_FILE_SIZE = 10000000

export const UploadPaymentBtn = ({application, getApplication}) => {
    const intl = useIntl()
    const {role} = useSelector((state) => state.global)
    const {uploadPaymentFile, putApplication} = useAppsDetail()
    const {
        actions: {open, close},
    } = useModalContext()

    const canUploadPaymentFile = application['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY
    const canUpdateApplication =
        (role === 'gremio' && application.gremio_id) || (role !== 'gremio' && !application.gremio_id)

    const handleUploadFile = (data) => {

        open({
            Component: BillingDataDialog,
            data: {application, data, putApplication, uploadPaymentFile, close},
            maxWidth: 'md',
            type: 'responsive',
            closable: true,
        })

        // uploadPaymentFile(application, data)
        //   .then(() => {
        //     feedback('success', 'Comprobante de pago subido')
        //     getApplication(application.id)
        //   })
        //   .catch(() => {
        //     feedback('error', 'Error al actualizar la solicitud')
        //   })
    }

    const handleUploadError = (data) => feedback('error', data.mssg)

    return canUploadPaymentFile && canUpdateApplication ? (
        <Box>
            <FileUploader
                maxSize={PAYMENT_FILE_SIZE}
                applicationId={application['id']}
                budgetId={application['presupuesto_exterior']['id']}
                clientId={application['usuario_id']}
                type="comprobante_pago"
                accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                onSuccess={handleUploadFile}
                onError={(data) => handleUploadError(data)}
            >
                {({loading}) => (
                    <ThemeButton
                        color="primary"
                        size="small"
                        component="span"
                        disabled={loading}
                        startIcon={<PublishOutlinedIcon/>}
                        extra={intl.formatMessage(
                            {
                                id: 'pages.application.detail.payment.uploadMessage',
                            },
                            {size: PAYMENT_FILE_SIZE / 1000000}
                        )}
                    >
                        {intl.formatMessage({
                            id: 'pages.application.detail.paymentBtn.title',
                        })}
                    </ThemeButton>
                )}
            </FileUploader>
        </Box>
    ) : null
}
