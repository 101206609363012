import React, { createContext, useContext } from 'react'

import { useGuildListReducer } from '../hooks'

const GuildListContext = createContext()

export const useGuildListContext = () => useContext(GuildListContext)

export const GuildListProvider = ({ children }) => {
  const guildListValues = useGuildListReducer()

  return <GuildListContext.Provider value={guildListValues}>{children}</GuildListContext.Provider>
}
