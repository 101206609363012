import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { requests } from '../../../../core/ajax'
import { GUILD_IP_ACTION_TYPES } from './constants'
import { loginUser } from '../../../global'
import { useModalContext, ConfirmationDialog } from '../../../../ui'

export const useCompanyGuildIP = (state, dispatch) => {
  const { get, put, deleteCall } = requests
  const { formatMessage } = useIntl()
  const reduxDispatch = useDispatch()
  const {
    actions: { open },
  } = useModalContext()
  const global = useSelector((state) => state.global)
  const company = global.empresa

  const setCurrentGuild = useCallback(
    (guildData) => {
      dispatch({
        type: GUILD_IP_ACTION_TYPES.SET_CURRENT_GUILD,
        payload: guildData,
      })
    },
    [dispatch]
  )

  const joinGuild = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guildIP3.joinDialog.title' }),
        text: formatMessage({ id: 'pages.profile.company.guildIP3.joinDialog.description' }),
        yesText: formatMessage({ id: 'global.continue' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: joinGuildCall,
      },
      type: 'centered',
    })
  }

  const joinGuildCall = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const payload = { recibir_emails_agremiado_ip3: state.notificationsChecked }
        put(`/empresa/${company.id}/asociar/${state.selectedGuild}`, payload)
          .then(() => {
            reduxDispatch(loginUser(global))
            resolve()
          })
          .catch(() => {
            reject()
          })
      }),
    [put, state, company, reduxDispatch, global]
  )

  const leaveGuild = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guildIP3.leaveDialog.title' }),
        text: formatMessage({ id: 'pages.profile.company.guildIP3.leaveDialog.description' }),
        yesText: formatMessage({ id: 'global.continue' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: leaveGuildCall,
      },
      type: 'centered',
    })
  }

  const leaveGuildCall = useCallback(
    () =>
      new Promise((resolve, reject) => {
        deleteCall(`/empresa/${company.id}/asociar/${company.gremio_ip.id}`)
          .then(() => {
            dispatch({ type: GUILD_IP_ACTION_TYPES.RESET })
            reduxDispatch(loginUser(global))
            resolve()
          })
          .catch(() => {
            reject()
          })
      }),
    [deleteCall, dispatch, company, reduxDispatch, global]
  )

  const getIPGuildsList = useCallback(() => {
    get('/instalador/gremios', { params: { grabadora: true } })
      .then(({ data }) => {
        const comboData = data.map((record) => ({
          key: record.id.toString(),
          value: record.nombre,
        }))
        dispatch({
          type: GUILD_IP_ACTION_TYPES.SET_GUILDS_LIST,
          payload: comboData,
        })
      })
      .catch(() => {
        dispatch({
          type: GUILD_IP_ACTION_TYPES.SET_LOADING,
          payload: false,
        })
      })
  }, [get, dispatch])

  const setNotificationsCheck = useCallback(
    (value) => {
      dispatch({
        type: GUILD_IP_ACTION_TYPES.SET_NOTIFICATIONS_CHECKED,
        payload: value,
      })
    },
    [dispatch]
  )

  const changeNotificationsCheck = useCallback(
    (value) => {
      if (!!company.gremio_ip) {
        dispatch({
          type: GUILD_IP_ACTION_TYPES.SET_NOTIF_LOADING,
          payload: true,
        })
        put(`/empresa/${company.id}`, { recibir_emails_agremiado_ip3: value })
          .then(() => {
            setNotificationsCheck(value)
            dispatch({
              type: GUILD_IP_ACTION_TYPES.SET_NOTIF_LOADING,
              payload: false,
            })
          })
          .catch(() => {
            dispatch({
              type: GUILD_IP_ACTION_TYPES.SET_NOTIF_LOADING,
              payload: false,
            })
          })
      } else {
        setNotificationsCheck(value)
      }
    },
    [put, dispatch, company, setNotificationsCheck]
  )

  const selectGuild = useCallback(
    (value) => {
      dispatch({
        type: GUILD_IP_ACTION_TYPES.SET_SELECTED_GUILD,
        payload: value,
      })
    },
    [dispatch]
  )

  const setMandatoryChecked = useCallback(
    (value) => {
      dispatch({
        type: GUILD_IP_ACTION_TYPES.SET_MANDATORY_CHECKED,
        payload: value,
      })
    },
    [dispatch]
  )

  const setError = useCallback(
    (mssg) => {
      dispatch({
        type: GUILD_IP_ACTION_TYPES.SET_ERROR,
        payload: mssg,
      })
    },
    [dispatch]
  )

  return {
    joinGuild,
    leaveGuild,
    setCurrentGuild,
    getIPGuildsList,
    changeNotificationsCheck,
    setNotificationsCheck,
    selectGuild,
    setMandatoryChecked,
    setError,
  }
}
