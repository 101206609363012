import React, { useContext } from 'react'
import { withStyles, Dialog, Box, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useWidth } from '../../../core/utils'
import { ModalContext } from '../contexts'
import { TYPES } from '../constants'
import { modalStyles } from './modal.styles'

export const ModalComponent = withStyles(modalStyles)(({ classes }) => {
  const {
    actions: { close },
    isOpen,
    type,
    Component,
    data,
    maxWidth,
    closable = false,
  } = useContext(ModalContext)

  const isMobileWidth = useWidth() === 'xs'

  return (
    <Dialog
      open={isOpen}
      fullScreen={type === TYPES.FULL_SCREEN || (type === TYPES.RESPONSIVE && isMobileWidth)}
      className={classes[type]}
      maxWidth={maxWidth}
      fullWidth
    >
      {closable ? (
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      {Component ? <Component close={close} {...data} /> : null}
    </Dialog>
  )
})
