import React, { useState } from 'react'
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography, Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { foldingPanelStyles } from './folding-panel.styles'

export const FoldingPanelComponent = withStyles(foldingPanelStyles)(
  ({ classes, isExpanded = false, noPadding, ...props }) => {
    const [expanded, setExpanded] = useState(isExpanded)

    return (
      <div className={classes.panel}>
        <Accordion
          className={classes.folding}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary
            className={classes.foldingHeader}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="h6">{props.title}</Typography>
              {props.headerComponent && props.headerComponent}
            </Box>
          </AccordionSummary>
          <AccordionDetails style={noPadding ? { padding: 0 } : {}}>
            {props.children}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
)
