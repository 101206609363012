//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const TARIFFS_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 300,
  },
  {
    field: 'comunidad',
    align: 'left',
    type: 'date',
    width: 300,
  },
  {
    field: 'provincias',
    align: 'left',
    minWidth: 300,
    width: 400,
  },
]

export const TARIFFS_TABLE_ACTIONS = [
  {
    field: 'active',
    width: 70,
    align: 'center',
    sorting: false,
  },
  {
    field: 'actions',
    width: 130,
    align: 'center',
    sorting: false,
  },
]
