import React from 'react'
import { InputAdornment } from '@material-ui/core'

export const GUILD_FORM_FIELDS = ({ formState, onChange, fm }) => ({
  NOMBRE: {
    required: true,
    name: 'nombre',
    value: formState.nombre,
    title: fm({ id: 'pages.profile.guild.fields.name.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.name.placeholder' }),
    onChange,
  },
  RAZON_SOCIAL: {
    required: true,
    name: 'razon_social',
    value: formState.razon_social,
    title: fm({ id: 'pages.profile.guild.fields.registered_name.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.registered_name.placeholder' }),
    onChange,
  },
  TELEFONO: {
    required: true,
    name: 'telefono',
    value: formState.telefono,
    title: fm({ id: 'pages.profile.guild.fields.telefono.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.telefono.placeholder' }),
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    InputProps: {
      startAdornment: <InputAdornment position="start">+34</InputAdornment>,
    },
  },
  EMAIL: {
    required: true,
    name: 'email',
    value: formState.email,
    title: fm({ id: 'pages.profile.guild.fields.email.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.email.placeholder' }),
    onChange,
    inputProps: { type: 'email' },
  },
  WEB: {
    required: false,
    name: 'web',
    value: formState.web,
    title: fm({ id: 'pages.profile.guild.fields.web.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.web.placeholder' }),
    onChange,
  },
  DIRECCION: {
    required: true,
    name: 'direccion',
    value: formState.direccion,
    title: fm({ id: 'pages.profile.guild.fields.address.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.address.placeholder' }),
    onChange,
  },
  CODIGO_POSTAL: {
    required: true,
    name: 'cod_codigo_postal',
    value: formState.cod_codigo_postal,
    title: fm({ id: 'pages.profile.guild.fields.postal_code_cod.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.postal_code_cod.placeholder' }),
    onChange,
    inputProps: {
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
      maxLength: 5,
    },
  },
  DESCRIPCION: {
    required: true,
    name: 'descripcion',
    value: formState.descripcion,
    title: fm({ id: 'pages.profile.guild.fields.description.title' }),
    placeholder: fm({ id: 'pages.profile.guild.fields.description.placeholder' }),
    onChange,
    multiline: true,
    minRows: 4,
    rowsMax: 4,
    inputProps: { maxLength: 250 },
  },
})
