import React, { useState } from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { ThemeButton } from '../theme-button'

export const ConfirmationDialog = ({
  title = 'Warning:',
  text = 'Are you sure you want to proceed?',
  yesText = 'Yes',
  yesColor = 'primary',
  yesAction = () => {},
  yesAndClose = true,
  noText = 'No',
  noColor = 'default',
  noAction = () => {},
  noAndClose = true,
  close,
}) => {
  const [loading, setLoading] = useState(false)
  const confirmAction = async () => {
    setLoading(true)
    try {
      await yesAction()
      setLoading(false)
      if (yesAndClose) {
        close()
      }
    } catch {
      setLoading(false)
    }
  }
  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary" align="center">
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <ThemeButton
          labelM="sm"
          color={noColor}
          onClick={() => {
            noAction()
            if (noAndClose) {
              close()
            }
          }}
        >
          {noText}
        </ThemeButton>
        <ThemeButton labelM="sm" color={yesColor} loading={loading} onClick={confirmAction}>
          {yesText}
        </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
