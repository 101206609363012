export const privateAreaNavigatioNGuidePanelStyles = (theme) => {
  return {
    headTitleContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: 20,
      paddingLeft: '2em',
      paddingRight: '2em',
      paddingBottom: '1em',
    },
    mainButton: {
      backgroundColor: '#ED7D30',
      color: 'rgb(255,255,255)',
      fontSize: '2em',
      '&:hover': {
        color: '#CCCCCC',
        backgroundColor: '#BE6326',
      },
    },
    guideItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-between',
      minHeight: '15em',
    },

    guideItemSeparator: {
      width: '90%',
      border: '2px solid',
      borderTop: '0px',
      borderRight: '0px',
      borderLeft: '0px',
      borderColor: theme.palette.primary.main,
      justifySelf: 'center',
    },
    link: {
      margin: '0 0.1em',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  }
}
