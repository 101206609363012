export const getISOStringInitDate = (date) => {
  const timestampInit = date.setHours(0, 0, 0)
  const dateInit = new Date(timestampInit)

  const year = dateInit.getFullYear()
  const month =
    dateInit.getMonth() + 1 >= 10 ? dateInit.getMonth() + 1 : '0' + (dateInit.getMonth() + 1)
  const day = dateInit.getDate() >= 10 ? dateInit.getDate() : '0' + dateInit.getDate()

  const newDate = year + '-' + month + '-' + day + 'T00:00:00.000Z'

  return newDate
}
