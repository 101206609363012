import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Grid } from '@material-ui/core'

import { useInspectionsContext } from '../../contexts'
import { IPInspInfoBlock } from './ip-insp-info-block.component'
import { JCAInspInfoBlock } from './jca-insp-info-block.component'

export const InspInfoBlock = () => {
  const { formatMessage } = useIntl()
  const { type } = useInspectionsContext()

  return (
    <>
      <Typography variant="h6" color="textSecondary">
        {formatMessage({ id: 'pages.inspections.inspection.title' })}
      </Typography>
      <Box p={2} mt={2} bgcolor="grey.100" borderRadius={6}>
        <Grid container spacing={2}>
          {type === 'ip' ? <IPInspInfoBlock /> : <JCAInspInfoBlock />}
        </Grid>
      </Box>
    </>
  )
}
