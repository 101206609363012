export const POTENTIAL_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}

export const POTENTIAL_FILTERS = [
  {
    name: 'provincia',
    comboId: 'provincias_comercializadora',
    size: 'small',
    multiple: true,
  },
  {
    name: 'mercado',
    comboId: 'tipo_mercado_comercializadora',
    size: 'small',
    multiple: true,
  },
  {
    name: 'cod_codigo_postal',
    filterType: 'basic-input',
    size: 'small',
  },
]

export const POTENTIAL_COMBOS = ['tipo_mercado_comercializadora']
