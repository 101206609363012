import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { RequestChangesBtn } from '../buttons'
import { APPLICATION_STATES } from '../../constants'
import { ButtonsToEdit, TextInput } from '../../../../ui'
import { useAppsDetail } from '../../hooks'

export const AppInfoBtns = ({ application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { role } = useSelector((state) => state.global)
  const { putApplication } = useAppsDetail()

  const pendingAccept = application['estado'] === APPLICATION_STATES.BUDGET_SENDED.KEY
  const permittedStates = !(
    application['estado'] === APPLICATION_STATES.REJECTED.KEY ||
    application['estado'] === APPLICATION_STATES.REJECTED_2.KEY
  )

  const canUpdateApplication =
    ((role === 'gremio' && application.gremio_id) ||
      (role !== 'gremio' && !application.gremio_id)) &&
    permittedStates

  const [financeCode, setFinanceCode] = useState(application?.codigo_financiacion)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleFinanceCodeChange = (event) => {
    setFinanceCode(event.target.value)
  }

  const handleEditFinanceCode = () => {
    setErrorMessage('')
    setEditMode(true)
  }

  const handleCancelFinanceCode = () => {
    setErrorMessage('')
    setFinanceCode(application?.codigo_financiacion)
    setEditMode(false)
  }
  const handleSaveFinanceCode = (event) => {
    const regex = /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/
    if (regex.test(financeCode)) {
      setLoading(true)
      putApplication(application.id, { codigo_financiacion: financeCode })
        .then(() => {
          getApplication(application.id)
          setEditMode(false)
          setLoading(false)
        })
        .catch((err) => {
          setFinanceCode(application?.codigo_financiacion)
          setLoading(false)
        })
    } else {
      setErrorMessage('El formato del código no es válido. Ejemplo: 202105880547041')
    }
  }

  return (
    <>
      {/* {application?.campana?.financiacion ? ( */}
      {canUpdateApplication ? (
        <ButtonsToEdit
          handleCancel={handleCancelFinanceCode}
          handleSave={handleSaveFinanceCode}
          handleEdit={handleEditFinanceCode}
          editMode={editMode}
          isLoading={loading}
        />
      ) : null}
      <Box display="flex" alignItems="baseline" style={{ marginLeft: '1em' }}>
        {/* <Typography variant="body2">Sujeta a campaña de financiación. </Typography> */}
        <Box pt={2}>
          <TextInput
            value={financeCode || (!editMode && '--')}
            name="codigo_financiacion"
            title={formatMessage({
              id: 'pages.application.detail.panels.2.fields.codigo_financiacion.label',
            })}
            onChange={handleFinanceCodeChange}
            readOnly={!editMode || !canUpdateApplication}
            fontSize="small"
            error={!!errorMessage}
            errorMessage={errorMessage}
            inputProps={{ maxLength: 15 }}
          />
        </Box>
      </Box>
      {/* ) : null} */}
      <Box display="flex" style={{ margin: '0em 1em', alignItems: 'center' }}>
        <Box flex={1}>
          {pendingAccept ? <RequestChangesBtn {...{ application, getApplication }} /> : null}
        </Box>
      </Box>
    </>
  )
}
