import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { CompanyProfileComponent } from '../../company-profile'
import { UserProfileComponent } from '../../user-profile'
import { PasswordProfile } from '../../password-profile'

export const ProfileRouter = () => {
  return (
    <Switch>
      <Route path="/profile/user" component={UserProfileComponent} />
      <Route path="/profile/change-password" component={PasswordProfile} />
      <Route path="/profile/company" component={CompanyProfileComponent} />
      <Redirect from="*" to="/profile/user" />
    </Switch>
  )
}
