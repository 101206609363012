import React, { useEffect } from 'react'
import { Box, Checkbox, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { AutocompleteInput, ThemeButton, TextInput } from '../../../../ui'
import { useProfileDialogs, useProfileCalls } from '../../hooks'
import { useGuildsCalls } from '../../../guilds/hooks'
import { useGuildListContext } from '../../../guilds/contexts/guild-list.context'
import { loginUser } from '../../../global'
import { GUILD_INFO } from '../../constants'
import { companyGuildStyles } from './company-guild.styles'

export const CompanyGuild = withStyles(companyGuildStyles)(
  ({ classes, empresa_id, readOnly = false }) => {
    const {
      confirmLeaveGuild,
      joinedGuild,
      authorizationToHandleMyDemands,
      disavowalToHandleMyDemands,
    } = useProfileDialogs()
    const intl = useIntl()
    const dispatch = useDispatch()

    const { joinGuild, leaveGuild } = useGuildsCalls()
    const { updateEmpresaCA } = useProfileCalls()
    const {
      state: { data: guildList },
    } = useGuildListContext()
    const [chosenGuild, setChosenGuild] = React.useState(null)
    const [chosenMails, setChosenMails] = React.useState(false)
    const [chosenPermit, setChosenPermit] = React.useState(false)
    const [chosenDemandas, setChosenDemandas] = React.useState(false)
    const global = useSelector((state) => state.global)
    const model =
      useSelector((state) => state.global.empresa_cc_aa)?.find((el) => el.id === empresa_id) || {}

    const guildData = model.gremio_id ? guildList.filter((el) => el.id === model.gremio_id)[0] : {}
    const data = {
      ...GUILD_INFO({ data: { ...guildData, status: model.aceptacion_gremio }, intl }),
    }

    const cleanup = () => {
      setChosenGuild(null)
      setChosenPermit(false)
      setChosenMails((model.gremio_id && model.recibir_mails_solicitud_agremiado) || false)
      setChosenDemandas((model.gremio_id && model.gremio_recibe_demandas) || false)
    }

    useEffect(() => {
      cleanup()
    }, [model.gremio_id, empresa_id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div data-role="company guild manager" className={classes.root}>
        {model.gremio_id ? (
          <div data-role="guild info" style={{ width: '100%' }}>
            <Typography variant="h6" color="primary">
              {intl.formatMessage({ id: 'pages.profile.company.guild.info.header' })}
            </Typography>
            <Box display="flex" mt={2}>
              {['NOMBRE', 'TELEFONO', 'STATUS'].map((entry) => {
                const el = data[entry]
                return <TextInput {...{ ...el, key: entry, readOnly: true }} />
              })}
            </Box>
            {!readOnly ? (
              <>
                <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
                  <Checkbox
                    {...{
                      ...data['MAIL_CHOICE'],
                      checked: chosenMails,
                      onChange: (e) => {
                        const newChosenMails = !chosenMails
                        setChosenMails(newChosenMails)
                        updateEmpresaCA(model.id, {
                          recibir_mails_solicitud_agremiado: newChosenMails,
                        }).then(() => dispatch(loginUser(global)))
                      },
                    }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {intl.formatMessage({
                      id: 'pages.profile.company.guild.selector.mail.checkbox',
                    })}
                  </Typography>
                </Box>
                <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
                  <Checkbox
                    {...{
                      ...data['DEMANDA_CHOICE'],
                      checked: chosenDemandas,
                      onChange: (e) => {
                        const newChosenDemandas = !chosenDemandas
                        const methodToCall = newChosenDemandas
                          ? authorizationToHandleMyDemands
                          : disavowalToHandleMyDemands
                        methodToCall(() => {
                          setChosenDemandas(newChosenDemandas)
                          updateEmpresaCA(model.id, {
                            gremio_recibe_demandas: newChosenDemandas,
                          }).then(() => dispatch(loginUser(global)))
                        })
                      },
                    }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {intl.formatMessage({
                      id: 'pages.profile.company.guild.selector.demandas.checkbox',
                    })}
                  </Typography>
                </Box>
                <ThemeButton
                  type="button"
                  data-role="leave guild"
                  onClick={() =>
                    confirmLeaveGuild(() => {
                      leaveGuild(model.id, model.gremio_id)
                        .then(() => {
                          dispatch(loginUser(global))
                        })
                        .catch(() => console.debug('error in updating user'))
                    })
                  }
                >
                  {intl.formatMessage({ id: 'pages.profile.company.guild.info.abandon.title' })}
                </ThemeButton>
              </>
            ) : null}
          </div>
        ) : (
          <div data-role="guild selector">
            <Typography variant="h6" color="primary" style={{ marginBottom: '1em' }}>
              {intl.formatMessage({ id: 'pages.profile.company.guild.selector.header' })}
            </Typography>
            <AutocompleteInput
              title="Lista de asociaciones:"
              data-role="id_gremio"
              name="id_gremio"
              optionKey="nombre"
              values={guildList}
              value={chosenGuild}
              onChange={(choice) => {
                setChosenGuild(choice.value)
                setChosenPermit(false)
              }}
            />
            <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
              <Checkbox
                {...{
                  ...data['PERMIT'],
                  checked: chosenPermit,
                  disabled: !chosenGuild,
                  onChange: () => setChosenPermit((value) => !value),
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage({ id: 'pages.profile.company.guild.selector.permit.checkbox' })}
              </Typography>
            </Box>
            <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
              <Checkbox
                {...{
                  ...data['MAIL_CHOICE'],
                  checked: chosenMails,
                  disabled: !chosenGuild,
                  onChange: () => setChosenMails((value) => !value),
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage({ id: 'pages.profile.company.guild.selector.mail.checkbox' })}
              </Typography>
            </Box>
            <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
              <Checkbox
                {...{
                  ...data['DEMANDA_CHOICE'],
                  checked: chosenDemandas,
                  disabled: !chosenGuild,
                  onChange: () => setChosenDemandas((value) => !value),
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {intl.formatMessage({
                  id: 'pages.profile.company.guild.selector.demandas.checkbox',
                })}
              </Typography>
            </Box>
            <ThemeButton
              disabled={!chosenGuild || !chosenGuild.id || !chosenPermit}
              type="button"
              data-role="subscribe"
              onClick={() => {
                const guild_id = chosenGuild.id
                joinGuild(model.id, guild_id, {
                  recibir_mails_solicitud_agremiado: chosenMails,
                  gremio_recibe_demandas: chosenDemandas,
                }).then(() => {
                  joinedGuild(() => {
                    dispatch(loginUser(global))
                  })
                })
              }}
            >
              {intl.formatMessage({ id: 'pages.profile.company.guild.selector.join.title' })}
            </ThemeButton>
          </div>
        )}
      </div>
    )
  }
)
