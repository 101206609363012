export const USERS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}

export const USERS_FILTERS = [
  {
    name: 'activo',
    filterType: 'switch',
  },
  {
    name: 'rol',
    comboId: 'roles',
    size: 'small',
    multiple: true,
  },
]
