import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { requests } from '../../../../core/ajax'
import { CAPTACION_ROLES } from '../../../../core/constants'
import { feedback } from '../../../../core/feedback'
import { setUsers } from '../../slices'
import { UsersForm } from '../form'

export const UsersCreateDialogComponent = ({ close }) => {
  const { post } = requests
  const dispatch = useDispatch()
  const empresa_id = useSelector((state) => state.global['empresa_id'])
  const searchParams = useSelector((state) => state.users.search)
  const adminRole = useSelector((state) => state.global.role)
  const isCaptadorAdmin = CAPTACION_ROLES[adminRole]

  const [formState, setFormState] = useState({
    // DR: If the admin is grabador only, the created/edited users can only be admin grabador.
    rol: !isCaptadorAdmin ? adminRole : null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleCreateUser = (event) => {
    event.preventDefault()
    const payload = {
      ...formState,
      active: true,
      empresa_id: Number(empresa_id),
    }
    if (!formState.email.replace(/\s/g, '').length) {
      feedback('warning', 'Introduce un email')
    } else {
      setIsLoading(true)
      post('/instalador', payload)
        .then(() => {
          setIsLoading(false)
          feedback('success', 'Usuario creado')
          dispatch(setUsers(searchParams, empresa_id))
          close()
        })
        .catch(({ response }) => {
          feedback('error', response.data.message || 'No se ha podido crear el usuario')
          setIsLoading(false)
        })
    }
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="textSecondary">
            Crear usuario
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <UsersForm
          {...{
            formState,
            close,
            onSubmit: handleCreateUser,
            onChange: handleChangeForm,
            isLoading,
          }}
        />
      </DialogContent>
    </Box>
  )
}
