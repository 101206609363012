export const GUILDS_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 200,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  {
    field: 'aceptacion_gremio',
    align: 'left',
    width: 120,
  },
  {
    field: 'gremio_recibe_demandas',
    align: 'left',
    width: 180,
    tooltip:
      'Se autoriza a la Asociación/Gremio a la visualización y gestión de las demandas que se generen a través de la plataforma Marketgas de la distribuidora Nedgia.',
  },
  {
    field: 'razon_social',
    align: 'left',
    width: 150,
  },
  {
    field: 'email',
    align: 'left',
    width: 250,
  },
  {
    field: 'telefono',
    align: 'left',
    width: 120,
  },
  {
    field: 'comunidad_autonoma',
    align: 'left',
    width: 200,
    comboId: 'cod_zona_zeus',
  },
  {
    field: 'direccion',
    align: 'left',
    width: 250,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  {
    field: 'cod_codigo_postal',
    align: 'left',
    width: 140,
  },
  {
    field: 'descripcion',
    align: 'left',
    width: 350,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
]

export const GUILD_TABLE_STATES = {
  accepted: 'Confirmado',
  pending: 'Pendiente',
  yes: 'Sí',
  no: 'No',
}
