import React, { createContext, useContext, useReducer } from 'react'

import { useCompanyProfileActions } from '../hooks'
import { companyProfileReducer } from '../reducers'
import { COMPANY_PROFILE_INITIAL_STATE } from '../constants'

const CompanyProfileContext = createContext()

export const useCompanyProfileContext = () => useContext(CompanyProfileContext)

export const CompanyProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(companyProfileReducer, COMPANY_PROFILE_INITIAL_STATE)

  const actions = useCompanyProfileActions(state, dispatch)

  return (
    <CompanyProfileContext.Provider value={{ ...state, ...actions }}>
      {children}
    </CompanyProfileContext.Provider>
  )
}
