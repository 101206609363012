export const marketerPotentialsDataPreparation = (columns, data, { intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    columns.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
    })
    return {
      ...item,
      ...formattedDates,
      tableData: {
        ...item.tableData,
      },
    }
  })
}
