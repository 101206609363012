export const JCA_ACTIONS_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_JCA: 'SET_JCA',
}

export const JCA_INITIAL_STATE = {
  loading: true,
  error: false,
  jca: {},
}
