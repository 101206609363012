export const pwdRecoveryStyles = (theme) => ({
  messageWrapper: {
    textTransform: 'none',
    marginTop: -10,
  },
  message: {
    color: '#004165',
    fontFamily: 'FSEmeric-Medium',
    marginLeft: 4,
    '&:hover': { textDecoration: 'underline' },
  },
  link: {
    margin: '0 0.1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})
