import { PUBLICOFFER_STATES } from './public-offer-states.constants'

export const PENDING_APPS = {
  [PUBLICOFFER_STATES.PENDING.KEY]: true,
  [PUBLICOFFER_STATES.PENDING_CODE.KEY]: true,
  [PUBLICOFFER_STATES.PENDING_FINISH.KEY]: true,
  [PUBLICOFFER_STATES.ZEUS_AD.KEY]: true,
  [PUBLICOFFER_STATES.CREATED.KEY]: true,
}

export const REJECTED_APPS = {
  [PUBLICOFFER_STATES.REJECTED.KEY]: true,
  [PUBLICOFFER_STATES.ANULED.KEY]: true,
}

export const ACCEPTED_APPS = {
  [PUBLICOFFER_STATES.ACCEPTED.KEY]: true,
}

export const OUTRANGED_APPS = {
  [PUBLICOFFER_STATES.OUTRANGED.KEY]: true,
}

export const NOT_PLAYED = {
  [PUBLICOFFER_STATES.null]: true,
}
