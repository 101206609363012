import React from 'react'

import { JCADetailProvider } from './contexts'
import { JCADetail } from './components'

export const JCADetailWrapper = () => (
  <JCADetailProvider>
    <JCADetail />
  </JCADetailProvider>
)
