import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ButtonGroup, Button, Typography } from '@material-ui/core'

import { PROFILE_TABS_CONFIG } from './profile-tabs.constants'

export const ProfileTabs = () => {
  const intl = useIntl()
  const history = useHistory()
  const currentTabId = history.location.pathname.split('/').reverse()[0]

  const handleChange = (tabId) => history.push(`/profile/${tabId}`)

  const tabs = PROFILE_TABS_CONFIG({ intl })

  return (
    <ButtonGroup size="large" color="primary" style={{ backgroundColor: 'white' }}>
      {tabs.map((tab) => {
        return (
          <Button
            key={tab.id}
            variant={tab.id === currentTabId ? 'contained' : 'outlined'}
            onClick={() => handleChange(tab.id)}
            style={{ lineHeight: 1 }}
          >
            <Typography variant="subtitle2">{tab.label}</Typography>
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
