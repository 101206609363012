import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../../ui'
import { useWizardContext } from '../contexts'

export const WizardView = ({ children, onNext, nextLabel, title, ...restProps }) => {
  const { step, setStep, total } = useWizardContext()
  const { formatMessage } = useIntl()

  const handlePrevious = () => (step ? setStep(step - 1) : null)

  const handleNext = (e) => {
    e.preventDefault()
    onNext ? onNext({ step, setStep }) : setStep(step + 1)
  }

  return (
    <Box>
      <Box textAlign="center" mb={3}>
        <Typography variant="h5" color="primary" gutterBottom>
          {`${step + 1}. ${title}`}
        </Typography>
        {restProps.subtitle && (
          <Typography variant="body1" color="textSecondary">
            {restProps.subtitle}
          </Typography>
        )}
      </Box>
      <Box pl={{ sm: 1, md: 4 }} pr={{ sm: 1, md: 4 }}>
        <form onSubmit={handleNext}>
          <Box mb={3}>{children}</Box>
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            <Box
              mr={{ xs: 0, sm: 2 }}
              mb={{ xs: 2, sm: 0 }}
              width={{ xs: '100%', sm: 'auto' }}
              textAlign="center"
            >
              {step !== 0 && (
                <ThemeButton
                  labelM="lg"
                  color="default"
                  variant="outlined"
                  onClick={handlePrevious}
                  data-role="prevButton"
                >
                  {formatMessage({ id: 'global.previous' })}
                </ThemeButton>
              )}
            </Box>
            <ThemeButton
              labelM="lg"
              color="secondary"
              type="submit"
              loading={restProps.isLoading}
              data-role="nextButton"
            >
              {step === total - 1
                ? formatMessage({ id: 'global.accept' })
                : formatMessage({ id: 'global.next' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
