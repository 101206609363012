import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'

import { TariffForm } from '../form'
import { PageDisplayer, InfoBox } from '../../../../ui'
import { useTariffCalls } from '../../hooks'
import { DEFAULT_FORM_VALUES } from '../../constants'
import { tariffNewStyles } from './tariff-new.style'

export const TariffNew = withStyles(tariffNewStyles)(({ classes }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const { newTariff, getTariffNameUnicity } = useTariffCalls()
  const empresa_id = useSelector((state) => state.global.empresa_cc_aa)[0].id_empresa
  const acceptsApplications = useSelector((state) => state.global.empresa.publica)

  const [tariff, setTariff] = useState({ ...DEFAULT_FORM_VALUES, empresa_id })

  const handleGoBack = () => {
    history.push(`/tariffs`)
  }

  const handleChange = (e) => {
    setTariff({
      ...tariff,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheckName = () => {
    return new Promise((resolve, reject) => {
      getTariffNameUnicity(tariff.nombre, tariff.empresa_id).then((response) => {
        if (response) {
          resolve(response.data)
        }
        return reject('Name exists')
      })
    })
  }

  const handleSubmit = () =>
    new Promise((resolve) => {
      handleCheckName()
        .then(() => newTariff(tariff))
        .then(({ data }) => {
          const obtainedId = parseInt(data.id_tarifa)
          history.replace('/tariffs/' + obtainedId + '/edit')
          resolve(obtainedId)
        })
        .catch((code) => {
          if (code === 400) {
            console.debug('Known error')
          }
        })
    })

  const handleGoToZoneConfig = () => {
    handleSubmit().then((obtainedId) => {
      history.push('/tariffs/' + obtainedId + '/zones')
    })
  }

  const inputProps = { formState: tariff, onChange: handleChange, intl }

  return (
    <PageDisplayer
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.new.back' })}
    >
      <InfoBox
        severity="warning"
        message={formatMessage({ id: 'pages.tariffs.alerts.1.description' })}
        hidden={acceptsApplications}
      />
      <TariffForm
        {...{
          inputProps,
          submit: handleSubmit,
          goToZoneConfig: handleGoToZoneConfig,
          checkName: handleCheckName,
        }}
      />
    </PageDisplayer>
  )
})
