export const APP_EVENTS_TABLE_CONFIG = (intl) => [
  {
    key: 'dg_ts_insert',
    label: intl.formatMessage({
      id: 'pages.application.detail.history.tableFields.dg_ts_insert.title',
    }),
    type: 'datetime',
  },
  {
    key: 'mensaje',
    label: intl.formatMessage({ id: 'pages.application.detail.history.tableFields.mensaje.title' }),
  },
  {
    key: 'tipo_usuario',
    label: intl.formatMessage({ id: 'pages.application.detail.history.tableFields.usuario.title' }),
    type: 'user',
  },
]
