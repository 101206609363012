import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { GuildUsersDashboard } from '../../../features/guild-users'
import { USERS_COMBOS_IDS } from '../../../pages/guild-users/constants'
import { useCombos } from '../../../features/combos'

export const GuildUsersPage = ({ classes = {} }) => {
  useCombos(USERS_COMBOS_IDS)

  return (
    <Switch>
      <Route exact path="/guildUsers" component={GuildUsersDashboard} />
      <Redirect from="*" to="/guildUsers" />
    </Switch>
  )
}
