import React, { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'

import { AcceptationDialog, ConfirmationDialog, ViewPDFDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'
import { useProfileCalls } from './profile-calls.hook'
import { feedback } from '../../../core/feedback'

export const useProfileDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const { getDocumentById } = useProfileCalls()

  const confirmLeaveGuild = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.title' }),
        text: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.text' }),
        yesText: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.yes' }),
        noText: formatMessage({ id: 'pages.profile.company.guild.warning.abandon.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const joinedGuild = (callback) => {
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.warning.join.title' }),
        text: formatMessage({ id: 'pages.profile.company.guild.warning.join.text' }),
        yesText: formatMessage({ id: 'pages.profile.company.guild.warning.join.yes' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const openGeneralCondition = useCallback((fileId, callback) => {
    getDocumentById(fileId)
      .then((data) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        open({
          Component: ViewPDFDialog,
          data: {
            file,
            setDisabled: callback,
            shortcutEnabled: true,
          },
          type: 'fullScreen',
        })
      })
      .catch((error) => {
        console.debug(error)
        feedback('error', 'No se encuentra el documento')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const authorizationToHandleMyDemands = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.warning.demands.title' }),
        text: formatMessage({
          id: 'pages.profile.company.guild.warning.demands.authorization.text',
        }),
        yesText: formatMessage({ id: 'pages.profile.company.guild.warning.demands.yes' }),
        noText: formatMessage({ id: 'pages.profile.company.guild.warning.demands.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const disavowalToHandleMyDemands = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.guild.warning.demands.title' }),
        text: formatMessage({ id: 'pages.profile.company.guild.warning.demands.disavowal.text' }),
        yesText: formatMessage({ id: 'pages.profile.company.guild.warning.demands.yes' }),
        noText: formatMessage({ id: 'pages.profile.company.guild.warning.demands.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const warnWrongCifTolerant = (callback) => {
    const NEDGIA_COLABORADORES_EMAIL_LINK = 'mailto:canal_oferta_publica@nedgia.es'
    const NEDGIA_COLABORADORES_EMAIL = 'canal_oferta_publica@nedgia.es'
    const textComponent = (
      <>
        <Typography paragraph>
          {formatMessage({ id: 'pages.signup.notify.wrongCifTolerant.description.1' })}
        </Typography>
        {formatMessage({
          id: 'pages.signup.notify.wrongCifTolerant.description.2',
        })}{' '}
        <a target="_blank" href={NEDGIA_COLABORADORES_EMAIL_LINK} rel="noopener noreferrer">
          {NEDGIA_COLABORADORES_EMAIL}
        </a>{' '}
        {formatMessage({
          id: 'pages.signup.notify.wrongCifTolerant.description.3',
        })}
        <ul>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.1',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.2',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.3',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.4',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.5',
            })}
          </li>
          <li>
            {formatMessage({
              id: 'pages.signup.notify.wrongCifTolerant.description.list.6',
            })}
          </li>
        </ul>
        <Typography paragraph>
          {formatMessage({
            id: 'pages.signup.notify.wrongCifTolerant.description.4',
          })}
        </Typography>
      </>
    )
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.notify.wrongCif.title' }),
        textComponent,
        yesText: formatMessage({ id: 'pages.signup.notify.wrongCif.yes' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const confirmNotSavedChanges = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.company.confirmationModal.title' }),
        text: formatMessage({ id: 'pages.profile.company.confirmationModal.description' }),
        yesText: formatMessage({ id: 'pages.profile.company.confirmationModal.accept' }),
        noText: formatMessage({ id: 'pages.profile.company.confirmationModal.cancel' }),
        yesAction: callback,
      },
      type: 'responsive',
    })
  }

  return {
    openGeneralCondition,
    confirmLeaveGuild,
    joinedGuild,
    authorizationToHandleMyDemands,
    disavowalToHandleMyDemands,
    warnWrongCifTolerant,
    confirmNotSavedChanges,
  }
}
