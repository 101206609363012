import React, { useMemo, useState } from 'react'

import { WizardProvider } from '../contexts'

export const WizardComponent = ({ stepViews }) => {
  const [step, setStep] = useState(0)
  const StepView = stepViews[step]

  return useMemo(
    () =>
      StepView ? (
        <WizardProvider value={{ step, setStep, total: stepViews.length }}>
          <div style={{ width: '100%' }}>
            <StepView />
          </div>
        </WizardProvider>
      ) : (
        ''
      ),
    [StepView, step, stepViews.length]
  )
}
