import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { CLIENT_IBAN_FIELDS } from '../../constants'
import { feedback } from '../../../../core/feedback'
import { ThemeButton, TextInput } from '../../../../ui'
import { isValidIban } from '../../../../core/utils'

export const EditClientIBANDialog = ({ close, application, getApplication, putApplication }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const defaultFormState = {
    iban: application.iban || '',
    titular_iban: application.titular_iban || '',
  }

  const [formState, setFormState] = useState(defaultFormState)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrorMssg('')
    if (!isValidIban(formState.iban)) {
      return setErrorMssg('El formato del IBAN no es válido.')
    } else {
      setLoading(true)
      const payload = { ...formState, iban: formState.iban.split(' ').join('') }
      putApplication(application.id, payload)
        .then(() => {
          getApplication(application.id)
          setLoading(false)
          close()
        })
        .catch((err) => {
          feedback('error', 'No se han podido guardar los cambios')
          setLoading(false)
        })
    }
  }

  const formFields = CLIENT_IBAN_FIELDS({ intl, formState, onChange: handleChange })

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5" color="textSecondary">
            {formatMessage({ id: 'pages.application.detail.editClientIBAN.dialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput {...formFields.IBAN} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...formFields.TITULAR_IBAN} />
            </Grid>
          </Grid>
          {errorMssg && (
            <Box mt={1} bgcolor="grey">
              <Alert severity="error">{errorMssg}</Alert>
            </Box>
          )}
          <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
            <Box mb={[3, 0]} p="0 5px">
              <ThemeButton onClick={close} variant="outlined" labelM="sm" disabled={loading}>
                {intl.formatMessage({ id: 'global.cancel' })}
              </ThemeButton>
            </Box>
            <Box p="0 5px">
              <ThemeButton
                type="submit"
                color="primary"
                labelM="sm"
                loading={loading}
                disabled={loading}
              >
                {intl.formatMessage({ id: 'global.save' })}
              </ThemeButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Box>
  )
}
