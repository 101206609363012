export const viewPdfDialogStyles = (theme) => ({
  pdfWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  pdfButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  pdfButton: {
    margin: '0 10px',
  },
})
