import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {DialogTitle, DialogContent, Box, Typography} from '@material-ui/core'

import {ThemeButton, BasicListItem} from '../../../../ui'

export const ValidateInspDialog = ({
                                     close,
                                     submitInspection,
                                     abortInspection,
                                     openNonconformity,
                                     formState,
                                     addresses,
                                     currentStep,
                                     type,
                                     onClose,
                                   }) => {
  const {formatMessage} = useIntl()

  const [loading, setLoading] = useState(false)

  const DIALOG_MESSAGES = {
    title: formatMessage({id: 'pages.inspections.validateInspDialog.title'}),
    description1: formatMessage({
      id: `pages.inspections.validateInspDialog.${type}.description.1`,
    }),
    description2: formatMessage({
      id: `pages.inspections.validateInspDialog.${type}.description.2`,
    }),
    description3: formatMessage({
      id: `pages.inspections.validateInspDialog.${type}.description.3`,
    }),
    description4: formatMessage({
      id: `pages.inspections.validateInspDialog.${type}.description.4`,
    }),
    description5: formatMessage({
      id: `pages.inspections.validateInspDialog.${type}.description.5`,
    }),
    buttons: {
      cancel: formatMessage({id: 'pages.inspections.validateInspDialog.cancel'}),
      reject: formatMessage({id: `pages.inspections.validateInspDialog.reject`}),
      nonconformity: formatMessage({id: `pages.inspections.validateInspDialog.nonconformity`}),
      confirm: formatMessage({id: 'pages.inspections.validateInspDialog.confirm'}),
    },
  }

  const handleCancel = () => {
    onClose()
    close()
  }

  const handleSubmit = () => {
    setLoading(true)
    submitInspection({closeModal: close})
      .then(() => {
        setLoading(false)
        close()
        onClose()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const dataPanelCUPS = (
    <Box>
      <Typography variant="body1">{DIALOG_MESSAGES.description1}</Typography>
      <Box mt={2} mb={2} p={1} bgcolor="grey.100" borderRadius={6}>
        <BasicListItem
          {...{
            label: 'Titular',
            value: formState.nombre_titular + " " + (formState.apellido_1_titular ? formState.apellido_1_titular : '') + " " + (formState.apellido_2_titular ? formState.apellido_2_titular : '')
          }}
        />
        <BasicListItem
          {...{
            label: 'Dirección',
            value:
              `${addresses[0].tipo_via || ''} ${addresses[0].calle || ''} ${
                addresses[0].numero_finca || ''
              }, ${addresses[0].municipio || ''} (${addresses[0].cp || ''}), ${
                addresses[0].provincia || ''
              }` +
              `${addresses[0].portal ? `, Portal: ${addresses[0].portal}` : ''}` +
              `${addresses[0].escalera ? `, Escalera: ${addresses[0].escalera}` : ''}` +
              `${addresses[0].piso ? `, Piso: ${addresses[0].piso}` : ''}` +
              `${addresses[0].puerta ? `, Puerta: ${addresses[0].puerta}` : ''}`,
          }}
        />
        <BasicListItem
          {...{
            label: 'Tipo de instalación',
            value: formState.tipo_instalacion + (formState.tipo_instalacion === 'IRI' ? ` (DOMESTICO ${formState.potencia}KW)` : ''),
          }}
        />
        <BasicListItem {...{label: 'Tipo de gas', value: formState.tipo_de_gas}} />
        {formState.comercializadora && (
          <BasicListItem {...{label: 'Comercializadora', value: formState.comercializadora?.key}} />
        )}
        <BasicListItem {...{label: 'Distribuidora', value: formState.sociedad}} />
      </Box>
      {type === 'jca' && !formState.nueva_direccion ?
        addresses.map((item, i) => (
          <>
            {addresses.length !== 1 && (<Typography gutterBottom variant="h6" color="textSecondary">
            {item.cod_derivacion}
          </Typography>)}

            {!item.anomalias.length ? (
          <BasicListItem {...{label: 'Anomalías', value: 'Sin anomalías'}} />
        ) : (
          <>
            <Typography gutterBottom variant="body1" color="textSecondary">
              Anomalías:
            </Typography>
            <Box pl={2}>
              {item.anomalias.map((a, i) => (
                <Typography key={i} gutterBottom variant="body1">
                  - {a.value}
                </Typography>
              ))}
            </Box>
          </>

        )}
          </>
        )) : null}
      <br/>
      <Typography variant="body1" paragraph>
        {DIALOG_MESSAGES.description2}
      </Typography>
    </Box>
  )

  const dataPanelIRC = () => (
    <Box mb={2}>
      <Typography variant="body1" gutterBottom>
        {DIALOG_MESSAGES.description3}
      </Typography>
      <Box mb={2}>
        {addresses.map((item, i) => (
          <Box key={i} display="flex">
            <Box p={1} mt={1} bgcolor="grey.100" borderRadius={6} flex={1}>
              <Typography variant="body2">{item['cod_derivacion']}</Typography>
              <Typography variant="body2" color="textSecondary">
                {`${item['yipo_via'] || ''} ${item['calle'] || ''} ${item['numero_finca'] || ''}, ${
                    item['municipio'] || ''
                  } (${item['cp'] || ''}), ${item['provincia'] || ''}` +
                  '\n' +
                  `${item['portal'] ? ', Portal: ' + item['portal'] : ''}` +
                  '\n' +
                  `${item['escalera'] ? ', Escalera: ' + item['escalera'] : ''}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Nº CUPS: {item['cups'] || 'No hay información'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item['tipo_gas']}
              </Typography>
              {type === 'jca' ? (
                !item.anomalias.length ? (
                  <Typography variant="body2" color="textSecondary">
                    Anomalías: No hay información
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Anomalías:
                    </Typography>
                    <Box pl={2}>
                      {item.anomalias.map((a) => (
                        <Typography variant="body2" color="textSecondary">
                          - {a.value}
                        </Typography>
                      ))}
                    </Box>
                  </>
                )
              ) : null}
            </Box>
          </Box>
        ))}
      </Box>
      <Typography variant="body1" paragraph>
        {DIALOG_MESSAGES.description4}
      </Typography>
    </Box>
  )

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {DIALOG_MESSAGES.title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {currentStep === 0 ? (
          dataPanelCUPS
        ) : (
          <Typography variant="body1">{DIALOG_MESSAGES.description5}</Typography>
        )}
        <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
          <Box width={150} mb={[3, 0]}>
            <ThemeButton onClick={handleCancel} color="inherit" disabled={loading} fullWidth>
              {DIALOG_MESSAGES.buttons.cancel}
            </ThemeButton>
          </Box>
          <Box mb={[3, 0]}>
            {currentStep === 0 &&
            ((type === 'ip' && formState.tipo_instalacion === 'IRC') || type === 'jca') ? (
              <ThemeButton onClick={openNonconformity} variant="outlined" disabled={loading}>
                {DIALOG_MESSAGES.buttons.nonconformity}
              </ThemeButton>
            ) : (
              <ThemeButton onClick={abortInspection} variant="outlined" disabled={loading}>
                {DIALOG_MESSAGES.buttons.reject}
              </ThemeButton>
            )}
          </Box>
          <Box width={150}>
            <ThemeButton
              onClick={handleSubmit}
              color="primary"
              fullWidth
              loading={loading}
              disabled={loading}
            >
              {DIALOG_MESSAGES.buttons.confirm}
            </ThemeButton>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  )
}
