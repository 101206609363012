import XLSX from 'xlsx'

export const encodeToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let result = ''
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      result = e.target.result
      resolve(result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })
}

const getCompleteFileType = (type) => {
  switch (type) {
    case 'pdf':
      return `application/${type}`
    case 'jpg':
    case 'jpeg':
    case 'png':
      return `image/${type}`
    case 'xls':
      return type
    default:
      return `application/${type}`
  }
}
export const isACorrectFormat = (filename, acceptedFormats) => {
  let acceptedExtensions = acceptedFormats.map((item) => item.split('/')[1])
  let filenameAndExtension = filename.toLowerCase().split('.')
  // without filename or extension
  if (filenameAndExtension.length === 1) {
    return false
  }
  let extension = filenameAndExtension[filenameAndExtension.length - 1]
  return acceptedExtensions.indexOf(extension) > -1
}

export const covertStrToBlob = (str, fileExtension) => {
  const hexStr = str.slice(2)
  const buf = new ArrayBuffer(hexStr.length / 2)
  const byteBuf = new Uint8Array(buf)
  for (let i = 0; i < hexStr.length; i += 2) {
    byteBuf[i / 2] = parseInt(hexStr.slice(i, i + 2), 16)
  }
  const blob = new Blob([byteBuf], { type: getCompleteFileType(fileExtension) })
  return blob
}

export const downloadFile = (data, fileExtension, fileName) => {
  const base64Data = data.split("'")[1]
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = `data:${getCompleteFileType(fileExtension)};base64,${base64Data}`
  link.download = `${fileName}.${fileExtension}`
  // link.rel = 'noopener noreferrer'
  // document.body.appendChild(link)
  link.click()
}

export const getFormattedTime = () => {
  var today = new Date()
  var y = today.getFullYear()
  var m = today.getMonth() + 1 // JavaScript months are 0-based
  var d = today.getDate()
  var h = today.getHours()
  var mi = today.getMinutes()
  return d + '_' + m + '_' + y + '_' + h + '_' + mi
}
const getComboOption = (item, column, combos) => {
  const comboOption =
    column.comboId &&
    combos[column.comboId] &&
    combos[column.comboId].data.find(({ key }) => key === item[column.field])
  return comboOption
}
export const getFormatedValue = (item, column, combos, intl) => {
  switch (column.exportType) {
    case 'date': {
      return intl.formatDate(new Date(item[column.field]))
    }
    case 'combo': {
      const comboOption = getComboOption(item, column, combos)
      return comboOption ? comboOption.value : item[column.field]
    }
    case 'custom_combo': {
      const comboOption = getComboOption(item, column, combos)
      return comboOption ? comboOption.value : '' // if the column values with the combo not match. Show nothing.
    }
    default: {
      return item[column.field]
    }
  }
}

export const capitalizeStr = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (!word.length) {
        return ''
      }
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

export const dataToXLS = (data, columns, combos, intl, from, fileName) => {
  const exportableColumns = columns.filter(
    (column) => !column.hasOwnProperty('exportable') || column.exportable
  )

  const headerRow = exportableColumns.map((column) => {
    return intl.formatMessage({
      id: `pages.${from}.table.columns.${column.field}.title`,
    })
  })

  const restRows = data.map((record) => {
    let rowArray = []
    exportableColumns.forEach((column) => {
      let value = ''
      if (record[column.field]) {
        value = getFormatedValue(record, column, combos, intl)
      } else {
        value = record[column.field]
      }
      rowArray.push(value)
    })
    return rowArray
  })

  const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...restRows])
  const new_workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
  XLSX.writeFile(new_workbook, fileName, {
    bookType: 'xlsx',
    type: 'binary',
  })
}
