import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'
import { useTypingRefresh } from '../../../../core/utils/useTypingRefresh'
import { TIMEOUT_REFRESH } from '../../constants'

export const ChooseClonedNameDialog = ({ close, name, checkName, save }) => {
  const [nameClone, setNameClone] = useState(name)
  const [nameExists, setNameExists] = useState(false)
  const { formatMessage } = useIntl()

  const { ref: textRef } = useTypingRefresh({
    timeout: TIMEOUT_REFRESH,
    action: (value) => {
      if (!value) {
        return
      }
      checkName(nameClone).then((response) => {
        setNameExists(response)
      })
    },
    textFromRef: (ref) => (ref.current ? ref.current.querySelector('input') : null),
  })

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.tariffs.dialog.name' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent ref={textRef}>
        <TextInput
          name="name"
          type="text"
          value={nameClone}
          onChange={(event) => {
            setNameExists(false)
            setNameClone(event.target.value)
          }}
          error={nameExists}
          helperText={
            nameExists ? formatMessage({ id: 'pages.tariffs.form.name.unicity.error' }) : ''
          }
        />
      </DialogContent>
      <DialogActions>
        <ThemeButton
          labelM="sm"
          color="default"
          onClick={() => {
            close()
          }}
        >
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          labelM="sm"
          color="primary"
          onClick={() => {
            checkName(nameClone)
              .then((response) => {
                if (response) {
                  throw Error('Name exists')
                }
              })
              .then(() => {
                save(nameClone)
              })
              .then(() => {
                close()
              })
              .catch((error) => {
                if (error === 'Name exists') {
                  console.debug('Duplication failed. Another tariff has this name already')
                  setNameExists(true)
                }
              })
          }}
        >
          {formatMessage({ id: 'global.create' })}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}
