import React, { useState, useEffect } from 'react'
import { Box, withStyles, Typography } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'

import { RatingInput } from '../../../../ui'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ffb400',
  },
}))(LinearProgress)

const getPreparedBarsData = (data) => {
  const identificationSummaryRating = [
    { id: 'valoraciones_5', value: '5 estrellas' },
    { id: 'valoraciones_4', value: '4 estrellas' },
    { id: 'valoraciones_3', value: '3 estrellas' },
    { id: 'valoraciones_2', value: '2 estrellas' },
    { id: 'valoraciones_1', value: '1 estrellas' },
  ]
  const rating = identificationSummaryRating.map((item) => {
    const selectedRating = data[item.id]
    const customPercentage = selectedRating.porcentaje
      ? parseInt(selectedRating.porcentaje.toFixed())
      : 0
    const preparedRating = { ...selectedRating, customPercentage, id: item.id, title: item.value }
    return preparedRating
  })
  return rating
}

const getPreparedSummaryData = (data) => {
  const totalMean = data.media ? parseFloat(data.media.toFixed(2)) : 0
  const summary = { totalRating: data.total_valoraciones, totalMean: totalMean }
  return summary
}

export const SummaryReview = ({ companyId, pagination }) => {
  const [summaryReviews, setSummaryReviews] = useState(undefined)
  const [barReviews, setBarReviews] = useState(undefined)

  useEffect(() => {
    const bars = getPreparedBarsData(pagination)
    setBarReviews(bars)
    const summary = getPreparedSummaryData(pagination)
    setSummaryReviews(summary)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const RatingSummaryStars = withStyles((theme) => ({
    textHeader: {
      marginLeft: '1em',
      fontSize: '1em',
    },
  }))(({ classes }) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <RatingInput
          {...{
            name: 'valoracion',
            value: summaryReviews.totalMean,
            readOnly: true,
            size: 'large',
            precision: 0.1,
          }}
        />
        <Typography variant="subtitle2" className={classes.textHeader}>
          {summaryReviews.totalMean + ' de 5'}
        </Typography>
        <Typography variant="subtitle2" className={classes.textHeader}>
          {'(' + summaryReviews.totalRating + ')'}
        </Typography>
      </Box>
    )
  })

  const RatingSummaryBar = () => {
    return barReviews.map((item) => {
      return (
        <Box
          key={item.id}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '100%', marginTop: '0.8em' }}
        >
          <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>
            {item.title}
          </Typography>
          <Box style={{ width: '70%', marginRight: '1em', marginLeft: '1em' }}>
            <BorderLinearProgress variant="determinate" value={item.customPercentage} />
          </Box>
          <Typography variant="subtitle2" style={{ width: '3em', textAlign: 'right' }}>
            {item.customPercentage}%
          </Typography>
        </Box>
      )
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ marginBottom: '1em', padding: '0px 16px' }}
    >
      {summaryReviews ? <RatingSummaryStars /> : null}
      {barReviews ? <RatingSummaryBar /> : null}
    </Box>
  )
}
