import React from 'react'
import {
  Grid,
  Box,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  withStyles,
} from '@material-ui/core'
import { useIntl } from 'react-intl'

import { feedback } from '../../../../core/feedback'
import {
  TextInput,
  SelectInput,
  FileUploaderAgnostic as FileUploader,
  UploadTooltip,
} from '../../../../ui'
import { normalizeFormStyles } from './normalize-form.styles'

export const DocumentFieldForm = withStyles(normalizeFormStyles)(
  ({
    classes,
    formState,
    onChange,
    flowType,
    fieldList,
    docList,
    config,
    textRoot = 'pages.consult.documents',
  }) => {
    const { formatMessage } = useIntl()
    const handleUploadError = (data) => feedback('error', data.mssg)

    const prepareLine = (document) => {
      if (document.doc) {
        const uploadButton = docList[document.doc]
        uploadButton.name = uploadButton.field
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="horizontal">
              <Typography style={{ marginRight: '0.5em' }} variant="subtitle1">
                {formatMessage({ id: textRoot + '.' + uploadButton.label })}
                {document.required && document.required(flowType) ? ' (*)' : ''}
              </Typography>
              <UploadTooltip file={uploadButton} />
              {formState[uploadButton.field]?.name && (
                <Typography variant="body1" color="textSecondary">
                  {formState[uploadButton.field]?.name}
                </Typography>
              )}
            </Box>
            <FileUploader {...uploadButton} onSuccess={onChange} onError={handleUploadError}>
              {({ loading }) => (
                <Button component="span" variant="outlined" disabled={loading}>
                  Adjuntar
                </Button>
              )}
            </FileUploader>
          </Box>
        )
      } else if (fieldList[document.field]?.values && document.radio) {
        const details = { ...fieldList[document.field] }
        return (
          <FormControl component="fieldset" value={details.value} onChange={onChange}>
            {document.title}
            {document.required && document.required(flowType) ? ' (*)' : ''}
            <RadioGroup row required={details.required}>
              {details.values?.map(({ key, value }) => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    checked={key === fieldList[document.field].value}
                    name={details.name}
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {value}
                      </Typography>
                    }
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        )
      } else {
        const InputType = document.values ? SelectInput : TextInput
        return (
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Typography>
                {document.title}
                {document.required && document.required(flowType) && !document.readOnly ? ' (*)' : ''}
              </Typography>
            </Box>
            <Box width={200} paddingTop="5px">
              <InputType {...{ ...fieldList[document.field], readOnly: document.readOnly }} />
            </Box>
          </Box>
        )
      }
    }

    return (
      <Grid container spacing={2}>
        {config.map((document) => {
          if (document.condition && !document.condition(formState)) {
            return null
          }
          return (
            <Grid key={document.field || document.doc} item xs={12}>
              {prepareLine(document)}
              {document.link ? (
                <Box display="block">
                  {document.link.prepend}
                  <a
                    href={document.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {document.link.text}
                  </a>
                  {document.link.append}
                </Box>
              ) : null}
            </Grid>
          )
        })}
      </Grid>
    )
  }
)
