import React from 'react'
import { useIntl } from 'react-intl'

import { useModalContext, ThemeButton } from '../../../../../ui'
import { RequestChangesDialog } from '../../dialogs'
import { useAppsDetail } from '../../../hooks'

export const RequestChangesBtn = ({ application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { requestReview } = useAppsDetail()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenModal = () =>
    open({
      Component: RequestChangesDialog,
      data: {
        appId: application.id,
        getApplication,
        clientId: application.usuario.id,
        requestReview,
      },
      type: 'responsive',
    })

  return (
    <ThemeButton size="small" color="primary" onClick={handleOpenModal}>
      {formatMessage({ id: 'pages.application.detail.requestChangesBtn.title' })}
    </ThemeButton>
  )
}
