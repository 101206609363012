export const USERS_FORM_CONFIG = ({ formState, onChange, combos, intl }) => ({
  NOMBRE: {
    required: true,
    value: formState['nombre'],
    name: 'nombre',
    title: 'Nombre',
    placeholder: 'Ej: Lucía',
    onChange,
  },
  APELLIDOS: {
    required: true,
    value: formState['apellidos'],
    name: 'apellidos',
    title: 'Apellidos',
    placeholder: 'Ej: Sánchez',
    onChange,
  },
  EMAIL: {
    required: true,
    inputProps: { type: 'email' },
    value: formState['email'],
    name: 'email',
    title: 'Correo electrónico',
    placeholder: 'Ej: nombre@dominio.com',
    onChange,
  },
  TELEFONO: {
    required: true,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    value: formState['telefono'],
    name: 'telefono',
    title: 'Teléfono',
    placeholder: 'Ej: 600000000',
    onChange,
  },
})

export const USERS_DETAIL_ITEMS = [
  {
    label: 'pages.users.detail.gridFields.name',
    valueParsed: (data) => `${data['nombre']} ${data['apellidos']}`,
  },
  {
    label: 'pages.users.detail.gridFields.email',
    value: 'email',
  },
  {
    label: 'pages.users.detail.gridFields.telefono',
    value: 'telefono',
  },
]
