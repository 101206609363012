import React, { useState } from 'react'

import { useAjaxContext } from '../../../../core/ajax'
import { ThemeButton } from '../../../../ui'

export const MarketerUserStateButtonComponent = ({ data, setData }) => {
  const { putById } = useAjaxContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleToggleUserState = () => {
    const { empresa, empresa_id, gremio_id, gremio, ...userData } = data
    setIsLoading(true)
    putById('instalador', data['id'], { ...userData, activo: !userData['activo'] })
      .then(({ data }) => {
        setData(data)
        setIsLoading(false)
      })
      .catch(() => {
        console.debug('Error fetching instalador data')
        setIsLoading(false)
      })
  }

  return (
    <ThemeButton variant="outlined" fullWidth loading={isLoading} onClick={handleToggleUserState}>
      {data['activo'] ? 'Deshabilitar' : 'Habilitar'}
    </ThemeButton>
  )
}
