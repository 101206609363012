import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'

import { InfoBox } from '../../../ui'
import { usePersistReducer } from '../../../core/utils/persist-reducer'
import { globalAlertReducer } from './global-alert.reducer'

const IP3_GUIDE_DOC_URL =
  'https://www.nedgia.es/wp-content/uploads/2024/05/Guia-Explicativa-EEII-Nueva-Web_Gremios-v1.0.pdf'

export const GlobalAlert = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const global = useSelector((state) => state.global)

  const [state, dispatch] = usePersistReducer(globalAlertReducer, {}, 'globalAlerts')

  const handleCloseAlert = (key) => dispatch({ type: 'CLOSE', payload: key })

  const showAddressAlert = () => {
    const isCatchmentInstaller = !!global.empresa?.captadora
    const existsMainAdress = !!global.empresa?.calle
    const existsSecondaryAdresses = !global.empresa_cc_aa?.some((company) => !company.calle)
    return isCatchmentInstaller && (!existsMainAdress || !existsSecondaryAdresses)
  }

  const globalAlerts = [
    {
      key: 'ipCompanyDisabled',
      severity: 'warning',
      hidden: !(
        global.empresa &&
        global.empresa.tipo_actividad === '01' &&
        global.empresa.grabadora &&
        !global.empresa.activo
      ),
      title: formatMessage({ id: 'global.alert.title' }),
      message: formatMessage({ id: 'global.alert.message.ipCompanyDisabled' }),
      closable: false,
    },
    {
      key: 'ipCompanySuggest',
      severity: 'warning',
      hidden: !(
        global.empresa &&
        global.empresa.tipo_actividad === '01' &&
        global.empresa.captadora &&
        !global.empresa.grabadora &&
        global.empresa.sugerir_ip3
      ),
      title: formatMessage({ id: 'global.alert.title' }),
      message: formatMessage({ id: 'global.alert.message.ipCompanySuggest' }),
      action: (
        <Button
          variant="contained"
          size="small"
          color="inherit"
          onClick={() => window.open(IP3_GUIDE_DOC_URL)}
        >
          {formatMessage({ id: 'global.alert.btn.ipCompanySuggest' })}
        </Button>
      ),
      closable: false,
    },
    {
      key: 'poRequests',
      severity: 'warning',
      hidden: !global.empresa?.captadora,
      title: formatMessage({ id: 'global.alert.title' }),
      message: formatMessage({ id: 'global.alert.message.poRequests' }),
      action: (
        <Button variant="contained" size="small" onClick={() => history.push('/profile/company')}>
          {formatMessage({ id: 'global.alert.btn.poRequests' })}
        </Button>
      ),
      closable: true,
    },
    {
      key: 'emptyAddress',
      severity: 'warning',
      hidden: !showAddressAlert(),
      title: formatMessage({ id: 'global.alert.title' }),
      message: formatMessage({ id: 'global.alert.message.emptyAddress' }),
      closable: false,
    },
    {
      key: 'marketersOp',
      severity: 'warning',
      hidden: global.role !== 'comercializadora',
      title: formatMessage({ id: 'global.alert.message.poMarketers.title' }),
      message: formatMessage({ id: 'global.alert.message.poMarketers' }),
      closable: true,
      action: (
        <Button variant="contained" size="small" onClick={() => history.push('/profile/company')}>
          {formatMessage({ id: 'global.alert.btn.poRequests' })}
        </Button>
      ),
    },
  ]

  return globalAlerts.map((alert) => (
    <InfoBox
      {...{
        ...alert,
        hidden: state[alert.key] || alert.hidden,
        onClose: () => handleCloseAlert(alert.key),
      }}
    />
  ))
}
