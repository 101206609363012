import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'
import { useCompanyProfileContext } from '../../contexts'

export const PrivacyPolicyCheckbox = ({ company }) => {
  const { formState, changeFormState } = useCompanyProfileContext()
  const intl = useIntl()

  const [showMore, setShowMore] = useState(false)

  const handleAcceptDataPolicy = (e) => {
    const event = { target: { name: e.target.name, value: e.target.checked } }
    changeFormState(event)
  }

  return (
    <>
      <Box
        mt={4}
        mb={4}
        p={2}
        bgcolor="common.white"
        borderRadius={6}
        boxShadow="1px 2px 4px rgba(0, 0, 0, 0.25)"
      >
        <Typography color="primary" variant={'h6'}>
          {'Información básica sobre Protección de Datos Personales'}
        </Typography>
        <Box mt={1}>
          <Typography variant="body1">
            {intl.formatMessage({ id: 'pages.profile.privacy-policy-checkbox.text1' })}
            {showMore ? '.' : '...'}
          </Typography>
          {showMore ? (
            <Box>
              <Typography variant="body1">
                {intl.formatMessage({ id: 'pages.profile.privacy-policy-checkbox.text2' })}
                <a
                  style={{ color: '#004a6b' }}
                  href="https://www.nedgia.es/politica-de-privacidad/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'pages.profile.privacy-policy-checkbox.link.text' })}
                </a>
                {intl.formatMessage({ id: 'pages.profile.privacy-policy-checkbox.text3' })}
              </Typography>
            </Box>
          ) : null}

          <Box mt={1} textAlign={'center'}>
            <ThemeButton color="primary" labelM="xs" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Mostrar Menos' : 'Mostrar Más'}
            </ThemeButton>
          </Box>
        </Box>
      </Box>
      <Box
        mt={4}
        mb={4}
        p={2}
        bgcolor="common.white"
        borderRadius={6}
        boxShadow="1px 2px 4px rgba(0, 0, 0, 0.25)"
      >
        <Typography color="primary" variant={'h6'}>
          {'Responsabilidad y seguridad de la información'}
        </Typography>
        <Box mt={1}>
          <Typography variant="body1">
            {
              'Los datos y la documentación aportada son de responsabilidad de los usuarios de área privada colaboradores, incluido los errores o defectos de seguridad, que se puedan producirse en consecuencia de la presencia de un virus en el ordenador del usuario que sea utilizado para la conexión a los servicios y contenidos de la web, un mal funcionamiento del navegador u otros programas del usuario, el uso indebido por parte del usuario y/o suplantación de identidad.'
            }
          </Typography>
        </Box>
        <FormGroup mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={company?.check_resp_sec_info}
                style={{ pointerEvents: 'auto', padding: 10 }}
                color="primary"
                checked={formState.check_resp_sec_info}
                onChange={handleAcceptDataPolicy}
                name="check_resp_sec_info"
              />
            }
            label={
              <Box>
                <Typography display="inline" variant="body1">
                  {
                    'He leído y acepto los términos y condiciones sobre la responsabilidad y seguridad de la información'
                  }
                </Typography>
              </Box>
            }
          />
        </FormGroup>
      </Box>
    </>
  )
}
