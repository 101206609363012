import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

export const GasMonitoringList = ({ data, config }) => {
  const intl = useIntl()
  if (data) {
    return config.map((row, i) => {
      return (
        <Box key={i} display="flex" width="100%" justifyContent="space-between" flexWrap="wrap">
          {row.map((section) => {
            if (data[section]) {
              return (
                <Box key={section} display="block" mb={2} mr={10}>
                  <Typography
                    display="block"
                    variant="h6"
                    color="primary"
                    style={{ marginBottom: 10 }}
                  >
                    {intl.formatMessage({
                      id: `pages.application.detail.panels.6.fields.${section}.section.title`,
                    })}
                  </Typography>
                  {Object.keys(data[section]).map((field) => {
                    return (
                      <Box display="flex" mb={1} style={{ flexWrap: 'wrap' }} key={field}>
                        <Typography
                          display="inline"
                          variant="body2"
                          style={{ marginRight: 5, fontFamily: 'FSEmeric-Medium', fontWeight: 500 }}
                        >
                          {intl.formatMessage({
                            id: `pages.application.detail.panels.6.fields.${section}.${field}.label`,
                          })}
                        </Typography>
                        {data[section][field] ? (
                          <>
                            {field === 'anomalias_observadas' ||
                            field === 'observaciones_ultima_visita' ? (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'FSEmeric-Regular' }}
                              >
                                {data[section][field]}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'FSEmeric-Regular' }}
                              >
                                {intl.formatDate(data[section][field])}
                              </Typography>
                            )}
                          </>
                        ) : (
                          ' -- '
                        )}
                      </Box>
                    )
                  })}
                </Box>
              )
            } else {
              return null
            }
          })}
        </Box>
      )
    })
  }
}
