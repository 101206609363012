import { TextInput, DateInput, NumberInput, SelectInput, CheckListInput } from '../../../../ui'
import { FileUploaderInput } from '../../../../ui'
import { IPAddressesViewer } from './ip-addresses-viewer.component'
import { IPAIValidations } from './ip-ai-validations.component'

const GRID_ITEM_FULL = {
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
}

const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}

export const INPUTS_CONFIG = ({ data, combos, intl, onChange, readOnly }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: 'pages.ipDetail.fields.' + extension + '.title' })
  const DEFAULT_PROPS = { onChange, readOnly, required: true }
  return {
    TIPO_INSTALACION: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      value: data['tipo_instalacion'],
      values: combos.tipo_instalacion_ab?.data || [],
      title: txt('tipo_instalacion'),
      name: 'tipo_instalacion',
    },
    UNE: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      values: combos.une?.data || [],
      value: data['une'],
      title: txt('une'),
      name: 'une',
    },
    TIPO_GAS: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['tipo_gas'],
      title: txt('tipo_gas'),
      name: 'tipo_gas',
    },
    ESTADO: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      values: combos.estados_ip_readonly?.data || [],
      value: data['estado'],
      title: txt('estado'),
      name: 'estado',
    },
    OBSERVACIONES_RECHAZO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['observaciones_rechazo'],
      title: txt('observaciones_rechazo'),
      name: 'observaciones_rechazo',
      hidden: !(data['observaciones_rechazo']?.length && IP_REJECTED_STATES[data['estado']]),
    },
    EMPRESA_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_nombre'],
      title: txt('empresa_nombre'),
      name: 'empresa_nombre',
    },
    EMPRESA_CIF: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_cif'],
      title: txt('empresa_cif'),
      name: 'empresa_cif',
    },
    INSTALADOR_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['nombre_instl'],
      title: txt('nombre_instl'),
      name: 'nombre_instl',
    },
    INSTALADOR_CATEGORIA: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['categoria_instl'],
      title: txt('categoria_instl'),
      name: 'categoria_instl',
    },
    RESULTADO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['resultado'],
      values: combos.resultado_inspeccion?.data || [],
      title: txt('resultado'),
      name: 'resultado',
    },
    FECHA_INSPECCION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_inspeccion'],
      title: txt('fecha_inspeccion'),
      name: 'fecha_inspeccion',
    },
    FECHA_CREACION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_creacion'],
      title: txt('fecha_creacion'),
      name: 'fecha_creacion',
    },
    ANOMALIAS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      values: data.anomalias?.map((a) => ({ key: a.id, value: a.tipo_anomalia })) || [],
      value: data.anomalias?.map((a) => ({ key: a.id, value: a.tipo_anomalia })) || [],
      comboId: 'anomalias',
      title: txt('anomalias'),
      name: 'anomalias',
    },
    NUMERO_FACTURA: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['numero_fra'],
      title: txt('numero_fra'),
      name: 'numero_fra',
    },
    FECHA_FACTURA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_fra'],
      title: txt('fecha_fra'),
      name: 'fecha_fra',
    },
    IMPORTE_FACTURA: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['importe_fra'],
      title: txt('importe_fra'),
      name: 'importe_fra',
      units: '€',
    },
    CERTIFICADO: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('certificado_ip'),
      name: 'certificado_ip',
      currentDoc: data['certificado_ip'],
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
    },
    FACTURA: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('factura_ip'),
      name: 'factura_ip',
      currentDoc: data['factura_ip'],
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
    },
    DIRECCIONES: {
      component: IPAddressesViewer,
      addresses: data['direcciones'] || [],
      instType: data['tipo_instalacion'],
    },
    IA_VALIDATIONS: {
      component: IPAIValidations,
      hidden:
        !IP_CAN_VIEW_AI_VALIDATIONS_STATES[data.estado] || !data.ai_validations?.length,
    },
  }
}

export const FORM_CONFIG = ({ intl }) => [
  {
    fields: [
      { ref: 'TIPO_INSTALACION', conf: GRID_ITEM_LARGE },
      { ref: 'UNE', conf: GRID_ITEM_LARGE },
      { ref: 'TIPO_GAS', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_CREACION', conf: GRID_ITEM_LARGE },
      { ref: 'ESTADO', conf: GRID_ITEM_LARGE },
      { ref: 'OBSERVACIONES_RECHAZO', conf: GRID_ITEM_LARGE },
      { ref: 'IA_VALIDATIONS', conf: GRID_ITEM_FULL },
    ],
    divider: true,
  },
  {
    title: intl.formatMessage({ id: 'pages.ipDetail.gridForm.title.1' }),
    fields: [
      { ref: 'EMPRESA_NOMBRE', conf: GRID_ITEM_LARGE },
      { ref: 'EMPRESA_CIF', conf: GRID_ITEM_LARGE },
      { ref: 'INSTALADOR_NOMBRE', conf: GRID_ITEM_LARGE },
      { ref: 'INSTALADOR_CATEGORIA', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: intl.formatMessage({ id: 'pages.ipDetail.gridForm.title.2' }),
    fields: [
      { ref: 'DIRECCIONES', conf: GRID_ITEM_FULL },
      { ref: 'FECHA_INSPECCION', conf: GRID_ITEM_LARGE },
      { ref: 'CERTIFICADO', conf: GRID_ITEM_FULL },
    ],
    divider: true,
  },
  {
    title: intl.formatMessage({ id: 'pages.ipDetail.gridForm.title.3' }),
    fields: [
      { ref: 'NUMERO_FACTURA', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FACTURA', conf: GRID_ITEM_LARGE },
      { ref: 'IMPORTE_FACTURA', conf: GRID_ITEM_FULL },
      { ref: 'FACTURA', conf: GRID_ITEM_FULL },
    ],
  },
]

export const IP_REJECTED_STATES = {
  20: true,
  23: true,
  26: true,
  25: true,
  27: true,
}

export const IP_NONCONFORMITY_STATES = {
  15: true,
}

export const IP_CAN_VIEW_AI_VALIDATIONS_STATES = {
  25: true,
  '26': true,
  27: true,
  40: true,
  41: true,
  42: true,
}