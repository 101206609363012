export const pwdRecoveryStyles = (theme) => ({
  root: { height: '100%' },
  panel: {
    width: '100%',
    margin: '20px 0',
    padding: '40px 60px',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  icon: {
    fontSize: 80,
  },
  button: {
    textAlign: 'center',
    textDecoration: 'none',
  },
})
