export const GUILD_LIST_ACTIONS = {
  GUILD_LIST_LOAD: 'GUILDS_LOAD',
  GUILDS_FAILURE: 'GUILDS_FAILURE',
  SET_GUILDS_DATA: 'SET_GUILDS_DATA',
}

export const GUILD_LIST_INITIAL_STATE = {
  data: [],
  search: {},
  loading: false,
  msLoaded: false,
  error: false,
}

export const GUILD_LIST_REDUCER = (state, { type, payload }) => {
  switch (type) {
    case GUILD_LIST_ACTIONS.GUILDS_LOAD:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case GUILD_LIST_ACTIONS.GUILDS_FAILURE:
      return {
        ...state,
        loading: false,
        msLoaded: true,
        error: payload,
      }
    case GUILD_LIST_ACTIONS.SET_GUILDS_DATA:
      return {
        ...state,
        loading: false,
        msLoaded: Date.now(),
        data: payload.data,
        search: payload.search,
      }
    default:
      throw new Error('unkown action')
  }
}
