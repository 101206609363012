import { useCallback, useReducer } from 'react'

import { GUILD_LIST_ACTIONS, GUILD_LIST_INITIAL_STATE, GUILD_LIST_REDUCER } from '../reducers'
import { useGuildsCalls } from './guilds-calls.hook'

export const useGuildListReducer = () => {
  const [state, dispatch] = useReducer(GUILD_LIST_REDUCER, GUILD_LIST_INITIAL_STATE)
  const { getGuildList } = useGuildsCalls()

  const guildListLoad = useCallback(
    (search) => {
      const config = { params: { ...search, captadora: true } }
      dispatch({
        type: GUILD_LIST_ACTIONS.GUILDS_LOAD,
      })
      getGuildList(config)
        .then(({ data }) => {
          dispatch({
            type: GUILD_LIST_ACTIONS.SET_GUILDS_DATA,
            payload: {
              data: data,
              search: search,
            },
          })
        })
        .catch(() => {
          dispatch({
            type: GUILD_LIST_ACTIONS.GUILDS_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    state,
    guildListLoad,
  }
}
