import React, { useState, useEffect } from 'react'
import { Map } from '../../core/maps'

const CENTER_POSITION = {
  lat: 41.9405,
  lng: 2.3163,
}

const DEFAULT_ZOOM = 20

export const MapPicker = ({
  position,
  setPosition,
  center = CENTER_POSITION,
  zoom = DEFAULT_ZOOM,
}) => {
  const [marker, setMarker] = useState()
  useEffect(() => {
    if (marker) {
      marker.setPosition(position)
    }
  }, [position]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Map
      id="test"
      options={{
        center,
        zoom,
        mapTypeId: 'hybrid',
        tilt: 0,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
        streetViewControl: false,
      }}
      onMapLoad={(map) => {
        var marker = new window.google.maps.Marker({
          position: position || center,
          map,
          draggable: true,
        })
        setMarker(marker)
        setPosition(center)
        window.google.maps.event.addListener(marker, 'dragend', (e) => {
          setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        })
      }}
    />
  )
}

export default MapPicker
