import React from 'react'
import { Typography, FormGroup } from '@material-ui/core'
import { FormControlLabel, Checkbox } from '@material-ui/core'

import { useSignupFormContext } from '../../contexts'

export const WorksChecklist = ({ options }) => {
  const { installerWorks, setInstallerWorks } = useSignupFormContext()

  const handleChange = (e) => {
    setInstallerWorks({ ...installerWorks, [e.target.name]: e.target.checked })
  }

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.key}
          style={{ pointerEvents: 'none' }}
          control={
            <Checkbox
              style={{ pointerEvents: 'auto', padding: 10 }}
              color="primary"
              checked={!!installerWorks[option.key]}
              onChange={handleChange}
              name={option.key}
            />
          }
          label={<Typography variant="body1">{option.label}</Typography>}
        />
      ))}
    </FormGroup>
  )
}
