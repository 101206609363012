import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCombos } from '../../../../combos'
import { useSignupFormContext } from '../../../contexts'
import { useAjaxContext } from '../../../../../core/ajax'
import { TextInput, SelectInput, FileUploaderInput } from '../../../../../ui'
import { COMPANY_FORM_CONFIG, INSTALLER_WORKS_OPTIONS } from '../constants'
import { useSignupDialogs } from '../../../hooks/useSignupDialog'
import { CompanyFormExtraComponent } from './company-form-extra.component'
import { WorksChecklist } from '../works-checklist.component'
import { IPInstallerAddTable } from './ip-installer-add-table.component'
import { SignUpCompanyFinance } from '../../signup-company-finance/signup-company-finance.component'
import { CompanyAddressForm } from '../company-address-form.component'

export const InstallerCompanyForm = () => {
  const intl = useIntl()
  const combos = useCombos(['cod_zona_zeus'])
  const { getPublic } = useAjaxContext()
  const { formState, onChange, installerWorks } = useSignupFormContext()
  const { warnWrongCifTolerant } = useSignupDialogs()
  const inputProps = { formState, onChange, combos, intl }

  const handleFetchSAPCodeByCIF = () => {
    getPublic(`/proveedores_sap/existsbycif/${formState['cif']}`)
      .then(({ data }) => {
        if (!data['exists']) {
          throw new Error('not_found')
        }
        onChange({ target: { name: 'cod_sap', value: data['cod_sap'] } })
      })
      .catch(() => {
        warnWrongCifTolerant()
        onChange({ target: { name: 'cod_sap', value: '' } })
      })
  }

  useEffect(() => {
    if (installerWorks.catchment && formState.cif?.length === 9) {
      handleFetchSAPCodeByCIF()
    }
  }, [formState.cif]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={12}>
        <WorksChecklist {...{ options: INSTALLER_WORKS_OPTIONS }} />
      </Grid>
      {installerWorks.catchment ? (
        <>
          <Grid item xs={12}>
            <CompanyFormExtraComponent />
          </Grid>
          {/* <Grid item xs={12}>
            <SignUpCompanyFinance inputProps={inputProps} initialValues={inputProps.formState} />
      </Grid> */}
        </>
      ) : null}
      {installerWorks.catchment || installerWorks.ip ? (
        <>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).RAZON }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CIF }} />
          </Grid>
          {installerWorks.catchment ? (
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_SAP }} />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).TELEFONO }} />
          </Grid>
          {installerWorks.catchment ? (
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).WEB }} />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).EMAIL }} />
          </Grid>
          <Grid item xs={12}>
            <CompanyAddressForm {...{ inputProps }} />
          </Grid>
          {installerWorks.catchment ? (
            <Grid item xs={12} sm={6}>
              <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CCAA }} />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <FileUploaderInput {...{ ...COMPANY_FORM_CONFIG(inputProps).REG_INDUSTRIAL }} />
          </Grid>
        </>
      ) : null}
      {installerWorks.ip ? (
        <>
          <Grid item xs={12} sm={6}>
            <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CATEGORIA }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).IBAN }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FileUploaderInput {...{ ...COMPANY_FORM_CONFIG(inputProps).IBAN_CERTIFICADO }} />
              </Grid>
              {!formState.iban && !formState.certificado_iban ? (
                <Grid item xs={12}>
                  <Alert severity="info">
                    {intl.formatMessage({ id: 'pages.signup.feedback.3.text' })}
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <IPInstallerAddTable onChange={onChange} data={formState.inspectores} />
            {!formState.inspectores?.length ? (
              <Alert severity="info">
                {intl.formatMessage({ id: 'pages.signup.feedback.4.text' })}
              </Alert>
            ) : null}
          </Grid>
        </>
      ) : null}
    </>
  )
}
