import React, { useState } from 'react'

import { encodeToBase64, isACorrectFormat } from '../../../core/utils'
import { useAjaxContext } from '../../../core/ajax'
import { DEFAULT_FILE_SIZE, API_ENDPOINT } from '../constants'

export const FileUploader = ({
  classes,
  accept,
  onSuccess,
  onError,
  disabled,
  maxSize = DEFAULT_FILE_SIZE,
  type = 'sin_informar',
  applicationId,
  budgetId,
  children,
  ...props
}) => {
  const { post } = useAjaxContext()
  const [loading, setLoading] = useState(false)

  const handleChangeUpload = async (e) => {
    setLoading(true)
    const file = e.target.files[0]
    if (file) {
      if (accept && accept.length > 0 && !isACorrectFormat(file.name, accept)) {
        setLoading(false)
        return onError({ mssg: 'Archivo con formato no válido' })
      } else if (file.size > maxSize) {
        setLoading(false)
        return onError({ mssg: 'Excede el tamaño máximo' })
      }
      const mockErrorData = {
        mssg: 'Problema con la subida del fichero',
      }
      postDocument(file)
        .then(({ data }) => {
          setLoading(false)
          return onSuccess(data)
        })
        .catch(({ response }) => {
          setLoading(false)
          return onError(response ? response.data : mockErrorData)
        })
    }
  }

  const postDocument = async (file) => {
    const base64File = await encodeToBase64(file)
    const payload = {
      documento: base64File.split(',')[1],
      nombre: file.name.replace(/\.[^/.]+$/, ''),
      tipo: type,
      extension: file.type.split('/')[1],
      cliente_id: parseInt(props.clientId),
      solicitud_id: applicationId.toString(),
    }
    if (budgetId) {
      payload['presupuesto_id'] = budgetId.toString()
    }
    return post(API_ENDPOINT, payload, { timeout: 0 })
  }

  return (
    <div>
      <input
        id={`file-upload-${props.id}`}
        type="file"
        style={{ display: 'none' }}
        accept={accept.join(',')}
        onChange={handleChangeUpload}
        disabled={disabled}
      />
      <label htmlFor={`file-upload-${props.id}`}>{children({ loading,disabled })}</label>
    </div>
  )
}
