import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'

import ic_conditions_doc from '../../../../../resources/documents/condiciones_identidad-corporativa.pdf'
import { useCompanyProfileContext } from '../../../contexts'

export const CoporateIdentityPanel = ({ disabled = false }) => {
  const { company, formState, changeFormState } = useCompanyProfileContext()

  const handleChangeCheckbox = (e) => {
    changeFormState({ target: { name: e.target.name, value: e.target.checked } })
  }
  const bloqueo_negocio = formState.bloqueo_negocio
  return !bloqueo_negocio ? (
    <Box mb={4}>
      <Typography variant="h6" color="primary" paragraph>
        Identidad Corporativa
      </Typography>
      <Typography variant="body1" gutterBottom>
        ¿Quieres usar la identidad de empresa colaboradora de Nedgia?
      </Typography>
      <RadioGroup color="primary" value={formState.acepta_uso_marca} onChange={changeFormState}>
        <FormControlLabel
          name="acepta_uso_marca"
          value="01"
          control={<Radio />}
          label="Sí"
          disabled={disabled}
        />
        <FormControlLabel
          name="acepta_uso_marca"
          value="00"
          control={<Radio />}
          label="No"
          disabled={disabled}
        />
      </RadioGroup>
      {formState.acepta_uso_marca === '01' && !disabled ? (
        <FormControlLabel
          label={
            <span>
              He leído y acepto las{' '}
              <a href={ic_conditions_doc} target="_blank" rel="noopener noreferrer">
                condiciones de uso
              </a>
            </span>
          }
          control={
            <Checkbox
              required
              checked={formState.acepta_uso_marca_checkbox}
              name="acepta_uso_marca_checkbox"
              onChange={handleChangeCheckbox}
            />
          }
        />
      ) : null}
      {company.acepta_uso_marca === '01' ? (
        <Box mt={2}>
          <Button variant="contained" color="primary">
            <Link style={{ textDecoration: 'none', color: 'white' }} to="/corporative-identity">
              Ver Recursos
            </Link>
          </Button>
        </Box>
      ) : null}
    </Box>
  ) : null
}
