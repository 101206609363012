export const gridRowStyles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: -10,
    justifyContent: 'space-between',
    // '& div:first-child': { paddingLeft: 0 },
  },
  item: {
    padding: 10,
    '& div:first': { paddingLeft: 0 },
  },
})
