export const foldingPanelStyles = (theme) => ({
  root: {
    height: '48px',
  },
  panel: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    margin: '24px 0 0 0',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  folding: {
    backgroundColor: 'transparent',
  },
  foldingHeader: {
    // backgroundColor: 'rgba(0, 65, 101, 0.1)',
    backgroundColor: '#0041651A',
  },
})
