import React, { useCallback, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper, Tooltip, Typography } from '@material-ui/core'
import { MTableCell } from 'material-table'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import AssignmentLate from '@material-ui/icons/AssignmentLate'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'

import { APPS_TABLE_COLUMNS, GUILD_TABLE_COLUMNS } from '../../constants'
import { TableUrlPagination } from '../../../../ui'
import { appsTableStyles } from './apps-table.styles'
import { setSelectedApps } from '../../slices'
import { applicationDataPreparation } from '../../logics'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const AppsTable = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    data,
    search,
    pagination: { total_solicitudes: totalEntries },
    isLoading: loading,
    selectedRows,
  } = useSelector((state) => state.applications)

  //Selections: unselect on first render, and manage with handler:
  useEffect(() => {
    dispatch(setSelectedApps([]))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback(
    (rows, row) => {
      dispatch(setSelectedApps(rows))
    },
    [setSelectedApps, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const combos = useSelector((state) => state.combos)
  const { role } = useSelector((state) => state.global)
  const columns = role === 'gremio' ? GUILD_TABLE_COLUMNS : APPS_TABLE_COLUMNS

  //Column structure
  const formattedColumns = useMemo(
    () =>
      columns.map((column) => {
        // if (column.field === 'actions') return column
        if (column.field === 'comunicaciones_pendientes')
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      Comunicaciones
                    </Typography>
                  }
                >
                  <NotificationsNoneIcon style={{ fontSize: 18 }} />
                </Tooltip>
              </span>
            ),
          }
        if (column.field === 'pendiente_peticionario')
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      Tareas
                    </Typography>
                  }
                >
                  <AssignmentOutlined style={{ fontSize: 18 }} />
                </Tooltip>
              </span>
            ),
          }

        if (column.field === 'valoraciones_pendientes')
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      Valoraciones
                    </Typography>
                  }
                >
                  <StarBorderIcon style={{ fontSize: 18 }} />
                </Tooltip>
              </span>
            ),
          }
        return {
          ...column,
          title: intl.formatMessage({
            id: `pages.applications.table.columns.${column.field}.title`,
          }),
          cellStyle: appsTableStyles.cell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [columns, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  //Notice that selectedRows is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = selectedRows.map((el) => el.id)
    return applicationDataPreparation(columns, data, { intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, intl, selectedRows]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      tableLayout: 'fixed',
      headerStyle: appsTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFormattedCell = (props) => {
    if (
      props.columnDef.field === 'comunicaciones_pendientes' &&
      props.rowData.comunicaciones_pendientes > 0
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NotificationsActiveIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    } else if (
      props.columnDef.field === 'valoraciones_pendientes' &&
      props.rowData.valoraciones_pendientes
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <StarIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    } else if (
      props.columnDef.field === 'pendiente_peticionario' &&
      props.rowData.pendiente_peticionario > 0
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AssignmentLate color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    }
  }

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const mTableCellProps =
          props.columnDef.field === 'comunicaciones_pendientes' ||
          props.columnDef.field === 'valoraciones_pendientes' ||
          props.columnDef.field === 'pendiente_peticionario'
            ? { ...props, value: null }
            : props
        return (
          <MTableCell {...mTableCellProps} style={{ padding: 0 }}>
            {getFormattedCell(props)}
          </MTableCell>
        )
      },
    }),
    []
  )

  //Case-specific additions
  const handleRowClick = useCallback(
    (e, row) => {
      const props = {
        pathname: `/applications/${row['id']}`,
        state: { prevSearch: history.location.search },
      }
      history.push(props)
    },
    [history]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
