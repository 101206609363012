import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ADMIN_ROLES } from '../../../../core/constants'
import { TextInput, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos'
import { COMPANY_FORM_CONFIG } from '../../constants'
import { CompanyLogo } from '../../../company-logo'
import { CompanyGuild } from '../company-guild'
import { useCompanyProfileContext } from '../../contexts'
import { CoporateIdentityPanel } from '../corporate-identity-panel'
import { PublicOfferForm } from '../public-offer-form'
import { ReviewsBtn } from '../reviews/reviews-btn.component'
import { CompanyDocuments } from '../company-documents'
import { AddPublicOffer } from '../public-offer'
import { PUBLICOFFER_STATES } from '../public-offer/constants'
import { CompanyAddressForm } from '../company-address-form'
import { PrivacyPolicyCheckbox } from '../primary-company/privacy-policy-checkbox.component'

export const SecondaryCompanyForm = () => {
  const { updateInstallerSecondaryCompany } = useCompanyProfileContext()
  const { error, company, formState, changeFormState } = useCompanyProfileContext()
  const intl = useIntl()
  const combos = useCombos([])
  const hasPublicProfile = useSelector((state) => state.global.empresa.publica)
  const global = useSelector((state) => state.global)
  const isAdmin = ADMIN_ROLES[global.role]

  const [loading, setLoading] = useState(false)

  const handleSubmitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    updateInstallerSecondaryCompany()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const inputProps = { formState, onChange: changeFormState, combos, intl }

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid container spacing={3}>
        {hasPublicProfile ? (
          <Grid item xs={12} sm={isAdmin ? 4 : 12}>
            <CompanyLogo {...{ model: formState, onChange: changeFormState, disabled: !isAdmin }} />
          </Grid>
        ) : null}
        {isAdmin ? (
          <Grid item xs={12} sm={hasPublicProfile ? 8 : 12}>
            <CompanyGuild empresa_id={company.id} readOnly={!isAdmin} />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).NOMBRE, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).RAZON, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).TELEFONO, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).WEB, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).EMAIL, readOnly: !isAdmin }} />
        </Grid>
        <Grid item xs={12}>
          <CompanyAddressForm {...{ inputProps, readOnly: !isAdmin }} />
        </Grid>
        {hasPublicProfile ? (
          <Grid item xs={12}>
            <TextInput
              {...{ ...COMPANY_FORM_CONFIG(inputProps).DESCRIPCION, readOnly: !isAdmin }}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <PublicOfferForm {...{ isPrimaryCompany: false }} />
        </Grid>
        <Grid item xs={12}>
          <CompanyDocuments {...{ isPrimaryCompany: false }} />
        </Grid>
        {isAdmin ? (
          <Grid item xs={12}>
            <AddPublicOffer />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <PrivacyPolicyCheckbox company={company} />
        </Grid>

        {company?.ofertas_publicas?.some((op) => op?.estado === PUBLICOFFER_STATES.ACCEPTED.KEY) ? (
          <Grid item xs={12}>
            <CoporateIdentityPanel {...{ disabled: !isAdmin }} />
          </Grid>
        ) : null}
      </Grid>
      {formState.total_valoraciones > 0 ? (
        <Grid item xs={12} sm={6}>
          <ReviewsBtn {...{ formState }} />
        </Grid>
      ) : null}
      {error.message ? (
        <Box mt={2} mb={1}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      ) : null}
      {isAdmin ? (
        <Box display="flex" justifyContent="center" width="100%" mt={4}>
          <ThemeButton color="primary" type="submit" disabled={loading} loading={loading}>
            {intl.formatMessage({ id: 'pages.profile.company.button.save' })}
          </ThemeButton>
        </Box>
      ) : null}
    </form>
  )
}
