import { COMPANY_PROFILE_ACTION_TYPES } from '../constants'

export const companyProfileReducer = (state, { type, payload }) => {
  switch (type) {
    case COMPANY_PROFILE_ACTION_TYPES.SET_COMPANY:
      return {
        ...state,
        company: payload,
        formState: payload,
        loadingCompany: false,
        error: {},
      }
    case COMPANY_PROFILE_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loadingCompany: payload,
      }
    case COMPANY_PROFILE_ACTION_TYPES.SET_FAILURE:
      return {
        ...state,
        error: payload,
        loadingCompany: false,
        savingCompany: false,
      }
    case COMPANY_PROFILE_ACTION_TYPES.CHANGE_FORM_STATE:
      return {
        ...state,
        formState: {
          ...state.formState,
          [payload.key]: payload.value,
        },
        error: {},
      }
    case COMPANY_PROFILE_ACTION_TYPES.SET_FORM_STATE_SAVED:
      return {
        ...state,
        formStateSaved: payload,
      }
    case COMPANY_PROFILE_ACTION_TYPES.SET_SAVING:
      return {
        ...state,
        savingCompany: payload,
      }
    case COMPANY_PROFILE_ACTION_TYPES.COMPANY_SET_CHECK_OP:
      return {
        ...state,
        formState: {
          ...state.formState,
          checks_op: [
            ...state.formState.checks_op.map((singleCheck) => {
              if (singleCheck.comunidad_id === payload) {
                return { ...singleCheck, checked: true }
              } else {
                return singleCheck
              }
            }),
          ],
        },
      }
    default:
      throw new Error('unknown action')
  }
}
