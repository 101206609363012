import React, { useEffect, useState } from 'react'
import { Box, Collapse, InputAdornment, withStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

import { FilterURL } from '../filter-url'
import { TextInput } from '../text-input'
import { ThemeButton } from '../theme-button'

import { filterStyles } from './filter-box.styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTypingRefresh } from '../../core/utils/useTypingRefresh'

const DEFAULT_TYPE_REFRESH_MS = 800

export const FilterBox = withStyles(filterStyles)(
  ({
    classes,
    filters = [],
    choice,
    cleanup,
    searchPlaceholder = 'Buscar',
    cleanupFiltersLabel = 'Limpiar filtros',
    toggleFiltersLabel = 'Filtros',
    typeRefresh = DEFAULT_TYPE_REFRESH_MS,
    children,
  }) => {
    const history = useHistory()
    const location = useLocation()
    const currentSearch = new URLSearchParams(location.search)
    const appName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const searchQuery = currentSearch.get('_search')

    const filterChoice = choice || ((filter) => <FilterURL {...filter} />)
    const filterCleanup =
      cleanup ||
      (() => {
        const pageSize = currentSearch.get('_num_x_pagina')
        history.replace({ search: pageSize ? '?_num_x_pagina=' + pageSize : '' })
      })



    const [formState, setFormState] = useState({ _search: searchQuery })
    useEffect(() => setFormState({ _search: searchQuery }), [searchQuery])
    const handleChangeForm = (event) => {
      setFormState({ ...formState, [event.target.name]: event.target.value })
    }

    const handleSubmitForm = (event) => {
      event.preventDefault()
      if (isSearchChanged(event.target.value)) {
        changeSearchValue(event.target.value)
      }
    }

    const isSearchChanged = (value) => {
      let searchChanged = false
      if (value && searchQuery) {
        searchChanged = !(value === searchQuery)
      }
      return searchChanged
    }

    const changeSearchValue = (search) => {
      if (!((!search || search.length === 0) && !currentSearch.get('_search'))) {
        currentSearch.set('_search', search)
        currentSearch.set('_pagina', 1)
        history.replace({
          search: '?' + currentSearch.toString(),
        })
      }
    }

    const { ref: textRef } = useTypingRefresh({
      timeout: typeRefresh,
      action: (val) => changeSearchValue(val),
      textFromRef: (ref) => (ref.current ? ref.current.querySelector('input') : null),
    })

    //Open filters control
    const [filtersOpen, setFiltersOpen] = useState(false)
    const handleToggleFilters = () => {
      setFiltersOpen(!filtersOpen)
    }

    return (
      <Box width="100%">
        <Box display="flex" flexWrap="wrap">
          <Box flexGrow={1} justifyContent="flex-start">
            <Box display="flex">
              <Box style={{ flex: 1 }} ref={typeRefresh ? textRef : null}>
                <TextInput
                  spaceless="true"
                  name="_search"
                  placeholder={searchPlaceholder}
                  value={formState['_search']}
                  onChange={handleChangeForm}
                  onBlur={handleSubmitForm}
                  onKeyDown={(e) => e.keyCode === 13 && handleSubmitForm(e)}
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box ml={filters.length ? 2 : 0} display="flex">
                {filters.length ? (
                  <ThemeButton color="primary" onClick={filterCleanup}>
                    {cleanupFiltersLabel}
                  </ThemeButton>
                ) : null}
                {filters.length ? (
                  <ThemeButton
                    variant="outlined"
                    endIcon={<FilterListIcon />}
                    onClick={handleToggleFilters}
                    style={{ marginLeft: '1em' }}
                  >
                    {toggleFiltersLabel}
                  </ThemeButton>
                ) : null}
              </Box>
            </Box>
          </Box>
          {!!children ? (
            <Box ml={2} display="flex">
              {children}
            </Box>
          ) : null}
        </Box>
        <Box>
          <Collapse in={filtersOpen}>
            <Box display="flex" flexWrap="wrap" pt={2}>
              {filters.map((filter, i) => {
                return (
                  <Box key={i} className={classes.filterSpace}>
                    {filterChoice(filter)}
                  </Box>
                )
              })}
            </Box>
          </Collapse>
        </Box>
      </Box>
    )
  }
)

export default FilterBox
