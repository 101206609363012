import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Container } from '@material-ui/core'

import { PasswordForm } from './password-form.component'

export const PasswordProfile = () => {
  const { formatMessage } = useIntl()

  return (
    <Container maxWidth="sm">
      <Box bgcolor="common.white" p={4} borderRadius={4}>
        <Typography variant="h4" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.pwd.title' })}
        </Typography>
        <PasswordForm />
      </Box>
    </Container>
  )
}
