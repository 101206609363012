import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { BasicListItem } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'

export const JCAInspSummary = () => {
  const { formatMessage } = useIntl()
  const { addresses, company } = useInspectionsContext()

  const getPendingAnomalies = (address) => {
    return address.anomalias
      .filter(
        (anomaly) =>
          !address.solvedAnomalies.some((solvedAnomaly) => solvedAnomaly.key === anomaly.key)
      )
      .map((anomaly, j) => {
        return (
          <Typography key={j} variant="body2">
            - {anomaly.value}
          </Typography>
        )
      })
  }

  const hasPendingAnomalies = (address) =>
    address.anomalias.length !== address.solvedAnomalies.length

  const wrapperProps =
    addresses.length > 1
      ? {
          p: 1,
          mb: 2,
          bgcolor: 'white',
          borderRadius: 6,
        }
      : {}

  return (
    <Box p={2} mt={1} bgcolor="grey.100" borderRadius={6}>
      <BasicListItem
        {...{
          label: formatMessage({ id: 'pages.inspections.fields.empresa.label' }),
          value: company.nombre,
        }}
      />
      {addresses.map((address, i) => (
        <Box {...{ key: i, ...wrapperProps }}>
          {address.cod_derivacion ? (
            <Typography variant="subtitle1" color="primary" gutterBottom>
              {address.cod_derivacion}
            </Typography>
          ) : null}
          {address.solvedAnomalies ? (
            <>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'pages.inspections.fields.solvedAnomalies.label' })}
              </Typography>
              <Box pl={1} mb={1}>
                {address.solvedAnomalies.map((anomaly, j) => {
                  return (
                    <Typography key={j} variant="body2">
                      - {anomaly.value}
                    </Typography>
                  )
                })}
              </Box>
              {hasPendingAnomalies(address) ? (
                <>
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage({ id: 'pages.inspections.fields.pendingAnomalies.label' })}
                  </Typography>
                  <Box pl={1} mb={1}>
                    {getPendingAnomalies(address)}
                  </Box>
                </>
              ) : null}
            </>
          ) : null}
          <BasicListItem
            {...{ label: 'Situación suministro', value: address.situacion_suministro }}
          />
          {address.co_ambiente ? (
            <BasicListItem {...{ label: 'CO Ambiente', value: `${address.co_ambiente} ppm` }} />
          ) : null}
          {address.co_corregido ? (
            <BasicListItem {...{ label: 'CO Corregido', value: `${address.co_corregido} ppm` }} />
          ) : null}
        </Box>
      ))}
    </Box>
  )
}
