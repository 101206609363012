import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { TextInput, SelectInput, BasicTable, FileUploaderInput } from '../../../../ui'
import { COMPANY_FORM_CONFIG, INSPECTORES_LIST_CONFIG } from '../../constants'
import { CompanyLogo } from './company-logo.component'
import { IpIBANDisplayer } from './ip-iban-displayer.component'
import { PORequests } from './po-requests.component'

export const CompanyForm = ({model = {}, isPrimaryCompany = false, getEIData}) => {
    const intl = useIntl()
    const combos = useSelector((state) => state.combos)

    const showLeftContent = (model.captadora || !isPrimaryCompany) && model.publica && !model.logo
    const formState = {
        ...model,
        acepta_financiacion: model['acepta_financiacion'] ? '01' : '00',
        publica: model['publica'] ? '01' : '00'
    }
    const inputProps = { formState, combos, intl }

    return (
        <form>
          <Box p={3} display="flex">
            <Grid container spacing={3}>
              {showLeftContent ? (
                <Grid item sm={12} md={4}>
                  <CompanyLogo
                    {...{
                      hasPublicProfile: formState.publica,
                      isPrimaryCompany,
                      model: formState,
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item sm={12} md={showLeftContent ? 8 : 12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).NOMBRE} />
                  </Grid>
                  {isPrimaryCompany ? (
                    <Grid item xs={12} sm={6}>
                      <TextInput {...COMPANY_FORM_CONFIG(inputProps).NIF} />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).RAZON} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).WEB} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).DIRECCION} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).COD_POSTAL} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).EMAIL} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput {...COMPANY_FORM_CONFIG(inputProps).TELEFONO} />
                  </Grid>
                  {formState.captadora || !isPrimaryCompany ? (
                    <>
                      {isPrimaryCompany ? (
                        <Grid item xs={12} sm={6}>
                          <TextInput {...COMPANY_FORM_CONFIG(inputProps).PUBLICA} />
                        </Grid>
                      ) : null}
                      {isPrimaryCompany && formState.publica === '01' ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <SelectInput {...COMPANY_FORM_CONFIG(inputProps).ACEPTA_F} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextInput {...COMPANY_FORM_CONFIG(inputProps).COD_ENTIDAD_FINANCIERA} />
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12}>
                        <TextInput {...COMPANY_FORM_CONFIG(inputProps).DESCRIPCION} />
                      </Grid>
                    </>
                  ) : null}
                  {isPrimaryCompany && formState.grabadora ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <SelectInput {...COMPANY_FORM_CONFIG(inputProps).CATEGORIA} />
                      </Grid>
                      <Grid item xs={12}>
                        <IpIBANDisplayer {...{ inputProps, getEIData }} />
                      </Grid>
                    </>
                  ) : null}
                  {formState.captadora || !isPrimaryCompany ? (
                    <>
                      <Grid item xs={12} style={{ margin: '15px 0 10px 0' }}>
                        <Typography variant="h6" color="primary">
                          Datos de Registro para Oferta Pública
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput {...COMPANY_FORM_CONFIG(inputProps).RESPONSABLE_LEGAL} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput {...COMPANY_FORM_CONFIG(inputProps).NIF_RESPONSABLE_LEGAL} />
                      </Grid>
                      {isPrimaryCompany ? (
                        <Grid item xs={12} sm={6}>
                          <TextInput {...COMPANY_FORM_CONFIG(inputProps).CODIGO_REG_INDUSTRIAL} />
                        </Grid>
                      ) : null}
                      {!isPrimaryCompany ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextInput {...COMPANY_FORM_CONFIG(inputProps).IBAN_OP} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FileUploaderInput
                              {...COMPANY_FORM_CONFIG(inputProps).CERTIFICADO_BANCARIO_IBAN_SWIFT}
                            />
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  <Grid item xs={12} style={{ margin: '15px 0 10px 0' }}>
                    {!isPrimaryCompany ? (
                      <PORequests
                        data={formState.ofertas_publicas}
                      />
                    ) : null}
                  </Grid>
                  {isPrimaryCompany && formState.inspectores?.length ? (
                    <Grid item xs={12}>
                      <Typography variant="h6" paragraph>
                        Listado de instaladores
                      </Typography>
                      <BasicTable config={INSPECTORES_LIST_CONFIG} data={formState.inspectores} />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      )
}