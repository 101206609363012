import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Checkbox } from '@material-ui/core'

import { useSignupFormContext } from '../../contexts'

import { useModalContext } from '../../../../ui/modal/contexts'
import { feedback } from '../../../../core/feedback'
import { mandatoryCheckboxStyles } from './mandatory-checkbox.styles'
import { ViewPDFDialog } from '../../../../ui/view-pdf-dialog'

export const MandatoryCheckbox = withStyles(mandatoryCheckboxStyles)(({ classes, doc, mssg }) => {
  const { formatMessage } = useIntl()
  const {
    formState,
    termsAndConditionsChecked,
    setTermsAndConditionsChecked,
    termsAndConditionsDisabled,
    setTermsAndConditionsDisabled,
  } = useSignupFormContext()
  const {
    actions: { open },
  } = useModalContext()

  useEffect(() => {
    setTermsAndConditionsChecked(false)
    setTermsAndConditionsDisabled(true)
  }, [formState.tipo_actividad]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDocument = () => {
    open({
      Component: ViewPDFDialog,
      data: { file: doc, setDisabled: setTermsAndConditionsDisabled, shortcutEnabled: true },
      type: 'fullScreen',
    })
  }

  const handleCheckboxInput = () => {
    if (termsAndConditionsDisabled)
      feedback('warning', formatMessage({ id: 'pages.signup.form.checkbox.warning' }))
    else setTermsAndConditionsChecked(!termsAndConditionsChecked)
  }

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        color="primary"
        size="small"
        checked={termsAndConditionsChecked}
        onClick={handleCheckboxInput}
        required
      />
      <Typography variant="body2" color="textSecondary">
        {formatMessage({ id: 'pages.signup.form.checkbox.message.1' })}
        <span className={classes.documentLink} onClick={handleOpenDocument}>
          {mssg}
        </span>
      </Typography>
    </Box>
  )
})
