import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ConfirmationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'

export const useAssociatesIP3Dialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const warnGuildEnterpriseDeletion = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.associatesIP3.dialog.delete.title' }),
        text: formatMessage({ id: 'pages.associatesIP3.dialog.delete.description' }),
        yesText: formatMessage({ id: 'global.continue' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  return {
    warnGuildEnterpriseDeletion,
  }
}
