//User

export const comboTipoActividadData = [
  { key: '01', value: 'Instalador' },
  { key: '02', value: 'Gremio' },
  { key: '03', value: 'Comercializadora' },
]

export const inputListUser = [
  'u_nombre',
  'u_apellidos',
  'u_telefono',
  'u_email',
  'u_remail',
  'u_password',
  'u_rpassword',
]

export const correctUser = {
  u_nombre: 'Ge',
  u_apellidos: 'To Ver',
  u_telefono: '666666666',
  u_email: 'getover@mail.com',
  u_remail: 'getover@mail.com',
  u_password: 'aaaaaa1A!',
  u_rpassword: 'aaaaaa1A!',
}

export const wrongCasesUser = [
  ['u_nombre', ''],
  ['u_apellidos', ''],
  ['u_telefono', ''],
  ['u_telefono', '66666A1!'],
  ['u_email', ''],
  ['u_email', 'getover@me'],
  ['u_email', 'getover.com'],
  ['u_email', '@me.com'],
  ['u_email', 'getover@.com'],
  ['u_remail', ''],
  ['u_remail', 'matches@mail.not'],
  ['u_password', ''],
  ['u_password', 'onlylowercase'],
  ['u_password', 'ONLYUPPERCASE'],
  ['u_password', 'NoSymbol4'],
  ['u_password', '123456789'],
  ['u_password', 'lowercase4numbers'],
  //['u_password', 'case WITH Sp4ces!'],
  ['u_rpassword', 'NotMatching!</3'],
]

//Empresa
export const activeInputListEmpresa = [
  'nombre',
  'tipo_actividad',
  'razon_social',
  'cif',
  'telefono',
  'web',
  'email',
  'direccion',
  'cod_codigo_postal',
  'cc_aa',
]

export const fullInputListEmpresa = [...activeInputListEmpresa, 'cod_sap']

const commonEmpresaMinimal = {
  nombre: 'Empresa',
  razon_social: 'Razon Social S.A.',
  cif: '21468845R',
  telefono: '666666666',
  email: 'getover@me.com',
  direccion: 'Carrer de Sant Tornem-hi, s.n., Vic',
  cod_codigo_postal: '08500',
  cc_aa: ['10', '12'],
}

export const correctInstaladorMinimal = {
  ...commonEmpresaMinimal,
  tipo_actividad: '01',
  registro_industrial: 'I AM A FILE',
  cod_sap: '123456B',
}

export const correctInstaladorComplete = {
  ...commonEmpresaMinimal,
  tipo_actividad: '01',
  cod_sap: '123456B',
  web: 'getover.com',
  registro_industrial: 'I AM A FILE',
}

export const correctGremioMinimal = {
  ...commonEmpresaMinimal,
  tipo_actividad: '02',
  cod_sap: '123456',
}

export const correctGremioComplete = {
  ...commonEmpresaMinimal,
  tipo_actividad: '02',
  cod_sap: '123456B',
  web: 'getover.com',
}

export const correctComercializadoraMinimal = {
  ...commonEmpresaMinimal,
  tipo_actividad: '03',
}

export const correctComercializadoraComplete = {
  ...commonEmpresaMinimal,
  tipo_actividad: '03',
  cod_sap: '123456B',
  web: 'getover.com',
}

const commonWrongCasesEmpresa = [
  ['nombre', ''],
  ['razon_social', ''],
  ['cif', ''],
  ['cif', '21468845M'],
  ['cif', '21468845!'],
  ['cif', 'Z0523833E'],
  ['telefono', ''],
  ['telefono', '6666661A!'],
  ['email', ''],
  ['email', 'getover@me'],
  ['email', 'getover.com'],
  ['email', '@me.com'],
  ['email', 'getover@.com'],
  ['direccion', ''],
  ['cod_codigo_postal', ''],
]

export const correctCasesCif = [
  ['cif', 'X3483209C'],
  ['cif', 'J60858792'],
]
export const wrongCasesInstalador = [
  ...commonWrongCasesEmpresa,
  ['cc_aa', ''],
  ['registro_industrial', ''],
  ['cod_sap', ''],
]
export const wrongCasesGremio = [...commonWrongCasesEmpresa, ['cc_aa', ''], ['cod_sap', '']]
export const wrongCasesComercializadora = [...commonWrongCasesEmpresa]

export const sap_answer_true = {
  exists: true,
  cod_sap: '312788',
}

export const sap_answer_false = {
  exists: false,
  cod_sap: null,
}
