import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAjaxContext } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const ERR_CODE_NOT_CONFIRMED = 3503

export const useLoginCalls = () => {
  const { postPublic, login } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () => {}

  const loginCall = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        login(data)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data.code === ERR_CODE_NOT_CONFIRMED) {
                  return reject('inactive_user')
                }
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resendValidation = useCallback(
    (mail) =>
      new Promise((resolve, reject) => {
        postPublic(`/usuario/resend_mail/${mail}`, { area: 'instalador' })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const pwdRecovery = useCallback((mail) => {
    return new Promise((resolve, reject) => {
      const payload = { email: mail, area: 'instaladores' }
      postPublic('/usuario/recuperar_password', payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              if (response.data.code === 4601) {
                return reject('inactive_user')
              }
              break
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loginCall,
    resendValidation,
    pwdRecovery,
  }
}
