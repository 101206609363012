import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { parse } from 'qs'

import { EIActionButtons, useEICalls, EIPanel } from '../../../features/ei-detail'
import { PageDisplayer } from '../../../ui'
import { useCombos } from '../../../features/combos'

export const EIDetail = () => {
  const { getEIPrimaryCompany } = useEICalls()
  const { id } = useParams()
  const history = useHistory()
  const params = parse(history.location.search.substring(1))
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  const [primaryCompany, setPrimaryCompany] = useState({})
  const [secondaryCompany, setSecondaryCompany] = useState({})
  const [loading, setLoading] = useState(true)

  const getEIData = () => {
    getEIPrimaryCompany(id).then(({ data }) => {
      setPrimaryCompany(data)
      params.ca && setSecondaryCompany(data.empresas_filiales.find((ef) => ef.id === parseInt(params.ca)))
      setLoading(false)
    })
  }

  useEffect(() => {
    getEIData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    history.goBack()
  }

  return (
      <PageDisplayer loading={loading} handleGoBack={handleGoBack} textGoBack={"Atrás"}>
        <EIActionButtons {...{primaryCompany}} />
        <EIPanel companyData={primaryCompany} guildCompany={secondaryCompany} getEIPrimaryCompany />
      </PageDisplayer>
  )
}

export default EIDetail
