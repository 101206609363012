import { IP_INITIAL_STATE, IP_ACTIONS_TYPES } from './ip-reducer.constants'

export const ipReducer = (state = IP_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case IP_ACTIONS_TYPES.SET_IP:
      return {
        ...state,
        ip: payload,
        loading: false,
        error: false,
      }
    case IP_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case IP_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      }
    default:
      throw new Error('unknown action')
  }
}
