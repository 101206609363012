import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedUser: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUsersData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload
    },
  },
})

export const usersReducer = usersSlice.reducer

export const { setLoading, setUsersData } = usersSlice.actions

export const setUsers = (searchParams, empresa_id) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 20, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }

  dispatch(setLoading(true))
  get(`empresa/${empresa_id}/empleados`, config)
    .then(({ data }) => {
      dispatch(
        setUsersData({
          data: data.empleados,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_usuarios: data['total_solicitudes'],
          },
        })
      )
      //dispatch(setLoading(false))
    })
    .catch(({ response }) => {
      console.error('Error recuperando usuarios', response)
      dispatch(setLoading(false))
    })
}

export const toggleUserActive = (row, users, setIsLoading) => (dispatch) => {
  setIsLoading(true)
  const { putById } = requests
  const payload = { activo: !row.activo }

  putById('/instalador', row.id, payload)
    .then(({ data }) => {
      const newUsers = users.slice()
      newUsers[row.tableData.id] = { ...newUsers[row.tableData.id], activo: data['activo'] }
      dispatch(
        setUsersData({
          data: newUsers,
        })
      )
      setIsLoading(false)
    })
    .catch(({ response }) => {
      feedback('error', response.data.message || 'No se han podido guardar los cambios')
      console.error('Error changing user state')
      setIsLoading(false)
    })
}
