import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Box } from '@material-ui/core'
import { CircularProgress, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { FormFoldingPanel, FoldingPanel, ThemeButton, useModalContext } from '../../../../ui'
import { ButtonsToEdit, ConfirmationDialog, BasicTable } from '../../../../ui'
import { I18N_ROOT, HOME_N_INSTALLATION_CONFIG } from '../../constants'
import { HOME_N_INSTALLATION_FIELDS, seguimientoPuestaGasConfig } from '../../constants'
import { CERTIFICATE_TABLE_FIELDS, APPLICATION_STATES } from '../../constants'
import { CAMPAIGN_CONFIG, CAMPAIGN_FIELDS, CAMPAIGN_OP_CONFIG } from '../../constants'
import { CAMPAIGN_OP_FIELDS, CLIENT_CONFIG, CLIENT_FIELDS } from '../../constants'
import { APPLICATION_CONFIG, APPLICATION_FIELDS } from '../../constants'
import { HeaderButtons } from '../header'
import { AppEventsTable } from '../events-table'
import { getFormattedDto } from '../../utils'
import { useAppsDetail } from '../../hooks'
import { AppInfoBtns } from './app-info-btns.component'
import { applicationStyles } from './application-detail.style'
import { EditClientBtn, EditClientIBANBtn, UploadPaymentBtn } from '../buttons'
import { RatingApp } from '../rating'
import { feedback } from '../../../../core/feedback'
import { GasMonitoringList } from '../gas-monitoring'
import { SolveAlertDialog } from '../solve-alert/solve-alert.component'

export const ApplicationDetail = withStyles(applicationStyles)(({ classes }) => {
  const intl = useIntl()
  const history = useHistory()
  const { appId } = useParams()
  const combos = useSelector((state) => state.combos)
  const { role } = useSelector((state) => state.global)
  const { getAppById, modifyBudget, putApplication, mockZeusRecording } = useAppsDetail()
  const { updateClientInfo, postGASP, putGASP, putOnTask } = useAppsDetail()
  const {
    actions: { open },
  } = useModalContext()
  const [application, setApplication] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [formState, setFormState] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [editModeComments, setEditModeComments] = useState(false)
  const [error, setError] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [isPlurifamiliar, setIsPlurifamiliar] = useState(false)

  const permitedStates = !(
    application['estado'] === APPLICATION_STATES.REJECTED.KEY ||
    application['estado'] === APPLICATION_STATES.REJECTED_2.KEY
  )

  const canUpdateApplication =
    ((role === 'gremio' && application.gremio_id) ||
      (role !== 'gremio' && !application.gremio_id)) &&
    permitedStates

  const zeusPendigStopper = application.estado === '10'

  const canEditClient =
    canUpdateApplication &&
    !application.usuario?.oauth2_id &&
    application['estado'] !== APPLICATION_STATES.REJECTED.KEY &&
    application['estado'] !== APPLICATION_STATES.REJECTED_2.KEY
  // const couldEditClient =
  //   canUpdateApplication &&
  //   application['estado'] !== APPLICATION_STATES.REJECTED.KEY &&
  //   application['estado'] !== APPLICATION_STATES.REJECTED_2.KEY
  const canEditClientIBAN =
    canUpdateApplication &&
    !application.iban &&
    application['estado'] !== APPLICATION_STATES.REJECTED.KEY &&
    application['estado'] !== APPLICATION_STATES.REJECTED_2.KEY
  const getApplication = useCallback(
    (appId) => {
      getAppById(appId)
        .then(({ data }) => {
          setApplication(data)
          setFormState({
            ...data,
            ...getFormattedDto(data['presupuesto_interior']),
          })
          setIsOpenAlert(data.pendiente_peticionario.length > 0)

          setIsPlurifamiliar(
            data.pasar_contratable &&
              data.tipo_vivienda === 'Plurifamiliar' &&
              !data?.certificado_IRG2?.id
          )
          setIsLoading(false)
        })
        .catch(() => {
          setError(true)
          setIsLoading(false)
        })
    },
    [forceRefresh] // eslint-disable-line react-hooks/exhaustive-deps
  )
  useEffect(() => {
    getApplication(appId)
  }, [appId, getApplication, forceRefresh])

  const handleGoBack = () => {
    const search = history.location.state?.prevSearch ? history.location.state.prevSearch : ''
    history.push('/applications' + search)
  }

  const handleSolveTaskAlert = (id) => {
    open({
      Component: SolveAlertDialog,
      data: { id, putOnTask, application, forceRefresh, setForceRefresh },
      closable: true,
    })
  }

  const handleSave = () => {
    // TODO Manage feedback
    setIsLoading(true)
    modifyBudget(formState).then(({ data }) => {
      setFormState({
        tipo_vivienda: application['tipo_vivienda'],
        cidi: application['cidi'],
        ...getFormattedDto(data),
      })
      setIsLoading(false)
      setEditMode(false)
    })
  }

  const handleUpdateApplication = useCallback(
    (callback) => {
      open({
        Component: ConfirmationDialog,
        data: {
          title: intl.formatMessage({
            id: 'pages.application.view.updateApplication.title',
          }),
          text: intl.formatMessage({ id: 'pages.application.view.updateApplication.text' }),
          yesText: intl.formatMessage({
            id: 'pages.application.view.updateApplication.yes',
          }),
          noText: intl.formatMessage({ id: 'pages.application.view.updateApplication.no' }),
          yesAction: callback,
        },
        type: 'centered',
      })
    },
    [intl, open]
  )

  const updateApplication = useCallback(
    (id, payload) => {
      return putApplication(id, payload)
        .then(({ data }) => {
          setApplication(data)
        })
        .catch(() => {
          console.debug('Error al actualizar la solicitud')
        })
    },
    [putApplication]
  )

  const handleChangeApplication = useCallback(
    (e) => {
      const { name, value } = e.target
      const complementaryInfo = {}
      if (name === 'contacto_instalador_fecha') {
        complementaryInfo['contacto_instalador'] = true
      }
      if (name === 'fecha_aceptacion_presupuesto_instalador') {
        complementaryInfo['aceptacion_presupuesto_instalador'] = true
      }
      const payload = { [name]: value, ...complementaryInfo }
      handleUpdateApplication(() => updateApplication(appId, payload))
    },
    [handleUpdateApplication, updateApplication, appId]
  )
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target
      setFormState((formState) => ({ ...formState, [name]: value }))
    },
    [setFormState]
  )

  const handleCancel = () => {
    setFormState({
      tipo_vivienda: application['tipo_vivienda'],
      cidi: application['cidi'],
      ...getFormattedDto(application['presupuesto_interior']),
    })
    setEditMode(false)
  }

  const handleEdit = () => setEditMode(true)

  const EditFormButtons = () => {
    return <ButtonsToEdit {...{ isLoading, editMode, handleCancel, handleEdit, handleSave }} />
  }

  const EditFormButtonsComments = () => {
    return (
      <ButtonsToEdit
        {...{
          isLoading,
          editMode: editModeComments,
          handleEdit: () => setEditModeComments(true),
          handleCancel: () => {
            setFormState({
              ...formState,
              observaciones: application['observaciones'],
            })
            setEditModeComments(false)
          },
          handleSave: () => {
            setIsLoading(true)
            updateApplication(appId, { observaciones: formState['observaciones'] })
              .then(() => {
                setIsLoading(false)
                setEditModeComments(false)
              })
              .catch(() => setIsLoading(false))
          },
        }}
      />
    )
  }

  const ApplicationExtraContent = () => {
    return (
      <Box display="flex" justifyContent="space-between">
        {canUpdateApplication ? (
          <Box display="flex">
            <EditFormButtonsComments />
          </Box>
        ) : null}
        <Box display="flex" justifyContent="space-between">
          <AppInfoBtns {...{ application, getApplication }} />
          <UploadPaymentBtn {...{ application, getApplication }} />
        </Box>
      </Box>
    )
  }

  const HomeExtraContent = () => {
    return (
      <Box>
        {canUpdateApplication && application.presupuesto_interior?.actual_acs ? (
          <EditFormButtons />
        ) : (
          ''
        )}
        <Divider light style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
        <RatingApp {...{ application, combos }} />
      </Box>
    )
  }

  const blockClient = useMemo(() => CLIENT_CONFIG({ intl }), [intl])

  const fieldListClient = useMemo(
    () =>
      CLIENT_FIELDS({
        combos,
        intl,
        application,
      }),
    [intl, combos, application]
  )

  const blockApplication = useMemo(() => APPLICATION_CONFIG({ intl }), [intl])

  const fieldListApplication = useMemo(
    () =>
      APPLICATION_FIELDS({
        combos,
        intl,
        role: role,
        editMode: editModeComments,
        onChange: handleChangeApplication,
        onChangeDirect: handleChange,
      }),
    [intl, combos, role, editModeComments, handleChangeApplication, handleChange]
  )

  const blockHome = useMemo(
    () =>
      HOME_N_INSTALLATION_CONFIG({
        intl,
        path: application['camino_solicitud'],
      }),
    [intl, application]
  )

  const fieldListHome = useMemo(
    () =>
      HOME_N_INSTALLATION_FIELDS({
        combos,
        intl,
        editMode,
        onChange: handleChange,
      }),
    [intl, combos, editMode, handleChange]
  )

  const blockCampaign = useMemo(
    () =>
      CAMPAIGN_CONFIG({
        intl,
      }),
    [intl]
  )

  const blockCampaignOp = useMemo(
    () =>
      CAMPAIGN_OP_CONFIG({
        intl,
      }),
    [intl]
  )

  const fieldListCampaign = useMemo(
    () =>
      CAMPAIGN_FIELDS({
        combos,
        intl,
      }),
    [intl, combos]
  )

  const fieldListCampaignOp = useMemo(
    () =>
      CAMPAIGN_OP_FIELDS({
        combos,
        intl,
      }),
    [intl, combos]
  )

  const cert = ['certificado_IRG1', 'certificado_IRG2', 'certificado_IRG3']
    .map((field) => ({ ...application[field], type: field.slice(-4) }))
    .filter((cert) => cert?.id)

  const handleDummyZeus = () => {
    mockZeusRecording(appId)
      .then(() => {
        feedback('success', 'La grabación se ha simulado')
      })
      .catch((err) => {
        console.debug(err)
      })
  }

  //!Object.keys(application).length
  if (isLoading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  if (error) {
    return <Box p={4}>No ha sido posible recuperar la información de la solicitud.</Box>
  }
  return (
    <Container className={classes.root} maxWidth="lg">
      {application.estado === '10' &&
      (process.env.REACT_APP_ENVIRONMENT === 'Local' ||
        process.env.REACT_APP_ENVIRONMENT === 'Development') ? (
        <div className={classes.dummyButton}>
          <ThemeButton onClick={handleDummyZeus}>Simular grabación ZEUS</ThemeButton>
        </div>
      ) : null}
      <Box height="100%" mt={[2, 4]}>
        <div className={classes.return} onClick={handleGoBack}>
          <ArrowBackIosIcon fontSize="inherit" /> {intl.formatMessage({ id: I18N_ROOT + 'return' })}
        </div>
        <Box mb={2}>
          {isPlurifamiliar && (
            <Alert severity="warning">
              {intl.formatMessage({ id: 'pages.detais.pasar_contratable.plurifamiliar.no.IRG2' })}
            </Alert>
          )}
          {isOpenAlert &&
            application.pendiente_peticionario?.map((tarea) => {
              return (
                <Alert
                  severity="warning"
                  action={
                    <ThemeButton
                      aria-label="close"
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleSolveTaskAlert(tarea.id)
                      }}
                    >
                      Hecho
                    </ThemeButton>
                  }
                >
                  {`Tienes cambios pendientes. Cuando los hayas realizado pulsa el botón de HECHO para informar al gestor, por favor :  ${tarea.observaciones_sspp}`}
                </Alert>
              )
            })}
        </Box>
        <Typography variant="h4" color="textSecondary" paragraph>
          {`${intl.formatMessage({ id: I18N_ROOT + 'title' })}`}
        </Typography>
        <HeaderButtons
          {...{
            application,
            setApplication,
            postGASP,
            putGASP,
            getAppById,
            forceRefresh,
            setForceRefresh,
          }}
        />
        <Box mb={3}>
          <div className={classes.gridRow}>
            <FormFoldingPanel
              {...{
                data: application,
                combos,
                intl,
                block: blockClient,
                fieldList: fieldListClient,
                extraContent: (
                  <>
                    {zeusPendigStopper ? null : !canEditClient ? (
                      <Alert severity="warning">
                        {intl.formatMessage({ id: I18N_ROOT + 'noEdit' })}
                      </Alert>
                    ) : null}
                    <Box display="flex" justifyContent="space-around" mt={1}>
                      {zeusPendigStopper ? null : canEditClient ? (
                        <EditClientBtn {...{ application, getApplication, updateClientInfo }} />
                      ) : null}
                      {zeusPendigStopper ? null : canEditClientIBAN ? (
                        <EditClientIBANBtn {...{ application, getApplication, putApplication }} />
                      ) : null}
                    </Box>
                  </>
                ),
              }}
            />
          </div>
          <div className={classes.gridRow}>
            <FormFoldingPanel
              {...{
                data: { ...application, observaciones: formState['observaciones'] },
                combos,
                intl,
                block: blockApplication,
                fieldList: fieldListApplication,
                extraContent: <ApplicationExtraContent />,
              }}
            />
          </div>
          {cert.length ? (
            <div className={classes.gridRow}>
              <FoldingPanel isExpanded title="Información de certificados">
                <BasicTable config={CERTIFICATE_TABLE_FIELDS} data={cert} />
              </FoldingPanel>
            </div>
          ) : null}
          <div className={classes.gridRow}>
            <FormFoldingPanel
              {...{
                data: formState,
                combos,
                intl,
                block: blockHome,
                fieldList: fieldListHome,
                extraContent: <HomeExtraContent />,
              }}
            />
          </div>

          {application.campana ? (
            <div className={classes.gridRow}>
              <FormFoldingPanel
                {...{
                  data: application,
                  combos,
                  intl,
                  block: blockCampaign,
                  fieldList: fieldListCampaign,
                }}
              />
            </div>
          ) : null}

          {application.campana_op ? (
            <div className={classes.gridRow}>
              <FormFoldingPanel
                {...{
                  data: application,
                  combos,
                  intl,
                  block: blockCampaignOp,
                  fieldList: fieldListCampaignOp,
                }}
              />
            </div>
          ) : null}

          <div className={classes.gridRow}>
            <FoldingPanel
              isExpanded={true}
              title={intl.formatMessage({ id: I18N_ROOT + 'panels.6.title' })}
            >
              <Box width="100%" pt={2}>
                <GasMonitoringList data={application} config={seguimientoPuestaGasConfig} />
              </Box>
            </FoldingPanel>
          </div>
          <div className={classes.gridRow}>
            <FoldingPanel
              isExpanded={false}
              title={intl.formatMessage({ id: I18N_ROOT + 'panels.4.title' })}
            >
              <AppEventsTable model={application} />
            </FoldingPanel>
          </div>
        </Box>
      </Box>
    </Container>
  )
})
