import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { feedback } from '../../../core/feedback'

export const useCertificatesCalls = () => {
  const { post } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const postCertificate = useCallback(
    (endpoint, data) =>
      new Promise((resolve, reject) => {
        post(`${endpoint}`, data)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'calls.certificates.success.post.description' })
            )
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    postCertificate,
  }
}
