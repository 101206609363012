export const signupConfirmationStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
  },
  panel: {
    margin: '20px 0',
    padding: '30px 60px',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  content: {
    margin: '20px 0',
  },
  icon: {
    fontSize: 80,
  },
  button: {
    textAlign: 'center',
    textDecoration: 'none',
  },
})
