import { useCallback } from 'react'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { useIntl } from 'react-intl'

export const useCombosCalls = () => {
  const { get } = requests
  const { formatMessage } = useIntl()
  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getTarifaByConsumo = useCallback(
    (cidi, config) =>
      new Promise((resolve, reject) => {
        get(`/tarifas_consumos/obtener_potencias/${cidi}`, config)
          .then(({ data = [] }) => {
            return resolve(data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getTarifaByConsumo,
  }
}
