import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useAppsCalls = () => {
  const { formatMessage } = useIntl()
  const { post, putById, put } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const surveyPost = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        post('/encuesta', { solicitud_cidi_serial_id: id })
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelPut = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes/anular_solicitud', id, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const uploadDocument = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/documento', payload, { timeout: 0 })
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const reviewCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambiar_estado/${id}/00`, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postGASPCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/gas_p', payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putGASPCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`/gas_p/${id}`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putTask = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`tareas/${id}`, payload)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.detail.panels.6.resolver_incidencia.success' })
            )
            resolve(response.data)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data?.code === 4009) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              case 500:
                if (response.data?.code === 8001) {
                  feedback(
                    'error',
                    formatMessage({ id: `global.errors.handled.code.${response.data.code}` })
                  )
                  return reject(response)
                }
                defaultError()
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    surveyPost,
    cancelPut,
    uploadDocument,
    reviewCall,
    postGASPCall,
    putGASPCall,
    putTask,
  }
}
