import React from 'react'
import { withStyles, Typography, Box } from '@material-ui/core'

import { actionButtonStyles } from './action-button.styles'

export const ActionButton = withStyles(actionButtonStyles)(({ classes, label, icon, onClick }) => {
  return (
    <Box onClick={onClick} className={classes.root}>
      <Box>{icon}</Box>
      <Box ml={1}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Box>
    </Box>
  )
})
