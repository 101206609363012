import { useCallback, useReducer } from 'react'

import { zonesReducer } from '../reducers'
import { zonesActions } from '../constants'

export const useZonesReducer = () => {
  const [state, dispatch] = useReducer(zonesReducer, {
    provincias: [],
    zips: {},
  })

  const zoneLoad = useCallback((payload) => {
    dispatch({
      type: zonesActions.SET_ZONES_DATA,
      payload: payload,
    })
  }, [])

  const zoneUpdateZip = useCallback((provincia, zip, newState) => {
    dispatch({
      type: zonesActions.CHECK_SINGLE_ZIP,
      payload: {
        provincia: provincia,
        zip: zip,
        newState: newState,
      },
    })
  }, [])

  const zoneUpdateProvincia = useCallback((provincia, newState) => {
    dispatch({
      type: zonesActions.CHECK_FULL_PROVINCE,
      payload: {
        provincia: provincia,
        newState: newState,
      },
    })
  }, [])

  const zoneUpdateFull = useCallback((newState) => {
    dispatch({
      type: zonesActions.CHECK_FULL_CC_AA,
      payload: { newState: newState },
    })
  }, [])

  const zoneUpdateFromCodes = useCallback((newState, keys) => {
    dispatch({
      type: zonesActions.CHECK_FROM_CODES,
      payload: {
        newState: newState,
        keys: keys,
      },
    })
  }, [])

  return {
    comunidad: state.comunidad,
    provincias: state.provincias,
    zips: state.zips,
    zoneLoad: zoneLoad,
    zoneUpdateZip: zoneUpdateZip,
    zoneUpdateProvincia: zoneUpdateProvincia,
    zoneUpdateFull: zoneUpdateFull,
    zoneUpdateFromCodes: zoneUpdateFromCodes,
  }
}
