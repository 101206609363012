import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DialogTitle, DialogContent, Box, Typography, Icon } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useAjaxContext } from '../../../../core/ajax'
import { MarketerUsersForm } from '../form'
import { setUsers } from '../../slices'
import { feedback } from '../../../../core/feedback'

export const MarketerUsersCreateDialogComponent = ({ close, user, setUser }) => {
  const { post } = useAjaxContext()
  const dispatch = useDispatch()
  const marketerId = useSelector((state) => state.global.comercializadora_id)
  const comercializadora_id = parseInt(marketerId)
  const searchParams = useSelector((state) => state.users.search)
  const [formState, setFormState] = useState({ comercializadora_id, rol: 'comercializadora' })
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleCreateUser = (event) => {
    event.preventDefault()
    setIsLoading(true)
    post('/instalador', formState)
      .then(({ data }) => {
        setIsLoading(false)
        toast.success(
          <>
            <Icon>check_circle</Icon>
            <Typography
              variant="body1"
              display="inline"
              style={{ verticalAlign: 'super', marginLeft: 8 }}
            >
              Usuario creado
            </Typography>
          </>
        )
        dispatch(setUsers(searchParams, comercializadora_id))
        close()
      })
      .catch(({ response }) => {
        feedback('error', response.data.message || 'No se ha podido crear el usuario')
        setIsLoading(false)
      })
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="textSecondary">
            Crear usuario
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MarketerUsersForm
          {...{
            formState,
            close,
            onSubmit: handleCreateUser,
            onChange: handleChangeForm,
            isLoading,
          }}
        />
      </DialogContent>
    </Box>
  )
}
