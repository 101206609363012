import { requests } from '../../../core/ajax'
const { get, getById } = requests

export const getDataForm = (cidi) => {
  return new Promise((resolve, reject) => {
    getById('/potenciales/formulario_data', cidi, {
      params: { tipo: 'instalador' },
    }).then(({ data }) => resolve(data))
  })
}

export const getEmpresaCAByZip = (id_installer, zip) => {
  return new Promise((resolve, reject) => {
    get(`/instalador/chek_emprsea_ca_by_cp/${id_installer}/${zip}`).then(({ data }) =>
      resolve(data)
    )
  })
}

export const getEmpresasByCA = (id_gremio, cc_aa) => {
  return new Promise((resolve, reject) => {
    get(`/gremios/${id_gremio}/empresas`, {
      params: { comunidad_autonoma: cc_aa, _num_x_pagina: 0 },
    }).then((data) => {
      resolve(data)
    })
  })
}

export const getAppByCidi = (cidi) => {
  return new Promise((resolve, reject) => {
    getById('/solicitudes/cidi', cidi).then(({ data }) => {
      resolve(data)
    })
  })
}

export const getUserConsistency = (email, identifier) => {
  return new Promise((resolve, reject) => {
    get(`/usuario/usuario_valido/${email}/${identifier}`).then(({ data }) => {
      resolve(data)
    })
  })
}
export const checkClientIdentification = (id_solicitud, email, identifier) => {
  return new Promise((resolve, reject) => {
    getById(`/usuario/check_exterior_user`, id_solicitud, {
      params: { identificador: identifier, email: email },
    })
      .then(({ data }) => {
        return resolve(data)
      })
      .catch(({ response }) => {
        let message
        switch (response.data.code) {
          case 3512:
            message = 'Este mail es inválido o no existe.'
            break
          case 3635:
            message = 'Este NIF ya está enlazado con otro correo electrónico'
            break
          case 3636:
            message = 'Este correo electrónico ya está enlazado con otro NIF'
            break
          case 3637:
            message =
              'Ya existe una solicitud con diferente correo electrónico/NIF para este mismo punto de suministro'
            break
          default:
            message = response.data.message || 'El usuario no es válido'
            break
        }
        return reject({ error: message })
      })
  })
}

export const getGestorGC = (municipioId, cod_cp, uso) => {
  return new Promise((resolve, reject) => {
    get(`/zona_gran_consumo/gestor/${cod_cp}`, { params: { uso: uso, mercado_id: municipioId } })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        if (!response) {
          return reject('No response')
        }
        switch (response.status) {
          case '404': //Permisson denied by role
            return reject(response)
          default:
        }
        return reject(response)
      })
  })
}
export const getTarifaByConsumo = (config) =>
  new Promise((resolve, reject) => {
    get(`/tarifas_consumos/obtener_potencias`, config)
      .then(({ data = [] }) => {
        return resolve(data)
      })
      .catch(({ response }) => {
        if (!response) {
          return reject('No response')
        }
        switch (response.status) {
          case '403': //Permisson denied by role
            return reject(response)
          default:
        }
        return reject(response.status)
      })
  })

export const getConsumoByPotencia = (config) =>
  new Promise((resolve, reject) => {
    get(`/tarifas_consumos/obtener_consumos`, config)
      .then(({ data = [] }) => {
        return resolve(data)
      })
      .catch(({ response }) => {
        if (!response) {
          return reject('No response')
        }
        switch (response.status) {
          case '403': //Permisson denied by role
            return reject(response)
          default:
        }
        return reject(response.status)
      })
  })
