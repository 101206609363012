import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography, CircularProgress } from '@material-ui/core'

import { MarketerForm } from './marketer-form.component'
import { useCompanyProfileContext } from '../../contexts'
import { OpDocumentsOnProfile } from './marketer-op-documents.component'

export const MarketerCompany = () => {
  const { formatMessage } = useIntl()
  const global = useSelector((state) => state.global)
  const { loadingCompany, getMarketerCompany } = useCompanyProfileContext()

  useEffect(
    () => getMarketerCompany(global.comercializadora_id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (loadingCompany) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  return (
    <Box bgcolor="common.white" p={4} borderRadius={4}>
      <Typography variant="h4" color="primary" paragraph>
        {formatMessage({ id: 'pages.profile.marketer.title' })}
      </Typography>
      <MarketerForm />
      <Box mt={7}>
        <OpDocumentsOnProfile />
      </Box>
    </Box>
  )
}
