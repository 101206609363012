import React from 'react'

import { CompanyProfileProvider } from '../contexts'
import { CompanyProfile } from './company-profile.component'

export const CompanyProfileComponent = () => {
  return (
    <CompanyProfileProvider>
      <CompanyProfile />
    </CompanyProfileProvider>
  )
}
