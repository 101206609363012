import { Box } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { TextInputReplace } from '../../../../ui/text-input'

const EMPRESA_INFO = ({ data, intl }) => {
  return {
    NOMBRE: {
      name: 'nombre',
      value: data['nombre'],
      title: 'Nombre',
    },
    TELEFONO: {
      name: 'telefono',
      value: data['telefono'],
      title: 'Teléfono',
    },
  }
}

export const EmpresaInfo = ({ empresa, empresas }) => {
  const user = useSelector((state) => state.global)
  const intl = useIntl()
  const data = {
    ...EMPRESA_INFO({ data: { ...empresa }, intl }),
  }
  return (
    <Box display="flex" justifyContent="space-between" style={{ flex: 1 }}>
      {empresa && empresa.nombre ? (
        ['NOMBRE', 'TELEFONO'].map((entry) => {
          const el = data[entry]
          return <TextInputReplace {...el} key={entry} />
        })
      ) : (
        <p>
          {user.empresa
            ? 'El instalador no está asociado a la comunidad autónoma de esta petición. Recuerda que cualquier usuario administrador de tu empresa puede configurar las zonas de operación de tu empresa y sus instaladores.'
            : empresas.length
            ? 'Elige una empresa asociada a la comunidad autónoma de esta petición para continuar.'
            : 'La asociación no gestiona ninguna empresa asociada en la comunidad autónoma de esta petición.'}
        </p>
      )}
    </Box>
  )
}

export default EmpresaInfo
