export const guildsTableStyles = {
  header: {
    height: 40,
    fontSize: 14,
    padding: '0 12px',
    color: '#1a171b',
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
    border: 'none',
  },
  cell: {
    height: 40,
    fontSize: 14,
    color: '#63666a',
    //backgroundColor: '#FFFFFF',
    padding: '0 12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    padding: 0,
    margin: 0,
  },
  success: {
    backgroundColor: '#ffffff',
  },
  failure: {
    backgroundColor: '#ffffdd',
  },
}
