import { companyDto, fileDto, userDto, guildDto, marketerDto } from '../constants'

export const assembleSubmitPayload = ({ formState, installerWorks }) => {
  let payload
  switch (formState['tipo_actividad']) {
    case '01': //Instalador
      payload = {
        administrador: userDto(formState),
        empresa: { ...companyDto(formState) },
      }
      if (installerWorks.catchment) {
        payload.empresa.captadora = true
        payload.empresa.cc_aa = formState.cc_aa
        payload.empresa.cod_sap = formState.cod_sap
        payload.empresa.aux_pendiente_renovacion = false
        if (formState.publica === '01') {
          payload.empresa.publica = true
          payload.empresa.descripcion = formState.descripcion
          if (formState.logo) payload.empresa.logo = fileDto(formState, 'logo')
          if (formState.acepta_financiacion === '01') {
            payload.empresa.acepta_financiacion = true
            payload.empresa.codigo_entidad_financiera = formState.codigo_entidad_financiera
            payload.empresa.aux_pendiente_financiacion = false
          } else {
            payload.empresa.acepta_financiacion = false
            payload.empresa.aux_pendiente_financiacion = false
          }
        } else {
          payload.empresa.publica = false
        }
      }
      if (installerWorks.ip) {
        payload.empresa.grabadora = true
        payload.empresa.iban = formState.iban
        if (formState.certificado_iban)
          payload.empresa.certificado_iban = fileDto(formState, 'certificado_iban')
        payload.empresa.categoria = formState.categoria
        payload.inspectores = formState.inspectores
      }
      return { endpoint: '/empresa', payload }
    case '02': //Gremio
      payload = {
        usuario: userDto(formState),
        gremio: guildDto(formState),
      }
      if (installerWorks.catchment) {
        payload.gremio.captadora = true
      }
      if (installerWorks.ip) {
        payload.gremio.grabadora = true
      }
      return { endpoint: '/gremios', payload }
    case '03': //Comercializadora
      payload = {
        usuario: userDto(formState),
        comercializadora: marketerDto(formState),
      }
      return { endpoint: '/comercializadoras', payload }
    default:
      console.debug('Error: "tipo_actividad" is not defined')
  }
}
