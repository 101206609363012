import React from 'react'
import { InputAdornment } from '@material-ui/core'

export const MARKETER_FORM_FIELDS = ({ formState, onChange, fm }) => ({
  NOMBRE: {
    required: true,
    name: 'nombre',
    value: formState.nombre,
    title: fm({ id: 'pages.profile.marketer.fields.name.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.name.placeholder' }),
    onChange,
  },
  RAZON_SOCIAL: {
    required: true,
    name: 'razon_social',
    value: formState.razon_social,
    title: fm({ id: 'pages.profile.marketer.fields.registered_name.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.registered_name.placeholder' }),
    onChange,
  },
  TELEFONO: {
    required: true,
    name: 'telefono',
    value: formState.telefono,
    title: fm({ id: 'pages.profile.marketer.fields.telefono.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.telefono.placeholder' }),
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    InputProps: {
      startAdornment: <InputAdornment position="start">+34</InputAdornment>,
    },
  },
  EMAIL: {
    required: true,
    name: 'email',
    value: formState.email,
    title: fm({ id: 'pages.profile.marketer.fields.email.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.email.placeholder' }),
    onChange,
    inputProps: { type: 'email' },
  },
  WEB: {
    required: false,
    name: 'web',
    value: formState.web,
    title: fm({ id: 'pages.profile.marketer.fields.web.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.web.placeholder' }),
    onChange,
  },
  DIRECCION: {
    required: true,
    name: 'direccion',
    value: formState.direccion,
    title: fm({ id: 'pages.profile.marketer.fields.address.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.address.placeholder' }),
    onChange,
  },
  CIF: {
    readOnly: true,
    name: 'cif',
    value: formState.cif,
    title: fm({ id: 'pages.profile.marketer.fields.cif.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.cif.placeholder' }),
    onChange,
  },
  DESCRIPCION: {
    required: false,
    name: 'descripcion',
    value: formState.descripcion,
    title: fm({ id: 'pages.profile.marketer.fields.description.title' }),
    placeholder: fm({ id: 'pages.profile.marketer.fields.description.placeholder' }),
    onChange,
    multiline: true,
    minRows: 4,
    rowsMax: 4,
    inputProps: { maxLength: 250 },
  },
})
