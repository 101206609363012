import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { useGuildsCalls } from './guilds-calls.hook'
import { guildsReducer } from '../reducers'
import { GUILDS_INITIAL_STATE, GUILDS_ACTIONS_TYPES } from '../reducers'

export const useGuildsReducer = () => {
  const [state, dispatch] = useReducer(guildsReducer, GUILDS_INITIAL_STATE)
  const { getGuildsApps, putAcceptCompany, deleteGuildCompany } = useGuildsCalls()
  const history = useHistory()

  const getTableData = useCallback(
    (search, gremio_id) => {
      const config = { params: search }
      dispatch({
        type: GUILDS_ACTIONS_TYPES.GUILDS_LOADING,
      })
      getGuildsApps(config, gremio_id)
        .then(({ data }) => {
          dispatch({
            type: GUILDS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['empresas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_solicitudes'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: GUILDS_ACTIONS_TYPES.GUILDS_FAILURE,
          })
        })
    },

    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: GUILDS_ACTIONS_TYPES.GUILDS_LOADING,
        })
        putAcceptCompany(gremio_id, id_empresa_ca)
          .then(({ data }) => {
            dispatch({
              type: GUILDS_ACTIONS_TYPES.UPDATE_GUILDS_COMPANY,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: GUILDS_ACTIONS_TYPES.GUILDS_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const deleteCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: GUILDS_ACTIONS_TYPES.GUILDS_LOADING,
        })
        deleteGuildCompany(gremio_id, id_empresa_ca)
          .then(({ data }) => {
            getTableData(state.search, gremio_id)
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: GUILDS_ACTIONS_TYPES.GUILDS_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = useCallback(
    (id) => {
      history.push(`/applications/${id}`)
    },
    [history]
  )

  return {
    onClickTableRow,
    getTableData,
    acceptCompany,
    deleteCompany,
    ...state,
  }
}
