import React from 'react'
import EuroIcon from '@material-ui/icons/Euro'

export const getEndAdornment = (units) => {
  switch (units) {
    case '€':
      return <EuroIcon style={{ opacity: 0.5, fontSize: 16 }} />
    default:
      return units
  }
}
