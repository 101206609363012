export const getFormattedDto = (dto) => {
  const optionKeys = [
    'gama_caldera',
    'tipo_termostato',
    'ubi_caldera_unifamiliar',
    'armario_regulacion',
  ]
  const boolKeys = ['toma_corriente', 'tomas_agua']
  for (let key in dto) {
    if (dto[key] && optionKeys.some((optionKey) => optionKey === key))
      dto[key] = dto[key].toString()
    else if (boolKeys.some((boolKey) => boolKey === key)) {
      if (dto[key]) dto[key] = '01'
      else dto[key] = '00'
    }
  }
  return dto
}
