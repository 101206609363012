import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

export const FilterSwitch = ({ disabled, label, ...props }) => {
  return (
    <FormControlLabel
      variant="outlined"
      disabled={disabled}
      label={label}
      control={<Switch {...props} />}
      labelPlacement="start"
    />
  )
}

export default FilterSwitch
