import { requests } from '../../../core/ajax'

export const useAddressCalls = () => {
  const { get,post } = requests

  const getZipMunicipalities = (cp_municipio) => {
    return new Promise((resolve, reject) => {
      get(`/puntos_suministro/obtener_cp_municipio/${cp_municipio}`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getStreets = (cp, street) => {
    return new Promise((resolve, reject) => {
      get(`/puntos_suministro/obtener_calles/${cp}/${street}`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getNumbers = (address) => {
    const { cp, calle, tipo_via } = address
    return new Promise((resolve, reject) => {
      get(`/puntos_suministro/obtener_numeros/${cp}/${calle}`, { params: { tipo_via } })
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getZipMunicipalitiesDerivaciones = (cp_municipio) => {
    return new Promise((resolve, reject) => {
      get(`/derivaciones/obtener_cp_municipio/${cp_municipio}`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getStreetsDerivaciones = (cp, street) => {
    return new Promise((resolve, reject) => {
      get(`/derivaciones/obtener_calles/${cp}/${street}`)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getNumbersDerivaciones = (address) => {
    const { cp, calle, tipo_via } = address
    return new Promise((resolve, reject) => {
      get(`/derivaciones/obtener_numeros/${cp}/${calle}`, { params: { tipo_via } })
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getDerivations = (address) => {
    const { cp, calle, numero_finca, tipo_via } = address
    return new Promise((resolve, reject) => {
      get(`/derivaciones/obtener_piso_y_resto_datos/${cp}/${calle}/${encodeURIComponent(encodeURIComponent(numero_finca))}`, {
        params: { tipo_via: tipo_via || '' },
      })
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const getVivienda = (address) => {
    const { cp, calle, numero_finca, tipo_via } = address
    return new Promise((resolve, reject) => {
      get(`/puntos_suministro/obtener_piso_y_resto_datos/${cp}/${calle}/${encodeURIComponent(encodeURIComponent(numero_finca))}`, {
        params: { tipo_via: tipo_via || '' },
      })
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  const validateCupsWithDireccion = (address) => {
    return new Promise((resolve, reject) => {
      post(`/puntos_suministro/validar_cups_direccion/`, address)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }



  return {
    getZipMunicipalities,
    getStreets,
    getNumbers,
    getZipMunicipalitiesDerivaciones,
    getStreetsDerivaciones,
    getNumbersDerivaciones,
    getDerivations,
    getVivienda,
    validateCupsWithDireccion,
  }
}
