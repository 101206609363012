import React, { useMemo } from 'react'
import { InputAdornment, Box, Checkbox, Typography } from '@material-ui/core'
import EuroIcon from '@material-ui/icons/Euro'

export const TIMEOUT_REFRESH = 400 //Milliseconds without typing before sending out a refresh info call

//Function to make sure we get numbers in the right format for € and with commas and not dots without having to change the usage of MaterialUI FormField
//The author apologizes for such an unimainative and unresourceful solution to the problem.
//Notice that it is used both in displaying the values and in updating them, with one small modification
//We want the values to be displayed with "," while we need to save them with "." to ensure a proper parsing by the back end
//Note: saving them as number would deprive the user from the possibility to add the ',' and '.' when typing
const currencyToVal = (original) => {
  const text = String(original).replace(/[^0-9.,]/g, '')
  const parts = text.split(/[.,]/)
  const separator = parts.length > 1 ? ',' : ''
  const val = parts[0] + separator + (parts.length > 1 ? parts[1].substring(0, 2) : '')
  return val
}

const EurAdornment = () => {
  const block = useMemo(() => {
    return (
      <InputAdornment position="end">
        <EuroIcon style={{ opacity: 0.5, fontSize: 16 }} />
      </InputAdornment>
    )
  }, [])
  return block
}

const ADORNMENT_INPUT_PROPS = {
  endAdornment: <EurAdornment />,
}

export const TARIFF_FORM_CONFIG = ({ formState, onChange, intl, ...extraProps }) => {
  const DEFAULT_CURRENCY_PROPS = {
    type: 'text',
    InputProps: ADORNMENT_INPUT_PROPS,
    units: '€',
    onChange: onChange
      ? (event) => {
          const correctValue = currencyToVal(event.target.value)
          return onChange({
            ...event,
            target: { name: event.target.name, value: correctValue.replace(/,/g, '.') },
          })
        }
      : null,
  }
  const txt = (field) => intl.formatMessage({ id: 'pages.tariffs.form.' + field })
  return {
    NOMBRE: {
      ...extraProps,
      custom_validation: 'required',
      text_invalid: txt('name.error'),
      value: formState['nombre'],
      name: 'nombre',
      title: txt('name'),
      placeholder: txt('name.placeholder'),
      onChange,
    },
    //Precio de la instalación
    INST_CAL: {
      ...extraProps,
      custom_validation: 'nonZero',
      text_invalid: txt('install.error'),
      value: currencyToVal(formState['calentadorInst']),
      name: 'calentadorInst',
      title: txt('install.heater'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    INST_COC: {
      ...extraProps,
      custom_validation: 'nonZero',
      text_invalid: txt('install.error'),
      value: currencyToVal(formState['cocinaInst']),
      name: 'cocinaInst',
      title: txt('install.stove'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    INST_CAL_COC: {
      ...extraProps,
      custom_validation: 'nonZero',
      text_invalid: txt('install.error'),
      value: currencyToVal(formState['calderaCocinaInst']),
      name: 'calderaCocinaInst',
      title: txt('install.heater_stove'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    //Calentadores
    CALENTADOR: {
      ...extraProps,
      value: currencyToVal(formState['calentador']),
      name: 'calentador',
      title: txt('heater.price'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CALENTADOR_MARCA: {
      ...extraProps,
      value: formState['calentadorMarca'],
      name: 'calentadorMarca',
      title: txt('common.brand'),
      placeholder: txt('placeholder.brand'),
      onChange,
    },
    CALENTADOR_MODELO: {
      ...extraProps,
      value: formState['calentadorModelo'],
      name: 'calentadorModelo',
      title: txt('common.model'),
      placeholder: txt('placeholder.model'),
      onChange,
    },
    //Calderas
    CALEFACCION: {
      ...extraProps,
      checked: formState['calefaccion'],
      name: 'calefaccion',
      color: 'primary',
      size: 'small',
      onChange: (e) => onChange({ target: { name: e.target.name, value: e.target.checked } }),
    },
    ALBANILERIA: {
      ...extraProps,
      checked: formState['trabajos_albanileria'],
      name: 'trabajos_albanileria',
      color: 'primary',
      size: 'small',
      onChange: (e) => onChange({ target: { name: e.target.name, value: e.target.checked } }),
    },
    CALDERA: {
      ...extraProps,
      value: currencyToVal(formState['caldera']),
      name: 'caldera',
      title: txt('heating.low'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CALDERA_MARCA: {
      ...extraProps,
      value: formState['calderaMarca'],
      name: 'calderaMarca',
      title: txt('common.brand'),
      placeholder: txt('placeholder.brand'),
      onChange,
    },
    CALDERA_MODELO: {
      ...extraProps,
      value: formState['calderaModelo'],
      name: 'calderaModelo',
      title: txt('common.model'),
      placeholder: txt('placeholder.model'),
      onChange,
    },
    CALDERA2: {
      ...extraProps,
      value: currencyToVal(formState['caldera2']),
      name: 'caldera2',
      title: txt('heating.mid'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CALDERA2_MARCA: {
      ...extraProps,
      value: formState['caldera2Marca'],
      name: 'caldera2Marca',
      title: txt('common.brand'),
      placeholder: txt('placeholder.brand'),
      onChange,
    },
    CALDERA2_MODELO: {
      ...extraProps,
      value: formState['caldera2Modelo'],
      name: 'caldera2Modelo',
      title: txt('common.model'),
      placeholder: txt('placeholder.model'),
      onChange,
    },
    CALDERA3: {
      ...extraProps,
      value: currencyToVal(formState['caldera3']),
      name: 'caldera3',
      title: txt('heating.hig'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CALDERA3_MARCA: {
      ...extraProps,
      value: formState['caldera3Marca'],
      name: 'caldera3Marca',
      title: txt('common.brand'),
      placeholder: txt('placeholder.brand'),
      onChange,
    },
    CALDERA3_MODELO: {
      ...extraProps,
      value: formState['caldera3Modelo'],
      name: 'caldera3Modelo',
      title: txt('common.model'),
      placeholder: txt('placeholder.model'),
      onChange,
    },
    ELEM_CALEFACCION: {
      ...extraProps,
      value: currencyToVal(formState['elemCalefaccion']),
      name: 'elemCalefaccion',
      title: txt('heating.element.basic'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    PUNTO_CALOR: {
      ...extraProps,
      value: currencyToVal(formState['puntoCalor']),
      name: 'puntoCalor',
      title: txt('heating.element.point'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TERMOSTATO: {
      ...extraProps,
      value: currencyToVal(formState['termostato']),
      name: 'termostato',
      title: txt('heating.sensor.basic'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TERMOSTATO2: {
      ...extraProps,
      value: currencyToVal(formState['termostato2']),
      name: 'termostato2',
      title: txt('heating.sensor.wireless'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TOALLERO: {
      ...extraProps,
      value: currencyToVal(formState['toallero']),
      name: 'toallero',
      title: txt('heating.element.towel'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CP_2: {
      ...extraProps,
      value: currencyToVal(formState['cp2']),
      name: 'cp2',
      title: txt('heating.complement.two'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    CP_3: {
      ...extraProps,
      value: currencyToVal(formState['cp3']),
      name: 'cp3',
      title: txt('heating.complement.three'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    //Extras
    ARMARIO_REG: {
      ...extraProps,
      value: currencyToVal(formState['armarioRegulacion']),
      name: 'armarioRegulacion',
      title: txt('extras.closet.mpb'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    ARMARIO_REG_2: {
      ...extraProps,
      value: currencyToVal(formState['armarioRegulacion2']),
      name: 'armarioRegulacion2',
      title: txt('extras.closet.mpa'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TUBO_GAS_ENT: {
      ...extraProps,
      value: currencyToVal(formState['tuboGasEnterrado']),
      name: 'tuboGasEnterrado',
      title: txt('extras.tube.meter'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    IRI_5_METROS: {
      ...extraProps,
      value: currencyToVal(formState['iri5_metros']),
      name: 'iri5_metros',
      title: txt('extras.tube.iri5'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TOMAS_AGUA: {
      ...extraProps,
      value: currencyToVal(formState['tomasAgua']),
      name: 'tomasAgua',
      title: txt('extras.complement.water'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    TOMAS_CORRIENTE: {
      ...extraProps,
      value: currencyToVal(formState['tomaCorriente']),
      name: 'tomaCorriente',
      title: txt('extras.complement.power'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
    //Certificación mercado
    CERTIFICADO: {
      ...extraProps,
      value: currencyToVal(formState['certificado']),
      name: 'certificado',
      title: txt('market.certificate'),
      placeholder: txt('placeholder.price'),
      onChange,
      ...DEFAULT_CURRENCY_PROPS,
    },
  }
}

export const GRID_ITEM_FULL = {
  item: true,
  xs: 12,
  sm: 12,
  md: 4,
}

export const GRID_ITEM_HALF = {
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
}

export const TARIFF_FORM_STRUCTURE = ({ intl }) => {
  const txt = (field) => intl.formatMessage({ id: 'pages.tariffs.form.' + field })
  //Structure of "items": array of rows on max screen size, with array of input blocks inside
  return [
    {
      title: txt('install.title'),
      items: [
        [
          { ref: 'INST_CAL', conf: GRID_ITEM_HALF },
          { ref: 'INST_COC', conf: GRID_ITEM_HALF },
          { ref: 'INST_CAL_COC', conf: GRID_ITEM_HALF },
        ],
      ],
    },
    {
      title: txt('heater.title'),
      items: [
        [
          { ref: 'CALENTADOR', conf: GRID_ITEM_HALF },
          { ref: 'CALENTADOR_MARCA', conf: GRID_ITEM_HALF },
          { ref: 'CALENTADOR_MODELO', conf: GRID_ITEM_HALF },
        ],
      ],
    },
    {
      title: txt('heating.title'),
      prepend: (inputProps) => (
        <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
          <Checkbox {...TARIFF_FORM_CONFIG(inputProps).CALEFACCION} />
          <Typography variant="body2" color="textSecondary">
            {txt('heating.checkbox')}
          </Typography>
        </Box>
      ),
      items: [
        [
          { ref: 'CALDERA', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA_MARCA', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA_MODELO', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'CALDERA2', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA2_MARCA', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA2_MODELO', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'CALDERA3', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA3_MARCA', conf: GRID_ITEM_HALF },
          { ref: 'CALDERA3_MODELO', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'ELEM_CALEFACCION', conf: GRID_ITEM_HALF },
          { ref: 'PUNTO_CALOR', conf: GRID_ITEM_HALF },
          { ref: 'TOALLERO', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'TERMOSTATO', conf: GRID_ITEM_HALF },
          { ref: 'TERMOSTATO2', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'CP_2', conf: GRID_ITEM_HALF },
          { ref: 'CP_3', conf: GRID_ITEM_HALF },
        ],
      ],
    },
    {
      title: txt('extras.title'),
      prepend: (inputProps) => (
        <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
          <Checkbox {...TARIFF_FORM_CONFIG(inputProps).ALBANILERIA} />
          <Typography variant="body2" color="textSecondary">
            {txt('extras.checkbox')}
          </Typography>
        </Box>
      ),
      items: [
        [
          { ref: 'ARMARIO_REG', conf: GRID_ITEM_HALF },
          { ref: 'ARMARIO_REG_2', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'TUBO_GAS_ENT', conf: GRID_ITEM_HALF },
          { ref: 'IRI_5_METROS', conf: GRID_ITEM_HALF },
        ],
        [
          { ref: 'TOMAS_AGUA', conf: GRID_ITEM_HALF },
          { ref: 'TOMAS_CORRIENTE', conf: GRID_ITEM_HALF },
        ],
      ],
    },
    {
      title: txt('market.title'),
      items: [[{ ref: 'CERTIFICADO', conf: GRID_ITEM_HALF }]],
    },
  ]
}

export const DEFAULT_FORM_VALUES = {
  activa: true,
  calefaccion: false,
  subvencionada: false,
}
