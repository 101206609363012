import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { setUsers } from '../../slices'
import { UsersTable } from '../table'
import { UsersFilter } from '../filter'
import { usersDashboardStyles } from './users-dashboard.styles'
import { USERS_DEFAULT_QUERY } from '../../constants'

export const UsersDashboard = withStyles(usersDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const empresa_id = useSelector((state) => state.global['empresa_id'])

  //Acquire url and parse its search options
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...USERS_DEFAULT_QUERY,
    ...urlSearchParams,
  }
  useEffect(() => {
    dispatch(setUsers(searchParams, empresa_id))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.users.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <UsersFilter />
        </Box>
        <Box>
          <UsersTable />
        </Box>
      </Container>
    </Box>
  )
})
