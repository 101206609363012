export * from './filter.constants'
export * from './table.constants'

export const APPS_FILTERS = [
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud_instalador_filtro',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_vivienda',
    comboId: 'tipo_vivienda',
    size: 'small',
    multiple: true,
  },
  {
    name: 'ccaa',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
  },
  {
    name: 'provincia',
    comboId: 'provincias',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'tipo_mercado',
    comboId: 'tipo_mercado',
    size: 'small',
    multiple: true,
  },
  {
    name: 'origen_solicitud',
    comboId: 'origen_solicitud',
    size: 'small',
    multiple: true,
  },
  {
    name: 'creada_desde',
    filterType: 'date',
    size: 'small',
    maxDateField: 'creada_hasta',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    size: 'small',
    minDateField: 'creada_desde',
  },
]

export const APPS_GUILD_FILTERS = [
  {
    name: 'eeii_id',
    filterType: 'autocomplete',
    comboId: 'empresas',
    size: 'small',
  },
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud_instalador_filtro',
    size: 'small',
    multiple: true,
  },{
    name: 'ccaa',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
  },

  {
    name: 'provincia',
    comboId: 'provincias',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'tipo_mercado',
    comboId: 'tipo_mercado',
    size: 'small',
    multiple: true,
  },
  {
    name: 'origen_solicitud',
    comboId: 'origen_solicitud',
    size: 'small',
    multiple: true,
  },
  {
    name: 'creada_desde',
    filterType: 'date',
    size: 'small',
    maxDateField: 'creada_hasta',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    size: 'small',
    minDateField: 'creada_desde',
  },
]

export const APPS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}
