import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Grid } from '@material-ui/core'

import { TextInput, NumberInput, DateInput, FileUploaderInput } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'

export const InspBillBlock = () => {
  const { formatMessage } = useIntl()
  const { formFields, confirmBillAmount } = useInspectionsContext()

  return (
    <>
      <Typography variant="h6" color="textSecondary">
        {formatMessage({ id: 'pages.inspections.bill.title' })}
      </Typography>
      <Box p={2} mt={2} bgcolor="grey.100" borderRadius={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput {...{ ...formFields.NUMERO_FACTURA }} />
          </Grid>
          <Grid item xs={12}>
            <DateInput {...{ ...formFields.FECHA_FACTURA }} />
          </Grid>
          <Grid item xs={12}>
            <NumberInput {...{ ...formFields.IMPORTE, onBlur: confirmBillAmount }} />
          </Grid>
          <Grid item xs={12}>
            <FileUploaderInput {...{ ...formFields.FACTURA }} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
