import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'

import { CERTIFICATES_FORM_STRUCTURE } from '../../constants'
import { CertificateForm } from '../certificate-form'
import { useConsultContext } from '../../contexts'

export const CertificatesFormsContainer = ({ application }) => {
  const intl = useIntl()
  const { consultData, certificateForm } = useConsultContext()

  //Form
  const [selectedForm, setSelectedForm] = useState('03')
  const handleSelectForm = (e) => setSelectedForm(e.target.value)
  const forms = CERTIFICATES_FORM_STRUCTURE({
    intl,
    market: consultData.mercado,
    finantiation: certificateForm.financiacion ? certificateForm.financiacion : false,
    applyCampaign: certificateForm.applyCampaign,
    ingreso: certificateForm.campanas?.campana?.ingreso,
    subvencion: certificateForm.campanas?.campana?.descuento,
  })
  const form = forms[selectedForm]

  if (form.length < 2) {
    return <CertificateForm form={form} application={application} />
  }
  return (
    <>
      <Box mb={2}>
        <Typography variant="body1" color="primary">
          {intl.formatMessage({ id: 'global.mandatory' })}
        </Typography>
        <FormControl component="fieldset" onChange={handleSelectForm}>
          <RadioGroup row value={selectedForm}>
            {Object.keys(forms).map((value) => {
              const label = forms[value].label
              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio size="small" color="primary" />}
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {label}
                    </Typography>
                  }
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Box>
      <CertificateForm form={form} application={application} />
    </>
  )
}
