import React, { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { withStyles, Paper } from '@material-ui/core'
import { parse } from 'qs'
import { MTableBodyRow, MTableCell } from 'material-table'

import { guildsTableStyles } from './guilds-table.styles'
import { TableUrlPagination } from '../../../../ui'
import { GUILDS_TABLE_COLUMNS, GUILD_TABLE_STATES } from '../../constants'
import { useGuildsContext } from '../../contexts'
import { DEFAULT_QUERY } from '../../reducers'
import { TableActions } from '../table-actions'
import { useCombos } from '../../../combos'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const GuildsTable = withStyles(guildsTableStyles)(({ classes }) => {
  const { formatMessage, formatDate } = useIntl()
  const combos = useCombos(['tipo_mercado'])
  const history = useHistory()
  const location = useLocation()
  const {
    data,
    pagination: { total_solicitudes: totalEntries },
    loading,
    search,
    getTableData,
  } = useGuildsContext()
  const global = useSelector((state) => state.global)

  const handleGoToDetail = (_, row) => {
    const props = {
      pathname: `/associates/${row.empresa_id}`,
      search: row.id ? `?ca=${row.id}` : '',
    }
    return history.push(props)
  }

  useCombos(['cod_zona_zeus', 'provincias'])

  useEffect(() => {
    const urlSearchParams = parse(location.search.split('?')[1])
    const searchParams = {
      ...DEFAULT_QUERY,
      ...urlSearchParams,
    }
    getTableData(searchParams, global.gremio_id)
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    const columns = [...GUILDS_TABLE_COLUMNS]

    const actionColumn = {
      field: 'actions',
      title: formatMessage({ id: 'global.actions' }),
      width: 110,
      align: 'center',
      sorting: false,
    }
    columns.push(actionColumn)

    return columns.map((column) => {
      if (column.field === 'actions') return column
      return {
        ...column,
        title: formatMessage({ id: `pages.guilds.table.columns.${column.field}.title` }),
        cellStyle: {
          ...guildsTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }
    })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Assembling and adjusting data
  const processedData = useMemo(
    () =>
      data.map((item) => {
        const formattedDates = {}
        const formattedStatus = {}
        GUILDS_TABLE_COLUMNS.forEach((column) => {
          if (column.type === 'date' && item[column.field]) {
            formattedDates[column.field] = formatDate(item[column.field])
          }
          if (column.field === 'aceptacion_gremio') {
            formattedStatus[column.field] = item[column.field]
              ? GUILD_TABLE_STATES.accepted
              : GUILD_TABLE_STATES.pending
          }
          if (column.field === 'gremio_recibe_demandas') {
            formattedStatus[column.field] = item[column.field]
              ? GUILD_TABLE_STATES.yes
              : GUILD_TABLE_STATES.no
          }
        })
        return {
          ...item,
          ...formattedDates,
          ...formattedStatus,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      tableLayout: 'fixed',
      fixedColumns: {
        right: 1,
      },
      headerStyle: guildsTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Row: (props) => {
        return (
          <MTableBodyRow
            {...props}
            className={
              props.data?.aceptacion_gremio === GUILD_TABLE_STATES.accepted
                ? classes.success
                : classes.failure
            }
          />
        )
      },
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'actions' ? <TableActions {...props} /> : props.children}
        </MTableCell>
      ),
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onRowClick={handleGoToDetail}
    />
  )
})
