export const BOILERS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '5',
}

export const BOILERS_SELECTORS = [
  {
    name: 'provincia',
    label: 'Provincia',
    selector: 'provincias',
    cleanup: ['municipio'],
    size: 'small',
    multiple: false,
    defaultFilter: 'Selecciona provincia',
  },
  {
    name: 'municipio',
    label: 'Municipio',
    selector: 'municipios',
    cleanup: ['_pagina'],
    size: 'small',
    multiple: false,
    defaultFilter: 'Selecciona municipio',
  },
]
