import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'
import { useCompanyProfileContext } from '../../contexts'
import { CoporateIdentityPanel } from '../corporate-identity-panel'
import { GUILD_FORM_FIELDS } from './guild-form.constants'
import { CompanyWorksChecklist } from '../company-works-checklist'
import { GUILD_WORKS_OPTIONS } from '../../constants'

export const GuildForm = () => {
  const { formatMessage } = useIntl()
  const { formState, changeFormState, updateGuildCompany } = useCompanyProfileContext()

  const [loading, setLoading] = useState(false)

  const handleSubmitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    updateGuildCompany()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const inputProps = { formState, onChange: changeFormState, fm: formatMessage }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CompanyWorksChecklist
              {...{ options: GUILD_WORKS_OPTIONS, formState, onChange: changeFormState }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).NOMBRE} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).RAZON_SOCIAL} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).TELEFONO} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).EMAIL} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).WEB} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).DIRECCION} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).CODIGO_POSTAL} />
          </Grid>
          <Grid item xs={12}>
            <TextInput {...GUILD_FORM_FIELDS(inputProps).DESCRIPCION} />
          </Grid>
          <Grid item xs={12}>
            <CoporateIdentityPanel />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton type="submit" loading={loading}>
          {formatMessage({ id: 'pages.profile.guild.button.save' })}
        </ThemeButton>
      </Box>
    </form>
  )
}
