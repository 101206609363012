export const publicOfferStyles = (theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    borderRadius: '3px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
  },

  docCheked: {
    color: '#F6BF00',
    cursor: 'pointer',
  },

  noChecked: {
    color: '#004a6b',
    cursor: 'pointer',
  },

  download: {
    color: '#004a6b',
    cursor: 'pointer',
  },

  card: {
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#EEEDED',
    shadowOffset: { width: 1, height: 1 },
    shadowColor: '#333',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    marginHorizontal: 4,
    marginVertical: 6,
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  origen: {
    textDecoration: 'underline',
    fontSize: 'bold',
  },
})
