//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const JCA_TABLE_COLUMNS = [
  {
    field: 'estado',
    align: 'left',
    width: 200,
    comboId: 'estados_jca_readonly',
  },
  {
    field: 'tipo_instalacion',
    align: 'left',
    width: 130,
  },
  {
    field: 'fecha_creacion_jca',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'fecha_jca',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'situacion_suministro',
    align: 'left',
    width: 180,
  },
  {
    field: 'codigo_instalacion',
    align: 'left',
    width: 230,
  },
  {
    field: 'via',
    align: 'left',
    width: 400,
    render: (rowData) =>
      `${rowData.tipo_via} ${rowData.via} ${rowData.numero}, ${rowData.poblacion} (${rowData.codigopostal}), ${rowData.provincia}`,
  },
]

export const JCA_TABLE_COLUMNS_GUILD = [
  {
    field: 'nombre_empresa',
    align: 'left',
    width: 220,
  },
  ...JCA_TABLE_COLUMNS,
]

export const JCA_EXPORT_COLUMNS = [
  { field: 'escenario' },
  { field: 'tipo_documento' },
  { field: 'id' },
  { field: 'estado' },
  { field: 'motivos_rechazo' },
  { field: 'fecha_estado', type: 'date' },
  { field: 'tipo_gas' },
  { field: 'une' },
  { field: 'tipo_instalacion' },
  { field: 'cups' },
  { field: 'cod_derivacion' },
  { field: 'provincia' },
  { field: 'poblacion' },
  { field: 'codigopostal' },
  { field: 'tipo_via' },
  { field: 'nombre_via' },
  { field: 'via' },
  { field: 'numero' },
  { field: 'anexo' },
  { field: 'bloque' },
  { field: 'escalera' },
  { field: 'piso' },
  { field: 'puerta' },
  { field: 'resultado' },
  { field: 'anomalias' },
  { field: 'cod_anomalias' },
  { field: 'situacion_suministro' },
  { field: 'fecha_jca', type: 'date' },
  { field: 'numero_factura' },
  { field: 'importe_factura' },
  { field: 'fecha_factura', type: 'date' },
  { field: 'observaciones' },
  { field: 'lopd_aceptacion' },
  { field: 'fecha_creacion_jca', type: 'date' },
  { field: 'nombre_empresa' },
  { field: 'cif_empresa' },
  { field: 'email_empresa' },
  { field: 'idusuario_empresa' },
  { field: 'iban_validado' },
  { field: 'iban_temporal' },
  { field: 'provincia_empresa' },
  { field: 'poblacion_empresa' },
  { field: 'cod_postal_empresa' },
  { field: 'reg_industrial_empresa' },
  { field: 'telefono_empresa' },
  { field: 'fecha_creacion_ip', type: 'date' },
  { field: 'identificador_inspector' },
  { field: 'nombre_instalador' },
  { field: 'apellidos_instalador' },
  { field: 'categoria_instalador' },
]
