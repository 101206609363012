export const PUBLICOFFER_STATES = {
  PENDING: {
    KEY: '20',
    DESCRIPTION: 'En Tramite',
  },
  ZEUS_AD: {
    KEY: '30',
    DESCRIPTION: 'En Tramite',
  },
  PENDING_CODE: {
    KEY: '40',
    DESCRIPTION: 'En Tramite',
  },
  PENDING_FINISH: {
    KEY: '50',
    DESCRIPTION: 'En Tramite',
  },
  ACCEPTED: {
    KEY: '60',
    DESCRIPTION: 'Adhesion Aceptada',
  },
  REJECTED: {
    KEY: '00',
    DESCRIPTION: 'Adhesion Rechazada',
  },
  ANULED: {
    KEY: '02',
    DESCRIPTION: 'Anulada por el gestor',
  },
  OUTRANGED: {
    KEY: '01',
    DESCRIPTION: 'Caducada',
  },
  CREATED: {
    KEY: '10',
    DESCRIPTION: 'Creada',
  },
}
