export const filterStyles = (theme) => ({
  input: {
    backgroundColor: '#FFFFFF',
    height: 48,
  },
  filterSpace: {
    marginRight: '16px',
    marginBottom: '8px',
    '&:empty': {
      margin: 0,
      padding: 0,
    },
  },
})
