import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { ThemeButton, InfoBox } from '../../../../ui'

const CERTIFICATES_MODAL_ITEMS = [
  'fuga',
  'ventilacion',
  'extractor',
  'materiales',
  'contacto',
  'vainas',
  'sujecion',
  'pruebas',
  'altas_espontaneas',
  'solo_aparato',
]

export const CertificateAcceptanceDialog = ({ proceed, close, EXTRA_INFO = [], fromModify }) => {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const ExtraInformation = () => {
    return EXTRA_INFO.length > 0 ? (
      <Box mt={3}>
        <ul style={{ padding: 0 }}>
          {EXTRA_INFO.map((item) => (
            <li key={item.name} style={{ listStyleType: 'none' }}>
              <InfoBox
                severity="warning"
                message={formatMessage({
                  id: 'pages.certificates.modal.items.additionalInfo.' + item.name,
                })}
              />
            </li>
          ))}
        </ul>
      </Box>
    ) : null
  }
  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.certificates.modal.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <ExtraInformation />
      <DialogContent>
        <p>{formatMessage({ id: 'pages.certificates.modal.intro' })}</p>
        <p>{formatMessage({ id: 'pages.certificates.modal.main' })}</p>
        <ul>
          {CERTIFICATES_MODAL_ITEMS.map((item) => (
            <li key={item}>{formatMessage({ id: 'pages.certificates.modal.items.' + item })}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <ThemeButton
          labelM="sm"
          loading={loading}
          color="default"
          onClick={() => {
            close()
          }}
        >
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          labelM="sm"
          color="primary"
          loading={loading}
          onClick={() => {
            setLoading(true)
            proceed(fromModify)
              .then(() => {
                setLoading(false)
                close()
              })
              .catch((err) => {
                close()
                setLoading(false)
              })
          }}
        >
          {formatMessage({ id: 'global.confirm' })}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}
