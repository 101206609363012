export const SIGNUP_COMBOS_IDS = ['tipo_actividad', 'cod_zona_zeus']

export const fileDto = (model, name) => ({
  documento: model[name].data.split(',')[1],
  extension: model[name]['type'],
  nombre: model[name]['name'].split('.')[0],
})

export const userDto = (model) => ({
  email: model['u_email'],
  nombre: model['u_nombre'],
  apellidos: model['u_apellidos'],
  telefono: model['u_telefono'],
  password: model['u_password'],
})

export const companyDto = (model) => ({
  nombre: model['nombre'],
  tipo_actividad: model['tipo_actividad'],
  razon_social: model['razon_social'],
  cif: model['cif']?.trim(),
  telefono: model['telefono'],
  web: model['web'],
  email: model['email'],
  calle: model['calle'],
  numero: model['numero'],
  piso: model['piso'] || null,
  poligono: model['poligono'] || null,
  poblacion: model['poblacion'],
  cod_codigo_postal: model['cod_codigo_postal'],
  registro_industrial: model['registro_industrial'] ? fileDto(model, 'registro_industrial') : null,
})

export const guildDto = (model) => ({
  nombre: model['nombre'],
  tipo_actividad: model['tipo_actividad'],
  razon_social: model['razon_social'],
  cif: model['cif'],
  cod_sap: model['cod_sap'],
  telefono: model['telefono'],
  web: model['web'],
  email: model['email'],
  direccion: model['direccion'],
  cod_codigo_postal: model['cod_codigo_postal'],
  aux_pendiente_renovacion: false,
  cc_aa: model.cc_aa || [],
})

export const marketerDto = (model) => {
  const payload = {
    nombre: model['nombre'],
    razon_social: model['razon_social'],
    cif: model['cif'],
    telefono: model['telefono'],
    web: model['web'],
    email: model['email'],
    direccion: model['direccion'],
    cod_codigo_postal: model['cod_codigo_postal'],
    aux_pendiente_renovacion: false,
    descripcion: model['descripcion'],
  }
  if (model.cod_sap) {
    payload.cod_sap = model.cod_sap
  }
  if (model.logo) payload.logo = fileDto(model, 'logo')
  return payload
}

export const INSTALLERS_TABLE_CONFIG = [
  { key: 'nombre', label: 'Nombre completo' },
  { key: 'identificador', label: 'NIF/NIE' },
  { key: 'categoria', label: 'Categoría' },
  { key: 'codigo', label: 'Código Instalador' },
]
