const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const themeButtonStyles = (theme) => ({
  root: {
    borderRadius: 6,
    backgroundColor: styledBy('version', {
      delete: '#dd422a',
    }),
  },
  label: {
    margin: styledBy('labelM', {
      lg: '4px 50px',
      md: '4px 30px',
      sm: '4px 20px',
      undefined: 'auto',
    }),
    textTransform: 'none',
    fontFamily: 'FSEmeric-Medium',
    fontSize: 14,
  },
  truncate: {
    display: 'inline-block',
    width: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
  },
  multiline: {
    whiteSpace: 'pre-line',
  },
})
