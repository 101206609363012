import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ConfirmationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'

export const useTooFarDialog = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const tooFar = (yesCallBack, noCallBack) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.consult.map.tooFarDialog.title' }),
        text: formatMessage({ id: 'pages.consult.map.tooFarDialog.text' }),
        yesText: formatMessage({ id: 'pages.consult.map.tooFarDialog.accept' }),
        yesAction: yesCallBack,
        noText: formatMessage({ id: 'pages.consult.map.tooFarDialog.cancel' }),
        noAction: noCallBack,
      },
      type: 'centered',
      maxWidth: 'md',
    })
  }

  return tooFar
}
