export const offersPanelStyles = (theme) => {
  return {
    link: {
      margin: '0 0.1em',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    img: {
      borderRadius: '10px',
    },
    ul: {
      listStyle: 'none',
      '& li:before': {
        content: '1',
        color: theme.palette.primary.main,
        fontWieght: 'bold',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
    },
  }
}
