import * as boilersActions from './boilers-reducer.constants'

export const boilersInitialState = {
  //Data:
  provincias: [],
  municipios: [],
  data: [],
  //General loading/error states:
  loading: false,
  msLoaded: false,
  error: false,
  //Search meta-data for "viviendas":
  search: {},
  pagination: { totalSolicitudes: 0 },
}

export const boilersReducer = (state, { type, payload }) => {
  switch (type) {
    case boilersActions.PROVINCIAS_LOAD:
    case boilersActions.MUNICIPIOS_LOAD:
    case boilersActions.CALDERAS_LOAD:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case boilersActions.PROVINCIAS_FAILURE:
    case boilersActions.MUNICIPIOS_FAILURE:
    case boilersActions.CALDERAS_FAILURE:
      return {
        ...state,
        loading: false,
        msLoaded: true,
        error: payload,
      }
    case boilersActions.SET_PROVINCIAS_DATA:
      return {
        ...state,
        loading: false,
        msLoaded: Date.now(),
        provincias: payload,
      }
    case boilersActions.SET_MUNICIPIOS_DATA:
      return {
        ...state,
        loading: false,
        msLoaded: Date.now(),
        municipios: payload,
      }
    case boilersActions.SET_CALDERAS_DATA:
      return {
        ...state,
        loading: false,
        msLoaded: Date.now(),
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
      }
    default:
      throw new Error('unknown action')
  }
}
