import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ADMIN_ROLES } from '../../../core/constants'
import { TariffsDashboard } from '../../../features/tariffs'
import { TariffDetail, TariffEdit, TariffNew, TariffZones } from '../../../features/tariff-detail'

export const TariffsPage = () => {
  const { role } = useSelector((state) => state.global)

  return ADMIN_ROLES[role] ? (
    <Switch>
      <Route exact path="/tariffs" component={TariffsDashboard} />
      <Route exact path="/tariffs/new" component={TariffNew} />
      <Route exact path="/tariffs/:tariffId" component={TariffDetail} />
      <Route exact path="/tariffs/:tariffId/edit" component={TariffEdit} />
      <Route exact path="/tariffs/:tariffId/zones" component={TariffZones} />
      <Redirect from="*" to="/tariffs" />
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/tariffs" component={TariffsDashboard} />
      <Route exact path="/tariffs/:tariffId" component={TariffDetail} />
      <Redirect from="*" to="/tariffs" />
    </Switch>
  )
}
