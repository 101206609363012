import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Grid, Box, Typography } from '@material-ui/core'

import { useConsultContext } from '../../contexts'
import { ThemeButton, AutocompleteInput, useModalContext, ConfirmationDialog } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG } from '../../constants'
import { WarningMssgDialog } from './warning-mssg.dialog'

export const ConsultAddressForm = () => {
  const intl = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { isLoading, reset, addressForm, changeAddressForm, getTownValues } = useConsultContext()
  const { townValues, getAddressValues, addressValues, numberValues } = useConsultContext()
  const { homeValues, getConsult, changeToNotNormalized } = useConsultContext()
  const installerId = useSelector((state) => state.global.user_id)

  //Transitions to non-normalized form. They happen whenever either
  // 1) a non-normalized choice is made,                         or
  // 2) all choices are made but there is still uncertainty.
  //In either case, the user controls whether the transition happens or not through a modal
  const handleUnnormalize = useCallback(
    (dataUpdate) => {
      open({
        Component: ConfirmationDialog,
        data: {
          title: intl.formatMessage({ id: 'pages.consult.dialogs.unnormalize.title' }),
          text: intl.formatMessage({ id: 'pages.consult.dialogs.unnormalize.text' }),
          yesText: intl.formatMessage({ id: 'pages.consult.dialogs.unnormalize.yesText' }),
          noText: intl.formatMessage({ id: 'pages.consult.dialogs.unnormalize.noText' }),
          yesAction: () => changeToNotNormalized(dataUpdate),
        },
        type: 'responsive',
      })
    },
    [changeToNotNormalized] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Upon selection of "other", suggest non-normalized form
  useEffect(() => {
    if (
      addressForm['vivienda']?.special === 'other' ||
      addressForm['numero']?.special === 'other'
    ) {
      handleUnnormalize({ numero: addressForm['numero']?.numero || '' })
    }
  }, [addressForm, handleUnnormalize])

  const handleSubmitForm = (e) => {
    e.preventDefault()
    getConsult(addressForm['vivienda'], installerId)
  }

  const onChange = (e) => {
    changeAddressForm(e)
    if (e?.value && typeof e?.value === 'object' && e.value.bloquear) {
      open({
        Component: WarningMssgDialog,
        data: {
          mssg: e.value.mensaje || 'No hay puntos de suministro disponibles para esta dirección',
          resetAction: reset,
        },
        type: 'responsive',
      })
    }
  }

  const inputProps = { formState: addressForm, onChange }

  return (
    <>
      <Box>
        <Typography variant="h4" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.title.1' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.addressSubtitle' })}
        </Typography>
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).CP_MUNICIPIO,
                  values: townValues,
                  onType: getTownValues,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).DIRECCION,
                  values: addressValues,
                  onType: getAddressValues,
                  inputMinLength: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).NUMERO,
                  values: [...numberValues, { special: 'other' }],
                }}
              />
            </Grid>
            <Grid item xs={12} sm>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).VIVIENDA,
                  values: [...homeValues, { special: 'other' }],
                }}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <ThemeButton
              type="submit"
              color="secondary"
              labelM="md"
              loading={isLoading}
              disabled={!addressForm['vivienda']?.cidi}
            >
              {intl.formatMessage({ id: 'pages.consult.addressSubmitButton' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </>
  )
}
