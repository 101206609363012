export const publicLayoutStyles = (theme) => ({
  root: { height: '100vh', display: 'flex', flexDirection: 'column' },
  headerWrapper: { backgroundColor: '#FFFFFF', minHeight: '80px', display: 'flex' },
  headerLeftContent: { flex: 1, marginLeft: '6%', display: 'flex', alignItems: 'center' },
  headerRightContent: { marginRight: '6%', paddingTop: 14 },
  bodyWrapper: { flex: 1 },
  footerWrapper: {
    backgroundColor: '#000000',
    color: '#f8f7f7',
    minHeight: '120px',
    textAlign: 'center',
  },
  footerContent: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 15,
    },
  },
  footerLink: { margin: '0 2%', color: '#f8f7f7', textDecoration: 'underline', cursor: 'pointer' },
})
