import React, { useState } from 'react'
import { withStyles, Box, Typography, Grid, DialogActions, Tooltip } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { ReactComponent as CancelIcon } from '../../../../../../resources/images/Delete.svg'
import { ReactComponent as ObservacionesIcon } from '../../../../../../resources/images/Comentari.svg'

import { publicOfferResumeStyles } from './public-offer-resume.styles'
import { ThemeButton, useModalContext, ConfirmationDialog } from '../../../../../../ui'
import { useCombos } from '../../../../../combos'
import { PUBLICOFFER_STATES } from '../../constants'
import { ObservacionesDialog } from './observacion.dialog'
import { feedback } from '../../../../../../core/feedback'

export const ResumePublicOfferDialog = withStyles(publicOfferResumeStyles)(
  ({
    classes,
    po,
    publicOfferData,
    cancelOp,
    addObservaciones,
    forceRefresh,
    setForceRefresh,
    setOpenResume,
  }) => {
    const { formatMessage, formatDate } = useIntl()
    const combos = useCombos(['estados_peticion_op'])
    const [ops, setOps] = useState(po)
    const {
      actions: { open },
    } = useModalContext()

    const handleCancelApp = (poId, estadoOp, currentEstado) => {
      currentEstado === PUBLICOFFER_STATES.REJECTED.KEY ||
      currentEstado === PUBLICOFFER_STATES.OUTRANGED.KEY ||
      currentEstado === PUBLICOFFER_STATES.ANULED.KEY
        ? feedback('error', 'Esta solicitud ya ha sido rechazada con anterioridad')
        : open({
            Component: ConfirmationDialog,
            data: {
              title: formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.reject.title',
              }),
              text: formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.reject.description',
              }),
              yesText: formatMessage({
                id: 'pages.application.view.documentRequestAssociation.yes',
              }),
              noText: formatMessage({ id: 'pages.application.view.documentRequestAssociation.no' }),
              yesAction: async () => {
                await cancelOp(poId, estadoOp, publicOfferData, setOps, ops)
                setForceRefresh(!forceRefresh)
              },
            },
            type: 'centered',
          })
    }

    const handleOpenDialog = (id, poObservacionesInstalador) => {
      open({
        Component: ObservacionesDialog,
        data: {
          AdhesionId: id,
          addObservaciones,
          publicOfferData,
          poObservacionesInstalador: poObservacionesInstalador,
        },
        type: 'centered',
        maxWidth: 'md',
      })
    }

    const handleAceptar = () => {
      setForceRefresh(!forceRefresh)
      setOpenResume(false)
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={2}
        height="90vh"
        overflow="none"
        minWidth="80em"
      >
        <Box m={2} flexDirection="column" position={'relative'}>
          <Box>
            <Typography variant="h6" color="primary">
              {formatMessage({
                id: 'pages.profile.company.guild.info.publicOffer.resume.title',
              })}
            </Typography>
          </Box>

          <Box mt={2} justifyContent="space-between" display="flex">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Box>
                  <Typography className={classes.title}>
                    {formatMessage({
                      id: 'pages.profile.company.guild.publicOffer.info.name.title',
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Typography className={classes.title}>
                    {formatMessage({
                      id: 'pages.profile.company.guild.publicOffer.info.status.title',
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography className={classes.title}>
                    {formatMessage({
                      id: 'pages.application.detail.panels.2.fields.observaciones.label',
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Typography className={classes.title}>
                    {formatMessage({
                      id: 'pages.profile.company.guild.publicOffer.info.hasta.title',
                    })}
                  </Typography>
                </Box>
              </Grid>

              <Grid ml={3} item xs={2}>
                <Box display="flex" justifyContent="center">
                  <Typography className={classes.title}>
                    {formatMessage({
                      id: 'global.actions',
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box flexDirection="column" display="flex" overflow="inherit" maxHeight="90%">
            {po
              ?.sort((a, b) => new Date(b.dg_ts_insert) - new Date(a.dg_ts_insert))
              .map((pa, i) => {
                return pa.estado !== PUBLICOFFER_STATES.OUTRANGED.KEY ? (
                  <Box key={i} mt={2} justifyContent="space-between" display="flex">
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <Box sx={{ maxWidth: '130px' }}>
                          <Typography className={classes.nombre}>{pa.nombre}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <Typography>
                            {combos.estados_peticion_op.data.map((combo) => {
                              return combo.key === pa.estado ? combo.value : null
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          {PUBLICOFFER_STATES.REJECTED.KEY === pa.estado
                            ? pa.observaciones_rechazo
                              ? pa.observaciones_rechazo
                              : 'Rechazado por instalador'
                            : PUBLICOFFER_STATES.ANULED.KEY === pa.estado
                            ? pa.observaciones_anulacion
                            : null}
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <Typography>{formatDate(pa.fechafin)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box justifyContent="center" display="flex">
                          <Box display="flex" justifyContent="center">
                            <Tooltip title="Observaciones">
                              <ObservacionesIcon
                                className={classes.noChecked}
                                onClick={() =>
                                  handleOpenDialog(pa.adhesion_id, pa.observaciones_instalador)
                                }
                              />
                            </Tooltip>
                          </Box>
                          <Box ml={2}>
                            <Tooltip title="Rechazar Adhesión">
                              <CancelIcon
                                className={classes.noChecked}
                                onClick={() =>
                                  handleCancelApp(
                                    pa.adhesion_id,
                                    PUBLICOFFER_STATES.REJECTED.KEY,
                                    pa.estado
                                  )
                                }
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null
              })}
          </Box>
        </Box>
        <DialogActions>
          <Box alignSelf="flex-end" display="flex" justifyContent="flex-end" right={20} bottom={20}>
            <Box display="flex">
              <ThemeButton color="primary" labelM="lg" onClick={handleAceptar}>
                {formatMessage({ id: 'global.close' })}
              </ThemeButton>
            </Box>
          </Box>
        </DialogActions>
      </Box>
    )
  }
)
