import { DateInput } from '../../../ui'

export const CAMPAIGN_FIELDS = ({ formState }) => ({
  NOMBRE: {
    value: formState['nombre'],
    name: 'nombre',
    title: 'Campaña',
    readOnly: true,
  },
  CODIGO: {
    value: formState['codigo'],
    name: 'codigo',
    title: 'Código',
    readOnly: true,
  },
  SUBVENCION: {
    value: formState['descuento'] + ' €',
    name: 'descuento',
    title: 'Subvención',
    readOnly: true,
  },
  TRANSFERENCIA: {
    value: formState['ingreso'] + ' €',
    name: 'ingreso',
    title: 'Transferencia a cliente',
    readOnly: true,
  },
  FECHAINIVIGENCIA: {
    component: DateInput,
    value: formState['fechainicio'],
    title: 'Fecha inicio',
    name: 'fechainvigencia',
    readOnly: true,
  },
  FECHAFINVIGENCIA: {
    component: DateInput,
    value: formState['fechafin'],
    title: 'Fecha fín',
    name: 'fechafin',
    readOnly: true,
  },
  MESES: {
    value: formState['meses_financiacion'] + ' meses',
    name: 'meses_financiacion',
    title: 'Meses de financiación',
    readOnly: true,
  },
  LIMITE: {
    value: formState['limite_financiacion'] + ' €',
    name: 'limite_financiacion',
    title: 'Límite financiación',
    readOnly: true,
  },
})
