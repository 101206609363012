import React, { useState, useEffect } from 'react'
import { withStyles, Box, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'

import { FileUploaderAgnostic } from '../../ui'
import { useAjaxContext } from '../../core/ajax'
import { feedback } from '../../core/feedback'
import { companyLogoStyles } from './company-logo.styles'

export const CompanyLogo = withStyles(companyLogoStyles)(
  ({ classes, model, onChange, disabled = false }) => {
    const { getById } = useAjaxContext()

    const [loadingImage, setLoadingImage] = useState(false)
    const [logo, setLogo] = useState(null)

    const getLogoImage = (id) => {
      setLoadingImage(true)
      getById('/documento', id)
        .then(({ data }) => {
          setLogo(data)
          setLoadingImage(false)
        })
        .catch(() => {
          console.error('Error fetching logo')
          setLoadingImage(false)
        })
    }

    const handleFileUploadError = (data) => feedback('error', data.mssg)

    useEffect(() => {
      if (model.logo_id && (!logo || logo.id !== model.logo_id)) getLogoImage(model.logo_id)
    }, [model.logo_id, logo]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box display="flex" justifyContent="space-around" pt={3} pb={3} width="100%">
        <Box className={classes.logoContainer}>
          {loadingImage ? (
            <CircularProgress size={40} />
          ) : (
            <div>
              {logo || model.logoNew ? (
                <img
                  src={
                    model.logoNew
                      ? model.logoNew.data
                      : `data:image/${logo.extension};base64,${logo.documento.split("'")[1]}`
                  }
                  alt="error"
                  style={{ width: '100%', objectFit: 'contain' }}
                />
              ) : (
                <CropOriginalIcon style={{ fontSize: 80 }} />
              )}
              {!disabled && (
                <FileUploaderAgnostic
                  {...{
                    name: 'logoNew',
                    maxSize: 10000000, // 10Mb
                    accept: ['image/jpg', 'image/jpeg', 'image/png'],
                    onSuccess: onChange,
                    onError: handleFileUploadError,
                  }}
                >
                  {() => (
                    <div className={classes.editLogoButton}>
                      <EditIcon />
                    </div>
                  )}
                </FileUploaderAgnostic>
              )}
            </div>
          )}
        </Box>
      </Box>
    )
  }
)
