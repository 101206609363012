import React from 'react'
import { toast } from 'react-toastify'
import { Typography, Icon } from '@material-ui/core'

export const feedback = (type, message = '', restProps) => {
  toast.dismiss()
  switch (type) {
    case 'error':
      return toast.error(
        <div style={{ display: 'flex' }}>
          <Icon>error_outlined</Icon>
          <Typography variant="body1" display="inline" style={{ marginLeft: 8 }}>
            {message}
          </Typography>
        </div>
      )
    case 'success':
      return toast.success(
        <div style={{ display: 'flex' }}>
          <Icon>check_circle</Icon>
          <Typography variant="body1" display="inline" style={{ marginLeft: 8 }}>
            {message}
          </Typography>
        </div>,
        restProps
      )
    case 'warning':
      return toast.warning(
        <div style={{ display: 'flex' }}>
          <Icon>error_outlined</Icon>
          <Typography variant="body1" display="inline" style={{ marginLeft: 8 }}>
            {message}
          </Typography>
        </div>,
        restProps
      )
    case 'info':
      return toast.info(
        <div style={{ display: 'flex' }}>
          <Icon>error_outlined</Icon>
          <Typography variant="body1" display="inline" style={{ marginLeft: 8 }}>
            {message}
          </Typography>
        </div>,
        restProps
      )
    default:
      return console.debug('Feedback Toaster: Type is not defined')
  }
}

export default feedback
