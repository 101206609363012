export const loadLocalStorage = (key) => {
  try {
    const serializedState = localStorage.getItem('dsc')
    return JSON.parse(serializedState)[key] ? JSON.parse(serializedState)[key] : undefined
  } catch (err) {
    return undefined
  }
}

export const saveLocalStorage = (key, state) => {
  const serializedState = JSON.stringify(state)
  localStorage.setItem(key, serializedState)
}
