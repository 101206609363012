export const ACTION_TYPES = {
  SET_TYPE: 'SET_TYPE',
  SET_COMPANY: 'SET_COMPANY',
  RESET_ADDRESSES: 'RESET_ADDRESSES',
  SET_LOADING: 'SET_LOADING',
  SET_LOADING_HOME_INFO: 'SET_LOADING_HOME_INFO',
  SET_VALID_CUPS: 'SET_VALID_CUPS',
  VALIDATE_INSTALLATION_STEP: 'VALIDATE_INSTALLATION_STEP',
  VALIDATE_INSPECTION_STEP: 'VALIDATE_INSPECTION_STEP',
  CHANGE_FORM: 'CHANGE_FORM',
  SET_ADDRESS_BY_CUPS: 'SET_ADDRESS_BY_CUPS',
  SET_ERROR: 'SET_ERROR',
  SET_API_ERROR: 'SET_API_ERROR',
  FETCHING_ADDRESS_BY_CUPS: 'FETCHING_ADDRESS_BY_CUPS',
  SET_ANOMALIES: 'SET_ANOMALIES',
  CHANGE_ADDRESSES: 'CHANGE_ADDRESSES',
  SET_CAN_CONTINUE: 'SET_CAN_CONTINUE',
  SET_CAN_VALIDATE_FORM: 'SET_CAN_VALIDATE_FORM',
  SET_LOADING_VALIDAR_CONTINUAR: 'SET_LOADING_VALIDAR_CONTINUAR',

}

export const A_P_FUGA_GAS_CODE = '11'
