import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const { post, putById, deleteById, put } = requests
const noResponseError = () => {
  console.error('No response!')
}
const defaultError = () => {
  console.error('Unknown error!')
}

export const postUsuario = (payload) => {
  return new Promise((resolve, reject) => {
    post('/usuario', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'No se ha podido crear el cliente')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const postProvisionalApp = (payload) => {
  return new Promise((resolve, reject) => {
    post('/solicitudes_tmp', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'No se ha creado la solicitud temporal')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const postApp = (payload) => {
  return new Promise((resolve, reject) => {
    post('/solicitudes', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          case 400:
            if (response?.data?.code === 3017 && response?.data?.message) {
              feedback('error', response.data.message)
            } else {
              defaultError()
            }
            break
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const putApp = (id_solicitud, payload) => {
  return new Promise((resolve, reject) => {
    putById('/solicitudes', id_solicitud, payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'No se ha podido actualizar la solicitud')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const postCertificate = (endpoint, data) => {
  return new Promise((resolve, reject) => {
    post(endpoint, data)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'No se ha podido crear el certificado')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const updateBudget = (id_presupuesto, payload) => {
  return new Promise((resolve, reject) => {
    putById('/presupuesto', id_presupuesto, payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'Error: No se han podido asociar los documentos a la solicitud (croquis)')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const updateAppDocs = (id_app, payload) => {
  return new Promise((resolve, reject) => {
    putById('/solicitudes/update_docs', id_app, payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'Error: No se han podido asociar los documentos a la solicitud')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}
export const rollbackApp = (id_app_tmp) => {
  return new Promise((resolve, reject) => {
    deleteById('/solicitudes/rollback/instaladores', id_app_tmp)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback(
          'error',
          'Error: El proceso de creación de la solicitud ha fallado y no se ha podido realizar un rollback'
        )
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const putGASPCall = (id, payload) => {
  return new Promise((resolve, reject) => {
    put(`/gas_p/${id}`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch(({ response }) => {
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          case '403': //Permisson denied by role
            return reject(response)
          default:
            defaultError()
        }
        return reject(response)
      })
  })
}
