import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Box } from '@material-ui/core'

import { SignupComponent } from '../../../features/signup'

import { signupStyles } from './signup.styles'

export const SignupPage = withStyles(signupStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <Box height="100%">
      <Container className={classes.container} maxWidth="md">
        <Box textAlign="center" mb={3}>
          <Typography variant="h4" gutterBottom>
            {formatMessage({ id: 'pages.signup.title' })}
          </Typography>
          <Box>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'pages.signup.description' })}
            </Typography>
          </Box>
        </Box>
        <Box bgcolor="common.white" borderRadius={4} p={4}>
          <SignupComponent />
        </Box>
      </Container>
    </Box>
  )
})
