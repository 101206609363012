import 'date-fns'
import esLocale from 'date-fns/locale/es'
import React from 'react'
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useIntl } from 'react-intl'
import { withStyles, Typography } from '@material-ui/core'

import { getISOStringInitDate } from '../../core/utils/dateUtils'
import { dateInputStyles } from './date-input.styles'

export const DateInput = React.memo(
  withStyles(dateInputStyles)(
    ({ classes, readOnly = false, minDate, maxDate, allowKeyboard = true, ...props }) => {
      const { formatMessage, formatDate } = useIntl()
      const typographyVariant = props.fontSize === 'small' ? '2' : '1'

      const handleChange = (date, value) => {
        if (date && !isNaN(date.getTime())) {
          props.onChange({ target: { name: props.name, value: getISOStringInitDate(date) } })
        }
      }

      const refuseDatePattern =
        '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'

      return (
        <div className={classes.root}>
          {props.title ? (
            <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
              {props.title}
              {props.required ? (<span style={{color: '#B3272C'}}> *</span>) : null}
            </Typography>
          ) : null}
          {readOnly ? (
            <Typography
              variant={`body${typographyVariant}`}
              color="textSecondary"
              style={{ whiteSpace: 'pre-line' }}
            >
              {props.value ? formatDate(props.value) : '--'}
            </Typography>
          ) : (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                {allowKeyboard ? (
                  <KeyboardDatePicker
                    {...props}
                    id={props.name}
                    name={props.name}
                    value={props.value || null}
                    onChange={handleChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    InputProps={{ className: classes.input }}
                    inputProps={{ pattern: refuseDatePattern }}
                    okLabel={formatMessage({ id: 'picker.ok' })}
                    cancelLabel={formatMessage({ id: 'picker.cancel' })}
                  />
                ) : (
                  <DatePicker
                    {...props}
                    id={props.name}
                    name={props.name}
                    value={props.value || null}
                    onChange={handleChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    InputProps={{ className: classes.input }}
                    okLabel={formatMessage({ id: 'picker.ok' })}
                    cancelLabel={formatMessage({ id: 'picker.cancel' })}
                  />
                )}
              </MuiPickersUtilsProvider>
            </>
          )}
        </div>
      )
    }
  )
)
