export const assembleInstallerPrimaryPutPayload = (model) => {
  let payload = {
    nombre: model.nombre,
    razon_social: model.razon_social,
    telefono: model.telefono,
    email: model.email,
    direccion: model.direccion,
    responsable_legal: model.responsable_legal,
    nif_responsable_legal: model.nif_responsable_legal,
    calle: model.calle,
    numero: model.numero,
    piso: model.piso || null,
    poligono: model.poligono || null,
    poblacion: model.poblacion,
    cod_codigo_postal: model.cod_codigo_postal,
    check_resp_sec_info: model?.check_resp_sec_info === true,
  }
  if (model.web) payload.web = model.web
  if (model.captadora) {
    payload.captadora = true
    payload.cod_sap = model.cod_sap
    payload.cc_aa = model.cc_aa
    payload.acepta_uso_marca = model.acepta_uso_marca === '01'
    payload.acepta_uso_marca_checkbox = model.acepta_uso_marca_checkbox
    payload.aux_pendiente_renovacion = false
    payload.codigo_registro_industrial = model.codigo_registro_industrial
    payload.check_proteccion_datos = model.check_proteccion_datos === true
    if (model.publica === '01') {
      payload.publica = true
      payload.descripcion = model.descripcion
      if (model.logoNew) {
        payload.logo = fileDto(model, 'logoNew')
      }
    } else {
      payload.publica = false
    }
    if (model.acepta_financiacion === '01') {
      payload.acepta_financiacion = true
      payload.codigo_entidad_financiera = model.codigo_entidad_financiera
      payload.aux_pendiente_financiacion = false
    } else {
      payload.acepta_financiacion = false
    }
    if (model.registro_industrial_nuevo) {
      payload.registro_industrial = fileDto(model, 'registro_industrial_nuevo')
    }
    if (model.documento_nif_nuevo) {
      payload.documento_nif = fileDto(model, 'documento_nif_nuevo')
    }
    if (model.documento_pf_nuevo) {
      payload.documento_pf = fileDto(model, 'documento_pf_nuevo')
    }
    if (model.formulario_rgpd_nuevo) {
      payload.formulario_rgpd = fileDto(model, 'formulario_rgpd_nuevo')
    }
  } else {
    payload.captadora = false
  }
  if (model.grabadora) {
    payload.grabadora = true
    payload.categoria = model.categoria
    if (model.iban) {
      payload.iban = model.iban.split(' ').join('')
      if (model.certificado_iban) {
        payload.certificado_iban = fileDto(model, 'certificado_iban')
      }
    }
  } else {
    payload.grabadora = false
  }

  return payload
}

export const assembleInstallerSecondaryPutPayload = (model) => {
  let payload = {
    nombre: model.nombre,
    razon_social: model.razon_social,
    telefono: model.telefono,
    email: model.email,
    acepta_condiciones_generales_op: model.acepta_condiciones_generales_op,
    acepta_uso_marca: model.acepta_uso_marca === '01',
    acepta_uso_marca_checkbox: model.acepta_uso_marca_checkbox,
    codigo_registro_industrial: model.codigo_registro_industrial,
    responsable_legal: model.responsable_legal || '',
    nif_responsable_legal: model.nif_responsable_legal || '',
    calle: model.calle,
    numero: model.numero,
    piso: model.piso || null,
    poligono: model.poligono || null,
    poblacion: model.poblacion,
    cod_codigo_postal: model.cod_codigo_postal,
    check_resp_sec_info: model?.check_resp_sec_info === true,
  }
  if (model.iban_op) {
    payload.iban_op = model.iban_op.split(' ').join('')
  }
  if (model.certificado_iban_op_nuevo) {
    payload.certificado_iban_op = fileDto(model, 'certificado_iban_op_nuevo')
  }
  if (model.web) payload.web = model.web
  if (model.publica) {
    payload.descripcion = model.descripcion
    if (model.logoNew) {
      payload.logo = fileDto(model, 'logoNew')
    }
  }
  return payload
}

const fileDto = (model, name) => ({
  documento: model[name].data.split(',')[1],
  extension: model[name]['type'],
  nombre: model[name]['name'].split('.')[0],
})

export const logoPostDto = (formState) => ({
  documento: formState.logoNew.data.split(',')[1],
  nombre: formState.logoNew.name.replace(/\.[^/.]+$/, ''),
  extension: formState.logoNew.type,
  tipo: 'logo',
  cif: formState.cif,
})

export const assembleGuildPutPayload = (formState) => {
  let payload = { ...formState }
  if (formState.acepta_uso_marca !== null) {
    payload.acepta_uso_marca = formState.acepta_uso_marca === '01'
  }
  return payload
}

export const assembleMarketerPutPayload = (formState) => {
  let payload = formState
  if (formState.logoNew) {
    delete payload.logoNew
  }
  return payload
}
