import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Tooltip,
  Icon,
} from '@material-ui/core'

import { CERTIFICATES_FORM_STRUCTURE, CERTIFICATES_DATA_TRANSFORMATION } from '../../constants'
import { CertificateForm } from '../certificate-form'
import { useCertificateContext } from '../../context'

export const CertificatesFormsContainer = ({ application }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const [selectedForm, setSelectedForm] = useState(application.mercado === 'SV' ? '03' : '01')
  const handleSelectForm = (e) => setSelectedForm(e.target.value)

  const [formState, setFormState] = useState({})
  useEffect(() => {
    setFormState(CERTIFICATES_DATA_TRANSFORMATION({ application, combos }))
  }, [combos, application])

  const forms = CERTIFICATES_FORM_STRUCTURE({ application, intl, formState })
  const { wasFinanciacion } = useCertificateContext()
  const form = forms[selectedForm]

  // let initialSelectedForm
  // for (let key in forms) {
  //   if (!forms[key].hidden) {
  //     initialSelectedForm = key
  //     break
  //   }
  // }

  // const [selectedForm, setSelectedForm] = useState(initialSelectedForm)

  return (
    <>
      <Box mb={2}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Typography variant="body1" color="primary">
            {intl.formatMessage({ id: 'global.mandatory' })}
          </Typography>
        </Box>
        <FormControl component="fieldset" onChange={handleSelectForm}>
          <Typography variant="body1" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'pages.certificates.picker.title' })}
          </Typography>
          <RadioGroup row value={selectedForm}>
            {Object.keys(forms).map((value) => {
              const label = forms[value].label
              return forms[value].hidden ? null : (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio size="small" color="primary" />}
                  label={
                    <>
                      <Typography variant="body1" color="textSecondary" display="inline">
                        {label}
                      </Typography>
                      {forms[value].tooltip ? (
                        <span style={{ fontSize: 16, marginLeft: 4 }}>
                          <Tooltip
                            placement="top"
                            title={
                              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                {forms[value].tooltip}
                              </Typography>
                            }
                          >
                            <Icon fontSize="inherit">help_outline</Icon>
                          </Tooltip>
                        </span>
                      ) : null}
                    </>
                  }
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Box>
      <CertificateForm
        form={form}
        application={application}
        formState={formState}
        setFormState={setFormState}
      />
    </>
  )
}
