export const USER_PROFILE_ACTION_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_USER: 'SET_USER',
}

export const USER_PROFILE_INITIAL_STATE = {
  loadingUser: true,
  error: {},
  user: {},
}
