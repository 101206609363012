import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { PUT_BUDGET_PAYLOAD } from '../constants'
import { useAppsCalls } from './application-calls.hook'

export const useAppsDetail = () => {
  const { formatMessage } = useIntl()
  const { getById, post, put, putById } = requests
  const { cancelPut, uploadDocument, reviewCall, postGASPCall, putGASPCall, putTask } =
    useAppsCalls()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getAppById = (id) =>
    new Promise((resolve, reject) => {
      getById('/solicitudes', id)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const putApplication = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes', id, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelApplication = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        cancelPut(id, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const appJoinGuild = useCallback((id_application, id_empresa_ca_gremio_id) => {
    return new Promise((resolve, reject) => {
      put(`/solicitudes/asignar_solicitud_a_gremio/${id_application}/${id_empresa_ca_gremio_id}`)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              feedback('error', formatMessage({ id: 'calls.application.error.put.description' }))
              return reject(response.status)
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const modifyBudget = (formState) =>
    new Promise((resolve, reject) => {
      const payload = PUT_BUDGET_PAYLOAD(formState)
      for (let key in payload) {
        if (payload[key] === null) delete payload[key]
      }
      putById('/presupuesto_interior', formState['id'], payload)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const requestReview = useCallback(
    (appId, clientId, payload, file) =>
      new Promise((resolve, reject) => {
        if (file) {
          const document = {
            documento: file.data.split(',')[1],
            nombre: file.name.replace(/\.[^/.]+$/, ''),
            tipo: 'FC',
            extension: file.type,
            cliente_id: parseInt(clientId),
            solicitud_id: appId.toString(),
            save_event: false,
          }
          uploadDocument(document)
            .then(({ data }) => {
              payload['list_doc_id'] = data['id'].toString()
              reviewCall(appId, payload)
                .then(({ data }) => resolve(data))
                .catch((response) => {
                  return reject(response)
                })
            })
            .catch((response) => {
              return reject(response)
            })
        } else {
          reviewCall(appId, payload)
            .then(({ data }) => resolve(data))
            .catch((response) => {
              return reject(response)
            })
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const uploadPaymentFile = useCallback(
    (application, fileData) =>
      new Promise((resolve, reject) => {
        const payload = {
          comprobante_id: fileData.id,
        }
        putById('/presupuesto', application['presupuesto_exterior']['id'], payload)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateClientInfo = (id, payload) =>
    new Promise((resolve, reject) => {
      putById('/usuario', id, payload)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              if (response.data?.code === 3508) {
                return reject('usuario_existe')
              }
              defaultError()
              break
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const mockZeusRecording = (id) =>
    new Promise((resolve, reject) => {
      post('/scr/zeus_dummy', {
        solicitud_id: id,
        cod_solicitud: 'SOL_' + id,
        cod_expediente: 'EXP_TEST_' + id,
        estado_pago_oferta: 'Tratado en Facturación OK',
        estado_certificacion: 'Registrada en SAP',
      })
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const postGASP = useCallback(
    (payload, appId) =>
      new Promise((resolve, reject) => {
        postGASPCall(payload)
          .then(() => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.view.requestGasP.call_success_post.title' })
            )
            getAppById(appId)
          })
          .then(() => resolve())
          .catch(() => {
            feedback(
              'error',
              formatMessage({ id: 'pages.application.view.requestGasP.call_error.title' })
            )
            return reject('Error')
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putGASP = useCallback(
    (payload, gas_pId, appId) =>
      new Promise((resolve, reject) => {
        putGASPCall(gas_pId, payload)
          .then(() => {
            feedback(
              'success',
              formatMessage({ id: 'pages.application.view.requestGasP.call_success_put.title' })
            )
            getAppById(appId)
          })
          .then(() => resolve())
          .catch(() => {
            feedback(
              'error',
              formatMessage({ id: 'pages.application.view.requestGasP.call_error.title' })
            )
            return reject('Error')
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putOnTask = useCallback(
    (id, payload, application) =>
      new Promise((resolve, reject) => {
        putTask(id, payload)
          .then((response) => {
            getAppById(application.id)
            resolve(response)
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const acceptBudgetCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        putById('/presupuesto/aceptar', id, { aceptacion: true })
          .then((response) => resolve(response))
          .catch(({ response }) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getAppById,
    cancelApplication,
    appJoinGuild,
    modifyBudget,
    requestReview,
    putApplication,
    uploadPaymentFile,
    updateClientInfo,
    mockZeusRecording,
    postGASP,
    putGASP,
    putOnTask,
    acceptBudgetCall,
  }
}
