import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Paper } from '@material-ui/core'
import MaterialTable, { MTableCell } from 'material-table'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const tableStyles = (theme) => ({
  root: {
    padding: 0,
  },
})

const styles = {
  header: {
    fontSize: 14,
    padding: 0,
    color: '#1a171b',
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
    border: 'none',
  },
  cell: {
    fontSize: 14,
    color: '#63666a',
    backgroundColor: '#FFFFFF',
    padding: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    padding: 0,
    margin: 0,
  },
}

export const TableComponent = withStyles(tableStyles)(({ classes, columns, data, ...props }) => {
  const { formatMessage } = useIntl()
  const Actions = props.actionsComponent

  const formattedColumns = [
    ...columns,
    {
      field: 'actions',
      title: formatMessage({ id: 'global.actions' }),
      width: 80,
      align: 'center',
      sorting: false,
    },
  ].map((column) => {
    if (column.field === 'actions') return column
    return {
      ...column,
      title: formatMessage({ id: `pages.applications.table.columns.${column.field}.title` }),
      cellStyle: styles.cell,
      emptyValue: () => <span>--</span>,
      lookup:
        column.hasOwnProperty('comboId') &&
        props.combos[column.comboId] &&
        props.combos[column.comboId].data
          ? getLookup(props.combos[column.comboId].data)
          : null,
    }
  })

  const processedData = data.map((item) => {
    let isRowChecked = false
    isRowChecked = props.selectedRows.some((selectedRow) => selectedRow['id'] === item['id'])
    return {
      ...item,
      tableData: {
        ...item.tableData,
        checked: isRowChecked,
      },
    }
  })

  return (
    <MaterialTable
      columns={formattedColumns}
      data={processedData}
      options={{
        toolbar: false,
        selection: true,
        fixedColumns: {
          right: 1,
        },
        // tableLayout: 'fixed',
        headerStyle: styles.header,
        draggable: false,
      }}
      isLoading={props.loading}
      localization={{
        pagination: {
          labelDisplayedRows: 'Mostrando {from}-{to} de {count}',
          labelRowsSelect: 'registros',
        },
      }}
      components={{
        Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
        Cell: (props) => (
          <MTableCell {...props} classes={classes}>
            {props.columnDef.field === 'actions' ? <Actions {...props} /> : props.children}
          </MTableCell>
        ),
      }}
      onSelectionChange={props.onSelectionChange}
      onPageChange={props.onChangePage}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onOrderChange={props.onOrderChange}
    />
  )
})
