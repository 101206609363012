import React, { useMemo } from 'react'
import { withStyles, Tooltip, Icon, Typography, Box } from '@material-ui/core'
import { Rating } from '@material-ui/lab'

import { ratingInputStyles } from './rating-input.styles'

export const RatingInput = React.memo(
  withStyles(ratingInputStyles)(
    ({ classes, readOnly = false, explicit = false, size = 'medium', ...props }) => {
      const tooltip = useMemo(() => {
        return props.tooltip ? (
          <span style={{ fontSize: 16, marginLeft: 4 }}>
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          </span>
        ) : null
      }, [props.tooltip])

      const typographyVariant = props.fontSize === 'small' ? '2' : '1'

      const handleChange = (_, value) => {
        props.onChange({ target: { name: props.name, value } })
      }

      return (
        <>
          {props.title ? (
            <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
              {props.title + (tooltip ? tooltip : '')}
              {props.required ? (<span style={{color: '#B3272C'}}> *</span>) : null}
            </Typography>
          ) : null}
          <Box display="flex" alignItems="center">
            <Rating
              {...{
                name: props.name,
                value: props.value,
                readOnly,
                size: readOnly ? size : 'large',
                onChange: handleChange,
              }}
            />
            {readOnly && explicit ? (
              <Box ml={1}>
                <Typography variant={`body${typographyVariant}`}>{props.value}</Typography>
              </Box>
            ) : null}
          </Box>
        </>
      )
    }
  )
)
