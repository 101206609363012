import React from 'react'

import { ConsultStepsController } from './steps-controller'
import { ConsultProvider } from '../contexts'
import { useConsultActions } from '../actions'

export const ConsultComponent = () => {
  const state = useConsultActions()

  return (
    <ConsultProvider value={state}>
      <ConsultStepsController />
    </ConsultProvider>
  )
}
