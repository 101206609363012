import { DocumentsActions } from '../components/documents-view/documents-actions.component'

export const CURRENT_DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
  {
    key: 'id',
    label: 'Acciones',
    type: 'component',
    component: DocumentsActions,
  },
]

export const APPLICATION_DOCUMENT_KEYS = [
  'PR',
  'PRA',
  'FI',
  'CI',
  'AC',
  'AI',
  'AR',
  'CA',
  'CC',
  'CH',
  'CM',
  'CP',
  'CPI',
  'CR',
  'DN',
  'EC',
  'FC1',
  'FC2',
  'FC3',
  'SG',
  'SP',
  'IRG1',
  'IRG2',
  'IRG3',
  'CCEE',
  'CB',
  'RC',
  'EF',
  'tallo_i',
  'croquis_i',
  'GASPP',
  'GASPEP',
  'GASPDN',
  'GASPCC',
  'GASPPG',
  'GASPPC',
]
