export const menuStyles = (theme) => ({
  horizontal: {
    display: 'flex',
    textAlign: 'center',
    height: '100%',
  },
  menuIcon: {
    color: theme.palette.common.black,
    fontSize: 28,
  },
  closeIcon: {
    color: theme.palette.common.black,
    fontSize: 35,
  },
  menuItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      fontSize: 20,
    },
  },
  selectedMenuItem: {
    boxSizing: 'border-box',
    color: theme.palette.primary.main,
    background: 'linear-gradient(45deg, #fff 30%, #fff 90%)',
    borderBottom: '3px solid #004a6b',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      borderRight: '3px solid #004a6b',
    },
  },
})
