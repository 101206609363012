import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { useSignupFormContext } from '../../../contexts'
import { useAjaxContext } from '../../../../../core/ajax'
import { TextInput, SelectInput } from '../../../../../ui'
import { COMPANY_FORM_CONFIG, GUILD_WORKS_OPTIONS } from '../constants'
import { useSignupDialogs } from '../../../hooks/useSignupDialog'
import { WorksChecklist } from '../works-checklist.component'

export const GuildCompanyForm = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { formState, onChange, installerWorks } = useSignupFormContext()
  const { getPublic } = useAjaxContext()
  const { warnWrongCif } = useSignupDialogs()
  const inputProps = { formState, onChange, combos, intl }

  const handleFetchSAPCodeByCIF = () => {
    getPublic(`/proveedores_sap/gremios/existsbycif/${formState['cif']}`)
      .then(({ data }) => {
        if (!data['exists']) {
          throw new Error('not_found')
        }
        onChange({ target: { name: 'cod_sap', value: data['cod_sap'] } })
      })
      .catch(() => {
        warnWrongCif()
        onChange({ target: { name: 'cif', value: '' } })
        onChange({ target: { name: 'cod_sap', value: '' } })
      })
  }

  useEffect(() => {
    if (formState.cif?.length === 9) {
      handleFetchSAPCodeByCIF()
    }
  }, [formState.cif]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xs={12}>
        <WorksChecklist {...{ options: GUILD_WORKS_OPTIONS }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).RAZON }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CIF }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_SAP }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).TELEFONO }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).WEB }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).EMAIL }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).DIRECCION }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_POSTAL }} />
      </Grid>
      {installerWorks.catchment ? (
        <Grid item xs={12} sm={6}>
          <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CCAA }} />
        </Grid>
      ) : null}
    </>
  )
}
