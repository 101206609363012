import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Tooltip } from '@material-ui/core'

import { useWidth } from '../../../../core/utils'
import { publicLayoutStyles } from './public-layout.styles'
import { FOOTER_LINKS, CONTACT_PHONE, DEFAULT_ROUTE, APP_VERSION_MSSG } from '../constants'
import { ReactComponent as LogoNedgiaFull } from '../../../../resources/images/logo_nedgia_full.svg'
import { ReactComponent as LogoNedgia } from '../../../../resources/images/logo_nedgia.svg'

export const PublicLayoutComponent = withStyles(publicLayoutStyles)(({ children, classes }) => {
  let { formatMessage } = useIntl()
  let screenWidth = useWidth()
  const isMobileWidth = screenWidth === 'xs'

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.headerLeftContent}>
          <Tooltip title={APP_VERSION_MSSG}>
            <Link to={DEFAULT_ROUTE}>{isMobileWidth ? <LogoNedgia /> : <LogoNedgiaFull />}</Link>
          </Tooltip>
        </div>
        <div className={classes.headerRightContent}>
          <Typography variant="h6">
            {formatMessage({ id: 'layout.public.header.right.upper.label' })}
          </Typography>
          <Typography variant="body1">
            {formatMessage(
              { id: 'layout.public.header.right.bottom.label' },
              { phone: CONTACT_PHONE }
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.bodyWrapper}>{children}</div>
      <div className={classes.footerWrapper}>
        <div className={classes.footerContent}>
          <Typography variant="body1" paragraph>
            {FOOTER_LINKS.map((item, i) => (
              <span key={i}>
                <span className={classes.footerLink} onClick={() => window.open(item.link)}>
                  {formatMessage({ id: item.id })}
                </span>
                {i !== FOOTER_LINKS.length - 1 && '|'}
              </span>
            ))}
          </Typography>
          <Typography variant="body1">
            {formatMessage({ id: 'layout.public.footer.bottom.label' })}
          </Typography>
        </div>
      </div>
    </div>
  )
})
