import React from 'react'
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { SelectInput } from '../../../../ui'
import { REQUIRE_NUMBER } from '../../constants'
import { useConsultContext } from '../../contexts'

export const NormalizeForm = ({ formState, onChange, fieldList }) => {
  const cases = useSelector((state) => state.combos.uso_punto_suministro?.data)
  const { customerForm } = useConsultContext()

  return (
    <Box mb={2}>
      <FormControl component="fieldset" onChange={onChange}>
        Uso (*):{' '}
        <RadioGroup row value={formState['normalization_case']}>
          {cases?.map(({ key, value }) => {
            return (
              <FormControlLabel
                key={key}
                value={key}
                checked={customerForm.uso === key}
                name="uso"
                control={<Radio size="small" color="primary" />}
                label={
                  <Typography variant="body1" color="textSecondary">
                    {value}
                  </Typography>
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>
      {REQUIRE_NUMBER.indexOf(formState['uso']) !== -1 ? (
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            mb={1}
            width="20em"
            justifyContent="space-between"
          >
            <Box mr={2}>
              <Typography variant="subtitle1">{'Potencia'}</Typography>
            </Box>
            <Box alignSelf="flex-end" width={200}>
              <SelectInput {...{ ...fieldList['POTENCIA'] }} />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mb={1}
            width="20em"
            justifyContent="space-between"
          >
            <Box mr={2}>
              <Typography variant="subtitle1">{'Consumo'}</Typography>
            </Box>
            <Box alignSelf="flex-end" width={200}>
              <SelectInput {...{ ...fieldList['CONSUMO'] }} />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Box>
  )
}

export default NormalizeForm
