import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, BasicTable } from '../../../../ui'
import { CURRENT_DOCUMENTS_LIST_CONFIG, APPLICATION_DOCUMENT_KEYS } from '../../constants'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'

export const DocumentsViewComponent = ({ classes, close, model, setApplication }) => {
  const { formatMessage } = useIntl()

  const [currentDocuments, setCurrentDocuments] = useState([])
  useEffect(() => {
    getListItems(model)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getListItems = (model) => {
    let currentDocuments = []
    APPLICATION_DOCUMENT_KEYS.forEach((key) => {
      const doc = DOCUMENT_ATTACHMENT_CONFIG[key]
      if (doc.subfield && !model[doc.subfield]) {
        return
      }
      const data = doc.subfield ? model[doc.subfield][doc.model] : model[doc.model]
      const estado = doc.subfield ? model[doc.subfield].estado : null
      if (data) {
        currentDocuments.push({
          ...doc,
          ...data,
          estado,
          tipo: formatMessage({
            id: `pages.application.view.documentViewer.documents.${doc.field}`,
          }),
        })
      }
    })
    setCurrentDocuments(currentDocuments)
  }

  return (
    <Box p={4}>
      {currentDocuments.length === 0 ? (
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.view.documentViewer.notFound' })}
          </Typography>
        </Box>
      ) : (
        <>
          {currentDocuments.length !== 0 && (
            <Box mb={4}>
              <Typography variant="h6" color="primary" paragraph>
                {formatMessage({ id: 'pages.application.view.documentViewer.documentsTitle' })}
              </Typography>
              <BasicTable
                config={CURRENT_DOCUMENTS_LIST_CONFIG}
                data={currentDocuments}
                context={{ model, setApplication, getListItems }}
                rowKey={(i, row) => {
                  return row.id
                }}
              />
            </Box>
          )}
        </>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={() => close()} color="primary" labelM="lg">
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
