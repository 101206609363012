import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'
import { InspInfoBlock } from './insp-info-block.component'
import { InspBillBlock } from './insp-bill-block.component'

export const InspectionForm = () => {
  const { formatMessage } = useIntl()
  const { validateInspectionStep, error, type } = useInspectionsContext()

  const handleSubmit = (e) => {
    e.preventDefault()
    validateInspectionStep()
  }

  return (
    <Box p={3} mt={2} bgcolor="grey.300" borderRadius={6}>
      <form onSubmit={handleSubmit}>
        <Box>
          <InspInfoBlock />
        </Box>
        {type === 'ip' ? (
          <Box mt={3}>
            <InspBillBlock />
          </Box>
        ) : null}
        {error.message && (
          <Box mt={2} bgcolor="common.white">
            <Alert severity="error">{error.message}</Alert>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <ThemeButton type="submit" color="primary" labelM="sm">
            {formatMessage({ id: 'pages.inspections.validateBtn.label' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
