import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { TextInput, PasswordInput, ThemeButton } from '../../../ui'
import { loginUser } from '../../../features/global'
import { PwdRecoveryMessageComponent } from '../../pwd-recovery'
import { useLoginCalls } from '../hooks/login-calls.hook'

import { makeStyles } from '@material-ui/styles'
import { loginFormStyles } from './login-form.styles'

export const LoginFormComponent = () => {
  const classes = makeStyles(loginFormStyles)()
  const history = useHistory()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const { loginCall, resendValidation } = useLoginCalls()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [validatedMailForRecovery, setValidatedMailForRecovery] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleResendValidation = () => {
    resendValidation(validatedMailForRecovery).then(() => {
      setErrorMssg(formatMessage({ id: 'calls.login.error.inactiveUser.sent' }))
    })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    setValidatedMailForRecovery(formState.username)
    loginCall(formState)
      .then(({ data }) => {
        dispatch(loginUser(data))
      })
      .catch((code) => {
        if (code === 'inactive_user') {
          setErrorMssg('inactive_user')
        } else {
          setErrorMssg(formatMessage({ id: 'calls.login.error.authFailed.description' }))
        }
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={4}>
        <TextInput
          // type="email"
          required
          value={formState['username']}
          name="username"
          title={formatMessage({ id: 'pages.login.form.field.user.title' })}
          placeholder={formatMessage({ id: 'pages.login.form.field.user.placeholder' })}
          onChange={handleChangeForm}
        />
        <Box mt={2} mb={2}>
          <PasswordInput
            required
            value={formState['password']}
            name="password"
            title={formatMessage({ id: 'pages.login.form.field.password.title' })}
            placeholder={formatMessage({ id: 'pages.login.form.field.password.placeholder' })}
            onChange={handleChangeForm}
          />
        </Box>
        <PwdRecoveryMessageComponent />
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg === 'inactive_user' ? (
              <span>
                {formatMessage({ id: 'calls.login.error.inactiveUser.description.before' })}
                <span onClick={handleResendValidation} className={classes.link}>
                  {formatMessage({ id: 'calls.login.error.inactiveUser.description.link' })}
                </span>
                {formatMessage({ id: 'calls.login.error.inactiveUser.description.after' })}
              </span>
            ) : (
              errorMssg
            )}
          </Typography>
        </Box>
      )}
      <ThemeButton type="submit" fullWidth loading={loading}>
        {formatMessage({ id: 'pages.login.button.label' })}
      </ThemeButton>
      <Box mt={1}>
        <Typography variant="body2" display="inline" color="textSecondary">
          {formatMessage({ id: 'pages.login.register.question' })}
          <span
            style={{
              color: '#004165',
              fontFamily: 'FSEmeric-Medium',
              marginLeft: 4,
              cursor: 'pointer',
            }}
            onClick={() => history.push('/signup')}
          >
            {formatMessage({ id: 'pages.login.register.answer' })}
          </span>
        </Typography>
      </Box>
    </form>
  )
}
