import { ADDRESS_FORM_FIELDS } from '../constants'

export const refineAddress = (name, value, state) => {
  const newAddressForm = { ...state.addressForm }
  const startIndex = ADDRESS_FORM_FIELDS.indexOf(name)
  ADDRESS_FORM_FIELDS.forEach((f, i) => {
    if (i === startIndex) newAddressForm[f] = value
    else if (i > startIndex) newAddressForm[f] = null
  })
  const fieldsValues = {
    townValues: newAddressForm['cp_municipio'] === null ? [] : [...state.townValues],
    addressValues: newAddressForm['direccion'] === null ? [] : [...state.addressValues],
    numberValues: newAddressForm['direccion'] === null ? [] : [...state.numberValues],
    homeValues: newAddressForm['numero'] === null ? [] : [...state.homeValues],
  }
  return {
    addressForm: newAddressForm,
    ...fieldsValues,
  }
}

export const refineUnnormalizedAddress = (name, value, state) => {
  const newAddressForm = { ...state.addressForm }
  switch (name) {
    case 'direccion':
      //TODO
      break
    case 'numero':
      //TODO
      break
    default:
      newAddressForm[name] = value
  }
  return {
    addressForm: newAddressForm,
  }
}
