export const companyPanelStyles = (theme) => ({
  logoContainer: {
    height: 150,
    width: 150,
    minWidth: 150,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  tabSelected: {
    backgroundColor: theme.palette.grey[100],
  },
  editLogoButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  poRow: ({ canNavigate }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 10,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: theme.palette.common.white,
    cursor: canNavigate ? 'pointer' : 'default',
    '&:hover': canNavigate
      ? {
          boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
          border: '1px solid',
          borderColor: theme.palette.grey[300],
          marginBottom: 8,
        }
      : null,
  }),
})
