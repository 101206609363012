import React from 'react'

import { AuthContext } from '../contexts'
import { AjaxContext } from '../../../core/ajax'

export const AuthService = (WrappedComponent) => (props) => {
  return (
    <AjaxContext.Consumer>
      {({ login }) => {
        return (
          <AuthContext.Provider value={login}>
            <WrappedComponent {...props} />
          </AuthContext.Provider>
        )
      }}
    </AjaxContext.Consumer>
  )
}
