import React, { useEffect, useState } from 'react'

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowForwardIos } from '@material-ui/icons'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { useIntl } from 'react-intl'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LinkIcon from '@material-ui/icons/Link'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const dialogStyles = () => ({
  root: {
    margin: 0,
  },
  dialogPaper: {
    maxWidth: '100%',
  }
})

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, users_reached = "", created_at=null, ...other } = props;
  const intl = useIntl()
  const { formatMessage } = intl
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div style={{ display: 'flex', flexDirection:'column', width: '100%', gap: '10px' }}>
        <div>
      <Typography variant="h6" align="center" color="primary" style={{marginTop: 8, fontSize:"1rem"}}><i>{children}</i></Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
        </div>
        {users_reached ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop:10 }}>
          <Tooltip title="Usuarios alcanzados">
          <Typography variant="body1" color="textSecondary" display="inline" style={{marginLeft:'10px'}}>
            {users_reached}
          </Typography>
          </Tooltip>
          <Typography variant="body1" color="textSecondary" display="inline" style={{paddingRight:'20px'}}>
            {formatMessage({id:"pages.notices.popup.created.title"})} {new Date(created_at).toLocaleString().substring(0,10)}
          </Typography>
        </div>
          ):null}
      </div>
    </MuiDialogTitle>
  );
});

let enddate = new Date()
enddate.setMonth(enddate.getMonth() + 1)

export const PopupDialogComponent = withStyles(dialogStyles) (({children,classes,
  id = '',
  title = '',
  text = '',
  links = [],
  onRead = () => {},
  onClose = () => {},
  onPrev = () => {},
  onNext = () => {},
  open = false,
  quantity = 0,
  position = 1,
  ignore = false,
}) => {
  const [checked, setChecked] = useState(ignore)

  const intl = useIntl()
  const { formatMessage } = intl

  useEffect(() => {
    setChecked(ignore)
  },[id,open])

  function handleRead() {
    setChecked(true)
    onRead()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"md"}
      style={{border: "1px solid red"}}
    >
      {quantity ? <DialogTitle onClose={onClose}>{formatMessage({id:'pages.notices.popup.new_notice.title'})} ({quantity})</DialogTitle> : <DialogTitle onClose={onClose}></DialogTitle>}
      <DialogContent style={{ padding: "0.5rem 3rem",borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} >
        <h1 style={{marginTop:0, textAlign:"center", textTransform:"uppercase",borderBottom: "1px solid rgba(0, 0, 0, 0.12)",paddingBottom:"0.5rem"}}>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: text }} style={{color:"#63666A",textAlign: "justify", marginTop: "1rem"}}></div>
        {links && links.length > 0 && (<><Typography variant="h6" style={{marginTop: "2rem", marginBottom:"0.5rem"}} color="primary">Enlaces Adjuntos <i>({links.length})</i></Typography>
        <List>
        {links.map((link) => (
          <ListItemLink href={link.key} target="_blank">
              <ListItemIcon color="primary">
                <LinkIcon/>
              </ListItemIcon>
              <ListItemText primary={link.value} secondary={link.key} />
          </ListItemLink>
        ))}
        </List></>)}
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: "0 20px" }}>
          <FormControlLabel
            style={{marginLeft:'5px'}}
            control={
              <Checkbox
                checked={checked}
                onChange={handleRead}
                name="checkedB"
                color="primary"
                disabled={checked}
              />
            }
            label={formatMessage({ id: 'pages.notices.popup.check_as_read.title' })}
          />
          { quantity ? (
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
            <IconButton aria-label="previous" disabled={position ===1} onClick={onPrev}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="body1" color="primary" display="inline">
              <i>{position} {formatMessage({ id: 'pages.notices.popup.de.text' })} {quantity}</i>
            </Typography>
            <IconButton aria-label="next" disabled={position ===quantity} onClick={onNext}>
              <ArrowForwardIos />
            </IconButton>
          </div>
          ): null}
        </div>
      </DialogActions>
    </Dialog>
  )
})
