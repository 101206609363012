import { PAYMENT_STATES, CERTIF_STATES } from './payment-description.constants'

export const I18N_ROOT = 'pages.application.detail.'
export const ROOT_DETAIL_CLIENT_FIELDS = I18N_ROOT + 'panels.1.fields'
export const ROOT_DETAIL_APPLICATION_FIELDS = I18N_ROOT + 'panels.2.fields'
export const ROOT_DETAIL_HOME_N_INST_FIELDS = I18N_ROOT + 'panels.3.fields'
export const ROOT_DETAIL_CAMPAIGN_FIELDS = I18N_ROOT + 'panels.5.fields'
const ROOT_FORM_CLIENT_FIELDS = I18N_ROOT + 'dialogs.editClient.fields'
const ROOT_FORM_CLIENT_IBAN_FIELDS = I18N_ROOT + 'editClientIBAN.fields'

const TEXT_AREA_LENGTH = 250

const GRID_ITEM_QUADRUPLET = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
}
const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
}

const GRID_ITEM_MID = {
  item: true,
  xs: 12,
  sm: 6,
}

export const CLIENT_CONFIG = ({ intl }) => ({
  foldable: true,
  title: intl.formatMessage({ id: I18N_ROOT + 'panels.1.title' }),
  items: [
    [
      { ref: 'NAME', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'IDENTIFICADOR', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'EMAIL', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'CUPS', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'ADDRESS', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'TELEFONO', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'TITULAR_IBAN', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'IBAN', conf: GRID_ITEM_QUADRUPLET },
    ],
  ],
})

export const CLIENT_FIELDS = ({ intl, combos, application, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_DETAIL_CLIENT_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
  }
  return {
    NAME: {
      fromData: (data) => ({
        value:
          data['usuario']['persona'] === 'J'
            ? `${data['usuario']['apellidos'] || ''}`
            : `${data['usuario']['nombre'] || ''} ${data['usuario']['apellidos'] || ''}`,
      }),
      name: 'name',
      componentType: 'text',
      title: application?.usuario?.persona === 'J' ? txt('juridic_name.label') : txt('name.label'),
      readOnly: true,
      ...commonProps,
    },
    IDENTIFICADOR: {
      fromData: (data) => ({
        value: data['usuario']['identificador'],
      }),
      name: 'identificador',
      componentType: 'text',
      title: txt('identificador.label'),
      readOnly: true,
      ...commonProps,
    },
    EMAIL: {
      fromData: (data) => ({
        value: data['usuario']['email'],
      }),
      name: 'email',
      componentType: 'text',
      title: txt('email.label'),
      readOnly: true,
      ...commonProps,
    },
    CUPS: {
      fromData: (data) => ({
        value: data['cups'],
      }),
      name: 'cups',
      componentType: 'text',
      title: txt('cups.label'),
      readOnly: true,
      ...commonProps,
    },
    ADDRESS: {
      fromData: (data) => ({
        value:
          `${data['tipo_calle']} ${data['direccion']} ${data['numero']}` +
            `${data['anexo'] ? '-' + data['anexo'] : ''} ` +
          `${data['bloque'] ? '-' + data['bloque'] : ''} ` +
          `${data['escalera'] ? '-' + data['escalera'] : ''} ` +
          `${data['piso']} ${data['puerta']}` +
          '\n' +
          `${data['cod_postal']} ${data['municipio']}`,
      }),
      name: 'address',
      componentType: 'text',
      title: txt('address.label'),
      readOnly: true,
      ...commonProps,
    },
    TELEFONO: {
      fromData: (data) => ({
        value: data['usuario']['telefono'],
      }),
      name: 'telefono',
      componentType: 'text',
      title: txt('telefono.label'),
      readOnly: true,
      ...commonProps,
    },
    TITULAR_IBAN: {
      fromData: (data) => ({
        value: data['titular_iban'],
      }),
      name: 'titular_iban',
      componentType: 'text',
      title: txt('titular_iban.label'),
      readOnly: true,
      ...commonProps,
    },
    IBAN: {
      fromData: (data) => ({
        value: data['iban'],
      }),
      name: 'iban',
      componentType: 'text',
      title: txt('iban.label'),
      readOnly: true,
      ...commonProps,
    },
  }
}
export const CLIENT_EDIT_CONFIG = ({ formState }) => ({
  items:
    formState.persona === 'J'
      ? [
          [
            { ref: 'APELLIDOS', conf: GRID_ITEM_MID },
            { ref: 'TIPO_IDENTIFICADOR', conf: GRID_ITEM_MID },
            { ref: 'IDENTIFICADOR', conf: GRID_ITEM_MID },
            { ref: 'EMAIL', conf: GRID_ITEM_MID },
            { ref: 'TELEFONO', conf: GRID_ITEM_MID },
          ],
        ]
      : [
          [
            { ref: 'NOMBRE', conf: GRID_ITEM_MID },
            { ref: 'APELLIDOS', conf: GRID_ITEM_MID },
            { ref: 'TIPO_IDENTIFICADOR', conf: GRID_ITEM_MID },
            { ref: 'IDENTIFICADOR', conf: GRID_ITEM_MID },
            { ref: 'EMAIL', conf: GRID_ITEM_MID },
            { ref: 'TELEFONO', conf: GRID_ITEM_MID },
          ],
        ],
})

export const CLIENT_EDIT_FIELDS = ({ intl, combos, onChange, formState, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_FORM_CLIENT_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
    onChange,
  }
  return {
    NOMBRE: {
      fromData: (data) => ({
        value: data['nombre'],
      }),
      name: 'nombre',
      componentType: 'text',
      title: txt('nombre.label'),
      required: true,
      ...commonProps,
    },
    APELLIDOS: {
      fromData: (data) => ({
        value: data['apellidos'],
      }),
      name: 'apellidos',
      componentType: 'text',
      title: formState?.persona === 'J' ? txt('juridic_name.label') : txt('apellidos.label'),
      required: true,
      ...commonProps,
    },
    TIPO_IDENTIFICADOR: {
      fromData: (data) => ({
        value: data['tipo_identificador'],
        values: [
          { key: 'NIF', value: 'NIF' },
          { key: 'NIE', value: 'NIE' },
          { key: 'Passport', value: 'Pasaporte' },
        ],
      }),
      name: 'tipo_identificador',
      componentType: 'select',
      fullWidth: true,
      title: txt('tipo_identificador.label'),
      required: true,
      ...commonProps,
    },
    IDENTIFICADOR: {
      fromData: (data) => ({
        value: data['identificador'],
      }),
      name: 'identificador',
      componentType: 'text',
      title: txt('identificador.label'),
      required: true,
      ...commonProps,
    },
    EMAIL: {
      fromData: (data) => ({
        value: data['email'],
      }),
      name: 'email',
      componentType: 'text',
      type: 'email',
      title: txt('email.label'),
      required: true,
      ...commonProps,
    },
    TELEFONO: {
      fromData: (data) => ({
        value: data['telefono'],
      }),
      name: 'telefono',
      componentType: 'text',
      title: txt('telefono.label'),
      required: true,
      ...commonProps,
    },
  }
}

export const CLIENT_IBAN_FIELDS = ({ intl, onChange, formState, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_FORM_CLIENT_IBAN_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
    onChange,
  }
  return {
    IBAN: {
      value: formState.iban,
      name: 'iban',
      componentType: 'text',
      title: txt('iban.label'),
      required: true,
      ...commonProps,
    },
    TITULAR_IBAN: {
      value: formState.titular_iban,
      name: 'titular_iban',
      componentType: 'text',
      title: txt('titular_iban.label'),
      required: true,
      ...commonProps,
    },
  }
}

export const APPLICATION_CONFIG = ({ intl }) => ({
  foldable: true,
  title: intl.formatMessage({ id: I18N_ROOT + 'panels.2.title' }),
  items: [
    [
      { ref: 'SOLICITUD_ID', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'FECHA_SOLICITUD', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'ESTADO', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'MERCADO', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'TIPO_PETICION', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'SOCIEDAD', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'TARIFA_APLICADA', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'TIPO_PAGO', conf: GRID_ITEM_QUADRUPLET },
    ],
    [
      { ref: 'CONTACTO_INSTALADOR_FECHA', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'ACEPTACION_PRESUPUESTO_FECHA', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'CERTIFICADO_FECHA', conf: GRID_ITEM_QUADRUPLET },
    ],
    [
      { ref: 'OBSERVACIONES', conf: GRID_ITEM_LARGE },
      { ref: 'OBSERVACIONES_CAMBIO_ESTADO', conf: GRID_ITEM_LARGE },
    ],
  ],
})

export const APPLICATION_FIELDS = ({
  combos,
  intl,
  role,
  onChange,
  onChangeDirect,
  editMode,
  ...restProps
}) => {
  const { formatMessage, formatDate } = intl
  const txt = (extension) => formatMessage({ id: ROOT_DETAIL_APPLICATION_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
  }

  return {
    SOLICITUD_ID: {
      fromData: (data) => ({
        value: data['solicitud_id'],
      }),
      name: 'solicitud_id',
      componentType: 'text',
      title: txt('solicitud_id.label'),
      readOnly: true,
      ...commonProps,
    },
    MERCADO: {
      fromData: (data) => ({
        value: data['mercado'],
        values: combos['tipo_mercado']?.data,
      }),
      name: 'mercado',
      componentType: 'select',
      title: txt('mercado.label'),
      readOnly: true,
      ...commonProps,
    },
    SOCIEDAD: {
      fromData: (data) => ({
        value: data['sociedad'],
      }),
      name: 'sociedad',
      componentType: 'text',
      title: txt('sociedad.label'),
      readOnly: true,
      ...commonProps,
    },
    FECHA_SOLICITUD: {
      fromData: (data) => ({
        value: formatDate(new Date(data['fecha_solicitud'])),
      }),
      name: 'fecha_solicitud',
      componentType: 'text',
      title: txt('fecha_solicitud.label'),
      readOnly: true,
      ...commonProps,
    },
    TIPO_PAGO: {
      fromData: (data) => ({
        value: data['tipo_pago'],
      }),
      name: 'tipo_pago',
      componentType: 'text',
      title: txt('tipo_pago.label'),
      readOnly: true,
      ...commonProps,
    },
    ESTADO: {
      fromData: (data) => ({
        value: data['estado'],
        values: combos['estados_solicitud_instalador']?.data,
      }),
      name: 'estado',
      componentType: 'select',
      title: txt('estado.label'),
      readOnly: true,
      ...commonProps,
    },
    TIPO_PETICION: {
      fromData: (data) => ({
        value: data['camino_solicitud']
          ? data['camino_solicitud'] === '01'
            ? 'Acometida'
            : data['camino_solicitud'] === '02' || data['camino_solicitud'] === '03'
            ? 'Instalación interior'
            : data['camino_solicitud'] === '04'
            ? 'Trámite certificado'
            : 'Lo desconozco'
          : 'Lo desconozco',
      }),
      name: 'tipo_peticion',
      componentType: 'text',
      title: txt('tipo_peticion.label'),
      readOnly: true,
      ...commonProps,
    },
    OBSERVACIONES: {
      fromData: (data) => ({
        value: editMode
          ? data['observaciones']
          : data['observaciones'] && data['observaciones'] !== ''
          ? data['observaciones']
          : '--',
      }),
      name: 'observaciones',
      componentType: 'text_area',
      type: 'text',
      multiline: true,
      rows: 3,
      disabled: !editMode,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      title: txt('observaciones.label'),
      onChange: onChangeDirect,
      ...commonProps,
    },
    OBSERVACIONES_CAMBIO_ESTADO: {
      fromData: (data) => ({
        value:
          data['observaciones_cambio_estado'] && data['observaciones_cambio_estado'] !== ''
            ? data['observaciones_cambio_estado']
            : '--',
      }),
      name: 'observaciones_cambio_estado',
      componentType: 'text_area',
      type: 'text',
      multiline: true,
      rows: 3,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      disabled: true,
      title: txt('observaciones_cambio_estado.label'),
      ...commonProps,
    },
    TARIFA_APLICADA: {
      fromData: (data) => ({
        value: data['numero_tarifa_aplicada'],
      }),
      name: 'numero_tarifa_aplicada',
      componentType: 'text',
      title: txt('numero_tarifa_aplicada.label'),
      readOnly: true,
      ...commonProps,
    },
    CONTACTO_INSTALADOR_FECHA: {
      fromData: (data) => {
        const hasPermissions =
          (data['gremio_id'] && role === 'gremio') || (!data['gremio_id'] && role !== 'gremio')
        const editable = !(
          data['estado'] === '03' ||
          data['estado'] === '04' ||
          data['estado'] === '90'
        )
        return {
          value: data['contacto_instalador_fecha']
            ? intl.formatDate(new Date(data['contacto_instalador_fecha']))
            : null,
          readOnly: !hasPermissions || !editable || data['contacto_instalador_fecha'],
          maxDate: new Date(),
          hiddenInput: true,
          componentType: 'custom_date',
          buttonMessage: 'informar fecha',
        }
      },
      name: 'contacto_instalador_fecha',

      title: txt('contacto_instalador_fecha.label'),
      onChange,
      ...commonProps,
    },
    ACEPTACION_PRESUPUESTO_FECHA: {
      fromData: (data) => {
        const hasPermissions =
          (data['gremio_id'] && role === 'gremio') || (!data['gremio_id'] && role !== 'gremio')
        const editable = !(
          data['estado'] === '03' ||
          data['estado'] === '04' ||
          data['estado'] === '90'
        )
        return {
          value: data['fecha_aceptacion_presupuesto_instalador']
            ? formatDate(new Date(data['fecha_aceptacion_presupuesto_instalador']))
            : null,
          readOnly: !hasPermissions || !editable || data['fecha_aceptacion_presupuesto_instalador'],
          maxDate: new Date(),
          hiddenInput: true,
          componentType: 'custom_date',
          buttonMessage: 'informar fecha',
        }
      },
      name: 'fecha_aceptacion_presupuesto_instalador',
      title: txt('fecha_aceptacion_presupuesto_instalador.label'),
      disabled: true,
      onChange,
      ...commonProps,
    },
    CERTIFICADO_FECHA: {
      fromData: (data) => ({
        value:
          data['certificado_IRG3'] && data['certificado_IRG3']['id']
            ? formatDate(data['certificado_IRG3']['fecha_certificado']) +
              ` (${data['certificado_IRG3']['estado']})`
            : '--',
      }),
      name: 'certificado_fecha',
      componentType: 'text',
      title: txt('certificado_fecha.label'),
      readOnly: true,
      ...commonProps,
    },
  }
}

const HOME_GENERAL_INFO = [
  { ref: 'TIPO_VIVIENDA', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CIDI', conf: GRID_ITEM_QUADRUPLET },
]

const HOME_OUTER_INST = [
  { ref: 'PRECIO', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'POWER', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CONSUMO', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'UBI_CONTADOR', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'PROP_CONTADOR', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'PROP_IRC', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CASE_CC', conf: GRID_ITEM_QUADRUPLET },
]

const HOME_INNER_INST_GENERIC = [
  { ref: 'PRODUCTS', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CURRENT_ENERGY', conf: GRID_ITEM_QUADRUPLET },
]

const HOME_INNER_INST_CALDERA = [
  { ref: 'UBI_CALDERA', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'NUEVA_CALDERA', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'GAMA_CALDERA', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CALDERA_INFO', conf: GRID_ITEM_QUADRUPLET },
]

const HOME_INNER_INST_CALENTADOR = [
  { ref: 'UBI_CALENTADOR', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'NUEVO_CALENTADOR', conf: GRID_ITEM_QUADRUPLET },
  // { ref: 'GAMA_CALENTADOR', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'CALENTADOR_INFO', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'IRI', conf: GRID_ITEM_QUADRUPLET },
]
const HOME_INNER_INST_CAL = [
  { ref: 'PLANTAS_CAL', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'METROS_CAL', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'ESTANCIAS_CAL', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'PERSONAS_ACS', conf: GRID_ITEM_QUADRUPLET },
]
const HOME_INNER_INST_NUMBERS = [
  { ref: 'RADIADORES_CAL', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'TOALLERO_CAL', conf: GRID_ITEM_QUADRUPLET },
  // { ref: 'TERMOSTATO_CAL', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'NUM_TERMOSTATO', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'TIPO_TERMOSTATO', conf: GRID_ITEM_QUADRUPLET },
]
const HOME_INNER_INST_REST = [
  { ref: 'TOMAS_DE_AGUA', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'TOMA_CORRIENTE', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'DISTANCIA_JARDIN', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'UBI_CALDERA_UNIFAMILIAR', conf: GRID_ITEM_QUADRUPLET },
  { ref: 'MPB', conf: GRID_ITEM_QUADRUPLET },
]

export const HOME_N_INSTALLATION_CONFIG = ({ intl, path }) => {
  return {
    foldable: true,
    title: intl.formatMessage({ id: I18N_ROOT + 'panels.3.title' }),
    items:
      path === '01'
        ? [HOME_GENERAL_INFO, [], HOME_OUTER_INST]
        : path === '02' || path === '03'
        ? [
            [...HOME_GENERAL_INFO, ...HOME_INNER_INST_GENERIC],
            [],
            HOME_INNER_INST_CALDERA,
            [],
            HOME_INNER_INST_CALENTADOR,
            [],
            HOME_INNER_INST_CAL,
            [],
            HOME_INNER_INST_NUMBERS,
            [],
            HOME_INNER_INST_REST,
          ]
        : [HOME_GENERAL_INFO],
  }
}

export const HOME_N_INSTALLATION_FIELDS = ({ intl, combos, editMode, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_DETAIL_HOME_N_INST_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
  }

  return {
    TIPO_VIVIENDA: {
      fromData: (data) => ({
        value: data['tipo_vivienda'],
      }),
      name: 'tipo_vivienda',
      componentType: 'text',
      title: txt('tipo_vivienda.label'),
      readOnly: true,
      ...commonProps,
    },
    CIDI: {
      fromData: (data) => ({
        value: data['cidi'],
      }),
      name: 'cidi',
      componentType: 'text',
      title: txt('cidi.label'),
      readOnly: true,
      ...commonProps,
    },
    PRECIO: {
      fromData: (data) => ({
        value: data['camino_solicitud'] === '01' ? data.presupuesto_exterior?.precio_instalacion_tuberia_con_iva : data.precio_instalacion_interior,
      }),
      name: 'precio',
      componentType: 'text',
      title: txt('precio.label'),
      readOnly: !editMode,
      units: '€',
      type: 'number',
      ...commonProps,
    },
    PRODUCTS: {
      fromData: (data) => ({
        value: [
          { key: 'agua_caliente', value: 'Agua caliente' },
          { key: 'calefaccion', value: 'Calefacción' },
          { key: 'cocina', value: 'Cocina' },
        ]
          .filter(({ key }) => data[key])
          .map(({ value }) => value)
          .join(' + '),
      }),
      name: 'products',
      componentType: 'text',
      title: txt('products.label'),
      readOnly: true,
      ...commonProps,
    },
    CURRENT_ENERGY: {
      fromData: (data) => ({
        value: `ACS: ${data['actual_acs'] || ''}\n Calefacción: ${
          data['actual_cal'] || ''
        }\n Cocina: ${data['actual_cocina'] || ''}`,
      }),
      name: 'currentEnergy',
      componentType: 'text',
      title: txt('currentEnergy.label'),
      readOnly: true,
      ...commonProps,
    },
    UBI_CALDERA: {
      fromData: (data) => ({
        value: data['ubi_caldera'],
        hidden: data['ubi_calentador'],
      }),
      name: 'ubi_caldera',
      values: combos['ubicacion_caldera']?.data,
      componentType: 'select',
      title: txt('ubi_caldera.label'),
      readOnly: true,
      ...commonProps,
    },
    NUEVA_CALDERA: {
      fromData: (data) => ({
        value: data['nueva_caldera'],
        hidden: data['nuevo_calentador'],
      }),
      name: 'nueva_caldera',
      values: combos['ubicacion_caldera']?.data,
      componentType: 'select',
      title: txt('nueva_caldera.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    GAMA_CALDERA: {
      fromData: (data) => ({
        value: data['gama_caldera'],
        hidden: data['nuevo_calentador'],
      }),
      name: 'gama_caldera',
      values: combos['gama_caldera']?.data || [],
      componentType: 'select',
      title: txt('gama_caldera.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    CALDERA_INFO: {
      fromData: (data) => ({
        value:
          `${data['marca_caldera'] ? data['marca_caldera'] + '/' : ''}` +
          `${data['modelo_caldera'] ? data['modelo_caldera'] + '/' : ''}` +
          `${data['caldera_precio'] ? data['caldera_precio'] : ''}`,
        hidden: data['nuevo_calentador'],
      }),
      name: 'caldera_info',
      componentType: 'text',
      title: txt('caldera_info.label'),
      readOnly: true,
      units: '€',
      ...commonProps,
    },
    UBI_CALENTADOR: {
      fromData: (data) => ({
        value: data['ubi_calentador'],
        hidden: data['ubi_caldera'],
      }),
      name: 'ubi_calentador',
      values: combos['ubicacion_caldera']?.data,
      componentType: 'select',
      title: txt('ubi_calentador.label'),
      readOnly: true,
      ...commonProps,
    },
    NUEVO_CALENTADOR: {
      fromData: (data) => ({
        value: data['nuevo_calentador'],
        hidden: data['nueva_caldera'],
      }),
      name: 'nuevo_calentador',
      values: combos['ubicacion_caldera']?.data,
      componentType: 'select',
      title: txt('nuevo_calentador.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    // GAMA_CALENTADOR: {
    //   name: 'gama_calentador',
    //   value: data['gama_calentador'],
    //   values: combos['gama_caldera'].data,
    //   componentType: 'select',
    //   title: txt('gama_calentador.label'),
    //   readOnly: true,
    //   hidden: data['nueva_caldera'],
    //   fullWidth: true,
    //   ...commonProps,
    // },
    CALENTADOR_INFO: {
      fromData: (data) => ({
        value:
          `${data['marca_calentador'] ? data['marca_calentador'] + '/' : ''}` +
          `${data['modelo_calentador'] ? data['modelo_calentador'] + '/' : ''}` +
          `${data['calentador_precio'] ? data['calentador_precio'] : ''}`,
        hidden: data['nueva_caldera'],
      }),
      name: 'calentador_info',
      componentType: 'text',
      title: txt('calentador_info.label'),
      readOnly: true,
      units: '€',
      ...commonProps,
    },
    IRI: {
      fromData: (data) => ({
        value: data['iri'],
      }),
      name: 'iri',
      componentType: 'text',
      title: txt('iri.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    PLANTAS_CAL: {
      fromData: (data) => ({
        value: data['plantas_cal'],
      }),
      name: 'plantas_cal',
      componentType: 'text',
      title: txt('plantas_cal.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    METROS_CAL: {
      fromData: (data) => ({
        value: data['metros_cal'],
      }),
      name: 'metros_cal',
      componentType: 'text',
      title: txt('metros_cal.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    ESTANCIAS_CAL: {
      fromData: (data) => ({
        value: data['estancias_cal'],
      }),
      name: 'estancias_cal',
      componentType: 'text',
      title: txt('estancias_cal.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    PERSONAS_ACS: {
      fromData: (data) => ({
        value: data['personas_acs'],
      }),
      name: 'personas_acs',
      componentType: 'text',
      title: txt('personas_acs.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    RADIADORES_CAL: {
      fromData: (data) => ({
        value: data['radiador_cal'],
      }),
      name: 'radiador_cal',
      componentType: 'text',
      title: txt('radiador_cal.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    TOALLERO_CAL: {
      fromData: (data) => ({
        value: data['toallero_cal'],
      }),
      name: 'toallero_cal',
      componentType: 'text',
      title: txt('toallero_cal.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    // TERMOSTATO_CAL: {
    //   name: 'termostato_cal',
    //   value: data['termostato_cal'],
    //   componentType: 'text',
    //   title: txt('termostato_cal.label'),
    //   readOnly: !editMode,
    //   ...commonProps,
    // },
    NUM_TERMOSTATO: {
      fromData: (data) => ({
        value: data['num_termostatos'],
      }),
      name: 'num_termostatos',
      componentType: 'text',
      title: txt('num_termostatos.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    TIPO_TERMOSTATO: {
      fromData: (data) => ({
        value: data['tipo_termostato'],
      }),
      name: 'tipo_termostato',
      values: combos['tipo_termostato']?.data || [],
      componentType: 'select',
      title: txt('tipo_termostato.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    TOMAS_DE_AGUA: {
      fromData: (data) => ({
        value: data['tomas_agua'],
      }),
      name: 'tomas_agua',
      values: [
        { key: '01', value: 'Sí' },
        { key: '00', value: 'No' },
      ],
      componentType: 'select',
      title: txt('tomas_agua.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    TOMA_CORRIENTE: {
      fromData: (data) => ({
        value: data['toma_corriente'],
      }),
      name: 'toma_corriente',
      values: [
        { key: '01', value: 'Sí' },
        { key: '00', value: 'No' },
      ],
      componentType: 'select',
      title: txt('toma_corriente.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    DISTANCIA_JARDIN: {
      fromData: (data) => ({
        value: data['metros_jardin'],
        hidden: data['tipo_vivienda'] === 'Unifamiliar',
      }),
      name: 'metros_jardin',
      componentType: 'text',
      title: txt('metros_jardin.label'),
      readOnly: !editMode,
      type: 'number',
      ...commonProps,
    },
    UBI_CALDERA_UNIFAMILIAR: {
      fromData: (data) => ({
        value: data['ubi_caldera_unifamiliar'],
        hidden: data['tipo_vivienda'] === 'Unifamiliar',
      }),
      name: 'ubi_caldera_unifamiliar',
      values: combos['ubicacion_caldera_unifamiliar']?.data || [],
      componentType: 'select',
      title: txt('ubi_caldera_unifamiliar.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    MPB: {
      fromData: (data) => ({
        value: data['armario_regulacion'],
        hidden: data['tipo_vivienda'] === 'Unifamiliar',
      }),
      name: 'armario_regulacion',
      values: combos['tipo_armario_regulacion']?.data || [],
      componentType: 'select',
      title: txt('tipo_armario_regulacion.label'),
      readOnly: !editMode,
      fullWidth: true,
      ...commonProps,
    },
    POWER: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['potencia'] }),
      title: txt('power.label'),
      name: 'potencia',
      componentType: 'select',
      values: combos['rangos_potencia']?.data,
      ...commonProps,
    },
    CONSUMO: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['consumo'] }),
      title: txt('consumo.label'),
      name: 'consumo',
      componentType: 'select',
      values: combos['rangos_consumo_todos']?.data,
      ...commonProps,
    },
    UBI_CONTADOR: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['ubi_contador'] }),
      title: txt('ubi_contador.label'),
      name: 'ubi_contador',
      componentType: 'select',
      values: combos['ubicacion_contador']?.data,
      ...commonProps,
    },
    PROP_CONTADOR: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['prop_contador'] }),
      title: txt('prop_contador.label'),
      name: 'prop_contador',
      componentType: 'select',
      values: combos['propiedad_contador']?.data,
      ...commonProps,
    },
    PROP_IRC: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['prop_irc_armario_reg'] }),
      title: txt('prop_irc.label'),
      name: 'prop_irc_armario_reg',
      componentType: 'select',
      values: combos['propiedad_irc_armario_regulacion']?.data,
      ...commonProps,
    },
    CASE_CC: {
      readOnly: true,
      fullWidth: true,
      fromData: (data) => ({ value: data['caso_caldera_centr'] }),
      title: txt('case_cc.label'),
      name: 'caso_caldera_centr',
      componentType: 'select',
      values: combos['caso_caldera_centralizada']?.data,
      ...commonProps,
    },
  }
}

export const CAMPAIGN_CONFIG = ({ intl }) => ({
  foldable: true,
  title: intl.formatMessage({ id: I18N_ROOT + 'panels.5.title' }),
  items: [
    [
      { ref: 'NOMBRE', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'CODIGO', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'ESTADO_PAGO_OFERTA', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'ESTADO_CERTIFICACION', conf: GRID_ITEM_QUADRUPLET },
    ],
  ],
})

export const CAMPAIGN_OP_CONFIG = ({ intl }) => ({
  foldable: true,
  title: intl.formatMessage({ id: I18N_ROOT + 'panels.5.op.title' }),
  items: [
    [
      { ref: 'NOMBRE_OP', conf: GRID_ITEM_QUADRUPLET },
      { ref: 'CODIGO_OP', conf: GRID_ITEM_QUADRUPLET },
    ],
  ],
})

export const CAMPAIGN_FIELDS = ({ intl, combos, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_DETAIL_CAMPAIGN_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
  }
  return {
    NOMBRE: {
      fromData: (data) => ({
        value: data['campana']['nombre'],
      }),
      name: 'nombre',
      componentType: 'text',
      title: txt('campana_nombre.label'),
      readOnly: true,
      ...commonProps,
    },
    CODIGO: {
      fromData: (data) => ({
        value: data['campana']['codigo'],
      }),
      name: 'codigo',
      componentType: 'text',
      title: txt('campana_codigo.label'),
      readOnly: true,
      ...commonProps,
    },
    ESTADO_PAGO_OFERTA: {
      fromData: (data) => ({
        value: data['estado_pago_oferta'],
        tooltipValue: PAYMENT_STATES(intl)[data?.estado_pago_oferta],
      }),
      name: 'estado_pago_oferta',
      componentType: 'text',
      title: txt('estado_pago_oferta.label'),
      readOnly: true,
      ...commonProps,
    },
    ESTADO_CERTIFICACION: {
      fromData: (data) => ({
        value: data['estado_certificacion_oferta'],
        tooltipValue: CERTIF_STATES(intl)[data?.estado_certificacion_oferta],
      }),
      name: 'estado_certificacion',
      componentType: 'text',
      title: txt('estado_certificacion.label'),
      readOnly: true,
      ...commonProps,
    },
  }
}

export const CAMPAIGN_OP_FIELDS = ({ intl, combos, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_DETAIL_CAMPAIGN_FIELDS + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
  }
  return {
    NOMBRE_OP: {
      fromData: (data) => ({
        value: data['campana_op']['nombre'],
      }),
      name: 'nombre_op',
      componentType: 'text',
      title: txt('campana_nombre.label'),
      readOnly: true,
      ...commonProps,
    },
    CODIGO_OP: {
      fromData: (data) => ({
        value: data['campana_op']['codigo'],
      }),
      name: 'codigo_op',
      componentType: 'text',
      title: txt('campana_codigo.label'),
      readOnly: true,
      ...commonProps,
    },
  }
}

export const PUT_BUDGET_PAYLOAD = (formState) => ({
  personas_acs: Number(formState['personas_acs']),
  metros_cal: Number(formState['metros_cal']),
  estancias_cal: Number(formState['estancias_cal']),
  plantas_cal: Number(formState['plantas_cal']),
  nueva_caldera: formState['nueva_caldera'],
  gama_caldera: Number(formState['gama_caldera']),
  tipo_termostato: Number(formState['tipo_termostato']),
  num_termostatos: Number(formState['num_termostatos']),
  nuevo_calentador: formState['nuevo_calentador'],
  precio: parseFloat(formState['precio']),
  radiador_cal: Number(formState['radiador_cal']),
  toallero_cal: Number(formState['toallero_cal']),
  iri: parseFloat(formState['iri']),
  tomas_agua: formState['tomas_agua'] === '01',
  toma_corriente: formState['toma_corriente'] === '01',
  metros_jardin: parseFloat(formState['metros_jardin']),
  ubi_caldera_unifamiliar: formState['ubi_caldera_unifamiliar'],
  armario_regulacion: Number(formState['armario_regulacion']),
})
// si no presupuesto_interior es null, solo se muestra tipo_vivienda, cidi

export const CERTIFICATE_TABLE_FIELDS = [
  { key: 'type', label: 'Certificado' },
  { key: 'fecha_certificado', label: 'Fecha', type: 'date' },
  { key: 'estado', label: 'Estado' },
  { key: 'observaciones', label: 'Observaciones' },
  { key: 'observaciones_cambio_estado', label: 'Motivos' },
]

export const seguimientoPuestaGasConfig = [['licencia', 'seguimiento_obras'], ['puesta_servicio']]

export const zeusMock = {
  licencia: {
    fecha_solicitud_licencia: '11/2/2021',
    fecha_prevision_licencia: '11/2/2021',
    fecha_recepcion_licencia: '11/2/2021',
  },
  seguimiento_obras: {
    fecha_adjudicacion_obra: '11/2/2021',
    fecha_planificacion_obra: '11/2/2021',
    fecha_puesta_gas_obra: '11/2/2021',
  },
  puesta_servicio: {
    fecha_visita_eop: '11/2/2021',
    observaciones_ultima_visita: 'blablablabla bablablablablabl bablablablab blablablablabla',
    anomalias_observadas: 'blabla blablabla blablablabla blablablablablabla blablabla',
  },
}
