import React from 'react'
import { useIntl } from 'react-intl'

import { useCombos } from '../../../../features/combos'
import { FilterBox } from '../../../../ui'
import { POTENTIAL_FILTERS, POTENTIAL_COMBOS } from '../../constants'
import { Box } from '@material-ui/core'
import { ExportPotentialsToXLSBtn } from '../export-potential'

export const MarketerPotentialFilter = () => {
  const { formatMessage } = useIntl()
  const combos = useCombos(POTENTIAL_COMBOS)

  useCombos(['provincias_comercializadora'])

  const filters = POTENTIAL_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.values && item.comboId === 'provincias_comercializadora') {
        item.values = item.values.map((item) => ({ ...item, key: item.key.toString() }))
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.marketer.potential.filters.' + item.name + '.title',
      }),
    }
  })
  const buttonsComponent = (
    <Box display="flex" flexGrow={0.5} justifyContent="flex-end">
      <ExportPotentialsToXLSBtn />
    </Box>
  )

  return <FilterBox filters={filters}>{buttonsComponent}</FilterBox>
}
