import React from 'react'
import { Box } from '@material-ui/core'

import { ActionButton } from '../action-button'
import { ThemeButton } from '../theme-button'

export const CustomActionButtons = ({iconButtons = [], fullButtons = []}) => {
  return (
    <Box display="flex" flexWrap="wrap" width="100%">
      {iconButtons.length ? (
        <Box display="flex" flexGrow={2} flexWrap="wrap">
          {iconButtons.map((button, i) =>
            !button['hidden'] ? <ActionButton key={i} {...button} /> : null
          )}
        </Box>
      ) : null}
      {fullButtons.length ? (
          <Box display="flex" flexGrow={0.5} justifyContent="flex-end">
          {fullButtons.forEach((button, i) => {
            return <ThemeButton key={i} {...button} color="primary" labelM="sm">
                {button.label}
            </ThemeButton>
          })}
        </Box>
      ) : null}
    </Box>
  )
}
