import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { feedback } from '../../../core/feedback'
import { requests } from '../../../core/ajax'
import { isValidCIF, isValidNIE, isValidNIF } from '../../../core/utils'
import { CERTIFICATEyt_ACTION_TYPES } from '../constants'
import {} from './index'
import { loginUser } from '../../global'
import { downloadFile } from '../../../core/utils'

export const useCertificatesPlusActions = (state, dispatch) => {
  const reduxDispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const { formatMessage: fm } = useIntl()
  const { getById, putById, post } = requests
  const { company, formState, formStateSaved } = state
  const { formatMessage } = useIntl()

  return {}
}
