import React from 'react'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router'
import { FindInPageOutlined, AnnouncementOutlined } from '@material-ui/icons'
import { useIntl } from 'react-intl'

import { CustomActionButtons, useModalContext } from '../../../../ui'
import { DocumentsView } from '../documents-view'



const EI_BUTTONS = (history, primaryCompany, open, params) => ({
  DOCUMENTS_VIEW: {
    id: 'documents_view',
    icon: <FindInPageOutlined />,
    onClick: () => {
      open({
        Component: DocumentsView,
        data: { company: primaryCompany },
        type: 'responsive',
        closable: true,
      })
    },
  },
  APPLICATIONS: {
    id: 'applications',
    icon: <AnnouncementOutlined />,
    onClick: () => {

      history.push({
        pathname: '/applications',
        search: `?eeii_id=${params}`,
      })
    },
  },
})

const userButtons = ['DOCUMENTS_VIEW', 'APPLICATIONS']

export const EIActionButtons = ({primaryCompany}) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  

  const params = history.location.search.substring(4)
  
  const buttons = EI_BUTTONS(history, primaryCompany, open, params)

  return (
    <Box pb={1}>
      <CustomActionButtons
        iconButtons={userButtons.map((index) => {
          return {
            ...buttons[index],
            label: formatMessage({ id: `pages.ei.customActionButtons.${buttons[index].id}.title` }),
          }
        })}
      />
    </Box>
  )
}

export default EIActionButtons
