export const usersFilterStyles = (theme) => ({
  actionsButton: {
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
  },
  input: {
    backgroundColor: '#FFFFFF',
    height: 48,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
})
