import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'

import { TariffForm } from '../form'
import { PageDisplayer, InfoBox } from '../../../../ui'
import { useTariffCalls, useTariffDialogs } from '../../hooks'
import { feedback } from '../../../../core/feedback'
import { tariffEditStyles } from './tariff-edit.style'

export const TariffEdit = withStyles(tariffEditStyles)(({ classes }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const { getTariff, newTariff, putTariff, getTariffNameUnicity } = useTariffCalls()
  const { chooseClonedName } = useTariffDialogs()
  const { tariffId } = useParams()
  const acceptsApplications = useSelector((state) => state.global.empresa.publica)

  const [tariff, setTariff] = useState({})

  useEffect(() => {
    getTariff(tariffId).then(({ data }) => setTariff(data))
  }, [tariffId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = useCallback(() => {
    history.push(`/tariffs`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback((e) => {
    setTariff((tariff) => ({
      ...tariff,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const handleCheckName = (cloneName = false) => {
    return new Promise((resolve, reject) => {
      const config = cloneName ? {} : { tarifa_id: tariffId }
      getTariffNameUnicity(cloneName ? cloneName : tariff.nombre, tariff.empresa_id, config).then(
        (response) => {
          if (response) {
            resolve(response.data)
          }
        }
      )
    })
  }

  const mainSubmit = (cloneName = false) => {
    return handleCheckName(cloneName).then(() => {
      if (cloneName) return newTariff({ ...tariff, nombre: cloneName })
      else return putTariff(tariffId, tariff)
    })
  }

  const handleSubmit = () => {
    return mainSubmit().then(() =>
      feedback('success', formatMessage({ id: 'calls.tariffs.success.TariffSaved.description' }))
    )
  }

  const handleGoToZoneConfig = () => {
    return mainSubmit().then(() => history.push('/tariffs/' + tariffId + '/zones'))
  }

  const handleClone = (cloneName) => {
    return mainSubmit(cloneName).then(({ data }) => {
      const obtainedId = parseInt(data.id_tarifa)
      history.replace('/tariffs/' + obtainedId + '/edit')
      return obtainedId
    })
  }

  const handleCloneRequest = () => {
    chooseClonedName({
      name: tariff.nombre + ' bis',
      checkName: handleCheckName,
      save: handleClone,
    })
  }

  const inputProps = { formState: tariff, onChange: handleChange, intl }

  return (
    <PageDisplayer
      loading={!tariff || !Object.keys(tariff).length}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.edit.back' })}
    >
      <InfoBox
        severity="warning"
        message={formatMessage({ id: 'pages.tariffs.alerts.1.description' })}
        hidden={acceptsApplications}
      />
      <TariffForm
        {...{
          inputProps,
          submit: handleSubmit,
          goToZoneConfig: handleGoToZoneConfig,
          checkName: handleCheckName,
          clone: handleCloneRequest,
        }}
      />
    </PageDisplayer>
  )
})
