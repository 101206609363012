import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, FormControlLabel, Checkbox } from '@material-ui/core'

import { ThemeButton, BasicList, CaptchaInput, BasicListItem } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useInspectionsContext } from '../../contexts'
import { INST_LIST_ITEMS, BILL_LIST_ITEMS, SUMMARY_HOME_ITEMS } from './summary.constants'
import condiciones_uso_jca from '../../../../resources/documents/condiciones_uso_JCA.pdf'
import { IPInspSummary } from './ip-insp-summary.component'
import { JCAInspSummary } from './jca-insp-summary.component'

export const Summary = () => {
  const { formatMessage } = useIntl()
  const { formState, finishInspection, type } = useInspectionsContext()
  const { addresses } = useInspectionsContext()
  const combos = useCombos([])

  const [isValidCaptcha, setValidCaptcha] = useState(false)
  const [isDisclaimerChecked, setDisclaimerCheck] = useState(type === 'ip' ? true : false)

  const handleCaptchaSubmit = (isValid) => setValidCaptcha(isValid)

  return (
    <Box p={3} mt={2} bgcolor="grey.300" borderRadius={6}>
      <Typography variant="h5" color="textSecondary">
        {formatMessage({ id: 'pages.inspections.summary.title' })}
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'pages.inspections.installation.title' })}
        </Typography>
        <Box p={2} mt={1} bgcolor="grey.100" borderRadius={6}>
          <BasicList {...{ data: formState, fields: INST_LIST_ITEMS(formState), combos }} />
          {formState.tipo_instalacion === 'IRC' ? (
            <>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'pages.inspections.fields.direcciones.label' })}:
              </Typography>
              {addresses.map((item, i) => (
                <Box key={i} display="flex">
                  <Box p={1} bgcolor="grey.100" borderRadius={6}>
                    <Typography variant="body2">{item['cod_derivacion']}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`${item['calle']} ${item['numero_finca']}, ${item['municipio']} (${item['cp']}), ${item['provincia']}` +
                        '\n' +
                        `${item['portal'] ? '-' + item['escalera'] : ''} `}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Nº CUPS: {item['count_cups']}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item['tipo_de_gas']}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box>
              <BasicListItem
                {...{
                  label: 'Titular',
                  value: `${addresses[0].nombre || ''} ${addresses[0].apellido_1 || ''} ${
                    addresses[0].apellido_2 || ''
                  }`,
                }}
              />
              {SUMMARY_HOME_ITEMS(addresses[0]).map(({ label, value }, i) => (
                <BasicListItem {...{ key: i, label, value }} />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'pages.inspections.inspection.title' })}
        </Typography>
        {type === 'ip' ? <IPInspSummary /> : <JCAInspSummary />}
      </Box>
      {type === 'ip' ? (
        <Box mt={2}>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'pages.inspections.bill.title' })}
          </Typography>
          <Box p={2} mt={1} bgcolor="grey.100" borderRadius={6}>
            <BasicList {...{ data: formState, fields: BILL_LIST_ITEMS(formState), combos }} />
          </Box>
        </Box>
      ) : null}
      <Box mt={3}>
        {!isValidCaptcha ? (
          <Typography variant="body1">
            {formatMessage({ id: 'pages.inspections.fields.captcha.label' })}
          </Typography>
        ) : null}
        <CaptchaInput layout="column" isReloadable={true} onSubmit={handleCaptchaSubmit} />
      </Box>
      {type === 'jca' ? (
        <Box mt={3}>
          <FormControlLabel
            style={{ pointerEvents: 'none' }}
            control={
              <Checkbox
                style={{ pointerEvents: 'auto', padding: 10 }}
                color="primary"
                checked={isDisclaimerChecked}
                onChange={(e) => setDisclaimerCheck(e.target.checked)}
              />
            }
            label={
              <Typography variant="body1">
                Debes leer y aceptar las{' '}
                <a
                  href={condiciones_uso_jca}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ pointerEvents: 'auto' }}
                >
                  condiciones de uso
                </a>{' '}
                para continuar.
              </Typography>
            }
          />
        </Box>
      ) : null}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ThemeButton
          onClick={finishInspection}
          color="primary"
          labelM="sm"
          disabled={!isValidCaptcha || !isDisclaimerChecked}
        >
          {formatMessage({ id: 'pages.inspections.finishBtn.label' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
