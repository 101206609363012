//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const MARKETER_POTENTIAL_TABLE_COLUMNS_KEY = 'marketer.potential'

export const POTENTIAL_TABLE_COLUMNS = [
  {
    field: 'mercado',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'provincia',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'cod_codigo_postal',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'municipio',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'poblacion',
    align: 'left',
    width: 270,
    sorting: true,
  },
  // {
  //   field: 'tipo_via',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  {
    field: 'nombre_via',
    align: 'left',
    width: 350,
    sorting: true,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  // {
  //   field: 'numero',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  // {
  //   field: 'anexo',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  // {
  //   field: 'escalera',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  // {
  //   field: 'piso',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  // {
  //   field: 'puerta',
  //   align: 'left',
  //   width: 100,
  //   sorting: true,
  // },
  {
    field: 'cups',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'estado',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'tipo_gas',
    align: 'left',
    width: 150,
    sorting: true,
  },
  {
    field: 'distancia_aproximada_red',
    align: 'left',
    width: 130,
    sorting: true,
  },
  {
    field: 'presion_servicio',
    align: 'left',
    width: 150,
    sorting: true,
  },
]

export const EXPORT_POTENTIAL_TABLE_COLUMNS = [
  {
    field: 'mercado',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'provincia',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'cod_codigo_postal',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'municipio',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'poblacion',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'tipo_via',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'nombre_via',
    align: 'left',
    width: 350,
    sorting: true,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  {
    field: 'numero',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'anexo',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'escalera',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'piso',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'puerta',
    align: 'left',
    width: 100,
    sorting: true,
  },
  {
    field: 'cups',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'estado',
    align: 'left',
    width: 270,
    sorting: true,
  },
  {
    field: 'tipo_gas',
    align: 'left',
    width: 150,
    sorting: true,
  },
  {
    field: 'distancia_aproximada_red',
    align: 'left',
    width: 130,
    sorting: true,
  },
  {
    field: 'presion_servicio',
    align: 'left',
    width: 150,
    sorting: true,
  },
]
