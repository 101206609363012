export const CERTIF_STATES = (intl) => {
  return {
    Certificada: intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.certificada',
    }),
    Validada: intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.validada',
    }),
    'Enviada a SAP': intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.enviada_a_SAP',
    }),
    'Registrada en SAP': intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.registrada_en_SAP',
    }),
    'No Registrada en SAP': intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.no_registrada_en_SAP',
    }),
    Errónea: intl.formatMessage({
      id: 'pages.application.detail.panels.5.certif_states.erronea',
    }),
  }
}

export const PAYMENT_STATES = (intl) => {
  return {
    'No aplica pago': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.no_aplica_pago',
    }),
    'Pendiente procesar': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.pendiente_procesar',
    }),
    'Enviado a Facturación': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.enviado_a_facturacion',
    }),
    'Tratado en Facturación OK': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.tratado_en_facturación_OK',
    }),
    'Tratado en Facturación KO': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.tratado_en_facturación_KO',
    }),
    Devuelto: intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.devuelto',
    }),
    Eliminada: intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.eliminada',
    }),
    'No cumple requisitos': intl.formatMessage({
      id: 'pages.application.detail.panels.5.payment_states.no_cumple_requisitos',
    }),
  }
}
