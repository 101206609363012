import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { JCADashboard } from '../../../features/jca'
import { JCADetailWrapper as JCADetail } from '../../../features/jca-detail'

export const JCARouter = () => {
  return (
    <Switch>
      <Route exact path="/jca" component={JCADashboard} />
      <Route exact path="/jca/:jcaId" component={JCADetail} />
      <Redirect from="*" to="/jca" />
    </Switch>
  )
}
