import React, { useState, useCallback, useContext } from 'react'
import { Box, Typography, Checkbox, withStyles } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useIntl } from 'react-intl'

import { useCompanyProfileContext } from '../../contexts'
import { marketerOpStyles } from './marketer-op-document.styles'
import { ModalContext } from '../../../../ui/modal/contexts'
import { ViewPDFDialog } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { useCombos } from '../../../combos/hooks'

export const OpDocumentsOnProfile = withStyles(marketerOpStyles)(({ classes }) => {
  const { formState, getDocumentById, downloadOPFile, putCheckOp } = useCompanyProfileContext()
  const documents = formState.checks_op
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const combos = useCombos(['cod_zona_zeus'])

  const [docsReaded, setDocsReaded] = useState([])

  const handleSetMultiCheck = (docId) => {
    setDocsReaded([...docsReaded, docId])
  }

  const handleShowFile = (fileId, docId) => {
    openMultiFile(fileId, docId, handleSetMultiCheck)
  }

  const handleDownloadFile = (docId) => {
    downloadOPFile(docId)
  }

  const handleCheckPublicOffer = (comunidadId) => {
    putCheckOp(formState.id, comunidadId)
  }

  const getCcAa = (comunidadId) => {
    let ccaa
    combos.cod_zona_zeus &&
      combos.cod_zona_zeus.data.forEach((comunidad) => {
        if (comunidad.key === String(comunidadId)) {
          ccaa = comunidad.value
        }
      })
    return ccaa
  }

  const openMultiFile = useCallback((fileId, docId, callback) => {
    getDocumentById(fileId)
      .then((data) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        open({
          Component: ViewPDFDialog,
          data: {
            docId,
            file,
            setMultipleArray: callback,
            shortcutEnabled: true,
          },
          type: 'fullScreen',
          closable: false,
        })
      })
      .catch((error) => {
        console.error(error)
        feedback('error', 'No se encuentra el documento')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <Box ml={1} mt={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.marketer.company.profile.op.documents.title' })}
        </Typography>
      </Box>
      {documents.length > 0 ? (
        documents.map((document, i) => (
          <Box key={i} display="flex" justifyContent="space-between" alignItems="center">
            <Box alignItems="center" display="flex" ml={1}>
              <Typography>{`Condiciones Generales Oferta Pública ${getCcAa(
                document.comunidad_id
              )} 2022`}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mr={1}>
              <PictureAsPdfIcon
                className={
                  docsReaded.includes(document.documento_id) || document.checked
                    ? classes.docCheked
                    : classes.noChecked
                }
                onClick={() => handleShowFile(document.documento_id, document.documento_id)}
              />
              <Box ml={1}>
                <Typography>{formatMessage({ id: 'global.accept' })}</Typography>
              </Box>
              <Checkbox
                disabled={document.checked || !docsReaded.includes(document.documento_id)}
                onChange={() => handleCheckPublicOffer(document.comunidad_id)}
                checked={document.checked}
              ></Checkbox>
              <GetAppIcon
                className={classes.download}
                onClick={() => handleDownloadFile(document.documento_id)}
              />
            </Box>
          </Box>
        ))
      ) : (
        <Box alignItems="center" display="flex" ml={1} mt={1} mb={1}>
          {formatMessage({
            id: 'pages.profile.company.guild.info.publicOffer.noOffers.market.origin',
          })}
        </Box>
      )}
    </Box>
  )
})
