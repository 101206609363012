import { useCallback, useReducer } from 'react'

import { useIPCalls } from './ip-calls.hook'
import { ipReducer } from '../reducers'
import { IP_INITIAL_STATE, IP_ACTIONS_TYPES } from '../reducers'

export const useIPReducer = () => {
  const [state, dispatch] = useReducer(ipReducer, IP_INITIAL_STATE)
  const { getIPCall } = useIPCalls()

  const getIP = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: IP_ACTIONS_TYPES.SET_LOADING,
          payload: true,
        })
        getIPCall(id)
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector?.nombre || '',
                categoria_instl: data.inspector?.categoria || '',
                empresa_nombre: data.empresa?.nombre || '',
                empresa_cif: data.empresa?.cif || '',
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getIP,
    ...state,
  }
}
