import React, { useState } from 'react'
import { withStyles, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ListboxComponent } from './virtualized-list-box.component'
import { filterAutocompleteStyles } from './filter-autocomplete.styles'

export const FilterAutocomplete = withStyles(filterAutocompleteStyles)(
  ({ classes, name, placeholder = '', onChange, values = [], ...props }) => {
    const [inputValue, setInputValue] = useState('')
    const [open, setOpen] = useState(false)

    const value =
      props.value && values.length
        ? values.find((item) => item.key.toString() === props.value)
        : null

    const filterOptions = (options, state) => {
      const { inputValue, getOptionLabel } = state
      const value = inputValue.trim().toLowerCase()
      return options.filter((option) => {
        if (option.special) {
          return true
        }
        return getOptionLabel(option).toLowerCase().includes(value)
      })
    }

    const handleChange = (e, option) => {
      onChange({ target: { name, value: option ? option.key : '' } })
    }

    return (
      <Autocomplete
        id={`${name}-autocomplete-input`}
        size="small"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={values}
        getOptionLabel={(option) => option.value}
        renderOption={(option) => option.value}
        onChange={handleChange}
        value={value}
        autoSelect
        ListboxComponent={ListboxComponent}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.root}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              className: classes.input,
              startAdornment: (
                <Typography variant="body1" className={classes.title}>
                  {props.label}:
                </Typography>
              ),
            }}
          />
        )}
      />
    )
  }
)
