import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { useConsultContext } from '../../contexts'
import { TextInput, ThemeButton } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG_NN } from '../../constants'
import { TextInputReplace } from '../../../../ui/text-input'
import { EmpresaBlock } from '../empresa-selector'

export const NotNormalizedForm = (props) => {
  const intl = useIntl()
  const {
    nextStep,
    changeNotNormalizedAddressForm,
    selectedHome,

    reset,
    empresa,
    setEmpresa,
  } = useConsultContext()

  const handleSubmitForm = (e) => {
    nextStep()
  }
  const handlePrevious = () => {
    reset()
  }
  const inputProps = {
    formState: selectedHome,
    filterHomeValues: [],
    onChange: (e) => {
      changeNotNormalizedAddressForm(e.target)
    },
  }

  /*const handleRequestGasP = () => {
    open({
      Component: RequestGasPNotNormalizatedComponent,
      data: {
        application: customerForm,
        changeCustomerForm,
      },
      type: 'responsive',
      maxWidth: 'sm',
    })
  }*/

  return (
    <>
      <Box>
        <Typography variant="h4" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.notNormalized.title.1' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.notNormalized.addressSubtitle' })}
        </Typography>
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={3} style={{ marginBottom: '1em' }}>
            <Grid item xs={12} sm={6}>
              <TextInputReplace
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).CP_MUNICIPIO,
                  type: 'text',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).DIRECCION,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).NUMERO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).BLOQUE,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).ESCALERA,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).PISO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).PUERTA,
                }}
              />
            </Grid>
          </Grid>
          <EmpresaBlock empresa={empresa} setEmpresa={setEmpresa} home={selectedHome} />
          <Box mt={3} display="flex" justifyContent="space-around">
            <ThemeButton type="button" variant="outlined" labelM="md" onClick={handlePrevious}>
              {intl.formatMessage({ id: 'pages.consult.resultsButtons.goBack' })}
            </ThemeButton>
            <ThemeButton type="submit" color="primary" labelM="md" disabled={!empresa}>
              {intl.formatMessage({ id: 'pages.notNormalized.addressSubmitButton' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </>
  )
}
