export const securePassword = (password) => {
  /* 
    Comprueba que la contraseña tenga:
    Una longitud mínima de 8
    1 número
    1 letra minúscula
    1 letra mayúscula
    1 carácter especial
    */
  const secureRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&'()*+, \-.:;<=>?@[\\\]^_`{|}~])(?=.*[a-zA-Z!#$%&'()*+, \-.:;<=>?@[\\\]^_`{|}~]).{8,}$/
  return secureRegex.test(password)
}

export const verifyPwd = (pwd, rePwd, valid, setIsValid, setError) => {
  /*
    Possible states (stateValue):
    S1- Empty pass -> not valid
    S2- Filled pass != repass -> Not valid
    S3- Filled pass == pass && not secure pass -> Not valid
    S4- Filled pass == pass && secure pass -> Valid
    */
  if (!pwd) {
    /* S1 */
    if (valid) {
      setIsValid(false)
    }
    setError('Es necesario introducir una contraseña')
    return false
  } else if (pwd && pwd !== rePwd) {
    /* S2 */
    if (valid) {
      setIsValid(false)
    }
    setError('Las contraseñas no coinciden')
    return false
  } else if (pwd && pwd === rePwd && !securePassword(pwd)) {
    /* S3 */
    if (valid) {
      setIsValid(false)
    }
    setError('La contraseña no cumple los criterios de seguridad')
    return false
  } else if (pwd && pwd === rePwd && securePassword(pwd)) {
    /* S4 */
    if (!valid) {
      setIsValid(true)
    }
    setError('')
    return true
  } else {
    return false
  }
}
