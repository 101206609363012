import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { CAPTACION_ROLES, GRABACION_ROLES } from '../../../../core/constants'
import { TextInput, SelectInput, ThemeButton } from '../../../../ui'
import { USERS_FORM_CONFIG } from '../../constants'

export const UsersFormComponent = ({ formState, close, onSubmit, onChange, isLoading }) => {
  const intl = useIntl()
  const global = useSelector((state) => state.global)
  const combos = useSelector((state) => state.combos)
  const adminRole = global.role
  const isCaptadorAdmin = CAPTACION_ROLES[adminRole]
  const isGrabadorAdmin = GRABACION_ROLES[adminRole]
  const isCaptadorUserSelected = CAPTACION_ROLES[formState.rol]

  const filteredCombos = useCallback(() => {
    let fCombos = { ...combos }
    if (isCaptadorAdmin && isGrabadorAdmin) {
      fCombos.roles = combos.rol_captador_grabador
    } else if (isCaptadorAdmin) {
      fCombos.roles = combos.rol_captador
    }
    if (isCaptadorAdmin) {
      const relatedCompanies = global.empresa.cc_aa
      const availableCompanies = combos.cod_zona_zeus?.data?.filter(({ key }) =>
        relatedCompanies.includes(key)
      )
      fCombos.cod_zona_zeus = { ...combos.cod_zona_zeus, data: availableCompanies }
    }
    return fCombos
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputProps = { formState, combos: filteredCombos(), onChange, intl }

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).NOMBRE }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).APELLIDOS }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).EMAIL }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...USERS_FORM_CONFIG(inputProps).TELEFONO }} />
        </Grid>
        {isCaptadorAdmin ? (
          <Grid item xs={12} sm={6}>
            <SelectInput {...{ ...USERS_FORM_CONFIG(inputProps).TIPO_PERFIL }} />
          </Grid>
        ) : null}
        {isCaptadorAdmin && isCaptadorUserSelected && (
          <Grid item xs={12} sm={6}>
            <SelectInput {...{ ...USERS_FORM_CONFIG(inputProps).AMBITO_GEOGRAFICO }} />
          </Grid>
        )}
      </Grid>
      {isCaptadorAdmin ? (
        <Box pt={2}>
          <Typography variant="subtitle2">Permisos</Typography>
          {filteredCombos().roles.data.map((role) => (
            <Typography variant="body2" color="textSecondary" key={role.key}>
              - {role.description}
            </Typography>
          ))}
        </Box>
      ) : null}
      <Box display="flex" justifyContent="space-around" p={8} pt={6} pb={3} flexWrap="wrap">
        <Box mb={[3, 0]}>
          <ThemeButton onClick={close} variant="outlined" labelM="sm" disabled={isLoading}>
            {intl.formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="sm"
            loading={isLoading}
            disabled={isLoading}
          >
            {intl.formatMessage({ id: formState.id ? 'global.save' : 'global.create' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
