import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ConfirmationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'
import { ChooseClonedNameDialog } from '../components/choose-cloned-name/choose-cloned-name.dialog'

export const useTariffDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const warnZoneUnsaved = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.tariffs.zone.warning.unsaved.title' }),
        text: formatMessage({ id: 'pages.tariffs.zone.warning.unsaved.text' }),
        yesText: formatMessage({ id: 'pages.tariffs.zone.warning.unsaved.yes' }),
        noText: formatMessage({ id: 'pages.tariffs.zone.warning.unsaved.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const warnZoneVisible = (cc_aa_name, callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.tariffs.zone.warning.visible.title' }),
        text: formatMessage(
          { id: 'pages.tariffs.zone.warning.visible.text' },
          { cc_aa: cc_aa_name }
        ),
        yesText: formatMessage({ id: 'pages.tariffs.zone.warning.visible.yes' }),
        noText: formatMessage({ id: 'pages.tariffs.zone.warning.visible.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const warnZoneChanges = (old_cc_aa_name, new_cc_aa_name, callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.tariffs.zone.warning.new_cc_aa.title' }),
        text: formatMessage(
          { id: 'pages.tariffs.zone.warning.new_cc_aa.text' },
          {
            old_cc_aa: old_cc_aa_name,
            new_cc_aa: new_cc_aa_name,
          }
        ),
        yesText: formatMessage({ id: 'pages.tariffs.zone.warning.new_cc_aa.yes' }),
        noText: formatMessage({ id: 'pages.tariffs.zone.warning.new_cc_aa.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  const chooseClonedName = ({ name, setName, checkName, save }) => {
    open({
      Component: ChooseClonedNameDialog,
      data: {
        name,
        checkName,
        save,
      },
    })
  }

  return {
    warnZoneUnsaved,
    warnZoneVisible,
    warnZoneChanges,
    chooseClonedName,
  }
}
