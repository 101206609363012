import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Link } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'
import { APP_FINISHED_STATES } from '../../constants'

export const NewCertificateUnavailableDialog = ({ close, application }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({
            id: 'pages.application.detail.dialogs.newCertificateUnavailable.title',
          })}
        </Typography>
      </Box>
      <Box mb={4}>
        {APP_FINISHED_STATES[application.estado] ? (
          <Typography>
            {formatMessage({
              id: 'pages.application.detail.dialogs.newCertificateUnavailable.description.1',
            })}
          </Typography>
        ) : !application['gran_consumo_id'] ? (
          <Typography>
            {formatMessage(
              {
                id: 'pages.application.detail.dialogs.newCertificateUnavailable.description.2',
              },
              {
                tutorialLink: (
                  <Link
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    href="https://vimeo.com/654881665/3dff37fc94"
                  >
                    aquí
                  </Link>
                ),
              }
            )}
          </Typography>
        ) : (
          <Typography>
            {formatMessage(
              {
                id: 'pages.application.detail.dialogs.newCertificateUnavailable.description.3',
              },
              { nombre: application.gestor.nombre, telefono: application.gestor.telefono }
            )}
          </Typography>
        )}
      </Box>
      <Box textAlign="center">
        <ThemeButton color="default" labelM="lg" onClick={close}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
