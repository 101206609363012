export const companyGuildStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '3px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    minWidth: '150px',
    minHeight: '150px',
    padding: '1em',
  },
})
