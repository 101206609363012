import { Box, Checkbox, FormHelperText, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAjaxContext } from '../../../../core/ajax'
import { useConsultContext } from '../../contexts'
import { SelectInput, TextInput } from '../../../../ui'
import { CampaignPanel } from '../../../campaigns'
import { CertificateServicesStyles } from './certificate-services.styles'
import { Alert } from '@material-ui/lab'
import { USO_MERCADO } from '../../constants'

export const CertificateServices = withStyles(CertificateServicesStyles)(
  ({ classes, formState, combos, handleChange, showErrors, errors, viewCampanas = true }) => {
    const { formatMessage } = useIntl()
    const { get } = useAjaxContext()
    const { empresa } = useConsultContext()
    const servicesStrigified = formState['services']?.join(',')

    const [financiacion, setFinanciacion] = useState(false)
    const [campanas, setCampanas] = useState()
    const [campana, setCampana] = useState()
    const [errorIban, setErrorIban] = useState(false)
    const [applieCampana, setApplieCampana] = useState()
    const updateCampana = (financiacion, data) => {
      if (financiacion && data.campana_financiacion) {
        setCampana(data.campana_financiacion)
        handleChange({ target: { name: 'financiacion', value: true } })
      } else {
        setCampana(data?.campana)
        handleChange({ target: { name: 'financiacion', value: false } })
      }
    }
    const applyCampaign = (event) => {
      const { name, checked } = event.target
      handleChange({ target: { name: name, value: checked } })
      if (checked) {
        //Apply campaign
        if (formState['financiacion']) {
          handleChange({
            target: { name: 'camp1', value: formState['campanas']?.campana_financiacion.codigo },
          })
        } else {
          handleChange({ target: { name: 'camp1', value: formState['campanas']?.campana?.codigo } })
        }
      }
    }

    const isValidIban = (iban) => {
      const regex =
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/
      return regex.test(iban)
    }

    const updateFinanciacion = () => {
      applyCampaign({ target: { name: 'applyCampaign', checked: false } })
      handleChange({ target: { name: 'iban', value: '' } })
      setFinanciacion((financiacion) => {
        updateCampana(!financiacion, campanas)
        return !financiacion
      })
    }

    const updateIban = (event) => {
      if (isValidIban(event.target.value)) {
        setErrorIban(false)
      } else {
        setErrorIban(true)
      }
      handleChange({ target: { name: 'iban', value: event.target.value } })
    }

    const getCampaigns = (params) => {
      get(`/campana/by_cidi/${formState['cidi']}`, { params }).then(({ data }) => {
        setCampanas(data)
        setApplieCampana(data.applies_empresa)
        setFinanciacion(false)
        updateCampana(false, data)
        !data.campana
          ? handleChange({ target: { name: 'campanas', value: null } })
          : handleChange({ target: { name: 'campanas', value: data } })
      })
    }

    useEffect(() => {
      if (!formState['cidi']) return
      applyCampaign({ target: { name: 'applyCampaign', checked: false } })
      handleChange({ target: { name: 'financiacion', checked: false } })
      setCampana()
      if (
        formState['uso'] !== 'residencial' &&
        formState['uso'] &&
        formState['numero_tarifa_aplicada']
      ) {
        //Others uses
        const { uso, numero_tarifa_aplicada } = formState
        const selected_uso = uso && USO_MERCADO.filter((uso_mercado) => uso_mercado.id === uso)[0]
        getCampaigns({
          empresa_ca_id: empresa.empresa_ca_id,
          mercado: selected_uso.value,
          tarifa: numero_tarifa_aplicada,
        })
        console.debug('Not residencial')
      } else {
        //For uso residential
        if (!formState['services']) return
        const params = { empresa_ca_id: empresa.empresa_ca_id }
        formState['services'].map((el) => (params[el] = true))
        getCampaigns(params)
      }
    }, [formState.numero_tarifa_aplicada, formState.uso, servicesStrigified]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box>
        {formState['uso'] === 'residencial' && (
          <Typography variant="body1" color="primary" paragraph>
            {formatMessage({ id: 'pages.certificates.services.title' })}
          </Typography>
        )}
        <Box marginBottom={3}>
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column">
                {formState['uso'] === 'residencial' && (
                  <SelectInput
                    required
                    name="services"
                    value={formState['services']}
                    values={combos?.productos_instalacion?.data || []}
                    onChange={handleChange}
                    size="small"
                    multiple={true}
                  />
                )}
                {viewCampanas && campanas?.campana_financiacion ? (
                  <Box display="flex" alignItems="center" mt={2}>
                    <Typography variant="body2">
                      {formatMessage({ id: 'pages.certificates.services.finance' })}
                    </Typography>
                    <Checkbox
                      color="primary"
                      size="small"
                      name="financiacion"
                      checked={!!formState['financiacion']}
                      onClick={updateFinanciacion}
                    />
                  </Box>
                ) : null}
                {!financiacion && formState['applyCampaign'] && campanas?.campana?.ingreso ? (
                  <Box /*display="flex" flexDirection="column"*/ mt={2} mb={2}>
                    <Typography variant="body1" color="primary" paragraph>
                      {formatMessage({ id: 'pages.certificates.services.iban.title' })}
                    </Typography>
                    <TextInput
                      required={formState['applyCampaign'] && !formState['financiacion']}
                      name="iban"
                      inputProps={{
                        pattern:
                          '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
                      }}
                      value={formState['iban']}
                      onChange={updateIban}
                    />
                    <FormHelperText error={(showErrors && errors['iban']) || errorIban}>
                      {errorIban &&
                        formatMessage({
                          id: 'pages.certificates.iban.error.text',
                        })}
                    </FormHelperText>
                    <Typography variant="body1" color="primary" paragraph>
                      {formatMessage({ id: 'pages.certificates.services.iban.owner.title' })}
                    </Typography>
                    <TextInput
                      required={formState['applyCampaign'] && !formState['financiacion']}
                      name="titular_iban"
                      value={formState['titular_iban']}
                      onChange={handleChange}
                    />
                    <FormHelperText error={(showErrors && errors['ibanOwner']) || true}>
                      {!formState.titular_iban
                        ? formatMessage({
                            id: 'pages.certificates.iban.owner.error.text',
                          })
                        : null}
                    </FormHelperText>
                  </Box>
                ) : null}
                {financiacion && formState['applyCampaign'] ? (
                  <Box /*display="flex" flexDirection="column"*/ mt={2} mb={2}>
                    <Typography variant="body1" color="primary" paragraph>
                      {formatMessage({
                        id: 'pages.application.detail.panels.2.fields.codigo_financiacion.label',
                      })}
                    </Typography>
                    <TextInput
                      required={formState['applyCampaign'] && formState['financiacion']}
                      inputProps={{
                        type: 'codigo_financiacion',
                        pattern: '20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$',
                      }}
                      name="codigo_financiacion"
                      value={formState['codigo_financiacion']}
                      onChange={handleChange}
                    />
                    <FormHelperText error={false}>
                      {formatMessage({
                        id: 'pages.certificates.codigo_financiacion.error.text',
                      })}
                    </FormHelperText>
                  </Box>
                ) : null}
              </Box>
            </Box>
            {viewCampanas && campana ? (
              applieCampana ? (
                <Box className={classes.campaignBox}>
                  <CampaignPanel formState={campana} isFinanceCampaign={financiacion} />
                  <Checkbox
                    name="applyCampaign"
                    checked={formState.applyCampaign ? formState.applyCampaign : false}
                    onChange={applyCampaign}
                  />
                  {formatMessage({ id: 'pages.certificates.services.apply.campaing' })}
                  {!formState.applyCampaign && (
                    <Alert severity="warning">
                      {formatMessage({ id: 'pages.certificates.services.apply.campaing.info' })}
                    </Alert>
                  )}
                </Box>
              ) : (
                <Box ml={1} display="flex" justifyContent="flex-end">
                  <Alert severity="warning">
                    {formatMessage({
                      id: 'pages.certificates.services.noapply.warning',
                    })}
                  </Alert>
                </Box>
              )
            ) : null}
          </Box>
          <FormHelperText error={showErrors && errors['services']}>
            {showErrors && errors['services']
              ? formatMessage({ id: 'pages.certificates.services.error.text' })
              : null}
          </FormHelperText>
        </Box>
      </Box>
    )
  }
)

export default CertificateServices
