import { useCallback, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { BOILERS_DEFAULT_QUERY } from '../constants'
import { parse } from 'qs'

import { boilersActions, boilersReducer, boilersInitialState } from '../reducers'
import { useBoilersCalls } from './boilers-calls.hook'

//URL controlled and reducer managed data

export const useBoilersReducer = () => {
  const [state, dispatch] = useReducer(boilersReducer, boilersInitialState)
  const { getBoilersMunicipios, getBoilersProvincias, getBoilersViviendas } = useBoilersCalls()

  //URL control:
  const location = useLocation()
  const history = useHistory()
  const searchText = location.search
  const urlSearchParams = parse(searchText.split('?')[1])
  const searchParams = {
    ...BOILERS_DEFAULT_QUERY,
    ...urlSearchParams,
  }
  const provincia = searchParams['provincia']
  const municipio = searchParams['municipio']
  searchParams['provincia'] = undefined
  searchParams['municipio'] = undefined

  //Loading logic
  useEffect(() => {
    provinciasLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (provincia) {
      municipiosLoad(provincia)
    }
  }, [provincia]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (provincia && municipio) {
      boilersLoad(municipio, searchParams)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  //Loading functions
  const provinciasLoad = useCallback(() => {
    dispatch({
      type: boilersActions.PROVINCIAS_LOAD,
    })
    getBoilersProvincias()
      .then(({ data }) => {
        dispatch({
          type: boilersActions.SET_PROVINCIAS_DATA,
          payload: data,
        })
      })
      .catch((error) => {
        dispatch({
          type: boilersActions.PROVINCIAS_FAILURE,
          payload: error,
        })
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const municipiosLoad = useCallback((provincia) => {
    dispatch({
      type: boilersActions.MUNICIPIOS_LOAD,
      payload: provincia,
    })
    getBoilersMunicipios(provincia)
      .then(({ data }) => {
        dispatch({
          type: boilersActions.SET_MUNICIPIOS_DATA,
          payload: data,
        })
      })
      .catch((error) => {
        dispatch({
          type: boilersActions.MUNICIPIOS_FAILURE,
          payload: error,
        })
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const boilersLoad = useCallback(
    (municipio, search) => {
      const config = { params: search }
      dispatch({
        type: boilersActions.CALDERAS_LOAD,
        payload: municipio,
      })
      getBoilersViviendas(municipio, config)
        .then(({ data }) => {
          dispatch({
            type: boilersActions.SET_CALDERAS_DATA,
            payload: {
              data: data['viviendas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_viviendas: data['total_viviendas'],
              },
            },
          })
        })
        .catch((error) => {
          const searchFallback = {
            ...searchParams,
            ...state.search,
            provincia: provincia,
            municipio: municipio,
          }
          const searchString = Object.keys(searchFallback)
            .map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(searchFallback[key])
            })
            .join('&')
          history.replace({ search: '?' + searchString })
          dispatch({
            type: boilersActions.CALDERAS_FAILURE,
            payload: error,
          })
        })
    },
    [dispatch, boilersActions, provincia, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    ...state,
    provincia: provincia,
    municipio: municipio,
  }
}
