import React from 'react'

import { ConsultAddressForm } from '../address-form'
import { ConsultResultsView } from '../results-view'
import { ConsultCustomerForm } from '../customer-form'
import { NotNormalizedForm } from '../not-normalized-form'
import { useConsultContext } from '../../contexts'
import { CertificatesFormsContainer } from '../certificate-form'

const STEPS = [
  ConsultAddressForm,
  ConsultResultsView,
  ConsultCustomerForm,
  CertificatesFormsContainer,
]

export const ConsultStepsController = () => {
  const { step, normalized } = useConsultContext()

  const STEPS_CURRENT_FLOW = [...STEPS]
  if (!normalized) {
    STEPS_CURRENT_FLOW[1] = NotNormalizedForm
  }
  const CurrentStep =
    STEPS_CURRENT_FLOW[step] ||
    (() => {
      return null
    })

  return <CurrentStep />
}
