import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import {
  CERTIFICATES_DEVICE_CONFIG,
  CERTIFICATES_DATA_TRANSFORMATION,
  CERTIFICATES_FORM_CONFIG,
} from '../../constants'
import { CertificateFormStructure } from '../certificate-form-structure'
import { useCertificatesDialogs } from '../../hooks'
import { useErrorReducer } from '../../../../core/utils/useErrorReducer'
import { useHistory } from 'react-router-dom'
import { useCertificateLogics } from '../../hooks/certificate-creation.hook'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'
import { Box, CircularProgress } from '@material-ui/core'
import { useModalContext } from '../../../../ui'

export const CertificateForm = ({ form, application, formState, setFormState }) => {
  const intl = useIntl()
  const history = useHistory()
  const {
    actions: { open },
  } = useModalContext()

  const combos = useSelector((state) => state.combos)

  //Data

  const prepareValues = (name, value) => {
    //Special case for services field in certificate form
    if (name === 'services') {
      if (value && value.includes('calefaccion')) {
        if (!value.includes('agua_caliente')) {
          value = [...value, 'agua_caliente']
        }
      }
    }
    return value
  }
  const handleChange = useCallback(
    (event) => {
      let { name, value } = event.target
      value = prepareValues(name, value)
      setFormState((formState) => ({
        ...formState,
        [name]: value,
      }))
    },
    [setFormState]
  )

  //Preparing validation and submission
  const { errors, resetErrors, updateError, validateField } = useErrorReducer()
  const [showErrors, setShowErrors] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [check, setCheck] = useState(false)
  const { createCertificate } = useCertificateLogics()
  const { warnCertificateAcceptance } = useCertificatesDialogs()
  const customValidation = () => {
    return new Promise((resolve, reject) => {
      setShowErrors(true)
      if (!check) {
        return reject('Incorrect data')
      }
      for (let error in errors) {
        if (errors[error]) {
          return reject('Incorrect data')
        }
      }
      resolve()
    })
  }
  const handleSubmitForm = (fromModify) => {
    return createCertificate(form.endpoint, formState, form.documents, fromModify)
      .then(() => {
        history.push('/applications/' + application.id)
      })
      .catch((error) => console.debug('Error: ', error))
  }
  const getAdditionalInfo = () => {
    const ADDITIONAL_INFO = []
    if (
      formState['services'] &&
      formState['services'].indexOf('calefaccion') !== -1 &&
      !formState['d_CC_id']
    ) {
      /*ADDITIONAL_INFO.push({ name: 'rite' }) //TODO Should we remove?*/
      console.debug('We remove RITE document')
    }
    return ADDITIONAL_INFO
  }
  const handleSubmitFormRequest = () => {
    customValidation()
      .then(() => {
        warnCertificateAcceptance(handleSubmitForm, getAdditionalInfo(), true)
      })
      .catch((error) => console.debug('Error: ', error))
  }

  //Validate upon data change
  useEffect(() => {
    resetErrors()
    //Validate errors for the present form
    form.validation.forEach((line) => {
      validateField(line.custom_validation, line.field, formState[line.field], line.aux)
    })
    //Validate presence of devices
    if (form.devices && form.devices.length) {
      let error = true
      let errorDevice = false
      form.devices.forEach((dev) => {
        const device = devices[dev]
        if (formState[device.id + '_nuevos'] || formState[device.id + '_transformar']) {
          error = false
          if (
            !formState[device.id + '_marca'] ||
            !formState[device.id + '_potencia'] ||
            !(formState[device.id + '_nombre'] || device.label)
          ) {
            errorDevice = true
          }
        }
      })
      updateError('devices', error)
      updateError('singleDevice', errorDevice)
    } else {
      updateError('devices', false)
      updateError('singleDevice', false)
    }
  }, [form.validation, form.devices, formState]) // eslint-disable-line react-hooks/exhaustive-deps

  //Config
  const { deviceList, devices } = useMemo(() => {
    const devices = CERTIFICATES_DEVICE_CONFIG({ intl })
    const deviceList = form.devices ? form.devices.map((dev) => devices[dev]) : null
    return { devices, deviceList }
  }, [form, intl])
  const documentList = useMemo(() => {
    const documents = DOCUMENT_ATTACHMENT_CONFIG
    return form.documents?.length ? form.documents.map((doc) => documents[doc]) : null
  }, [form])
  const services = useMemo(() => form.services, [form])
  const fieldList = useMemo(() => {
    return CERTIFICATES_FORM_CONFIG({
      combos,
      intl,
      onChange: (event) => {
        handleChange(event)
      },
    })
  }, [combos, intl, handleChange])
  if (!application || !formState.solicitud_id) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  if (application && application[form['doc_in_app']]?.estado === 'Tramitado') {
    return <p>La solicitud ya dispone de un certificado tramitado del tipo seleccionado</p>
  }

  return (
    <CertificateFormStructure
      {...{
        form,
        formState,
        handleChange,
        fieldList,
        documentList,
        forceRefresh,
        setForceRefresh,
        handleSubmitForm,
        deviceList,
        services,
        errors,
        showErrors,
        check,
        setCheck,
        dontViewApply: true,
        viewCampanas: true,
        onSubmit: handleSubmitFormRequest,
      }}
    />
  )
}

export default CertificateForm
