import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { useSignupFormContext } from '../../../contexts'
// import { useAjaxContext } from '../../../../../core/ajax'
import { TextInput } from '../../../../../ui'
import { COMPANY_FORM_CONFIG } from '../constants'
import { MandatoryCheckbox } from '../mandatory-checkbox.component'
import documentComercializadoras from '../../../../../resources/documents/condiciones_comercializadora.pdf'

export const MarketerCompanyForm = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { formState, onChange } = useSignupFormContext()
  // const { getPublic } = useAjaxContext()
  const inputProps = { formState, onChange, combos, intl }

  // const handleFetchSAPCodeByCIF = () => {
  //   getPublic(`/proveedores_sap/comercializadoras/existsbycif/${formState['cif']}`)
  //     .then(({ data }) => {
  //       if (!data['exists']) {
  //         throw new Error('not_found')
  //       }
  //       onChange({ target: { name: 'cod_sap', value: data['cod_sap'] } })
  //     })
  //     .catch(() => onChange({ target: { name: 'cod_sap', value: '' } }))
  // }

  // useEffect(() => {
  //   if (formState.cif?.length === 9) {
  //     handleFetchSAPCodeByCIF()
  //   }
  // }, [formState.cif])

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).RAZON }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).CIF }} />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_SAP }} />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).TELEFONO }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).WEB }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).EMAIL }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).DIRECCION }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).COD_POSTAL }} />
      </Grid>
      <Grid item xs={12}>
        <MandatoryCheckbox
          {...{
            doc: documentComercializadoras,
            mssg: intl.formatMessage({ id: 'pages.signup.form.checkbox.message.4' }),
          }}
        />
      </Grid>
    </>
  )
}
