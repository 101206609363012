import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'
import { requests } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

export const ReplayRatingDialog = ({ close, valoracionId, respuesta }) => {
  const { formatMessage } = useIntl()
  const { putById } = requests

  const [input, setInput] = useState(respuesta ? respuesta : '')
  const [loading, setLoading] = useState(false)
  const TEXT_AREA_LENGTH = 250

  const handleChangeForm = (e) => {
    setInput(e.target.value)
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (input.trim()) {
      setLoading(true)
      const payload = { respuesta: input }
      putById('/valoraciones', valoracionId, payload)
        .then(({ data }) => {
          feedback(
            'success',
            formatMessage({ id: 'pages.profile.guild.ratings.answer.feedback.success' })
          )
          setLoading(false)
          close()
        })
        .catch(() => {
          feedback(
            'error',
            formatMessage({ id: 'pages.profile.guild.ratings.answer.feedback.error' })
          )
          setLoading(false)
        })
    } else {
    }
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            required
            name="respuesta"
            placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
            value={input}
            multiline
            rows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
