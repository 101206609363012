import React, { useCallback } from 'react'
import { Box, Grid, Divider } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { CustomTextInputDatepickerButton, TextInput, TextInputReplace } from '../text-input'
import { DateInput } from '../date-input'
import { SelectInput } from '../select-input'
import { NumberInput } from '../number-input'
import { RatingInput } from '../rating-input'

const Component = (props) => {
  const { component, defaultComponent, componentType, error, ...entryData } = props
  const ComponentClass = component || defaultComponent(componentType)
  return (
    <ComponentClass
      {...{
        ...entryData,
        error: error,
        helperText: error && entryData.text_invalid,
      }}
    />
  )
}

const FormPanelBlockItem = ({ defaultComponent, data, errors, showErrors, fieldList, row, i }) => {
  return (
    <Grid container spacing={3} key={i} style={{ marginTop: 0, marginBottom: 0 }}>
      {row.length ? (
        row.map((entry) => {
          const { fromData, ...staticElement } = fieldList[entry.ref]
          const element = {
            ...staticElement,
            ...fromData(data),
          }
          if (element?.hidden) return null
          const { component, componentType, ...entryData } = element || {}
          const error = errors[entryData.name] && showErrors
          return (
            <Grid {...entry.conf} key={entry.ref}>
              <Component {...{ component, defaultComponent, componentType, error, ...entryData }} />
            </Grid>
          )
        })
      ) : (
        <Divider light style={{ width: '100%' }} />
      )}
    </Grid>
  )
}

const FormPanelBlock = ({
  defaultComponent,
  block,
  data,
  errors,
  showErrors,
  combos,
  fieldList,
  extraContent,
}) => {
  const intl = useIntl()
  return (
    <Box display="block" style={{ width: '100%' }}>
      {block.prepend ? block.prepend({ data, combos, intl }) : null}
      {block.items.map((row, i) => {
        return (
          <FormPanelBlockItem
            key={i}
            {...{ defaultComponent, data, errors, showErrors, fieldList, row, i }}
          />
        )
      })}
      {block.append ? block.append({ data, combos, intl }) : null}
      {extraContent}
    </Box>
  )
}

export const FormPanel = ({
  data,
  combos,
  block,
  fieldList,
  errors = {},
  showErrors,
  extraContent,
}) => {
  const defaultComponent = useCallback((type) => {
    switch (type) {
      case 'read_only':
        return TextInputReplace
      case 'date':
        return DateInput
      case 'custom_date':
        return CustomTextInputDatepickerButton
      case 'select':
        return SelectInput
      case 'text_area':
        return TextInput
      case 'number':
        return NumberInput
      case 'rating':
        return RatingInput
      default:
        return TextInput
    }
  }, [])

  return (
    <FormPanelBlock
      {...{
        defaultComponent,
        block,
        data,
        errors,
        showErrors,
        combos,
        fieldList,
        extraContent,
      }}
    />
  )
}

export default FormPanel
