import React from 'react'
import { IntlProvider } from 'react-intl'

import { messages, locale } from './constants'

// TODO: make custom hook that returns simplified use of formatMessage
// to use inside component like this:
// const { fn } = useSimplifyIntl
// <span>{fm('pages.login.button.label')}</span>

// in front of current use:
// const intl = useIntl;
// <span>{intl.formatMessage({ id: 'pages.login.button.label })}</span>

export const I18NProvider = ({ children }) => (
  <IntlProvider locale={locale} messages={messages}>
    {children}
  </IntlProvider>
)
