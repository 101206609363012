import React, { createContext, useContext } from 'react'

import { useIPReducer } from '../hooks'

const IPDetailContext = createContext()

export const IPDetailProvider = ({ children }) => {
  const reducer = useIPReducer()
  return <IPDetailContext.Provider value={reducer}>{children}</IPDetailContext.Provider>
}

export const useIPDetail = () => useContext(IPDetailContext)
