import React from 'react'
import { useIntl } from 'react-intl'

import { BasicListItem } from './basic-list-item.component'

export const BasicList = ({ data, fields, combos }) => {
  const { formatDate, formatNumber } = useIntl()

  const getValueByType = (value, field, combos) => {
    switch (field.type) {
      case 'combo': {
        if (field.comboId && combos[field.comboId]?.data) {
          if (field.multiple) {
            let selectedOptionsValues = []
            value.forEach((key) => {
              const selectedOption = combos[field.comboId].data.find((option) => option.key === key)
              if (selectedOption) selectedOptionsValues.push(selectedOption.value)
            })
            return selectedOptionsValues.join(', ')
          } else {
            const selectedOption = combos[field.comboId].data.find((option) => option.key === value)
            if (selectedOption) {
              return selectedOption.value
            }
          }
        }
        break
      }
      case 'date': {
        return formatDate(new Date(value))
      }
      case 'currency': {
        return formatNumber(value, { style: 'currency', currency: field.currency })
      }
      case 'file': {
        return value.name
      }
      default:
        return value
    }
  }

  return fields.map((field) => {
    const value = data[field.key]
    return value ? (
      <BasicListItem
        {...{ key: field.key, label: field.label, value: getValueByType(value, field, combos) }}
      />
    ) : null
  })
}
