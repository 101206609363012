import React from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const WarningMssgDialog = ({ close, mssg = '', resetAction }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.consult.dialogs.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{mssg}</Typography>
      </DialogContent>
      <DialogActions>
        <Box width="100%" mt={2} display="flex" justifyContent="center">
          <ThemeButton
            labelM="md"
            onClick={() => {
              resetAction()
              close()
            }}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
