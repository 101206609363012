import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { getFormattedTime, dataToXLS } from '../../../../core/utils'
import {
  APPLICATION_TABLE_COLUMNS_KEY,
  APPS_TABLE_COLUMNS,
  GUILD_TABLE_COLUMNS,
} from '../../constants'
import { ExportNotEmptyDialog, ExportSomethingDialog } from '../dialogs'
import { useAjaxContext } from '../../../../core/ajax'
import { applicationDataPreparation } from '../../logics'

export const ExportToXLSBtn = () => {
  const intl = useIntl()
  const selectedRows = useSelector((state) => state.applications.selectedRows)
  const search = useSelector((state) => state.applications.search)
  const pagination = useSelector((state) => state.applications.pagination)
  const combos = useSelector((state) => state.combos)
  const [loading, setLoading] = useState(false)
  const { get } = useAjaxContext()
  const userRole = useSelector((state) => state.global.role)
  const URL_GUILD = '/solicitudes/tabla_gremios'
  const URL_INSTALLER = '/solicitudes/tabla_instaladores'
  const exportUrl = userRole === 'gremio' ? URL_GUILD : URL_INSTALLER
  const columns = userRole === 'gremio' ? GUILD_TABLE_COLUMNS : APPS_TABLE_COLUMNS
  const {
    actions: { open },
  } = useModalContext()

  const mainExport = (data) => {
    const fileName = `solicitudes_${getFormattedTime()}.xlsx`
    dataToXLS(data, columns, combos, intl, APPLICATION_TABLE_COLUMNS_KEY, fileName)
    setLoading(false)
  }

  const handleExport = async () => {
    if (selectedRows && selectedRows.length) {
      setLoading(true)
      const data = selectedRows
      mainExport(data)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  const handleExportAll = async () => {
    setLoading(true)
    const { _num_x_pagina, _pagina, ...otherSearch } = search
    const { total_solicitudes } = pagination
    if (!total_solicitudes) {
      open({
        Component: ExportNotEmptyDialog,
      })
      setLoading(false)
      return
    }
    get(exportUrl, {
      params: { ...otherSearch, _num_x_pagina: total_solicitudes, _pagina: '1' },
    })
      .then(({ data }) => {
        const recievedData = applicationDataPreparation(columns, data.solicitudes, { intl })
        mainExport(recievedData)
      })
      .catch(({ response }) => {
        console.debug('Error recuperando los datos de la exportación')
        setLoading(false)
      })
  }

  return (
    <>
      <ThemeButton
        color="primary"
        labelM="sm"
        onClick={handleExport}
        style={{ marginRight: '1em' }}
      >
        {intl.formatMessage({ id: 'global.exportSelected' })} ({selectedRows.length})
      </ThemeButton>
      <ThemeButton color="primary" labelM="sm" loading={loading} onClick={handleExportAll}>
        {intl.formatMessage({ id: 'global.exportAll' })}
      </ThemeButton>
    </>
  )
}
