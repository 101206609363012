import 'date-fns'
import esLocale from 'date-fns/locale/es'
import React from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Grid, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { filterDateStyles } from './filter-date.styles'

export const FilterDate = withStyles(filterDateStyles)(
  ({ classes, label, value, name, onChange, defaultValue, minDate, maxDate, ...props }) => {
    //Processing value, expecing inputs either as yyyy-MM-dd or dd/MM/yyyy
    //Since javascript date uses american (illogical) conventions, we need to transform to 'yyyy-MM-dd' to avoid confusion

    const changeDateFormat = (date) => {
      if (date) {
        let splitValue = date.split('/')
        if (splitValue.length === 3) {
          date = splitValue[2] + '-' + splitValue[1] + '-' + splitValue[0]
        }
      }
      return date
    }
    let realValue = value || defaultValue
    realValue = changeDateFormat(realValue)
    let realMinDate = minDate
    realMinDate = changeDateFormat(realMinDate)
    let realMaxDate = maxDate
    realMaxDate = changeDateFormat(realMaxDate)

    const minDateValue = realMinDate ? new Date(realMinDate) : undefined
    const maxDateValue = realMaxDate ? new Date(realMaxDate) : undefined
    //const objectDate = realValue ? new Date(realValue) : new Date()
    const objectDate = realValue ? new Date(realValue) : null
    const { formatDate } = useIntl()
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Grid container justifyContent="space-around" className={classes.filter}>
          {label && <div className={classes.label}>{label}</div>}
          <DatePicker
            className={classes.main}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            name={name}
            value={objectDate}
            onChange={(date) => onChange({ target: { name: name, value: formatDate(date) } })}
            emptyLabel="--"
            minDate={minDateValue}
            maxDate={maxDateValue}
            // KeyboardButtonProps={{
            //   ariaLabel: 'change date',
            // }}
            // okLabel={formatMessage({ id: 'picker.ok' })}
            // cancelLabel={formatMessage({ id: 'picker.cancel' })}
            {...props}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }
)

export default FilterDate
