import { JCA_INITIAL_STATE, JCA_ACTIONS_TYPES } from './jca-reducer.constants'

export const jcaReducer = (state = JCA_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case JCA_ACTIONS_TYPES.SET_JCA:
      return {
        ...state,
        jca: payload,
        loading: false,
        error: false,
      }
    case JCA_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case JCA_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      }
    default:
      throw new Error('unknown action')
  }
}
