const ADDRESS_FORM_MOCK = {
  cp_municipio: { cod_postal: '08880', municipio: 'CUBELLES', zona_nedgia: true },
  direccion: {
    id_municipio: '08074000110',
    id_provincia: '08',
    id_via: '72457',
    nombreProvincia: 'BARCELONA',
    nombreVia: 'CAMELIES',
    tipoVia: 'CALLE',
  },
  numero: {
    anexo: '',
    finca_gas: false,
    idfinca: 45000446521,
    numero: '30',
  },
  vivienda: {
    bloque: '',
    certificado_vigente: false,
    cidi: 45000752194,
    cups: null,
    dispone_gas: '',
    distancia_acometida: 26.92,
    escalera: '',
    estado: 'Potencial',
    estado_cups: '',
    id_municipio: '08074000110',
    id_provincia: '08',
    mercado: 'SH',
    motivo_no_captabilidad: '',
    piso: 'BJ',
    precio_instalacion_tuberia: 3394.12744,
    provincia: 'BARCELONA',
    puerta: '',
    tipo_calle: 'CALLE',
    tipo_vivienda: 'Unifamiliar',
    vivienda_gas: false,
  },
}

const ADDRESS_FORM_2_MOCK = {
  cp_municipio: { cod_postal: '29680', municipio: 'ESTEPONA', zona_nedgia: true },
  direccion: {
    id_municipio: '29051000000',
    id_provincia: '29',
    id_via: '634928',
    nombreProvincia: 'MALAGA',
    nombreVia: 'DOCTOR CRISTOBAL RUIZ MENDEZ',
    tipoVia: 'CALLE',
  },
  numero: {
    anexo: '3',
    finca_gas: true,
    idfinca: 10000221621,
    numero: '27',
  },
  vivienda: {
    bloque: '',
    certificado_vigente: false,
    cidi: 10001688184,
    cups: 'ES0218030100166173LF',
    dispone_gas: 'GAS_NATURAL',
    distancia_acometida: 0,
    escalera: '',
    estado: 'Certificado no vigente',
    estado_cups: 'contratable',
    id_municipio: '29051000000',
    id_provincia: '29',
    mercado: 'SV',
    motivo_no_captabilidad: '',
    piso: '1',
    precio_instalacion_tuberia: 0,
    provincia: 'MALAGA',
    puerta: 'A',
    tipo_calle: 'CALLE',
    tipo_vivienda: 'Plurifamiliar',
    vivienda_gas: false,
  },
}

const CONSULT_DATA_MOCK = {
  canContinueFlow: true,
  consumo_max_anual: '100.000,00 kWh/año',
  costes_canon_contador: 0.58,
  costes_canon_instalacion: 0,
  costes_canon_irc: 0,
  costes_de_derechos_de_acometida: 106.34,
  costes_de_derechos_de_alta: 63.51,
  cups: null,
  distancia_aproximada_de_la_red: 32.92,
  estado: 'Potencial',
  estado_cups: null,
  fecha_ultimo_cese: null,
  flowType: 'SCR1',
  importe_aproximado_extensión_de_red: 2805.0640000000003,
  latitude: 41.47577328,
  longitude: 2.28960876,
  mercado: 'SH',
  naturaleza_del_gas: null,
  presion_de_servicio: '150 mbar',
  tipo_de_gas: null,
}

const CONSULT_DATA_2_MOCK = {
  canContinueFlow: true,
  consumo_max_anual: '100.000,00 kWh/año',
  costes_canon_contador: 0.58,
  costes_canon_instalacion: 6.16,
  costes_canon_irc: 0,
  costes_de_derechos_de_acometida: 106.34,
  costes_de_derechos_de_alta: 59.5,
  cups: 'ES0218030100166173LF',
  distancia_aproximada_de_la_red: 4.25,
  estado: 'Certificado no vigente',
  estado_cups: 'CONTRATABLE',
  fecha_ultimo_cese: null,
  flowType: 'SCR2',
  importe_aproximado_extensión_de_red: 0,
  mercado: 'SV',
  naturaleza_del_gas: 'Seco',
  presion_de_servicio: '400 mbar',
  tipo_de_gas: 'Gas natural',
}

const SELECTED_HOME_MOCK = {
  anexo: '',
  bloque: '',
  certificado_vigente: false,
  cidi: 45000752194,
  cod_postal: '08880',
  cups: null,
  dispone_gas: '',
  distancia_acometida: 26.92,
  escalera: '',
  estado: 'Potencial',
  estado_cups: '',
  finca_gas: false,
  id_municipio: '08074000110',
  id_provincia: '08',
  idfinca: 45000446521,
  mercado: 'SH',
  motivo_no_captabilidad: '',
  municipio: 'CUBELLES',
  nombre_via: 'CAMELIES',
  numero: '30',
  piso: 'BJ',
  precio_instalacion_tuberia: 3394.12744,
  provincia: 'BARCELONA',
  puerta: '',
  tipo_calle: 'CALLE',
  tipo_vivienda: 'Unifamiliar',
  vivienda_gas: false,
  zona_nedgia: true,
}

const SELECTED_HOME_2_MOCK = {
  anexo: '3',
  bloque: '',
  certificado_vigente: false,
  cidi: 10001688184,
  cod_postal: '29680',
  cups: 'ES0218030100166173LF',
  dispone_gas: 'GAS_NATURAL',
  distancia_acometida: 0,
  escalera: '',
  estado: 'Certificado no vigente',
  estado_cups: 'contratable',
  finca_gas: true,
  id_municipio: '29051000000',
  id_provincia: '29',
  idfinca: 10000221621,
  mercado: 'SV',
  motivo_no_captabilidad: '',
  municipio: 'ESTEPONA',
  nombre_via: 'DOCTOR CRISTOBAL RUIZ MENDEZ',
  numero: '27',
  piso: '1',
  precio_instalacion_tuberia: 0,
  provincia: 'MALAGA',
  puerta: 'A',
  tipo_calle: 'CALLE',
  tipo_vivienda: 'Plurifamiliar',
  vivienda_gas: false,
  zona_nedgia: true,
}

const SELECTED_HOME_NN_MOCK = {
  bloque: '',
  cod_postal: '08880',
  escalera: '',
  municipio: 'CUBELLES',
  nombre_via: 'CAMELIES',
  numero: '30',
  piso: '',
  puerta: '',
  tipo_calle: 'CALLE',
  zona_nedgia: true,
}

const CUSTOMER_FORM_MOCK = {
  apellidos: 'Torrents',
  email: 'genis.torrents@basetis.com',
  identificador: '47849464B',
  nombre: 'Genís',
  telefono: '605584394',
  tipo_identificador: 'NIF',
  persona: 'F',
}

const EMPRESA_MOCK = {
  nombre: 'Basetis test',
  telefono: '666666666',
  empresa_ca_id: '1',
}

export const MOCK_NORMALIZED = {
  addressForm: ADDRESS_FORM_MOCK,
  consultData: CONSULT_DATA_MOCK,
  selectedHome: SELECTED_HOME_MOCK,
  customerForm: CUSTOMER_FORM_MOCK,
  empresa: EMPRESA_MOCK,
}

export const MOCK_NORMALIZED_2 = {
  addressForm: ADDRESS_FORM_2_MOCK,
  consultData: CONSULT_DATA_2_MOCK,
  selectedHome: SELECTED_HOME_2_MOCK,
  customerForm: CUSTOMER_FORM_MOCK,
  empresa: EMPRESA_MOCK,
}

export const MOCK_NN = {
  addressForm: {
    ...ADDRESS_FORM_MOCK,
    vivienda: { special: 'other' },
  },
  selectedHome: SELECTED_HOME_NN_MOCK,
  customerForm: CUSTOMER_FORM_MOCK,
  normalized: false,
  empresa: EMPRESA_MOCK,
}

/*if (
  formState.campanas.campana.descuento &&
  !formState.campanas.campana.ingreso &&
  formState.wasTransferencia &&
  !formState.wasFinanciacion
) {
  setDocs(['CCEE', 'CPI'])
} else if (
  formState.wasTransferencia &&
  formState.campanas.campana.ingreso &&
  !formState.wasFinanciacion
) {
  formState.campanas.campana.descuento
    ? setDocs(['CB', 'CCEE', 'CPI'])
    : setDocs(['CB', 'CCEE'])
} else if (
  formState.campanas.campana.financiacion &&
  formState.wasTransferencia &&
  !formState.wasFinanciacion
) {
  setDocs(['CCEE'])
} else if (
  formState.wasSubvencion &&
  formState.campanas.campana.ingreso &&
  !formState.wasFinanciacion
) {
  formState.campanas.campana.descuento
    ? setDocs(['CB', 'CCEE', 'CPI'])
    : setDocs(['CB', 'CCEE'])
} else if (
  formState.campanas.campana.descuento &&
  formState.wasSubvencion &&
  !formState.wasFinanciacion
) {
  setDocs(['CCEE'])
} else if (formState.financiacion && formState.wasSubvencion && !formState.wasFinanciacion) {
  setDocs(['CCEE'])
} else if (
  formState.wasFinanciacion &&
  formState.campanas.campana.descuento &&
  !formState.campanas.campana.ingreso
) {
  setDocs(['CCEE', 'CPI'])
} else if (formState.wasFinanciacion && formState.campanas.campana.ingreso) {
  formState.campanas.campana.descuento
    ? setDocs(['CB', 'CCEE', 'CPI'])
    : setDocs(['CB', 'CCEE'])
}*/
