import { useContext } from 'react'

import { CertificateAcceptanceDialog } from '../components'
import { ModalContext } from '../../../ui/modal/contexts'

export const useCertificatesDialogs = () => {
  const {
    actions: { open },
  } = useContext(ModalContext)

  const warnCertificateAcceptance = (proceed, EXTRA_INFO, fromModify) => {
    open({
      Component: CertificateAcceptanceDialog,
      data: {
        proceed,
        EXTRA_INFO,
        fromModify,
      },
      type: 'centered',
    })
  }

  return {
    warnCertificateAcceptance,
  }
}
