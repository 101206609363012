import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Box, Grid, Typography, Checkbox, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ADMIN_ROLES } from '../../../../core/constants'
import { SelectInput, ThemeButton } from '../../../../ui'
import { useCompanyGuildIP } from './company-guild-ip.hook'
import { companyGuildIPStyles } from './company-guild-ip.styles'
import { GUILD_IP_INITIAL_STATE, I18N_ROOT } from './constants'
import { companyGuildIPReducer } from './company-guild-ip.reducer'

export const CompanyGuildIP = withStyles(companyGuildIPStyles)(({ classes }) => {
  const [state, dispatch] = useReducer(companyGuildIPReducer, GUILD_IP_INITIAL_STATE)

  const { formatMessage: fm } = useIntl()
  const { selectGuild, getIPGuildsList, joinGuild, leaveGuild } = useCompanyGuildIP(state, dispatch)
  const { setMandatoryChecked, changeNotificationsCheck, setNotificationsCheck } =
    useCompanyGuildIP(state, dispatch)
  const { mandatoryChecked, selectedGuild, guildsList, error, notifLoading } = state
  const { notificationsChecked } = state
  const global = useSelector((state) => state.global)
  const isAdmin = ADMIN_ROLES[global.role]
  const company = global.empresa

  const stateValue = fm({
    id: `${I18N_ROOT}.fields.state.${company.aceptacion_gremio ? '1' : '2'}`,
  })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (!!company.gremio_ip) {
      leaveGuild()
    } else {
      joinGuild()
    }
  }

  const handleChangeNotificationsCheck = (e) => {
    const { checked } = e.target
    changeNotificationsCheck(checked)
  }

  const handleChangeMandatoryCheck = (e) => {
    const { checked } = e.target
    setMandatoryChecked(checked)
  }

  const handleSelectGuild = (e) => {
    const { value } = e.target
    selectGuild(value)
  }

  useEffect(() => {
    getIPGuildsList()
  }, [getIPGuildsList])

  useEffect(() => {
    setNotificationsCheck(company.recibir_emails_agremiado_ip3)
  }, [setNotificationsCheck, company.recibir_emails_agremiado_ip3])

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {fm({ id: `${I18N_ROOT}.title.${!company.gremio_ip ? '1' : '2'}` })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!company.gremio_ip ? (
            <SelectInput
              fullWidth
              title={fm({ id: I18N_ROOT + '.fields.guild.title' })}
              name="selectedGuild"
              value={selectedGuild}
              values={guildsList}
              onChange={handleSelectGuild}
              readOnly={!isAdmin}
            />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {fm({ id: I18N_ROOT + '.fields.name.title' })}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {company.gremio_ip.nombre}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {fm({ id: I18N_ROOT + '.fields.phone.title' })}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {company.gremio_ip.telefono}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {fm({ id: I18N_ROOT + '.fields.state.title' })}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {stateValue}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!company.gremio_ip ? (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="mandatoryCheckbox"
                checked={!!mandatoryChecked}
                disabled={!selectedGuild}
                onChange={handleChangeMandatoryCheck}
              />
              <Typography variant="body2" color="textSecondary">
                {fm({ id: I18N_ROOT + '.fields.mandatory.title' })}
              </Typography>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            {notifLoading ? (
              <Box p="0 11px">
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Checkbox
                name="notificationsChecked"
                checked={!!notificationsChecked}
                disabled={!(isAdmin && (selectedGuild || !!company.gremio_ip))}
                onChange={handleChangeNotificationsCheck}
              />
            )}
            <Typography variant="body2" color="textSecondary">
              {fm({ id: I18N_ROOT + '.fields.notifications.title' })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {error ? (
        <Box mt={2} mb={1}>
          <Alert severity="error">{error}</Alert>
        </Box>
      ) : null}
      {isAdmin ? (
        <Box display="flex" justifyContent="center" width="100%" mt={4}>
          <ThemeButton
            color="primary"
            disabled={!company.gremio_ip && !(selectedGuild && mandatoryChecked)}
            onClick={handleSubmitForm}
          >
            {fm({ id: `${I18N_ROOT}.btn.title.${!company.gremio_ip ? '1' : '2'}` })}
          </ThemeButton>
        </Box>
      ) : null}
    </Box>
  )
})
