import { APP_PATH_RULES } from '../constants/application-types.constants'

export const checkConsultAnswers = (estado, consulta, homeData) => {
  let flowType = 'none'
  for (let key in APP_PATH_RULES) {
    const path = APP_PATH_RULES[key]
    if (path.condition({ homeData, consulta })) {
      flowType = path.id
      break
    }
  }

  // const canContinueFlow = flowType !== 'none' && empresa.empresa_id && homeData['zona_nedgia']
  // if (!canContinueFlow) {
  //   console.debug('Data does not allow continuation:', homeData, empresa)
  // }

  return {
    ...consulta,
    estado,
    flowType,
    //canContinueFlow,
  }
}
