export const ROLES = {
  INSTALADOR_CAPTADOR: {
    KEY: 'instalador', // Visualización Captación
    DESC:
      'El tipo de perfil Visualización tendrá permisos de consulta de usuario, empresa y tarifa.',
  },
  ADMINISTRADOR_CAPTADOR: {
    KEY: 'administrador', // Gestión Captación
    DESC: 'El tipo de perfil Gestión tendrá permisos de consulta y edición.',
  },
  ADMINISTRADOR_GRABADOR: {
    KEY: 'administrador_grabador', // Gestión Inspecciones
    DESC:
      'El tipo de perfil Gestión IP tendrá permisos de creación, consulta y mantenimiento de IP’s y JCA’s así como mantenimiento de usuarios.',
  },
  ADMINISTRADOR_CAPTADOR_GRABADOR: {
    KEY: 'admin_captador_grabador', // Gestión Captación + Gestión Inspecciones
    DESC: 'Gestión + Gestión IP',
  },
}

export const ADMIN_ROLES = {
  [ROLES.ADMINISTRADOR_CAPTADOR.KEY]: true,
  [ROLES.ADMINISTRADOR_GRABADOR.KEY]: true,
  [ROLES.ADMINISTRADOR_CAPTADOR_GRABADOR.KEY]: true,
}

export const CAPTACION_ROLES = {
  [ROLES.INSTALADOR_CAPTADOR.KEY]: true,
  [ROLES.ADMINISTRADOR_CAPTADOR.KEY]: true,
  [ROLES.ADMINISTRADOR_CAPTADOR_GRABADOR.KEY]: true,
}

export const GRABACION_ROLES = {
  [ROLES.ADMINISTRADOR_GRABADOR.KEY]: true,
  [ROLES.ADMINISTRADOR_CAPTADOR_GRABADOR.KEY]: true,
}
