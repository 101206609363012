//From https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/ + transformation to street view
import React from 'react'
import MapsLoader from './maps-loader.class'

class Panoramic extends MapsLoader {
  onScriptLoad = () => {
    const pano = new window.google.maps.StreetViewPanorama(
      document.getElementById(this.props.id + '-pano'),
      this.props.options
    )

    window.google.maps.event.addListener(pano, 'position_changed', () => {
      const markerPosition = new window.google.maps.LatLng(
        this.props.options.position.lat,
        this.props.options.position.lng
      )
      const viewPosition = pano.getPosition()
      const currentHeading = window.google.maps.geometry.spherical.computeHeading(
        viewPosition,
        markerPosition
      )
      pano.pov.heading = currentHeading
    })

    this.props.onMapLoad(pano)
  }

  render() {
    return (
      <>
        <div style={{ width: '100%', height: '100%' }} id={this.props.id + '-pano'} />
      </>
    )
  }
}

export default Panoramic
