// export * as types from './types'
import * as types from './action-types.constants'

export { types }
export * from './address-form.constants'
export * from './results-view.constants'
export * from './customer-form.constants'
export * from './certificate-form.constants'
export * from './application-types.constants'
export * from './request-gas_p.constants'
export const REQUIRE_NUMBER = ['residencial', 'pymes', 'caldera_centralizada', 'gran_consumo']
