import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Box } from '@material-ui/core'

import { useModalContext } from '../../../../ui/modal/contexts'
import { ThemeButton, FilterBox } from '../../../../ui'
import { UsersCreateDialog } from '../dialog'
import { usersFilterStyles } from './users-filter.styles'
import { USERS_FILTERS } from '../../constants'
import { ADMIN_ROLES } from '../../../../core/constants'
import { CAPTACION_ROLES, GRABACION_ROLES } from '../../../../core/constants'

export const UsersFilter = withStyles(usersFilterStyles)(() => {
  const intl = useIntl(),
    { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const combos = useSelector((state) => state.combos)
  const { role } = useSelector((state) => state.global)
  const isCaptadorUser = CAPTACION_ROLES[role]
  const isGrabadorUser = GRABACION_ROLES[role]

  const canAddUsers = ADMIN_ROLES[role]

  const handleCreateUser = () =>
    open({
      Component: UsersCreateDialog,
      type: 'responsive',
      maxWidth: 'md',
    })

  const data = USERS_FILTERS.filter(
    // Remove rol filter if user is only grabador (because there is only one role within their rol combo)
    (item) => item.name !== 'rol' || isCaptadorUser
  ).map((item, i) => {
    if (item.comboId) {
      if (item.name === 'rol') {
        if (isCaptadorUser && isGrabadorUser) {
          item.values = combos.rol_captador_grabador.data
        } else if (isCaptadorUser) {
          item.values = combos.rol_captador.data
        }
      } else {
        item.values = combos[item.comboId] && combos[item.comboId].data
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.users.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox filters={data}>
      {canAddUsers && (
        <Box ml={2}>
          <ThemeButton color="primary" labelM="sm" onClick={handleCreateUser}>
            {intl.formatMessage({ id: 'pages.users.addButton.label' })}
          </ThemeButton>
        </Box>
      )}
    </FilterBox>
  )
})
