import { useIntl } from 'react-intl'

import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'

export const useCommunicationsCalls = () => {
  const { formatMessage } = useIntl()
  const { post, put } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const uploadDocument = (payload) =>
    new Promise((resolve, reject) => {
      post('/documento', payload, { timeout: 0 })
        .then((response) => resolve(response))
        .catch((response) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const postMessage = (payload) =>
    new Promise((resolve, reject) => {
      post('/comunicacion_interna', payload)
        .then((response) => resolve(response))
        .catch((response) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  const sendMessage = (appId, clientId, payload, file) =>
    new Promise((resolve, reject) => {
      if (file) {
        const document = {
          documento: file.data.split(',')[1],
          nombre: file.name.replace(/\.[^/.]+$/, ''),
          tipo: 'FC', //Necesito conocer el tipo de documento
          extension: file.type,
          cliente_id: parseInt(clientId),
          solicitud_id: appId.toString(),
          save_event: false,
        }
        uploadDocument(document)
          .then(({ data }) => {
            payload['documentos'] = [data['id']]
            postMessage(payload)
              .then(({ data }) => resolve(data))
              .catch((response) => {
                return reject(response)
              })
          })
          .catch((response) => {
            return reject(response)
          })
      } else {
        postMessage(payload)
          .then(({ data }) => resolve(data))
          .catch((response) => {
            return reject(response)
          })
      }
    })

  const readMessage = (payload) =>
    new Promise((resolve, reject) => {
      put('/comunicacion_interna/leer_comunicaciones', payload)
        .then((response) => resolve(response))
        .catch((response) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case '403': //Permisson denied by role
              return reject(response)
            default:
              defaultError()
          }
          return reject(response)
        })
    })

  return {
    sendMessage,
    readMessage,
  }
}
