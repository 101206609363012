import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { withStyles, Grid, Typography, Box, Button } from '@material-ui/core'

import { useSignupFormContext } from '../../contexts'
import { TextInput, FileUploaderAgnostic as FileUploader } from '../../../../ui'
import { WizardView } from '../wizard'
import { companyPublicFormStyles } from './company-public-form.styles'
import { CompanyFinance } from './company-public-finance.component'
import { TEXT_AREA_LENGTH, FILE_PROPS } from './constants'
import { feedback } from '../../../../core/feedback'

export const CompanyPublicFormComponent = withStyles(companyPublicFormStyles)(({ classes }) => {
  const intl = useIntl()
  const { formState, onChange } = useSignupFormContext()
  const combos = useSelector((state) => state.combos)

  const handleFileUploadError = (data) => feedback('error', data.mssg)

  const inputProps = { formState, onChange, combos, intl }

  return (
    <WizardView title="Configura tu empresa como quieres que la vea el cliente">
      <Grid container spacing={3}>
        {/*formState['tipo_actividad'] !== '03' ? (
          <CompanyFinance {...{ inputProps, initialValues: formState }} />
        ) : null */}
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Logo de la empresa (opcional)
            </Typography>
            <Box className={classes.uploadBox}>
              {formState['logo'] && (
                <Box display="flex" justifyContent="center">
                  <img
                    src={formState['logo'].data.split(',')}
                    alt="error"
                    style={{ maxHeight: 300, marginBottom: 10, objectFit: 'contain' }}
                  />
                </Box>
              )}
              <FileUploader
                name="logo"
                onSuccess={onChange}
                onError={handleFileUploadError}
                {...FILE_PROPS}
              >
                {(props) => (
                  <Button component="span" variant="outlined" style={{ marginBottom: 10 }}>
                    Seleccionar archivo
                  </Button>
                )}
              </FileUploader>
              <Typography variant="body2">{`Formato: ${FILE_PROPS.accept
                .map((item) => '.' + item.split('/')[1])
                .join(', ')} Tam. max: ${FILE_PROPS.maxSize / 1000000} Mb`}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            {...{
              required: true,
              value: formState['descripcion'],
              name: 'descripcion',
              title: 'Descripción de la empresa (pública)',
              placeholder: `Máx. ${TEXT_AREA_LENGTH} caracteres`,
              multiline: true,
              rows: 4,
              rowsMax: 4,
              inputProps: { maxLength: TEXT_AREA_LENGTH },
              onChange,
            }}
          />
        </Grid>
      </Grid>
    </WizardView>
  )
})
