import { useReducer } from 'react'

import { inspectionsReducer, inspectionsInitialState } from '.'
import { useInspectionsActions } from '../actions'

export const useInspections = () => {
  const [state, dispatch] = useReducer(inspectionsReducer, inspectionsInitialState)

  const actions = useInspectionsActions(state, dispatch)

  return {
    ...state,
    ...actions,
  }
}
