import { zonesActions } from '../constants'

/*Goals of our reducer:
- Easy access to province list and zip list per province, both of array type
- Maximal persistance of information while updating immutably
- Avoid innecessary calculations threading over arrays
Price to pay for it:
- Slight redundancy

The state will be like:
{ 
  comunidad: "Catalunya",
  zips: {
    Barcelona: [
      {activa: true, value: '08519'},
      ...
    ],
    ...
  },
  provincias: [
    {name: "Barcelona", chosen: 256, size: 400}, 
    ...
  ]
}
*/

export const zonesReducer = (state, { type, payload }) => {
  switch (type) {
    case zonesActions.SET_ZONES_DATA:
      const zips = {}
      return {
        comunidad: payload.comunidad,
        provincias: payload.codPostales.map((provincia) => {
          zips[provincia.prov] = provincia.cps
          return {
            name: provincia.prov,
            chosen: provincia.cps.filter((cp) => cp.activa).length,
            size: provincia.cps.length,
          }
        }),
        zips: zips,
      }
    case zonesActions.CHECK_SINGLE_ZIP:
      const newZips = {
        ...state.zips,
        [payload.provincia]: state.zips[payload.provincia].map((cp) =>
          cp.value !== payload.zip
            ? cp
            : {
                ...cp,
                activa: payload.newState,
              }
        ),
      }
      const newProvincia = state.provincias.map((provincia) =>
        provincia.name !== payload.provincia
          ? provincia
          : {
              ...provincia,
              chosen: provincia.chosen + (payload.newState ? 1 : -1),
            }
      )
      return {
        ...state,
        zips: newZips,
        provincias: newProvincia,
      }
    case zonesActions.CHECK_FULL_PROVINCE:
      return {
        ...state,
        zips: {
          ...state.zips,
          [payload.provincia]: state.zips[payload.provincia].map((cp) => ({
            ...cp,
            activa: payload.newState,
          })),
        },
        provincias: state.provincias.map((provincia) =>
          provincia.name !== payload.provincia
            ? provincia
            : {
                ...provincia,
                chosen: payload.newState ? provincia.size : 0,
              }
        ),
      }
    case zonesActions.CHECK_FULL_CC_AA:
      const newZips2 = {}
      for (let provincia in state.zips) {
        newZips2[provincia] = state.zips[provincia].map((cp) => ({
          ...cp,
          activa: payload.newState,
        }))
      }
      return {
        ...state,
        zips: newZips2,
        provincias: state.provincias.map((provincia) => ({
          ...provincia,
          chosen: payload.newState ? provincia.size : 0,
        })),
      }
    case zonesActions.CHECK_FROM_CODES:
      const keys = payload.keys || []
      const newZips3 = {}
      for (let provincia in state.zips) {
        newZips3[provincia] = state.zips[provincia].map((cp) =>
          keys.indexOf(cp.value) < 0
            ? cp
            : {
                ...cp,
                activa: payload.newState,
              }
        )
      }
      return {
        ...state,
        zips: newZips3,
        provincias: state.provincias.map((provincia) => ({
          ...provincia,
          chosen: newZips3[provincia.name].filter((cp) => cp.activa).length,
        })),
      }
    default:
      throw new Error('Unrecognized action')
  }
}
