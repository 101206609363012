import React from 'react'
import { Container } from '@material-ui/core'

import { PrivateAreaNavigationGuidePanel } from '../../../features/private-area-navigation-guide/components'

export const PrivateAreaNavigationGuidePage = () => {
  return (
    <div>
      <Container maxWidth="lg">
        <PrivateAreaNavigationGuidePanel />
      </Container>
    </div>
  )
}
