import React from 'react'

import { FoldingPanel } from '../folding-panel'
import { FormPanel } from '../form-panel'

export const FormFoldingPanel = (props) => {
  return props.block.foldable !== false ? (
    <FoldingPanel isExpanded={!props.block.isFolded} title={props.block.title}>
      <FormPanel {...props} />
    </FoldingPanel>
  ) : (
    <FormPanel {...props} />
  )
}

export default FormFoldingPanel
