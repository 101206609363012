import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, IconButton, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons//Close'

import { SelectInput } from '../../../../ui'
import { useInspectionsContext } from '../../contexts'
import { useCombos } from '../../../combos'
import { feedback } from '../../../../core/feedback'
import { getAnomaliesComboByResult } from './anomalies-input.logics'

export const AnomaliesInput = ({ address, addressIndex }) => {
  const { formatMessage } = useIntl()
  const { formFields, formState, type, changeAddress } = useInspectionsContext()
  const combos = useCombos([])
  const fieldKey = type === 'ip' ? 'anomalias' : 'solvedAnomalies'

  const [anomaliesComboData, setAnomaliesComboData] = useState([])

  const handleChangeAnomalies = (e, address, addressIndex) => {
    const value = anomaliesComboData.find((a) => a.key === e.target.value)
    if (value) {
      if (address[fieldKey]?.length) {
        if (address[fieldKey].some((a) => a.key === value.key)) {
          feedback(
            'warning',
            formatMessage({ id: 'pages.inspections.anomalies.autocomplete.feedback.1' })
          )
        } else {
          changeAddress(
            { target: { name: fieldKey, value: [...address[fieldKey], value] } },
            addressIndex
          )
        }
      } else {
        changeAddress({ target: { name: fieldKey, value: [value] } }, addressIndex)
      }
    }
  }

  const deleteAnomaly = (anomalyToDelete, addressIndex) => {
    const newAnomalies = address[fieldKey].filter((anomaly) => anomaly.key !== anomalyToDelete.key)
    changeAddress({ target: { name: fieldKey, value: newAnomalies } }, addressIndex)
  }

  const resetAddressAnomalies = (addressIndex) => {
    changeAddress({ target: { name: fieldKey, value: [] } }, addressIndex)
  }

  const updateAnomaliesComboByResult = () => {
    const anomalies = getAnomaliesComboByResult({ type, address, formState, combos })
    setAnomaliesComboData(anomalies)
  }

  useEffect(() => {
    updateAnomaliesComboByResult()
    resetAddressAnomalies(addressIndex)
  }, [address.resultado_inspeccion]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SelectInput
        {...{
          ...formFields.ANOMALIAS,
          values: anomaliesComboData,
          onChange: (e) => handleChangeAnomalies(e, address, addressIndex),
        }}
      />
      {address[fieldKey]?.length ? (
        <Box mt={1} mb={2}>
          {address[fieldKey].map((anomaly) => (
            <Box key={anomaly.key} display="flex" style={{ alignItems: 'center' }}>
              <Box p={1} flex={1} style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: 0 }}>
                <Tooltip title={anomaly.value}>
                  <Typography
                    variant="body2"
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {anomaly.value}
                  </Typography>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Suprimir">
                  <IconButton size="small" onClick={() => deleteAnomaly(anomaly, addressIndex)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  )
}
