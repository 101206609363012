export const compareArrays = (array1, array2) => {
    array1.sort()
    array2.sort()
    if(array1 instanceof Array && array2 instanceof Array && array1.length === array2.length){
     for( let i = 0; i < array1.length; i++){
         if(array1[i] !== array2[i]){
            return false
         }
     }
     return true
    }else {
        return false
    }
}