import { Component } from 'react'

class MapsLoader extends Component {
  onScriptLoad = () => {}

  awaitMapsReady = () => {
    setTimeout(() => {
      if (window.google) {
        this.onScriptLoad()
      } else this.awaitMapsReady()
    }, 30)
  }

  componentDidMount() {
    if (!window.google) {
      if (window.globalGoogleMapsHasBeenCalled) {
        this.awaitMapsReady()
        return
      }
      window.globalGoogleMapsHasBeenCalled = true
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=geometry`
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', (e) => {
        this.onScriptLoad()
      })
    } else {
      this.onScriptLoad()
    }
  }
}

export default MapsLoader
