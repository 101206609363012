import React from 'react'
import { useIntl } from 'react-intl'
import { Tooltip, Icon, withStyles } from '@material-ui/core'

import { fileSizeTransform } from '../logics/file-size-transform.logics'
import { uploadTooltipStyles } from './upload-tooltip.styles'

export const UploadTooltip = withStyles(uploadTooltipStyles)(({ classes, file, ...props }) => {
  const { formatMessage } = useIntl()

  if (!file) return null
  if (!file.accept && !file.maxSize) return null

  return (
    <Tooltip
      data-role="upload tooltip"
      title={
        <div data-role="upload info">
          <div className={classes.container}>
            <span className={classes.title}>
              {formatMessage({ id: 'documents.tooltip.maximumSize' })}
            </span>
            <span className={classes.description} data-role="size info">
              {fileSizeTransform(file.maxSize)}
            </span>
          </div>
          <div className={classes.container}>
            <span className={classes.title}>
              {formatMessage({ id: 'documents.tooltip.acceptedFiles' })}
            </span>
            {file.accept ? (
              <span className={classes.description} data-role="type info">
                {file.accept.map((type) => type.split('/').pop()).join(', ')}
              </span>
            ) : null}
          </div>
        </div>
      }
    >
      <span style={{ fontSize: 16, marginLeft: 4 }} data-role="upload help label">
        <Icon fontSize="inherit">help_outline</Icon>
      </span>
    </Tooltip>
  )
})
