import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { feedback } from '../../../core/feedback'
import { requests } from '../../../core/ajax'
import { loginUser } from '../../global'
import { USER_PROFILE_ACTION_TYPES } from '../constants'

export const useUserProfileActions = (state, dispatch) => {
  const reduxDispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const { formatMessage } = useIntl()
  const userId = useSelector((state) => state.global.user_id)
  const { getById, putById } = requests

  const getUserData = () =>
    new Promise((resolve, reject) =>
      getById('/instalador', userId)
        .then(({ data }) => {
          if (data.rol === 'gremio') {
            const { empresa_id, empresa, ...userData } = data
            dispatch({ type: USER_PROFILE_ACTION_TYPES.SET_USER, payload: userData })
            return resolve()
          } else if (data.rol === 'comercializadora') {
            const { empresa_id, empresa, gremio_id, gremio, ...userData } = data
            dispatch({ type: USER_PROFILE_ACTION_TYPES.SET_USER, payload: userData })
            return resolve()
          } else {
            if (!data['empresa_id']) {
              return resolve()
            }
            const { gremio_id, gremio, ...userData } = data
            getById('/empresa', data['empresa_id']).then(({ data: empresa }) => {
              const userDataPrepared = {
                ...userData,
                empresa: {
                  ...empresa,
                  ...userData['empresa'],
                  cc_aa: empresa.empresas_filiales?.map((el) => '' + el.comunidad_autonoma) || [],
                },
              }
              dispatch({ type: USER_PROFILE_ACTION_TYPES.SET_USER, payload: userDataPrepared })
              return resolve()
            })
          }
        })
        .catch(({ response }) => {
          dispatch({ type: USER_PROFILE_ACTION_TYPES.SET_FAILURE, payload: response.repsonse.data })
          return reject()
        })
    )

  const updateUser = (payload) =>
    new Promise((resolve, reject) =>
      putById('/instalador', userId, payload)
        .then(({ data }) => {
          const { gremio_id, gremio, ...userData } = data
          if (data.rol === 'gremio' || data.role === 'comercializadora') {
            return resolve(data)
          }
          getById('/empresa', data['empresa_id']).then(({ data: empresa }) => {
            const userDataPrepared = {
              ...userData,
              empresa: {
                ...empresa,
                ...userData['empresa'],
                cc_aa: empresa.empresas_filiales?.map((el) => '' + el.comunidad_autonoma) || [],
              },
            }
            dispatch({ type: USER_PROFILE_ACTION_TYPES.SET_USER, payload: userDataPrepared })
            if (data.captadora) {
              feedback('success', formatMessage({ id: 'pages.profile.user.feedback.1' }))
            }
            reduxDispatch(loginUser(global))
            return resolve(data)
          })
        })
        .catch(({ response }) => {
          let error = { message: formatMessage({ id: 'pages.profile.user.feedback.2' }) }
          if (response?.data?.code) {
            error = response.data
          }
          feedback('error', error.message)
          return reject(error)
        })
    )

  return { getUserData, updateUser }
}
