export const attachmentInputStyles = (theme) => ({
  btn: {
    height: 150,
    minWidth: 250,
    color: theme.palette.grey.A200,
    cursor: 'pointer',
    borderWidth: 2,
    borderColor: theme.palette.grey.A200,
    borderStyle: 'dashed',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 50,
    marginBottom: 10,
  },
  chipIcon: {
    backgroundColor: 'transparent',
  },
})
