import React, { useState } from 'react'
import { Dialog, Box, IconButton, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import { requests } from '../../../../core/ajax'
import { BasicTable, ThemeButton } from '../../../../ui'
import { IPInstallersForm } from './ip-installers-form.component'
import { INSTALLERS_FORM_CONFIG } from '../../constants'
import { feedback } from '../../../../core/feedback'

export const IPInstallersTable = ({ data = [], companyId }) => {
  const { post, putById, deleteById, getById } = requests
  const [open, setOpen] = useState(false)
  const [tableData, setTableData] = useState(data)
  const [currentRow, setCurrentRow] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getTableData = () => {
    getById('/empresa', companyId).then(({ data }) => {
      const sortedData = data.inspectores.sort(
        (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
      )
      setTableData(sortedData || [])
    })
  }

  const handleClose = () => {
    setCurrentRow({})
    setOpen(false)
  }

  const handleSubmit = (_, data) => {
    setIsLoading(true)
    if (data.id) {
      putById('/inspectores', data.id, data)
        .then(() => {
          getTableData()
          handleClose()
          setIsLoading(false)
        })
        .catch((response) => {
          if (response?.response?.data?.message) {
            setIsLoading(false)
            feedback('error', response.response.data.message)
          }
        })
    } else {
      const payload = { ...data, empresa_id: companyId }
      post('/inspectores', payload)
        .then(() => {
          getTableData()
          handleClose()
          setIsLoading(false)
        })
        .catch((response) => {
          if (response?.response?.data?.message) {
            setIsLoading(false)
            feedback('error', response.response.data.message)
          }
        })
    }
  }

  const handleOpenModal = (row) => {
    setCurrentRow(row || [])
    setOpen(true)
  }

  const handleDelete = (row) => {
    deleteById('/inspectores', row.id)
      .then(() => {
        getTableData()
        feedback('success', 'Instalador eliminado')
      })
      .catch(({ response }) => {
        feedback('error', response.data.message || 'No se ha podido eliminar el instalador')
        console.error('Error deleting inspector')
      })
  }

  const IPInstallersTableActions = ({ value, context, row }) => {
    const useActionsStyles = makeStyles((theme) => ({
      actionButton: {
        borderRadius: '100%',
        border: '1px solid #333',
        height: '1.7em',
        width: '1.7em',
      },
      actionRow: {
        display: 'flex',
        justifyContent: 'space-around',
      },
    }))

    const classes = useActionsStyles()

    return (
      <Box className={classes.actionRow}>
        <IconButton
          className={classes.actionButton}
          key="download"
          size="small"
          onClick={() => handleOpenModal(row)}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          className={classes.actionButton}
          key="delete"
          size="small"
          onClick={() => handleDelete(row)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    )
  }

  const INSTALLERS_TABLE_CONFIG = [
    { key: 'nombre', label: 'Nombre completo' },
    { key: 'identificador', label: 'NIF/NIE' },
    { key: 'categoria', label: 'Categoría' },
    { key: 'codigo', label: 'Código Instalador' },
    {
      key: 'id',
      label: 'Acciones',
      type: 'component',
      component: IPInstallersTableActions,
    },
  ]

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="flex-end">
        <ThemeButton onClick={handleOpenModal}>Añadir</ThemeButton>
      </Box>
      <BasicTable
        {...{
          data: tableData,
          config: INSTALLERS_TABLE_CONFIG,
        }}
      />
      <Dialog open={open} maxWidth="xs" fullWidth>
        <Box p={3}>
          <IPInstallersForm
            {...{
              onSubmit: handleSubmit,
              onClose: handleClose,
              fields: INSTALLERS_FORM_CONFIG,
              data: currentRow,
              tableData,
              isLoading,
            }}
          />
        </Box>
      </Dialog>
    </Box>
  )
}
