import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Box } from '@material-ui/core'

import { useSignupFormContext } from '../../contexts'
import { ThemeButton, BasicTable, BasicList } from '../../../../ui'
import { WizardView, useWizardContext } from '../wizard'
import { useCombos } from '../../../combos'
import { INSTALLERS_TABLE_CONFIG } from '../../constants'
import { ALL_LIST_ITEMS, IP_LIST_ITEMS } from './constants'

const PublicCompany = () => {
  const { formState } = useSignupFormContext()
  const { setStep } = useWizardContext()
  const tipo_actividad = useSelector((state) => state.combos.tipo_actividad.data)

  const handleEdit = () => setStep(2)

  return (
    <Box border="1px solid" borderRadius={4} borderColor="grey.400" bgcolor="grey.100" p={3}>
      <Box display="flex" mb={3} flexWrap="wrap">
        {formState['logo'] && (
          <Box width={180} mr={3}>
            <img
              src={formState['logo'].data.split(',')}
              alt="error"
              style={{ width: '100%', objectFit: 'contain' }}
            />
          </Box>
        )}
        <Box>
          <Box mb={1}>
            <Typography variant="subtitle1" paragraph style={{ textTransform: 'uppercase' }}>
              {formState['nombre']}
            </Typography>
          </Box>
          {formState['telefono'] && (
            <Box display="flex" mb={1}>
              <Typography variant="body1" color="textSecondary" style={{ marginRight: 6 }}>
                Teléfono:
              </Typography>
              <Typography variant="body1">{formState['telefono']}</Typography>
            </Box>
          )}
          {formState['web'] && (
            <Box display="flex" mb={1}>
              <Typography variant="body1" color="textSecondary" style={{ marginRight: 6 }}>
                Web:
              </Typography>
              <Typography variant="body1">{formState['web']}</Typography>
            </Box>
          )}
          {formState['email'] && (
            <Box display="flex" mb={1}>
              <Typography variant="body1" color="textSecondary" style={{ marginRight: 6 }}>
                Email contacto:
              </Typography>
              <Typography variant="body1">{formState['email']}</Typography>
            </Box>
          )}
          {formState['tipo_actividad'] && (
            <Box display="flex" mb={1}>
              <Typography variant="body1" color="textSecondary" style={{ marginRight: 6 }}>
                Tipo de empresa:
              </Typography>
              <Typography variant="body1">
                {tipo_actividad.find((el) => el.key === formState['tipo_actividad']).value}
              </Typography>
            </Box>
          )}
          {formState['tipo_actividad'] === '01' &&
            formState.publica === '01' &&
            formState['acepta_financiacion'] && (
              <Box display="flex">
                <Typography variant="body1" color="textSecondary" style={{ marginRight: 6 }}>
                  Ofrece financiación:
                </Typography>
                <Typography variant="body1">
                  {formState['acepta_financiacion'] === '01' ? 'Sí' : 'No'}
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
      <Box mb={3}>
        <Typography variant="body1" gutterBottom>
          {formState['descripcion']}
        </Typography>
      </Box>
      <Box textAlign="center">
        <ThemeButton labelM="lg" variant="outlined" onClick={handleEdit}>
          Editar
        </ThemeButton>
      </Box>
    </Box>
  )
}

export const CompanySummaryComponent = () => {
  const { formState, onSubmit, isLoading, installerWorks } = useSignupFormContext()
  const combos = useCombos(['tipo_actividad', 'binary_values'])

  const companyListConfig =
    installerWorks.catchment && installerWorks.ip ? ALL_LIST_ITEMS(formState) : IP_LIST_ITEMS

  const subtitle =
    installerWorks.catchment && !installerWorks.ip
      ? 'Este es el perfil que verán los clientes. Si es correcto, clica a aceptar, si quieres modificarlo, clica a editar.'
      : 'Este es el resumen de los datos introducidos. Si es correcto, clica en Aceptar, si quieres modificar algo, clica en Atrás.'

  return (
    <WizardView title="Resumen" subtitle={subtitle} onNext={onSubmit} isLoading={isLoading}>
      {installerWorks.ip ? (
        <Box mb={3}>
          <Typography variant="h6" color="primary" paragraph>
            Datos de la empresa
          </Typography>
          <BasicList {...{ data: formState, fields: companyListConfig, combos }} />
        </Box>
      ) : null}
      {installerWorks.ip && formState.inspectores?.length ? (
        <Box mb={3}>
          <Typography variant="h6" color="primary" paragraph>
            Listado de Instaladores
          </Typography>
          <BasicTable
            {...{ data: formState.inspectores, config: INSTALLERS_TABLE_CONFIG, size: 'small' }}
          />
        </Box>
      ) : null}
      {installerWorks.catchment && formState.publica === '01' ? (
        <>
          <Typography variant="h6" color="primary" paragraph>
            Perfil público
          </Typography>
          <PublicCompany />
        </>
      ) : null}
    </WizardView>
  )
}
