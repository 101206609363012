import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useGuildsCalls = () => {
  const { get, put, putById, deleteCall, deleteById } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getGuildList = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get(`/instalador/gremios`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const joinGuild = useCallback((id_empresa, id_guild, body) => {
    return new Promise((resolve, reject) => {
      putById(`/empresa_ca/${id_empresa}/asociar`, id_guild, body)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const leaveGuild = useCallback((id_empresa, id_guild) => {
    return new Promise((resolve, reject) => {
      deleteById(`/empresa_ca/${id_empresa}/asociar`, id_guild)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGuildsApps = useCallback(
    (config, gremio_id) =>
      new Promise((resolve, reject) => {
        get(`/gremios/${gremio_id}/empresas`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putAcceptCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        put(`/gremios/${gremio_id}/empresas/${id_empresa_ca}`)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.guilds.error.put.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const deleteGuildCompany = useCallback(
    (gremio_id, id_empresa_ca) =>
      new Promise((resolve, reject) => {
        deleteCall(`/gremios/${gremio_id}/empresas/${id_empresa_ca}`)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.guilds.error.delete.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getGuildList,
    joinGuild,
    leaveGuild,
    getGuildsApps,
    putAcceptCompany,
    deleteGuildCompany,
  }
}
