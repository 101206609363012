import { GUILDS_ACTIONS_TYPES } from './guilds-reducer.constants'

export const guildsReducer = (state, { type, payload }) => {
  switch (type) {
    case GUILDS_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case GUILDS_ACTIONS_TYPES.GUILDS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case GUILDS_ACTIONS_TYPES.GUILDS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
        msLoaded: true,
      }
    case GUILDS_ACTIONS_TYPES.UPDATE_GUILDS_COMPANY:
      return {
        ...state,
        loading: false,
        data: state.data.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
      }
    case GUILDS_ACTIONS_TYPES.DELETE_GUILDS_COMPANY:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item.id !== payload.id),
        pagination: {
          ...state.pagination,
          total_solicitudes: state.pagination.total_solicitudes - 1,
        },
      }
    default:
      throw new Error('unknown action')
  }
}
