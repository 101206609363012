import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { setApplications } from '../../slices'
import { AppsTable } from '../table'
import { AppsFilter } from '../filter'
import { applicationsDashboardStyles } from './applications-dashboard.styles'
import { APPS_DEFAULT_QUERY } from '../../constants'

export const ApplicationsDashboard = withStyles(applicationsDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { role } = useSelector((state) => state.global)

  //Acquire url and parse its search options
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...APPS_DEFAULT_QUERY,
    ...urlSearchParams
  }

  useEffect(() => {
    if (role) dispatch(setApplications(searchParams, role))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container className={classes.container} maxWidth="xl">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.applications.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <AppsFilter />
        </Box>
        <Box>
          <AppsTable />
        </Box>
      </Container>
    </Box>
  )
})
