//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const BOILERS_TABLE_COLUMNS = [
  {
    field: 'cidi',
    align: 'right',
    width: 300,
  },
  {
    field: 'codigo_postal',
    align: 'left',
    width: 500,
  },
  {
    field: 'nombre_via',
    align: 'left',
    width: 300,
  },
  {
    field: 'combustible_cc',
    align: 'left',
    width: 300,
  },
  {
    field: 'servicio',
    align: 'left',
    width: 300,
  },
  {
    field: 'total_viviendas',
    align: 'left',
    width: 300,
  },
  {
    field: 'rango',
    align: 'left',
    width: 300,
  },
]
