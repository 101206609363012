import React from 'react'
import { withStyles, Box, Typography, Grid, Button } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { privateAreaNavigatioNGuidePanelStyles } from './private-area-navigation-guide-panel.style'
import {
  TABLET_IMG,
  privateAreaGuideArray,
} from '../../constants/privateAreaNavigationGuide.constants'

export const PrivateAreaNavigationGuidePanel = withStyles(privateAreaNavigatioNGuidePanelStyles)(
  ({ classes }) => {
    const intl = useIntl()

    const openLinkHandler = (link) => {
      window.open(link)
    }

    const handlePrintList = () =>
      privateAreaGuideArray.map((item, i) => {
        return (
          <Grid key={i} item lg={4} className={classes.guideItem}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Typography style={{ fontWeight: 'bold' }} variant="h4" color="primary">
                {intl.formatMessage({ id: `pages.privateAreaNavigationGuide.${item.title}` })}
              </Typography>
              <Typography style={{ fontSize: '1.5em' }} color="primary">
                {intl.formatMessage({ id: `pages.privateAreaNavigationGuide.${item.description}` })}
              </Typography>
            </Box>
            <Box width="100%">
              <Button
                onClick={() => openLinkHandler(item.url)}
                color="primary"
                style={{ fontSize: '1.2em', padding: '0em 2em', marginBottom: '1em' }}
                variant="contained"
              >
                {intl.formatMessage({ id: 'pages.privateAreaNavigationGuide.checkGuide' })}
              </Button>
              <Box className={classes.guideItemSeparator}></Box>
            </Box>
          </Grid>
        )
      })

    return (
      <Box
        display="flex"
        bgcolor="rgb(255,255,255)"
        flexDirection="column"
        alignItems="center"
        borderRadius={20}
        mt={2}
        mb={2}
        style={{ padding: '4em 5em' }}
      >
        <Grid container className={classes.headTitleContainer}>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }} lg={4}>
            <Box>
              <Typography style={{ fontWeight: 'bold' }} variant="h3" color="primary">
                {intl.formatMessage({ id: 'pages.privateAreaNavigationGuide.headLine' })}
              </Typography>

              <Box mt={5}>
                <Typography variant="h3" color="primary">
                  {intl.formatMessage({ id: 'pages.privateAreaNavigationGuide.subHeadLine' })}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item style={{ display: 'flex', justifyContent: 'center' }} lg={4}>
            <Box mt={2}>
              <img className={classes.img} src={TABLET_IMG} width="300" alt="" />
            </Box>
          </Grid>

          <Grid item style={{ display: 'flex', justifyContent: 'center' }} lg={4}>
            <Button
              className={classes.mainButton}
              variant="contained"
              onClick={() =>
                openLinkHandler(
                  'https://www.nedgia.es/colaboradores/wp-content/uploads/sites/4/2022/08/MANUAL-COMPLETO-AP.pdf'
                )
              }
            >
              {intl.formatMessage({ id: 'pages.privateAreaNavigationGuide.completeGuide' })}
            </Button>
          </Grid>
        </Grid>

        <Box mt={4} mb={4} alignSelf="start">
          <Typography variant="h3" color="primary" style={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'pages.privateAreaNavigationGuide.guidesByThemes' })}
          </Typography>
        </Box>
        <Grid spacing={5} container alignItems="flex-start">
          {handlePrintList()}
        </Grid>
      </Box>
    )
  }
)
