import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { withStyles, Typography, Box, Container, CircularProgress } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import { useModalContext } from '../../../../ui/modal/contexts'
import { useAjaxContext } from '../../../../core/ajax'
import { ThemeButton, GridRow } from '../../../../ui'
import { MarketerUsersEditDialog } from '../dialog'
import { USERS_DETAIL_ITEMS } from '../../constants'
import { MarketerUserStateButtonComponent } from './marketer-user-state-button.component'
import { marketerUsersDetailStyles } from './marketer-users-detail.styles'

export const MarketerUsersDetailComponent = withStyles(marketerUsersDetailStyles)(({ classes }) => {
  const history = useHistory()
  const { getById } = useAjaxContext()
  const { userId } = useParams()
  const global = useSelector((state) => state.global)
  const {
    actions: { open },
  } = useModalContext()

  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getUserData(userId, setUser)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserData = (id) => {
    getById('instalador', id)
      .then(({ data }) => {
        const { gremio_id, gremio, ...userData } = data //Destructuring - properties related to guild it is not valid.
        setUser(userData)
        setIsLoading(false)
      })
      .catch(() => {
        console.error('Error fetching instalador data')
        // setIsLoading(false)
      })
  }

  const handleGoBack = () => history.push(`/marketerUsers`)

  const handleEditUser = () =>
    open({
      Component: MarketerUsersEditDialog,
      data: { user, setUser, getUserData },
      type: 'responsive',
      maxWidth: 'md',
    })

  return isLoading ? (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Box mb={3}>
          <div className={classes.return} onClick={handleGoBack}>
            <ArrowBackIosIcon fontSize="inherit" />
            Volver al listado
          </div>
          <Box bgcolor="common.white" borderRadius={6} p={6} pt={3}>
            <Typography variant="h4" color="textSecondary">
              Detalle del usuario
            </Typography>
            {global.role && (
              <Box display="flex" mt={2} mb={2}>
                {userId !== global.user_id && (
                  <Box width={160} mr={4}>
                    <MarketerUserStateButtonComponent data={user} setData={setUser} />
                  </Box>
                )}
                <Box width={160}>
                  <ThemeButton color="primary" fullWidth onClick={handleEditUser}>
                    Editar
                  </ThemeButton>
                </Box>
              </Box>
            )}
            <Box bgcolor="grey.200" borderRadius={4} p={[2, 4]} mt={3}>
              <GridRow items={USERS_DETAIL_ITEMS} data={user} />
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
})
