import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'

import { setPotential } from '../../slices'
import { MarketerPotentialTable } from '../table'
import { MarketerPotentialFilter } from '../filter'
import { marketerPotentialDashboardStyles } from './marketer-potential-dashboard.styles'
import { POTENTIAL_DEFAULT_QUERY } from '../../constants'

export const MarketerPotentialDashboard = withStyles(marketerPotentialDashboardStyles)(
  ({ classes }) => {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const marketerId = useSelector((state) => state.global.comercializadora_id)
    //Acquire url and parse its search options
    const url = useLocation().search
    const urlSearchParams = parse(url.split('?')[1])
    const searchParams = {
      ...POTENTIAL_DEFAULT_QUERY,
      ...urlSearchParams,
    }
    useEffect(() => {
      dispatch(setPotential(searchParams, marketerId))
    }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box>
        <Container className={classes.container} maxWidth="lg">
          <Box mb={2}>
            <Typography variant="h4" color="textSecondary">
              {formatMessage({ id: 'pages.potential.title' })}
            </Typography>
          </Box>
          <Box mb={2}>
            <MarketerPotentialFilter />
          </Box>
          <Box>
            <MarketerPotentialTable />
          </Box>
        </Container>
      </Box>
    )
  }
)
