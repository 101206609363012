export const profileStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '40px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
})
