import React from 'react'
import { Typography, Box } from '@material-ui/core'

export const BasicListItem = ({ label, value }) => {
  return (
    <Box mb={1}>
      <Typography
        display="inline"
        variant="body1"
        color="textSecondary"
        style={{ marginRight: 10 }}
      >
        {label}:
      </Typography>
      <Typography display="inline" variant="body1">
        {value}
      </Typography>
    </Box>
  )
}
